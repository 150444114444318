<template>
    <kc-modal modal-id="new-payout-item" :modal-backdrop="'static'" :modal-keyboard="false">
        <div class="modal-header">
            <h4 class="modal-title">Add Item</h4>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
        </div>
        <form @submit.prevent="submitForm">
            <div class="modal-body">
                <div class="mb-3">
                    <label class="form-label">Session</label>
                    <vue-select :options="sessionOptions" @search="onSearchSession" v-model="selectedSession" label="no" />
                </div>
                <div class="mb-3">
                    <label class="form-label">Type</label>
                    <select v-model="selectedRateAmountId" name="selectedTypeId" class="form-control form-select">
                        <option v-for="rateAmount in sessionRateAmounts" :key="rateAmount.id" :value="rateAmount.id">{{ rateAmount.name }}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">Description</label>
                    <input v-model="description" class="form-control form-control" name="description" type="text">
                </div>
                <div class="mb-3">
                    <label class="form-label">Unit</label>
                    <input v-model="unit" class="form-control form-control" name="unit" type="number">
                </div>
                <div class="mb-3">
                    <label class="form-label">Amount (RM)</label>
                    <input v-model="amount" class="form-control form-control" name="unit" type="number">
                </div>
                <div class="mb-3">
                    <label class="form-label">Remark</label>
                    <textarea v-model="remark" class="form-control" name="remark" rows="3"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-light" type="button" @click="closeModal">Cancel</button>
                <button class="btn btn-primary" type="submit" :disabled="isSavingItem">
                    <span class="spinner-border spinner-border-sm me-2" v-if="isSavingItem"></span>
                    <span>{{ isSavingItem ? 'Saving...' : 'Save' }}</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import CarerPayoutItemService from '@/services/CarerPayoutItemService'
import SessionService from '@/services/SessionService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import CarerPayoutItem from '@/types/CarerPayoutItem'
import { SessionRateAmount } from '@/types/formData/SessionRateAmount'
import Session from '@/types/Session'
import Modal from 'bootstrap/js/dist/modal'
import _ from 'lodash/fp'
import { storeToRefs } from 'pinia'
import { onMounted, ref, toRefs, watch } from 'vue'


const { addToastNotification } = useNotificationsStore()
const resourcesStore = useResourcesStore()
const { sessionRateAmounts } = storeToRefs(resourcesStore)

const sessionOptions = ref<Array<Session>>([])
const selectedSession = ref<Session>()
const selectedRateAmountId = ref<number>()
const unit = ref<number>(1)
const description = ref<string>('')
const amount = ref<number>(0)
const remark = ref<string>('')
const isSavingItem = ref<boolean>(false)

const props = defineProps({
    isEdit: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    itemId: { type: Number, required: false, default: 0 },
    recipientId: { type: Number, required: true },
    item: { type: Object as () => CarerPayoutItem|null, required: true },
})

const { item, isEdit } = toRefs(props)

const emit = defineEmits(['closeModal', 'refreshPayoutItems'])

let modal: any = null
onMounted(() => {
    modal = new Modal('#new-payout-item')
})

const onSearchSession = ((search: string, loading: Function) => {
    if (search.length) {
        searchSession(search, loading)
    }
})

const searchSession = _.debounce(350, (search, loading) => {
    loading(true)
    
    const query = { perPage: 10, page: 1, session_no: search }
    
    SessionService.index(query)
        .then(({ data: { data } }) => {
            sessionOptions.value = data.map((session: Session) => {
                return {
                    id: session.id,
                    no: session.no
                }
            })
        })
        .catch(() => {})
        .finally(() => loading(false))
})

const initForm = () => {
    selectedSession.value = item.value?.session
    selectedRateAmountId.value = item.value?.sessionRateAmountId
    description.value = item.value?.description ?? ''
    unit.value = item.value?.unit ?? 1
    remark.value = item.value?.remark ?? ''
}

const openModal = () => {
    initForm()
    modal.show()
}

const closeModal = () => {
    emit('closeModal')
    modal.hide()
}

const submitForm = () => {
    isSavingItem.value = true
    const data: any = {
        session_id: selectedSession.value?.id,
        description: description.value,
        carer_payout_recipient_id: props.recipientId,
        session_rate_amount_id: selectedRateAmountId.value,
        unit: unit.value,
        amount: amount.value * 100,
        remark: remark.value
    }

    let payoutItemService = isEdit.value ? CarerPayoutItemService.update(props.itemId, data) : CarerPayoutItemService.store(data)

    payoutItemService
        .then(({ data: { data } }) => {
            addToastNotification({ message: `Payout item ${ isEdit ? 'updated' : 'added' } successfully`, type: 'success' })
            emit('closeModal')
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team if error persists.',
                type: 'danger'
            })
        })
        .finally(() => {
            emit('refreshPayoutItems')
            isSavingItem.value = false
        })
}

watch(() => props.isOpen, (isOpen) => {
    isOpen ? openModal() : closeModal()
})

watch(selectedRateAmountId, (currentRateAmountId) => {
    const rateAmount = sessionRateAmounts.value.find((findRateAmount: SessionRateAmount) => findRateAmount.id === currentRateAmountId)
    if (rateAmount) {
        if (rateAmount.metadata?.hasOwnProperty('hour')) {
            unit.value = rateAmount.metadata.hour
        } else if (rateAmount.metadata?.hasOwnProperty('count')) {
            unit.value = rateAmount.metadata.count
        } else {
            unit.value = 1
        }

        amount.value = rateAmount.payAmount / 100
    } else {
        amount.value = 0
        unit.value = 1
    }
})

</script>
