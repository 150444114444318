<template>

    <div v-if="$slots.action"
         role="button"
         @click="openModal"
    >
        <slot name="action"></slot>
    </div>

    <template v-else>
        <button class="btn btn-icon btn-green "
                @click="openModal()"
        >
            <icon name="brand-whatsapp"/>
        </button>
    </template>

    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="lg">

            <div class="modal-header">
                <h4 class="modal-title">
                    Whatsapp Template: Order {{ order.no }}
                </h4>
                <button type="button" class="btn-close" aria-label="Close"
                        @click="openModal(false)"
                ></button>
            </div>

            <div class="modal-body bg-white">

                <pre ref="orderWhatsappTemplate" style="white-space: pre-wrap;">
Good morning afternoon evening Mr/Mrs {{ order.user.mainProfile?.fullName }}. We are from Kiddocare. We have received your booking as shown below:

User ID: **{{ order.user.mainProfile?.onlineId ?? 'N/A' }}**
Order no: **{{ order.no ?? 'N/A' }}**
<span v-for="(session, index) in order.sessions" :key="index">
Session no: **{{ session.no ?? 'N/A' }}**
Date: **{{ session.startedAtDate }} ({{ session.startedAtDay }})**
Time: {{ session.startedAtTime }} - {{ session.endedAtTime }}
Duration: **{{ session.totalHours }} hours**
Address: {{ getFullAddress(session.address, addressStates, postcodes) }}
Assigned Carer: **{{ session.carer?.mainProfile?.fullName ?? 'Finding Carer' }}**

**No of dependents - {{ session.dependents.length }}**
<span v-for="(dependent, index) in session.dependents" :key="index">
Child {{ index + 1 }}: {{ dependent.name }} ({{ dependent.gender === '1' ? 'Boy' : 'Girl' }})
Age: {{ toAge(dependent.birthDate) }}
Medical Conditions: {{ dependent.medical }}
Allergies: {{ dependent.allergies }}
Language: {{ dependent.languages.map((language: Language) => language.name).join(', ') }}
Religion: {{ religions.find((findReligion: Religion) => findReligion.id === dependent.religionId)?.name }}
</span>
**Additional Info**
Adult Presence: {{ session.metadata?.adult_details ?? '-' }}
Pets: {{ session.metadata?.pet_details ?? '-' }}
Special Requirements: {{ session.metadata?.other_details ?? '-' }}
</span>
Please review and confirm details above
                </pre>
            </div>

            <div class="modal-footer pt-2 border-top">
                <button :id="`${modalId}-close-button`"
                        class="btn btn-light" type="button"
                        @click="openModal(false)"
                >Close
                </button>
                <CopyButton :data="orderWhatsappTemplate?.innerText ?? ''"
                            resource-name="Whatsapp Template"
                            label="Copy"
                />
            </div>
        </kc-modal>
    </Teleport>

</template>

<script setup lang="ts">
import {toAge} from '@/composable/useDate'
import {getFullAddress} from '@/composable/useSession'
import {useResourcesStore} from '@/stores/resources'
import Language from '@/types/Language'
import Order from '@/types/Order'
import Religion from '@/types/Religion'
import {storeToRefs} from 'pinia'
import CopyButton from '@/components/CopyButton.vue'
import {useModal} from '@/composable/useModal'
import {ref} from 'vue'

const {religions, addressStates, postcodes} = storeToRefs(useResourcesStore())

const props = defineProps({
    order: {type: Object as () => Order, default: {}},
    display: {type: String}
})

let {
    modalId,
    openModal,
    onModalShown,
    onModalHidden
} = useModal(`order-whatsapp-template-${ props.order?.no }-modal`)

const orderWhatsappTemplate = ref<HTMLElement | null>(null)

</script>
