<template>
    <!-- ERROR MESSAGES -->
    <div v-if="errors.session_ids"
         class="mt-4 pt-3 px-3 text-danger border border-danger rounded"
    >
        <p class="text-uppercase">ERRORS</p>
        <ul class="mt-1">
            <li v-for="error in errors.session_ids"
            >
                {{error}}
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">

import {useCreateOrderStore} from '@/stores/createOrder'
import {storeToRefs} from 'pinia'

const createOrderStore = useCreateOrderStore()
const {errors} = storeToRefs(createOrderStore)

</script>