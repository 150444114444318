<template>
    <div class="card">
        <div class="card-header">
            <div class="ms-auto">
                <button class="btn btn-primary float-right" @click.prevent="addQuestion">
                    <icon name="plus" />
                    Add New Question
                </button>
            </div>
        </div>
        
        <form @submit.prevent="saveReviewQuestion">
            <div class="card-body">
                <overlay-spinner :is-showing="isFetchingReviewQuestions" />
                <ul class="list-unstyled border rounded">
                    <li class="p-3 d-flex align-items-center" :class="[{ 'border-bottom': index !== inputUser.questions.length - 1 }]" 
                        :style="[{ 'opacity': `${question.isEnabled}` ? 1 : 0.5 }]"
                        v-for="(question, index) in inputUser.questions" :key="question.id">
                        <span>{{ index + 1 }}</span>
                        <div class="btn-group mx-3">
                            <button class="btn btn-default btn-sm" @click.prevent="moveToTop(index)">
                                <icon name="arrow-up" class="mx-auto text-muted"/>
                            </button>
                            <button class="btn btn-default btn-sm" @click.prevent="moveToBottom(index)">
                                <icon name="arrow-down" class="mx-auto text-muted"/>
                            </button>
                        </div>
                        <input class="mb-0 me-4 form-control" v-model="question.content" placeholder="Enter question" required>

                        <label class="form-check form-switch mb-0 ms-auto">
                            <input class="form-check-input" type="checkbox" v-model="question.isEnabled">
                            <span class="form-check-label ms-1" v-if="question.isEnabled">Enabled</span>
                            <span class="form-check-label ms-1" v-else>Disabled</span>
                        </label>
                        <a href="#" class="text-danger fw-bold ms-5" role="button" @click.prevent="removeQuestion(index)">Remove</a>
                    </li>
                </ul>
            </div>

            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="fetchReviewQuestion">Reset</button>
                    <button class="btn btn-primary" type="submit">Save Changes</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import ReviewQuestionService from '@/services/settings/ReviewQuestionService'

import { useNotificationsStore } from '@/stores/notifications'
import ReviewQuestion from '@/types/ReviewQuestion'
import UserInput from '@/types/Input'
import { map } from 'lodash'

const DEFAULT_REVIEW_QUESTION_DATA: Omit<ReviewQuestion, 'id' | 'is_enabled'> = {
    content: '',
    isEnabled: false,
    order: 0,
}

const { addToastNotification } = useNotificationsStore()
const isFetchingReviewQuestions = ref<boolean>(false)
const inputUser = ref<UserInput>({
    questions: [DEFAULT_REVIEW_QUESTION_DATA]
})

fetchReviewQuestion()

function fetchReviewQuestion () {
    isFetchingReviewQuestions.value = true

    ReviewQuestionService.index()
        .then(({ data: { data } }) => {
            inputUser.value.questions = data.map((question: ReviewQuestion) => {
                return {
                    content: question.content,
                    isEnabled: question.isEnabled,
                    order: question.order
                }
            })
        })
        .finally(() => {
            isFetchingReviewQuestions.value = false
        })
}

function saveReviewQuestion () {
    let formattedData = formatData(inputUser.value)

    ReviewQuestionService.store(formattedData)
        .then(({ data }) => {
            addToastNotification({
                message: 'Review question saved',
                type: 'success',
            })
        }).catch(({ response: { data, status } }) => {
            if (status === 422) {
                // Object.assign($externalResults.value.inputUser, data.errors)
            } else {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            }
        })

}

function addQuestion () {
    inputUser.value.questions.push(DEFAULT_REVIEW_QUESTION_DATA)
}

function removeQuestion (index: number) {
    inputUser.value.questions.splice(index, 1)
}

function moveToTop (index: number) {
    if (index === 0) return null

    let prevItem = inputUser.value.questions[index - 1]
    let currentItem = inputUser.value.questions[index]

    inputUser.value.questions[index - 1] = currentItem
    inputUser.value.questions[index] = prevItem
}

function moveToBottom (index: number) {
    if (index === inputUser.value.questions.length - 1) return null

    let nextItem = inputUser.value.questions[index + 1]
    let currentItem = inputUser.value.questions[index]

    inputUser.value.questions[index + 1] = currentItem
    inputUser.value.questions[index] = nextItem
}

function formatData (obj: UserInput) {
    let questions = obj.questions.map((question: ReviewQuestion, index: number) => {
        return {
            content: question.content,
            order: index + 1,
            is_enabled: question.isEnabled
        }
    })

    return { questions }
}
</script>

<style scoped></style>
