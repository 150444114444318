<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <!-- <div class="col-12 col-md-2">
                    <label class="form-label">Ticket Status</label>
                    <vue-select
                        :options="statusOptions" :reduce="(status) => status.value" label="name"
                        class="text-capitalize" v-model="inputFilter.status">
                    </vue-select>
                </div> -->
    
                <div class="col-12 col-md-2">
                    <label class="form-label">Rating</label>
                    <vue-select :options="[1, 2, 3, 4, 5, 'all']" label="name"
                        class="text-capitalize" v-model="inputFilter.rating">
                    </vue-select>
                </div>
    
                <div class="col-12 col-md-3">
                    <label class="form-label">Date Range</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range" readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar"/>
                        </span>
                    </div>
                </div>
                
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchReviews" :is-loading="isLoading"></button-filter>
                </div>
                
                <div class="col-12 col-md-auto ms-auto">
                    <button class="btn btn-primary" @click="generateReport">
                        <icon name="file-spreadsheet"/>
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table text-nowrap datatable">
                    <thead>
                        <tr>
                            <th class="col-1">Date</th>
                            <!-- <th class="col-1">Status</th>
                            <th class="col-1">Attended By</th> -->
                            <th>Customer</th>
                            <th>Session</th>
                            <th>Carer</th>
                            <th>Rating</th>
                            <th>Comment</th>
                            <th>Display to Public</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="sessionReviews.length > 0">
                        <tr v-for="review in sessionReviews" :key="review.id">
                            <td class="col-1">{{ formatDate(review.createdAt, 'DD MMM YYYY hh:mm A') }}</td>
                            <!-- <td class="col-1">
                                <span class="badge badge-pill" :class="[getStatus(review.ticket.status).class]">{{ getStatus(review.ticket.status).name }}</span>
                            </td>
                            <td class="col-1">{{ review.ticket.assignee.name ?? '-' }}</td> -->
                            <td class="col-2 text-wrap" style="vertical-align: top">
                                <div>[{{ review.session?.customer?.mainProfile?.onlineId }}] {{ review.session?.customer?.mainProfile?.fullName }}</div>
                                <div>{{ review.session?.customer?.mainProfile?.mobileNo }}</div>
                            </td>
                            <td>
                                <div>{{ review.session.no }}</div>
                                <div>{{ formatDate(review.session.startedAt, 'DD MMM YYYY') }}</div>
                                <div>{{ formatDate(review.session.startedAt, 'hh:mm A') }} -  {{ formatDate(review.session.endedAt, 'hh:mm A') }}</div>
                            </td>
                            <td class="col-2 text-wrap">[{{ review.reviewedUser?.mainProfile?.onlineId }}] {{ review.reviewedUser?.mainProfile?.fullName }}</td>
                            <td class="col-1 text-wrap">{{ review.rating ?? '-' }}</td>
                            <td class="col-3 text-wrap">{{ review.comment ?? '-' }}</td>
                            <td class="col-3 text-wrap">{{ review.isPublicDisplayed ? 'Yes' : 'No' }}</td>
                            <td class="col-3 text-wrap">
                                <a class="btn btn-icon btn-azure" role="button" data-bs-toggle="modal" data-bs-target="#modal-update-carer-rating"  @click.prevent="reviewSessionNo = review.session.no; reviewModel = review; ">
                                    <icon name="pencil"/>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="10" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isLoading" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>

        <UpdateCarerRatingFormModal v-if="reviewModel" :session-no="reviewSessionNo" :review="reviewModel" @carer-review-updated="fetchReviews" />
    </div>
</template>

<script setup lang="ts">
import { Core, easepick } from '@easepick/core'
import date from '@/utils/date'
import { onMounted, ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import { formatDate } from '@/composable/useDate'
import { getStatuses } from '@/composable/useTicket'
import useDateRangePicker from '@/composable/useDateRangePicker'
import CarerReviewService from '@/services/CarerReviewService'
import SessionReview from '@/types/SessionReview'
import { useNotificationsStore } from '@/stores/notifications'
import UpdateCarerRatingFormModal from '../users/carers/UpdateCarerRatingFormModal.vue'
import SessionReviewService from '@/services/SessionReviewService'

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
const sessionReviews = ref<Array<SessionReview>>([])
const inputFilter = ref<UserFilter>({
    status: 'all',
    rating: 'all',
    from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { easePickDefaultOptions } = useDateRangePicker()
const { addToastNotification } = useNotificationsStore()

let reviewModel = ref<SessionReview>()
let reviewSessionNo = ref<string>('')

const isLoading = ref<boolean>(false)
const getStatus = (status: string|undefined) => getStatuses().find((findStatus) => findStatus.value === status)
const statusOptions = [
    { name: 'New', value: 'new' },
    { name: 'Attending', value: 'attending' },
    { name: 'Follow Up', value: 'follow_up' },
    { name: 'Resolved', value: 'resolved' },
    { name: 'All', value: 'all' },
]

fetchReviews()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchReviews()
    }
)

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value = {
                    status: inputFilter.value.status ?? 'all',
                    rating: inputFilter.value.rating ?? 'all',
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT),
                }
                picker.hide()
            })
        }
    })
    
    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})

function resetFilter () {
    inputFilter.value = {
        status: 'all',
        rating: 'all',
        from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
    }
    
    fetchReviews()
}

function fetchReviews () {
    isLoading.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }
    
    SessionReviewService.index(query)
        .then(({ data: { data, meta } }) => {
            sessionReviews.value = data

            if(sessionReviews.value.length > 0)
                reviewModel.value = sessionReviews.value[0]
            
            updateMetaPage(meta)
        })
        .finally(() => {
            isLoading.value = false
        })
}

function generateReport () {
    isLoading.value = true
    const query = {
        ...inputFilter.value,
    }
    
    SessionReviewService.generateReport(query)
        .then(({ data: { message } }) => {
            addToastNotification({
                message: message,
                type: 'success',
            })
        })
        .finally(() => {
            isLoading.value = false
        })
}

const toggleDisplay = (reviewId: number) => {
    CarerReviewService.toggleDisplay(reviewId)
        .then(() => {
            const carerReview = sessionReviews.value.find((review: SessionReview) => review.id === reviewId)
            if (carerReview) {
                carerReview.isPublicDisplayed = ! carerReview.isPublicDisplayed
            }

            addToastNotification({
                message: 'Review updated',
                type: 'success',
            })

        }).catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
}
</script>
