import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { required, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        name: {
            required: helpers.withMessage(
                'This name field is required',
                required
            ),
        },
        gender: {
            required: helpers.withMessage(
                'This gender field is required',
                required
            ),
        },
        language: {
            required: helpers.withMessage(
                'This language field is required',
                required
            ),
        },
        birth_date: {
            required: helpers.withMessage(
                'This birth date field is required',
                required
            ),
        },
        religion: {
            required: helpers.withMessage(
                'This religion field is required',
                required
            )
        }
    },
})
