<template>
    <kc-modal modal-id="modal-user-organization-form" modal-size="xl">
      <div class="modal-header">
        <h4 class="modal-title">Add User</h4>
        <button id="btn-user-organization-modal-close" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body bg-white">

        <form>
        <div class="mb-3">
          <label for="user-id" class="form-label">User ID</label>
          <select v-model="input.user_id" class="form-select">
            <option v-for="userOption in userOptions.data" :value="userOption.id" :key="userOption.id">
              {{ userOption.name }}
            </option>
          </select>
        </div>
        <input type="hidden" name="organization_id" :value="organizationId">
      </form>

      </div>
      <div class="modal-footer pt-3 border-top">
        <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button class="btn btn-primary" @click.prevent="saveUserOrganization">Save</button>
      </div>
    </kc-modal>
  </template>
  
  <script setup lang="ts">
  import { defineProps, ref, onMounted, getCurrentInstance } from 'vue';
  import { useRouter, useRoute } from 'vue-router'
  import { useNotificationsStore } from '@/stores/notifications';
  import OrganizationUserService from '@/services/settings/OrganizationUserService'
  
  const { addToastNotification } = useNotificationsStore();
  const router = useRouter()
  const props = defineProps({
    userOrganization: { type: Object, required: true },
    organizationId: { type: Number, required: true },
    fetchOrganization: { type: Function, required: true },
  });
  
  const userOptions = ref([]);
  const input = ref({
    user_id: null,
    organization_id: props.organizationId,
  });
  
  let btnCloseModal: HTMLElement | null = null
  onMounted(async () => {
    btnCloseModal = document.getElementById('btn-user-organization-modal-close')
    try {
      const userResponse = await OrganizationUserService.allUser();
      userOptions.value = userResponse.data;

    } catch (error) {

    }
  });

  const saveUserOrganization = async () => {
    
    const organizationUserService = OrganizationUserService.store(input.value)

    try {      
      const response = await organizationUserService;
      if (response.data) {
        addToastNotification({
            message: `User organization created`,
            type: 'success',
        });
        btnCloseModal?.click()
        props.fetchOrganization();
      }
    } catch (error) {
        const typedError = error as any;
        if (typedError.response && typedError.response.status === 422) {
            addToastNotification({
                message: typedError.response.data.errors.user_id[0],
                type: 'danger',
            });
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            });
        }
    }
  }

</script>
  