<template>
    <div class="my-0 d-inline-flex align-items-center space-x-2 py-2">
        <div class="fw-bold">{{ userMetadata?.mainProfile?.fullName }}</div>
        <div class="badge badge-outline badge-pill badge-sm flex-shrink-0 text-capitalize bg-[#F48B29]"
             :style="{'letter-spacing': 0, 'color': userType === 'customer' ? '#F48B29' : '#18D2C5'}"
        >
            {{ userType }}
        </div>
        <status-badge :show="userMetadata?.isBanned" label="Banned" color="red"></status-badge>
        <status-badge :show="userMetadata?.isDeleted" label="Deleted" color="gray"></status-badge>
    </div>
    <ul class="d-flex space-x-2 mb-0 list-unstyled text-muted">
        <li>
            <icon name="id" :stroke-width="1.2" class="text-muted"/>
            {{ userMetadata?.mainProfile?.onlineId }}
        </li>
        <li>/</li>
        <li>
            <icon name="mail" :stroke-width="1.2" class="text-muted"/>
            {{ userMetadata?.email }}
        </li>
        <li>/</li>
        <li>
            <icon name="phone" :stroke-width="1.2" class="text-muted"/>
            {{ userMetadata?.mainProfile?.mobileNo }}
        </li>
        <li>/</li>
        <li v-if="viewProfileLink">
            <a :href="viewProfileLink" target="_blank" class="fw-bold">View Profile
                <icon name="external-link" :stroke-width="1.2" class="text-muted"/>
            </a>
        </li>
    </ul>
</template>

<script setup lang="ts">

import StatusBadge from '@/components/StatusBadge.vue'
import ChatUserMetadata from '@/types/ChatUserMetadata'
import {computed} from 'vue'

const props = defineProps({
    userMetadata: {type: Object as () => ChatUserMetadata, required: true},
    userType: {type: String as () => 'customer' | 'carer'}
})

const viewProfileLink = computed(() => {

    if (!props.userMetadata) {
        return null
    }

    let link
    switch (props.userType) {
        case 'customer':
            link = `/users/customer/${ props.userMetadata?.id }`
            break
        case 'carer':
            link = `/users/carer/${ props.userMetadata?.id }`
    }

    return link
})
</script>