<template>
    <div class="card">
        <form @submit.prevent="saveSession">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="form-group col-6">
                        <form-label label="Customer" is-required/>
                        <input class="form-control" :class="{ 'is-invalid': hasError('customer') }" type="text" :value="formattedCustomer" @focus="clearError('customer')" readonly>
                        <error-message :has-error="hasError('customer')" :message="getError('customer')"></error-message>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="form-group col-3">
                        <form-label label="Date / Time" is-required/>
                        <div class="input-icon mb-2">
                            <input class="form-control bg-white" type="text" id="input-session-date"
                                   :value="sessionDateDisplay"
                                   required/>
                            <span class="input-icon-addon"><icon name="calendar"/></span>
                        </div>
                    </div>
                    <div class="form-group col-3">
                        <form-label label="Duration (Hour)" is-required/>
                        <input type="number" class="form-control" v-model.number="inputUser.duration"/>
                        <error-message :has-error="hasError('duration')"
                                       :message="getError('duration')"></error-message>
                    </div>
                    <div class="form-group col-6">
                        <label class="form-label">Carer</label>
                        <vue-select :options="carerOptions" @search="onSearchCarer"
                                    v-model.number="inputUser.assigned_carer"
                                    :reduce="(carer: any) => carer.id" label="name"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="form-group col-6">
                        <form-label label="Session Type" is-required/>
                        <vue-select
                            :options="sessionTypes"
                            label="name"
                            :reduce="(sessionType: SessionType) => sessionType.id"
                            v-model="inputUser.session_type">
                        </vue-select>
                        <error-message :has-error="hasError('session_type')"
                                       :message="getError('session_type')"></error-message>
                    </div>
                    <div class="form-group col-6">
                        <form-label label="Session Service Type" is-required/>
                        <vue-select
                            :options="sessionServiceTypes"
                            label="name"
                            :reduce="(sessionServiceType: SessionServiceType) => sessionServiceType.id"
                            v-model="inputUser.session_service_type">
                        </vue-select>
                        <error-message :has-error="hasError('session_service_type')"
                                       :message="getError('session_service_type')"></error-message>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="form-group">
                        <form-label label="Address" is-required/>
                        <vue-select :options="addressOptions" v-model="inputUser.address" label="name"/>
                        <div class="border mt-2 p-3 rounded" v-if="inputUser.address">
                            <p>{{ getFullAddress(inputUser.address, addressStates, postcodes) }}</p>
                            <p>Remarks: {{ inputUser.address.remarks }}</p>
                        </div>
                        <error-message :has-error="hasError('address')" :message="getError('address')"></error-message>
                    </div>
                </div>
                <div class=" mb-3 row">
                    <div class="form-group">
                        <form-label label="Dependent" is-required/>
                        <ul v-if="dependents.length > 0" class="list-unstyled border rounded">
                            <li class="p-3"
                                :class="{ 'border-bottom' : dependentIndex !== dependents.length - 1 }"
                                v-for="(dependent, dependentIndex) in dependents" :key="dependentIndex"
                            >
                                <label class="form-check mb-0 row" :for="`dependent-${dependentIndex}`">
                                    <input class="form-check-input col-1" type="checkbox" name="child"
                                           v-model="inputUser.dependents"
                                           :value="dependent" :id="`dependent-${dependentIndex}`"
                                    >
                                    <span class="form-check-label col-11 ">
                                    <span class="row">
                                        <span class="fw-bold mb-1 col-12">{{ dependent.name }}</span>
                                        <span class="text-muted mb-0">
                                            {{ getGenderByType(dependent as Dependent) }},
                                            {{ dependent.birthDate ? toAge(dependent.birthDate) : null }}
                                        </span>
                                    </span>
                                    </span>
                                </label>
                            </li>
                        </ul>
                        <ul class="list-unstyled border rounded" v-else>
                            <li class="p-3">No Dependent</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit" :disabled="isSubmitting">Create Session</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">

import { getGenderByType } from '@/composable/useDependent'
import { getFullAddress } from '@/composable/useSession'
import { DependentTypeEnum } from '@/enums/DependentTypeEnum'
import { SessionTypeEnum } from '@/enums/SessionTypeEnum'
import { SessionServiceTypeEnum } from '@/enums/SessionServiceTypeEnum'
import DependentService from '@/services/DependentService'
import Dependent from '@/types/Dependent'
import { AxiosError } from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { $externalResults, rules as SessionRule } from '@/rules/SessionRule'
import _ from 'lodash/fp'
import { TimePlugin } from '@easepick/time-plugin'
import { LockPlugin } from '@easepick/lock-plugin'
import { Core, easepick } from '@easepick/core'
import UserInput from '@/types/Input'
import Customer from '@/types/Customer'
import User from '@/types/User'
import Address from '@/types/Address'
import Order from '@/types/Order'
import SessionType from '@/types/SessionType'
import SessionServiceType from '@/types/SessionServiceType'
import CustomerService from '@/services/users/CustomerService'
import AddressService from '@/services/AddressService'
import CarerService from '@/services/users/CarerService'
import SessionService from '@/services/SessionService'
import {getMainProfile} from '@/composable/profile'
import useFormError from '@/composable/useFormError'
import {dateObject, formatDate, toAge} from '@/composable/useDate'
import {useNotificationsStore} from '@/stores/notifications'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import {storeToRefs} from 'pinia'
import {useResourcesStore} from '@/stores/resources'
import {useRouter} from 'vue-router'
import FormLabel from '@/components/form/FormLabel.vue'

const isSubmitting = ref(false)
const customerOptions = ref<Array<Customer>>([])
const carerOptions = ref<Array<any>>([])
const addressOptions = ref<Array<Address>>([])
const dependents = ref<Array<Dependent>>([])

const router = useRouter()
const {addToastNotification} = useNotificationsStore()

const {
    addressStates,
    postcodes,
    sessionTypes,
    sessionServiceTypes,
} = storeToRefs(useResourcesStore())

const props = defineProps({
    order: { type: Object as () => Order, default: {} },
    orderId: { type: Number },
    redirectTo: { type: Object }
})

const inputUser = ref<UserInput>({
    customer: '',
    start_time: dateObject().add(3, 'h').set('m', 0).set('s', 0).format('YYYY-MM-DD HH:mm:ss'),
    duration: 2,
    assigned_carer: null,
    address: null,
    dependents: [],
    session_type: SessionTypeEnum.ID_CHILDCARE,
    session_service_type: SessionServiceTypeEnum.ID_ON_DEMAND,
})

const DEFAULT_SESSION_DATE_FORMAT = 'DD-MMM-YYYY hh:mm A'
const easePickDefaultOptions = {
    format: DEFAULT_SESSION_DATE_FORMAT,
    css: [
        'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.0/dist/index.css'
    ],
    autoApply: false,
    zIndex: 10,
    plugins: [TimePlugin, LockPlugin],
    TimePlugin: {
        format12: true
    },
    LockPlugin: {
        minDate: new Date()
    }
}


watch(
    () => inputUser.value.customer,
    async (customer: any) => {
        await watchCustomer(customer)
    }
);

const watchCustomer = async (customerId: number) => {
    if (customerId) {
         fetchAddresses(customerId);
         fetchDependents(customerId);
    } else {
        addressOptions.value = [];
        dependents.value = [];
    }
};

const formattedCustomer = computed(() => {
  const { fullName, onlineId } = props.order.user.mainProfile;
  return `(${onlineId}) ${fullName}`;
});

let sessionDatePicker: Core | null = null

const v$ = useVuelidate(SessionRule, {inputUser}, {$externalResults})
const {hasError, getError, clearError, clearAllErrors} = useFormError(v$)

const sessionDateDisplay = computed<string>(() => formatDate(inputUser.value.start_time, DEFAULT_SESSION_DATE_FORMAT))

const saveSession = async () => {

    if (isSubmitting.value) {
        return
    }

    $externalResults.value.inputUser = {}
    await v$.value.$validate()

    isSubmitting.value = true

    try {
        await SessionService.storeWithOrder(formattedData())
        addToastNotification({
            message: `Session created`,
            type: 'success'
        })

        await router.push( props.redirectTo ?? {name: 'home-page'})

    } catch (err) {
        const error = err as AxiosError
        if (error.response?.status === 422) {

            const responseData = error.response.data as { errors?: Record<string, string[]> }

            if (responseData.errors) {
                const errors = responseData.errors

                $externalResults.value.inputUser = {
                    session_type: errors.session_type_id,
                    session_service_type: errors.session_service_type,
                    customer: errors.customer_user_id,
                    start_time: errors.started_at,
                    duration: errors.ended_at,
                    address: errors.address_id,
                    dependents: errors.dependent_ids
                }
            }
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    }

    isSubmitting.value = false

}

const formattedData = () => {
    return {
        session_type_id: inputUser.value.session_type,
        session_service_type_id: inputUser.value.session_service_type,
        customer_user_id: (inputUser.value.customer?.id || inputUser.value.customer) ?? null,
        started_at: inputUser.value.start_time,
        ended_at: dateObject(inputUser.value.start_time).add(inputUser.value.duration, 'h').set('m', 0).set('s', 0).format('YYYY-MM-DD HH:mm:ss'),
        duration: inputUser.value.duration,
        address_id: inputUser.value.address?.id ?? null,
        dependent_ids: inputUser.value.dependents.map((child: { id: number }) => child.id),
        order_id: props.orderId !== null ? props.orderId : null
    }
}

const onSearchCustomer = (search: string, loading: Function) => {
    if (search.length) {
        searchCustomers(search, loading)
    }
}

const searchCustomers = _.debounce(350, (search, loading) => {
    loading(true)

    CustomerService.index({search})
        .then(({data: {data}}) => {
            customerOptions.value = data.map((customer: User) => {
                const mainProfile = getMainProfile(customer.profiles)

                return {
                    id: customer.id,
                    name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`,
                    data: customer
                }
            })
        })
        .catch(() => {
        })
        .finally(() => loading(false))
})


const onSearchCarer = (search: string, loading: Function) => {
    if (search.length) {
        searchCarers(search, loading)
    }
}

const searchCarers = _.debounce(350, (search, loading) => {
    loading(true)

    CarerService.index({search})
        .then(({data: {data}}) => {
            carerOptions.value = mapCarersData(data)
        })
        .finally(() => loading(false))
})


const mapCarersData = (carers: Array<User>) => {
    return carers.map((carer: User) => {
        const mainProfile = getMainProfile(carer.profiles)
        return {
            id: carer.id,
            name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`
        }
    })
}

const fetchAddresses = (userId: number) => {
    AddressService.index({user_id: userId})
        .then(({data: {data, meta}}) => {
            addressOptions.value = data
        })
        .catch(() => {
        })
}

const fetchDependents = (userId: number) => {
    const serviceTypeId = inputUser.value.session_service_type as number

    let dependentTypeId = null
    if (serviceTypeId === SessionTypeEnum.ID_CHILDCARE) {
        dependentTypeId = DependentTypeEnum.ID_CHILD
    } else if (serviceTypeId === SessionTypeEnum.ID_ELDERCARE) {
        dependentTypeId = DependentTypeEnum.ID_ELDER
    }

    DependentService.index({ user_id: userId, dependent_type_id: dependentTypeId })
        .then(({data: {data, meta}}) => {
            dependents.value = data
        })
        .catch(() => {
            dependents.value = []
        })
}

onMounted(async () => {
    inputUser.value.customer = props?.order.userId ?? null,
    await watchCustomer(inputUser.value.customer);
    sessionDatePicker = new easepick.create({
        ...easePickDefaultOptions,
        ...{
            element: '#input-session-date',
            setup: (picker: Core) => {
                picker.on('select', (e: any) => {
                    inputUser.value.start_time = formatDate(e.detail.date, 'YYYY-MM-DD HH:mm:ss')
                })
            }
        }
    })
})

</script>