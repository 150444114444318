<template>
    <div :class="[ ticket.status !== 'resolved' ? 'dropdown' : '' ]">
        <button type="button" data-bs-toggle="dropdown" class="btn badge text-uppercase py-1 px-3" :class="buttonStyle" :disabled="isUpdating">
            {{ statusLabel }}
        </button>
        <div class="dropdown-menu dropdown-menu-end" style="width: 164px;">
            <div v-for="(option, index) in options" :key="index"
                 class="dropdown-item" style="font-size: 0.75rem"
                 @click="emit('updated', ticket, option.value)">
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Ticket } from '@/types/Ticket'


const props = defineProps({
    ticket: { type: Object as () => Ticket, required: true },
    isUpdating: { type: Boolean, default: false }
})

const emit = defineEmits(['updated'])

const statusLabel = computed(() => {
    return props.ticket.status.replace('_', ' ')
})

const buttonStyle = computed(() => {
    switch (props.ticket.status) {
        case 'new':
            return 'btn-primary'
        case 'attending':
            return 'btn-cyan'
        case 'follow_up':
            return 'btn-yellow'
        case 'resolved':
            return 'btn-success'
        default:
            return 'new'
    }
})

const options = computed(() => {
    switch (props.ticket.status) {
        case 'new':
        case 'attending':
            return [
                { label: 'Follow Up', value: 'follow_up' },
                { label: 'Resolved', value: 'resolved' },
            ]
        case 'follow_up':
            return [
                { label: 'Resolved', value: 'resolved' },
            ]
        default:
            return []
    }
})
</script>
