<template>
    <label class="form-check form-check-inline">
        <input class="form-check-input"
               type="checkbox"
               :name="name"
               :value="value"
               :checked="isChecked"
               @input="onCheckboxClicked"
        >
        <span class="form-check-label">{{ label }}</span>
    </label>
</template>

<script setup lang="ts">

import {computed} from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {type: Array, default: []},
    value: {type: [String, Number], required: true},
    name: {type: String, required: true},
    label: {type: String}
})

const isChecked = computed<Boolean>(() => {
    return props.modelValue.includes(props.value)
})

const onCheckboxClicked = () => {
    let updatedValue = [...props.modelValue]

    if (isChecked.value) {
        updatedValue.splice(updatedValue.indexOf(props.value), 1)
    } else {
        updatedValue.push(props.value)
    }

    emit('update:modelValue', updatedValue)
}
</script>
