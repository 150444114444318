<!--TODO (hannah): componentize payout index page-->
<template>
    <div class="d-flex justify-content-end mb-3">
        <button class="btn btn-primary" type="button" @click="showModal(true)">
            <icon name="plus" />Add New
        </button>
    </div>
    <div class="card">
        <div class="card-body p-0 text-muted border-bottom">
            <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                <div class="d-flex align-items-center">
                    <div>
                        Entries per page<div class="mx-3 d-inline-block">
                        <div class="form-group row">
                            <select v-model.number="metaPage.perPage" class="form-select col" @change="updatePerPage">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class=" pt-3 px-3">
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
            </div>
        </div>
        <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap datatable">
                <thead>
                <tr>
                    <th>Cut Off Date</th>
                    <th>Confirmed Date</th>
                    <th class="text-center">Status</th>
                    <th>Type</th>
                    <th>Schedule</th>
                    <th class="text-center">Carers</th>
                    <th class="text-center">Sessions</th>
                    <th class="text-end">Total Payout</th>
                    <th></th>
                </tr>
                </thead>
                <tbody v-if="payouts.length > 0">
                    <tr v-for="payout in payouts" :key="payout.id">
                        <td>{{ formatDate(payout.cutOffAt, 'DD-MMM-YYYY') }}</td>
                        <td>
                            <div v-if="payout.confirmedAt">{{ formatDate(payout.confirmedAt, 'DD-MMM-YYYY') }}</div>
                            <div v-else>N/A</div>
                        </td>
                        <td class="text-center">
                            <div class="badge badge-pill" :class="getStatusBadgeColor(payout.status)">{{ carerPayoutStatuses.find((findStatus: KeyLabel) => payout.status === findStatus.key)?.label }}</div>
                        </td>
                        <td><div>{{ carerPayoutTypes.find((findType: KeyLabel) => payout.type === findType.key)?.label }}</div></td>
                        <td><div>{{ carerPayoutSchedules.find((findSchedule: KeyLabel) => payout.schedule === findSchedule.key)?.label }}</div></td>
                        <td class="text-center">{{ payout.totalRecipients }}</td>
                        <td class="text-center">{{ payout.totalSessions }}</td>
                        <td class="text-end">{{ toMYR(payout.totalAmount) }}</td>
                        <td class="text-end">
                            <div class="btn-group">
                                <button class="btn btn-icon btn-danger" @click.prevent="deletePayout(payout.id)" v-if="payout.status === CarerPayoutStatusEnum.STATUS_DRAFT">
                                    <icon name="trash" />
                                </button>
                                <button class="btn btn-icon btn-success" @click.prevent="exportPayout(payout.id, payout.cutOffAt)" v-if="payout.status === CarerPayoutStatusEnum.STATUS_CONFIRMED" :disabled="isExportingPayout">
                                    <icon name="file-download" />
                                </button>
                                <router-link class="btn btn-icon btn-primary" :to="{ name: 'payout-show', params: { payoutId: payout.id } }" v-if="payout.status !== CarerPayoutStatusEnum.STATUS_GENERATING">
                                    <icon name="eye" />
                                </router-link>
                                <button class="btn btn-icon btn-yellow" v-if="payout.status === CarerPayoutStatusEnum.STATUS_GENERATING">
                                    <span class="spinner-border"></span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="8" class="text-center">No data</td>
                    </tr>
                </tbody>
            </table>
            <overlay-spinner :is-showing="isFetchingPayouts" />
        </div>
        <div class="card-footer pb-0">
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
    
    <kc-modal modal-id="new-payout">
        <div class="modal-header">
            <h4 class="modal-title">New Payout</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="onSubmit">
            <div class="modal-body">
                <label class="form-label">Cut Off Date</label>
                <input class="form-control" name="cut_off_at" v-model="input.cutOffAt" type="date" required><br>
                <label class="form-label">Type</label>
                <select class="form-control" v-model="input.type">
                    <option v-for="payoutType in carerPayoutTypes" :key="payoutType.key" :value="payoutType.key">{{ payoutType.label }}</option>
                </select><br>
                <label class="form-label">Schedule</label>
                <select class="form-control" v-model="input.schedule">
                    <option v-for="payoutSchedule in carerPayoutSchedules" :key="payoutSchedule.key" :value="payoutSchedule.key">{{ payoutSchedule.label }}</option>
                </select><br>
            </div>
            <div class="modal-footer">
                <button class="btn btn-light" type="button" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="submit" :disabled="isCreatingPayout">
                    <span class="spinner-border spinner-border-sm me-2" v-if="isCreatingPayout"></span>
                    <span>{{ isCreatingPayout ? 'Creating...' : 'Create' }}</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import { getStatusBadgeColor } from '@/composable/useCarerPayout'
import { toMYR } from '@/composable/useCurrency'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import { CarerPayoutStatusEnum } from '@/enums/CarerPayoutEnum'
import PayoutService from '@/services/PayoutService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import CarerPayout from '@/types/CarerPayout'
import KeyLabel from '@/types/KeyLabel'
import Modal from 'bootstrap/js/dist/modal'
import { storeToRefs } from 'pinia' // import { Modal } from 'bootstrap' disrupts the dropdown. Also reported in https://github.com/twbs/bootstrap/issues/32749
import { onMounted, ref, watch } from 'vue'

const { addToastNotification } = useNotificationsStore()
const { carerPayoutTypes, carerPayoutStatuses, carerPayoutSchedules } = storeToRefs(useResourcesStore())

const payouts = ref<Array<CarerPayout>>([])
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const isFetchingPayouts = ref<boolean>(false)
const isCreatingPayout = ref<boolean>(false)
const isExportingPayout = ref<boolean>(false)

const input = ref<any>({
    cutOffAt: null,
    type: null,
    schedule: null,
})

let modal: any = null
onMounted(() => {
    modal = new Modal('#new-payout')
})

window.Echo.private('backoffice')
    .listen('.CarerPayoutCreated', ({ model }: any) => addPayoutToList(model))
    .listen('.CarerPayoutUpdated', ({ model }: any) => addPayoutToList(model))

fetchPayouts()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchPayouts()
    }
)

const addPayoutToList = (payout: CarerPayout) => {
    const index = payouts.value.findIndex(findPayout => findPayout.id === payout.id)

    if (index !== -1) {
        payouts.value[index] = payout
    } else {
        if (metaPage.value.total === metaPage.value.perPage && metaPage.value.currentPage === 1) {
            payouts.value.unshift(payout)
            payouts.value.pop()

            if (payouts.value.length === metaPage.value.perPage) {
                payouts.value.pop()
            }

            let to = metaPage.value.to + 1
            to = Math.min(to, metaPage.value.perPage);

            const total = metaPage.value.total + 1
            const lastPage = Math.ceil(total / metaPage.value.perPage)

            updateMetaPage({
                ...metaPage.value,
                current_page: metaPage.value.currentPage,
                last_page: lastPage,
                to: to,
                total: total,
            })
        } else {
            payouts.value.unshift(payout)
        }
    }
}

const onSubmit = () => {
    if (input.value.cutOffAt === null || input.value.type === null|| input.value.schedule === null) {
        return
    }

    isCreatingPayout.value = true
    PayoutService.store({ cut_off_at: input.value.cutOffAt, type: input.value.type, schedule: input.value.schedule })
        .then(({ data: { message } }) => {
            addToastNotification({
                message: message,
                type: 'success',
            })

            showModal(false)
        })
        .catch(({ response: { data: { message }, status }}) => {
            const errorMessage = status < 500 ? message : 'Internal server error. Please contact tech team if error persists.'
            addToastNotification({
                message: errorMessage,
                type: 'danger',
            })
        })
        .finally(() => {
            input.value.cutOffAt = null
            input.value.type = null
            input.value.schedule = null

            isCreatingPayout.value = false
        })
}

function exportPayout (id: number, cutOffAt: string) {
    isExportingPayout.value = true

    PayoutService.export(id)
        .then(() => {
            addToastNotification({
                message:
                    'Payout report being generated. Please wait',
                type: 'success',
            })
        })
        .catch(() => {
            addToastNotification({
                message:
                    'Internal server error. Please contact tech team if error persists.',
                type: 'danger',
            })
        })
        .finally(() => {
            isExportingPayout.value = false
        })
}

function showModal (value: boolean) {
    value ? modal.show() : modal.hide()
}

function resetFilter () {
    fetchPayouts()
}

function fetchPayouts () {
    isFetchingPayouts.value = true
    const query = {
        perPage: metaPage.value.perPage,
        page: metaPage.value.currentPage,
    }

    PayoutService.index(query)
        .then(({ data: { data, meta } }) => {
            payouts.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingPayouts.value = false
        })
}

function deletePayout (id: number) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete this payout draft?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            PayoutService.destroy(id)
                .then(() => {
                    fetchPayouts()
                    addToastNotification({
                        message: `Payout draft has been deleted.`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message: `Internal server error. Please contact tech team if the error persists.`,
                        type: 'danger',
                    })
                })
        }
    })
}

</script>

<style scoped></style>