<template>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Rate For</th>
                            <th>Currency</th>
                            <th>Rate</th>
                            <th>Pay</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="rate in rates" :key="rate.id">
                            <td class="fw-bold">{{ rate.hours }} hours</td>
                            <td width="80px">
                                <input class="form-control" :id="`currency_${rate.id}`" :name="`currency_${rate.id}`" type="text" v-model="rate.currency">
                            </td>
                            <td width="240px">
                                <input class="form-control" :id="`rate_${rate.id}`" :name="`rate_${rate.id}`" type="number" step=".01" v-model="rate.rate">
                            </td>
                            <td width="240px">
                                <input class="form-control" :id="`pay_${rate.id}`" :name="`pay_${rate.id}`" type="number" step=".01" v-model="rate.pay">
                            </td>
                            <td class="text-end">
                                <button class="btn btn-primary" @click="update(rate.id, rate)">
                                    <icon name="device-floppy" />
                                    Save
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingUsers" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useNotificationsStore } from '@/stores/notifications'
import RateService from '@/services/settings/RateService'
import RateHour from '@/types/RateHour'

const { addToastNotification } = useNotificationsStore()
const rates = ref<Array<RateHour>>([])
const isFetchingUsers = ref<boolean>(false)

function fetch() {
    isFetchingUsers.value = true

    RateService.getHourlyRates()
        .then(({ data }) => {
            rates.value = data.map((item: RateHour) => {
                return {
                    ...item,
                    rate: (item.rate / 100).toFixed(2),
                    pay: (item.pay / 100).toFixed(2),
                }
            })
        })
        .finally(() => {
            isFetchingUsers.value = false
        })
}

function update(id: number, rate: RateHour) {
    RateService.updateHourlyRate(id, rate)
        .then(() => {
            addToastNotification({
                message: `Rate for ${rate.hours} hours updated!`,
                type: 'success',
            })
        })
        .catch(({ response: { data, status } }) => {
            if (status === 422) {
                // errorBag.value = data.errors
            } else {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            }
        })
}

fetch()
</script>