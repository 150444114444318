import ErrorBag from '@/types/ErrorBag'
import { helpers, minValue, required, requiredIf } from '@vuelidate/validators'
import { computed, ref } from 'vue'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = (mode: string) => {
    const validateDiscountAmount = (mode: string) =>
        helpers.withParams(
            { type: 'validateDiscountAmount' },
            (value: number, siblings) => {
                let validated = true

                if (mode === 'create') {
                    if (siblings.is_fixed_discount === true) {
                        const minValue = 1
                        validated = value >= minValue
                    } else {
                        const minValue = 1
                        const maxValue = 100
                        validated = (value >= minValue && value <= maxValue)
                    }
                }

                return validated
            }
        )

    return computed(() => {
        return {
            inputUser: {
                name: {
                    required: helpers.withMessage(
                        'This promotion name field is required',
                        required
                    ),
                },
                code_prefix: {
                    required: helpers.withMessage(
                        'This code prefix field is required',
                        required
                    ),
                },
                start_date: {
                    required: helpers.withMessage(
                        'This start date field is required',
                        required
                    ),
                },
                end_date: {
                    required: helpers.withMessage(
                        'This end date field is required',
                        required
                    ),
                },
                quantity: {
                    requiredIf: helpers.withMessage(
                        'This quantity field is required',
                        requiredIf(() => mode === 'create')
                    ),
                    minValueValue: helpers.withMessage(
                        ({ $params }) => `This prefix field requires a min length of ${$params.min}`,
                        minValue(1)
                    )
                },
                discount_amount: {
                    requiredIf: helpers.withMessage(
                        'This discount amount field is required',
                        requiredIf(() => mode === 'create')
                    ),
                    validateDiscountAmount: helpers.withMessage(
                        'This discount amount is invalid.',
                        validateDiscountAmount(mode)
                    )
                },
                is_fixed_discount: {
                    requiredIf: (
                        requiredIf(() => mode === 'create')
                    )
                },
            },
        }
    })
}
