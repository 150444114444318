<template>
    <overlay-spinner :is-showing="isFetching"></overlay-spinner>

    <PromotionForm mode="update" :promotion="promotion" v-if="promotion" />
</template>

<script setup lang="ts">
import PromotionForm from '@/pages/settings/promotions/PromotionForm.vue'
import PromotionService from '@/services/settings/PromotionService'
import { useNotificationsStore } from '@/stores/notifications'
import Promotion from '@/types/Promotion'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()

const promotionId = Number(route.params.promotionId)
const promotion = ref<Promotion>()
const isFetching = ref<boolean>(false)

onMounted(() => fetchPromotion())

const fetchPromotion = async () => {
    isFetching.value = true

    await PromotionService.show(promotionId)
        .then(({ data: { data } }) => {
            promotion.value = data
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => isFetching.value = false)
}
</script>