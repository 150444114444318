<template>
    <holiday-form :holiday="holiday" :id="holidayId"></holiday-form>
</template>

<script setup lang="ts">
import HolidayService from '@/services/settings/HolidayService'
import { useNotificationsStore } from '@/stores/notifications'
import Holiday from '@/types/Holiday'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import HolidayForm from './HolidayForm.vue'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const holidayId = Number(route.params.holidayId)
const holiday = ref<Holiday>()

HolidayService.show(holidayId)
    .then(({ data: { data } }) => {
        holiday.value = data
    })
    .catch(() => {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    })
</script>