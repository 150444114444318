<template>
    <div class="card">
        <form @submit.prevent="save" ref="promotionForm">
            <div class="card-body">
                <div class="mb-3">
                    <div class="row g-3">
                        <div class="col-12 col-md-6">
                            <FormLabel label="Promotion Name" is-required />
                            <input type="text" id="promotionName" class="form-control" :class="{'is-invalid': hasError('name')}" v-model="inputUser.name">
                            <small class="invalid-feedback" v-if="hasError('name')">{{ getError('name') }}</small>
                        </div>
                        <div class="col-12 col-md-6">
                            <FormLabel label="Code Prefix" is-required />
                            <input type="text" id="codePrefix" class="form-control" :class="{'is-invalid': hasError('code_prefix')}" @focus="clearError('code_prefix')" v-model="inputUser.code_prefix">
                            <small class="invalid-feedback" v-if="hasError('code_prefix')">{{ getError('code_prefix') }}</small>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <FormLabel label="Description" />
                    <textarea id="description" class="form-control" rows="4" v-model="inputUser.description"></textarea>
                </div>
                <div class="mb-3">
                    <div class="row g-3">
                        <div class="col-12 col-md-6">
                            <FormLabel label="Start Date" is-required />
                            <input type="date" id="startDate" class="form-control bg-white" :class="{'is-invalid': hasError('start_date')}" @focus="clearError('start_date')" v-model="inputUser.start_date">
                            <small class="invalid-feedback" v-if="hasError('start_date')">{{ getError('start_date') }}</small>
                        </div>
                        <div class="col-12 col-md-6">
                            <FormLabel label="End Date" is-required />
                            <input type="date" id="endDate" class="form-control bg-white" :class="{'is-invalid': hasError('end_date')}" @focus="clearError('end_date')" v-model="inputUser.end_date">
                            <small class="invalid-feedback" v-if="hasError('end_date')">{{ getError('end_date') }}</small>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <FormLabel label="Content" />
                    <MdEditor language="en-US" :toolbarsExclude="excludedToolbars" v-model="inputUser.content" />
                </div>
                <div v-if="mode === 'create'">
                    <h2>Bulk Generate Voucher</h2>
                    <div class="form-group mb-3 row">
                        <div class="form-group col">
                            <FormLabel label="Quantity" is-required />
                            <div class="col">
                                <input class="form-control" :class="{ 'is-invalid': hasError('quantity') }" type="number" v-model="inputUser.quantity" @focus="clearError('quantity')">
                                <ErrorMessage :has-error="hasError('quantity')" :message="getError('quantity')" />
                                <HelperText text="Number of vouchers you wish to generate." />
                            </div>
                        </div>
                        <div class="col">
                            <FormLabel label="Discount Amount" is-required />
                            <div class="input-group">
                                <input class="form-control" :class="{ 'is-invalid': hasError('discount_amount') }" type="number" step="0.01" v-model="inputUser.discount_amount" @focus="clearError('discount_amount')">
                                <select class="form-select flex-grow-0" style="width: 120px" name="is_fixed_discount" v-model="inputUser.is_fixed_discount">
                                    <option :value="true">Flat (RM)</option>
                                    <option :value="false">%</option>
                                </select>
                            </div>
                            <ErrorMessage :has-error="hasError('discount_amount')" :message="getError('discount_amount')" />
                            <HelperText text="Discount amount. Based on type of discount." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="No. of Max Uses" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('max_uses') }" type="number" v-model="inputUser.extra_attributes.max_uses" @focus="clearError('max_uses')">
                            <ErrorMessage :has-error="hasError('max_uses')" :message="getError('max_uses')" />
                            <HelperText text="Maximum number of voucher usages. Leave blank to set allow unlimited usage." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="No. of Max Uses per User" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('max_uses_per_user') }" type="number" v-model="inputUser.extra_attributes.max_uses_per_user" @focus="clearError('max_uses_per_user')">
                            <ErrorMessage :has-error="hasError('max_uses_per_user')" :message="getError('max_uses_per_user')" />
                            <HelperText text="Maximum number of voucher usages per user. Leave blank to set grant every user unlimited usage." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Minimum Sessions to Applicable" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('min_sessions') }" type="number" v-model="inputUser.extra_attributes.min_sessions" @focus="clearError('min_sessions')">
                            <ErrorMessage :has-error="hasError('min_sessions')" :message="getError('min_sessions')" />
                            <HelperText text="Minimum number of sessions per booking. Leave blank to not set minimum number of sessions." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Minimum Hours to Applicable" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('min_hours_per_session') }" type="number" v-model="inputUser.extra_attributes.min_hours_per_session" @focus="clearError('min_hours_per_session')">
                            <ErrorMessage :has-error="hasError('min_hours_per_session')" :message="getError('min_hours_per_session')" />
                            <HelperText text="Minimum number per sessions. Leave blank to not set minimum number of hours per session." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Valid on Public Holidays" />
                        <div class="col">
                            <RadioInput
                                v-model="inputUser.extra_attributes.is_available_on_holidays" :value="false"
                                :checked="false === inputUser.extra_attributes.is_available_on_holidays"
                                name="is_available_on_holidays" label="No"
                            />
                            <RadioInput
                                v-model="inputUser.extra_attributes.is_available_on_holidays" :value="true"
                                :checked="true === inputUser.extra_attributes.is_available_on_holidays"
                                name="is_available_on_holidays" label="Yes"
                            />
                        </div>
                        <ErrorMessage :has-error="hasError('available_holiday')"
                            :message="getError('available_holiday')" />
                        <HelperText text="Select Yes if voucher is valid for public holidays" />
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Increment Discount for Each Bundle Minimum  Session" />
                        <div class="col">
                            <RadioInput
                                v-model="inputUser.extra_attributes.increment_discount_each_bundle"
                                :value="false"
                                :checked="false === inputUser.extra_attributes.increment_discount_each_bundle"
                                name="increment_discount_each_bundle" label="No"
                            />
                            <RadioInput
                                v-model="inputUser.extra_attributes.increment_discount_each_bundle"
                                :value="true"
                                :checked="true === inputUser.extra_attributes.increment_discount_each_bundle"
                                name="increment_discount_each_bundle" label="Yes"
                            />
                        </div>
                        <ErrorMessage
                            :has-error="hasError('increment_discount_each_bundle')"
                            :message="getError('increment_discount_each_bundle')"
                        />
                        <HelperText
                            text="Discount amount will be multiply by discount amount for each bundle
                            session that meet requirement voucher setting except this field. Minimum Sessions to
                            Applicable field must be filled before set this."
                        />
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit" :disabled="isSaving">
                        {{ isSaving ? 'Saving...' : 'Save Promotion' }}
                    </button>
                </div>
            </div>
        </form>
        <overlay-spinner :is-showing="isSaving"></overlay-spinner>
    </div>
</template>

<script setup lang="ts">
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import HelperText from '@/components/form/HelperText.vue'
import RadioInput from '@/components/form/RadioInput.vue'
import useCamelToSnake from '@/composable/useCamelToSnake'
import useFormError from '@/composable/useFormError'
import { $externalResults, rules } from '@/rules/PromotionRule'
import PromotionService from '@/services/settings/PromotionService'
import { useNotificationsStore } from '@/stores/notifications'
import UserInput from '@/types/Input'
import { useVuelidate } from '@vuelidate/core'
import { MdEditor } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
    mode: {
        type: String,
        required: true,
        validator(value: string) {
            return ['create', 'update'].includes(value)
        },
    },
    promotion: {
        type: Object as () => object | any,
    },
})
const router = useRouter()
const excludedToolbars: Array<string> = [
    'task',
    'image',
    'table',
    'mermaid',
    'katex',
    'revoke',
    'next',
    'save',
    'pageFullscreen',
    'fullscreen',
    'htmlPreview',
    'catalog',
    'github',
]
const { addToastNotification } = useNotificationsStore()

const promotionForm = ref<HTMLElement>()
const isSaving = ref<boolean>(false)
const inputUser = ref<UserInput>({
    name: '',
    code_prefix: '',
    description: '',
    start_date: '',
    end_date: '',
    content: '',
    quantity: 1,
    discount_amount: 0,
    is_fixed_discount: false,
    extra_attributes: {
        is_available_on_holidays: false,
        increment_discount_each_bundle: false,
        max_uses: null,
        max_uses_per_user: null,
        min_sessions: null,
        min_hours_per_session: null
    }
})

const v$ = useVuelidate(rules(props.mode), { inputUser }, { $externalResults })
const { hasError, getError, clearError } = useFormError(v$)

onMounted(() => {
    if (props.promotion) {
        Object.assign(inputUser.value, useCamelToSnake(props.promotion))

        inputUser.value.content = inputUser.value.content ?? ''
    }
})

const save = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (!validated) {
        promotionForm.value?.scrollIntoView()
        return
    }

    isSaving.value = true

    const promotionService = props.mode === 'create'
        ? PromotionService.store(getFormattedData())
        : PromotionService.update(props.promotion?.id, inputUser.value)

    promotionService
        .then(() => {
            addToastNotification({
                message: `Promotion ${inputUser.value.name} ${props.mode}d`,
                type: 'success',
            })

            props.mode === 'create'
                ? router.push({ name: 'promotion-index' })
                : router.push({ name: 'promotion-show', params: { promotionId: props.promotion.id } })
        })
        .catch(({ response: { data, status } }) => {
            if (status === 422) {
                const errors = handleErrorMessages(data.errors)
                Object.assign($externalResults.value.inputUser, errors)
                promotionForm.value?.scrollIntoView()
            } else {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            }

            isSaving.value = false
        })
}

const getFormattedData = () => {

    const inputs = JSON.parse(JSON.stringify(inputUser.value))

    const data = {
        promotion: {
            name: inputs.name,
            code_prefix: inputs.code_prefix,
            description: inputs.description,
            start_date: inputs.start_date,
            end_date: inputs.end_date,
            content: inputs.content,
        },
        quantity: inputs.quantity,
        discount_amount: inputUser.value.is_fixed_discount ? (inputUser.value.discount_amount * 100) : inputUser.value.discount_amount,
        is_fixed_discount: inputs.is_fixed_discount,
        extra_attributes: {
            is_available_on_holidays: inputs.extra_attributes.is_available_on_holidays === 'true',
            increment_discount_each_bundle: inputs.extra_attributes.increment_discount_each_bundle === 'true',
            min_sessions: inputs.extra_attributes.min_sessions,
            max_uses: inputs.extra_attributes.max_uses,
            max_uses_per_user: inputs.extra_attributes.max_uses_per_user,
            min_hours_per_session: inputs.extra_attributes.min_hours_per_session,
        },
    }

    return data
}

const handleErrorMessages = (dataErrors: any) => {
    interface ErrorObject {
        [key: string]: string[]
    }

    const removePrefix = (key: string): string => {
        for (const prefix of ['promotion.', 'extra_attributes.']) {
            if (key.startsWith(prefix)) {
                return key.slice(prefix.length)
            }
        }

        return key
    }

    const errors: ErrorObject = Object.keys(dataErrors)
        .reduce((result: ErrorObject, key: string) => {
            const strippedKey = removePrefix(key)
            result[strippedKey] = dataErrors[key]
            return result
        }, {})

    return errors
}
</script>