import httpClientV2 from './HttpClientV2'
import { GenderEnum } from '@/enums/GenderEnum'
import { getQueryString } from '@/composable/http'

export interface DependentData {
    user_id: number;
    dependent_type_id: number;
    name: string;
    gender: GenderEnum;
    birth_date?: string | null;
    nationality_id?: number | null;
    race_id?: number | null;
    religion_id?: number | null;
    nric_passport?: string | null;
    phone_no?: string | null;
    medical?: string | null;
    allergies?: string | null;
    is_emergency_contact?: boolean | null;
    language_ids?: Array<any>;
}

export default {
    index(query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`backoffice/dependents?${queryString}`)
    },

    store(data: DependentData) {
        return httpClientV2.post('/backoffice/dependents', data)
    },

    show(dependentId: number) {
        return httpClientV2.get(`backoffice/dependents/${dependentId}`)
    },

    update(dependentId: number, data: object = {}) {
        return httpClientV2.patch(`/backoffice/dependents/${dependentId}`, data)
    },

    destroy(dependentId: number) {
        return httpClientV2.delete(`/backoffice/dependents/${dependentId}`)
    }
}
