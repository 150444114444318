import { ref, computed } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import UserInput from '@/types/Input'
import { required, requiredIf, sameAs, minLength, maxLength, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = (input: UserInput) => {
    return computed(() => {
        return {
            inputUser: {
                name: {
                    required: helpers.withMessage(
                        'This name field is required',
                        required
                    ),
                    maxLength: helpers.withMessage(
                        'This name field may not be greater than 255 characters',
                        maxLength(255)
                    ),
                },
                password: {
                    minLength: helpers.withMessage(
                        'The password field must be at least 8 characters',
                        minLength(8)
                    ),
                    maxLength: helpers.withMessage(
                        'This password field may not be greater than 255 characters',
                        maxLength(255)
                    ),
                },
                password_confirmation: {
                    requiredIf: helpers.withMessage(
                        'This password confirmation field is required',
                        requiredIf(input.password !== '')
                    ),
                    sameAs: helpers.withMessage(
                        'The password confirmation does not match',
                        sameAs(input.password)
                    )
                }
            },
        }
    })
}