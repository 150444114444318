import httpClientV2 from './HttpClientV2'

export default {
    updateActiveStatus(data: UpdateActiveStatusRequest) {
        return httpClientV2.post('/backoffice/sendbird-chat-channels/update-active-status', data)
    }
}

interface UpdateActiveStatusRequest {
    isActive: boolean,
    channelUrl: string,
    channelType: string,
}
