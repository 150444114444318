<template>
    <router-link :to="redirectTo" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Sessions</span>
    </router-link>

    <job-form :redirect-to="redirectTo"></job-form>
</template>

<script setup lang="ts">
import JobForm from '@/pages/jobs/JobForm.vue'

const redirectTo = {
    name: 'job-index'
}
</script>