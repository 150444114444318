<template>
    <SessionPreferredCarersAlert :preferred-carers="preferredCarers" />

    <div class="row mb-4">
        <h4>Active Applications</h4>
        <ActiveSessionCarerApplicationList/>
    </div>

    <div class="row mb-4">
        <h4>Inactive Applications</h4>
        <InactiveCarerApplicationList/>
    </div>
</template>

<script setup lang="ts">

import InactiveCarerApplicationList
    from "@/pages/sessions/components/SessionCarerApplication/InactiveCarerApplicationList.vue";
import ActiveSessionCarerApplicationList
    from "@/pages/sessions/components/SessionCarerApplication/ActiveSessionCarerApplicationList.vue";
import SessionPreferredCarersAlert from "@/pages/sessions/components/SessionPreferredCarersAlert.vue";
import {useSessionCarerApplicationStore} from '@/stores/sessionCarerApplication'
import {storeToRefs} from 'pinia'

const {
    preferredCarers
} = storeToRefs(useSessionCarerApplicationStore())
</script>