import { defineStore } from 'pinia'
import { Ticket, TicketType } from '@/types/Ticket'

export const useTicketStore = defineStore('ticket', {
    state: () => {
        return {
            currentTicketType: {} as TicketType,
            ticketTypes: [] as Array<any>,
            tickets: [] as Array<Ticket>
        }
    },
    actions: {
        updateStatusSuccessAction(payload: Ticket) {
            const idx = this.tickets.findIndex(ticket => ticket.id === payload.id)
            this.tickets.splice(idx, 1, payload)
        },
        updateAssigneeSuccessAction(payload: Ticket) {
            this.tickets.push(payload)
        },
        updateRemarkSuccessAction(payload: Ticket) {
            const idx = this.tickets.findIndex(ticket => ticket.id === payload.id)
            this.tickets.splice(idx, 1, payload)
        },
    }
})
