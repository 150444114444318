<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Date Range</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range" readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar"/>
                        </span>
                    </div>
                </div>
                
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchInvoiceItems" :is-loading="isLoading"></button-filter>
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button class="btn btn-primary" @click="generateReport">
                        <icon name="file-spreadsheet"/>
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Job ID</th>
                            <th>Job No</th>
                            <th>Customer ID</th>
                            <th>Customer</th>
                            <th>Started At</th>
                            <th>Ended At</th>
                            <th>Total Hours</th>
                            <th>Extension Hours</th>
                            <th>Total Children</th>
                            <th>Off Hours</th>
                            <th>Overnight</th>
                            <th>Status</th>
                            <th>Carer ID</th>
                            <th>Carer</th>
                            <th>Bank Details</th>
                            <th>Invoice Amount</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody v-if="invoiceItems.length > 0">
                        <tr v-for="item in invoiceItems" :key="item.index">
                            <td>{{ item.job.id }}</td>
                            <td>{{ item.job.no }}</td>
                            <td>{{ item.job.booking.user.main_profile?.online_id }}</td>
                            <td>{{ item.job.booking.user.main_profile?.full_name }}</td>
                            <td>{{ date.format(item.job.started_at, 'DD-MM-YYYY') }}</td>
                            <td>{{ date.format(item.job.ended_at, 'DD-MM-YYYY') }}</td>
                            <td>{{ item.job.total_hours }}</td>
                            <td>{{ item.job.extension_hours }}</td>
                            <td>{{ item.job.total_children }}</td>
                            <td>{{ item.job.off_hours }}</td>
                            <td>{{ item.job.overnight }}</td>
                            <td><span class="badge badge-outline text-primary">{{ _.startCase(item.job.status) }}</span></td>
                            <td>{{ item.job.carer.main_profile?.online_id }}</td>
                            <td>{{ item.job.carer?.name }}</td>
                            <td v-if="item.job.carer?.carer?.bank?.name || item.job.carer?.carer?.bank_details">({{ item.job.carer?.carer?.bank?.name }}) - {{ item.job.carer?.carer?.bank_details }}</td>
                            <td v-else>-</td>
                            <td>{{ toMYR(item.amount) }}</td>
                            <td>{{ item.type?.type }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="16" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isLoading" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { Core, easepick } from '@easepick/core'
import date from '@/utils/date'
import { toMYR } from '@/composable/useCurrency'
import { onMounted, ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import useDateRangePicker from '@/composable/useDateRangePicker'
import ReportService from '@/services/ReportService'

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
const invoiceItems = ref<Array<any>>([])
const inputFilter = ref<UserFilter>({
    from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { easePickDefaultOptions } = useDateRangePicker()

const isLoading = ref<boolean>(false)

fetchInvoiceItems()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchInvoiceItems()
    }
)

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value = {
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT),
                }
                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})

function resetFilter () {
    inputFilter.value = {
        from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
    }

    fetchInvoiceItems()
}

function fetchInvoiceItems () {
    isLoading.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    ReportService.getPayoutExtra(query)
        .then(({ data }) => {
            let meta = {
                current_page: data.current_page,
                last_page: data.last_page,
                from: data.from,
                to: data.to,
                total: data.total,
            }

            invoiceItems.value = data.data
            updateMetaPage(meta)
        })
        .finally(() => {
            isLoading.value = false
        })
}

function generateReport () {
    isLoading.value = true
    const query = {
        ...inputFilter.value,
    }

    ReportService.generateReport('payouts', query)
        .then(({ data: { data, meta } }) => { })
        .finally(() => {
            isLoading.value = false
        })
}
</script>
