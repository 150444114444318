<template>
    <carer-form :redirectTo="redirectTo"></carer-form>
</template>

<script setup lang="ts">
import CarerForm from './CarerForm.vue'

const redirectTo = {
    name: 'carer-index'
}
</script>
