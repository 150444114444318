import { SessionCarerApplicationStatusEnum } from '@/enums/SessionCarerApplicationStatusEnum'
import SessionCarerApplicationStatus from '@/types/SessionCarerApplicationStatus'

export function getApplicationStatusBadge (statusId: number): string {
    const prefix = 'bg'
    let postfix

    switch (statusId) {
        case SessionCarerApplicationStatusEnum.ID_ACCEPTED:
            postfix = 'success'
            break
        case SessionCarerApplicationStatusEnum.ID_APPLIED:
            postfix = 'primary'
            break
        case SessionCarerApplicationStatusEnum.ID_DECLINED:
            postfix = 'danger'
            break
        case SessionCarerApplicationStatusEnum.ID_NEGOTIATED:
            postfix = 'warning'
            break
        case SessionCarerApplicationStatusEnum.ID_CARER_CANCELLED:
            postfix = 'danger'
            break
        case SessionCarerApplicationStatusEnum.ID_CUSTOMER_CANCELLED:
            postfix = 'danger'
            break
        case SessionCarerApplicationStatusEnum.ID_ADMIN_CANCELLED:
            postfix = 'danger'
            break
        case SessionCarerApplicationStatusEnum.ID_CHECKING_WITH_CARER:
            postfix = 'warning'
            break
        case SessionCarerApplicationStatusEnum.ID_APPLIED_WITH_ACCEPTED_NEGOTIATION:
            postfix = 'primary'
            break
        case SessionCarerApplicationStatusEnum.ID_APPLIED_WITH_REJECTED_NEGOTIATION:
            postfix = 'primary'
            break
        case SessionCarerApplicationStatusEnum.ID_AUTO_CANCELLED:
            postfix = 'danger'
            break
        default:
            postfix = 'dark'
    }

    return `${prefix}-${postfix}`
}

export function getApplicationStatusLabel (statusId: number, sessionCarerApplicationStatuses: Array<SessionCarerApplicationStatus>) {
    return sessionCarerApplicationStatuses.find((findApplicationStatus: SessionCarerApplicationStatus) => findApplicationStatus.id === statusId)?.label ?? 'N/A'
}
