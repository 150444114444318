<template>
    <div class="card">
        <form @submit.prevent="saveBooking">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="form-group col-3">
                        <label class="form-label">Customer <span class="text-danger">*</span></label>
                        <vue-select :options="customerOptions" 
                            @search="onSearchCustomer" 
                            v-model="inputUser.customer" 
                            :reduce="(customer: any) => customer.data" 
                            label="name" 
                            :class="{'is-invalid': hasError('customer')}"
                            @option:change="clearError('customer')"
                            placeholder="Search customer"/>
                        <small class="invalid-feedback" v-if="hasError('customer')">{{ getError('customer') }}</small>
                    </div>
                    <div class="form-group col-3">
                        <label class="form-label">Discount Code</label>
                        <input type="text" class="form-control" v-model="inputUser.discount_code">
                    </div>
                </div>
                <hr />
                <div class="row mb-3">
                    <h2>Carer Preferences</h2>
                </div>
                <div class="row mb-3 gap-4">
                    <div class="col form-group">
                        <label class="form-label col-form-label">Language</label>
                        <div class="col">
                            <div class="row">
                                <div class="col-4 py-2" v-for="(lang, index) in languages" :key="index">
                                    <label class="form-check mb-0" :for="`lang-${lang.name}`">
                                        <input class="form-check-input" type="checkbox" name="languages" v-model.number="inputUser.carer_language" :value="lang.name" :id="`lang-${lang.name}`">
                                        <span class="form-check-label">{{ lang.name }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col form-group">
                        <label class="form-label col-form-label">Races</label>
                        <div class="col">
                            <div class="row">
                                <div class="col-4 py-2" v-for="(race, index) in races" :key="index">
                                    <label class="form-check mb-0" :for="`race-${race.name}`">
                                        <input class="form-check-input" type="checkbox" name="races" v-model.number="inputUser.carer_race" :value="race.name" :id="`race-${race.name}`">
                                        <span class="form-check-label">{{ race.name }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col form-group">
                        <label class="form-label col-form-label">Religions</label>
                        <div class="col">
                            <div class="row">
                                <div class="col-4 py-2" v-for="(religion, index) in religions" :key="index">
                                    <label class="form-check mb-0" :for="`religion-${religion.name}`">
                                        <input class="form-check-input" type="checkbox" name="religions" v-model.number="inputUser.carer_religion" :value="religion.name" :id="`religion-${religion.name}`">
                                        <span class="form-check-label">{{ religion.name }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row mb-3">
                    <h2>Additional Info</h2>
                </div>
                <div class="row mb-3">
                    <div class="form-group col-4">
                        <label class="form-label">Adult Presence (required for child below 6 months)</label>
                        <div class="input-icon mb-2">
                            <textarea class="form-control" rows="3" v-model="inputUser.adult_present"></textarea>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label class="form-label">Pets in the House</label>
                        <div class="input-icon mb-2">
                            <textarea class="form-control" rows="3" v-model="inputUser.pets_present"></textarea>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label class="form-label">Any other details or special requirements</label>
                        <div class="input-icon mb-2">
                            <textarea class="form-control" rows="3" v-model="inputUser.special_requirements"></textarea>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="d-flex justify-content-between mb-3">
                    <h2>Sessions</h2>
                    <a href="#" @click.prevent="() => addJob()" class="fw-bold fs-3 ml-auto" role="button">
                        <icon name="plus" class="me-1"/>
                        Add Session
                    </a>
                </div>
                <booking-create-session-form v-for="(job, index) in inputUser.jobs" :key="`job-${job.jobCounter}`" :index="index" :address-options="addressOptions" :child-options="childOptions" :job="job" @update-job="updateJob" @copy-job="copyJob" @remove-job="removeJob" />
            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit">Create Booking</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import router from '@/router'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, ref, watch } from 'vue'
import _ from 'lodash/fp'
import CustomerService from '@/services/users/CustomerService'
import { useResourcesStore } from '@/stores/resources'
import { storeToRefs } from 'pinia'
import { getMainProfile } from '@/composable/profile'
import User from '@/types/User'
import UserInput from '@/types/Input'
import Job from '@/types/Job'
import Child from '@/types/Child'
import Carer from '@/types/Carer'
import Customer from '@/types/Customer'
import Address from '@/types/Address'
import ChildService from '@/services/ChildService'
import AddressService from '@/services/AddressService'
import BookingService from '@/services/BookingService'
import { useNotificationsStore } from '@/stores/notifications'
import useFormError from '@/composable/useFormError'
import { $externalResults, rules as BookingRule } from '@/rules/BookingRule'
import BookingCreateSessionForm from '@/pages/bookings/BookingCreateSessionForm.vue'
import { dateObject } from '@/composable/useDate'

const { addToastNotification } = useNotificationsStore()
const { races, religions, languages } = storeToRefs(useResourcesStore())

const customerOptions = ref<Array<Customer>>([])
const addressOptions = ref<Array<Address>>([])
const childOptions = ref<Array<Child>>([])

const inputUser = ref<UserInput>({
    customer: '',
    discount_code: '',
    adult_present: '',
    pets_present: '',
    special_requirements: '',
    carer_race: [],
    carer_religion: [],
    carer_language: [],
    jobs: [] as Array<Job>
})

const v$ = useVuelidate(BookingRule, { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

const jobCounter = ref<number>(0)

const DEFAULT_JOB_DATA = {
    start_time: dateObject().add(3, 'h').set('m', 0).set('s', 0).format('YYYY-MM-DD HH:mm:ss'),
    duration: 2,
    assigned_carer: null,
    address: null,
    children: [],
    jobCounter: jobCounter.value,
}

onMounted(() => {
    addJob()
})

watch(
    () => inputUser.value.customer,
    async (customer: any) => {
        if (customer) {
            fetchAddresses(customer.id)
            fetchChildren(customer.id)
        } else {
            addressOptions.value = []
            childOptions.value = []

            const job = Object.assign({}, DEFAULT_JOB_DATA)
            job.jobCounter = ++jobCounter.value
            inputUser.value.jobs = [job]
        }
    }
)

const addJob = (job?: any) => {
    if (! job) {
        job = Object.assign({}, DEFAULT_JOB_DATA)
    }

    job.jobCounter = ++jobCounter.value
    inputUser.value.jobs.push(job)
}

const findJobIndexByCounter = (jobCounter: number) => inputUser.value.jobs.findIndex((job: any) => job.jobCounter === jobCounter)

const removeJob = (jobCounter: number) => {
    if (inputUser.value.jobs.length > 1) {
        const index = findJobIndexByCounter(jobCounter)

        if (index !== -1) {
            inputUser.value.jobs.splice(index, 1)
        }
    }
}

const copyJob = (jobCounter: number) => {
    const index = findJobIndexByCounter(jobCounter)

    if (index !== -1) {
        const job = Object.assign({}, inputUser.value.jobs[index])
        job.start_time = dateObject(job.start_time).add(1, 'd').format('YYYY-MM-DD HH:mm:ss')

        addJob(job)
    }
}

const updateJob = (updatedJob: any) => {
    const index = findJobIndexByCounter(updatedJob.jobCounter)

    if (index !== -1) {
        inputUser.value.jobs[index] = updatedJob
    }
}

const onSearchCustomer = (search: string, loading: Function) => {
    if (search.length) {
        searchCustomers(search, loading)
    }
}

const searchCustomers = _.debounce(350, (search, loading) => {
    loading(true)

    CustomerService.index({ search })
        .then(({ data: { data } }) => {
            customerOptions.value = data.map((customer: User) => {
                const mainProfile = getMainProfile(customer.profiles)

                return {
                    id: customer.id,
                    name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`,
                    data: customer,
                }
            })
        })
        .catch(() => { })
        .finally(() => loading(false))
})

async function saveBooking () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        const formattedData = formatData(inputUser.value)

        BookingService.store(formattedData)
            .then(({ data: data }) => {
                addToastNotification({
                    message: 'Booking saved',
                    type: 'success',
                })
                router.push({ name: 'booking-show', params: { bookingId: data.booking_id } })
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    // Object.assign($externalResults.value.inputUser, data.errors)
                    addToastNotification({
                        message: 'Please fill up all the required form.',
                        type: 'danger',
                    })
                } else {
                    addToastNotification({
                        message: 'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}

function fetchChildren (userId: number) {
    ChildService.index({ user_id: userId })
        .then(({ data: { data, meta } }) => {
            childOptions.value = data
        })
        .catch(() => { })
}

function fetchAddresses (userId: number) {
    AddressService.index({ user_id: userId })
        .then(({ data: { data, meta } }) => {
            addressOptions.value = data
        })
        .catch(() => { })
}

function formatData (obj: UserInput) {
    obj.jobs = obj.jobs.map((job: any) => {
        let start_time: string = job.start_time
        let end_time: string = dateObject(start_time).add(job.duration, 'h').set('m', 0).set('s', 0).format('YYYY-MM-DD HH:mm:ss')
        return { ...job, end_time: end_time }
    })

    return {
        ...obj,
        sessions: obj.jobs
    }
}
</script>
