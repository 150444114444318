import { getQueryString } from '@/composable/http'
import UpdateSessionReviewFormData from '@/types/formData/UpdateSessionReviewFormData'
import httpClientV2 from './HttpClientV2'

const endpoint = 'backoffice/session-reviews'

export default {
    index(query?: {
        user_id?: number,
        session_id?: number,
        status?: string,
        rating?: string,
        from_date?: string,
        to_date?: string,
        perPage?: number,
        page?: number, 
    }) {
        const queryString = getQueryString(query)

        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    update(id: number, data: UpdateSessionReviewFormData) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },

    generateReport(data: object = {}) {
        return httpClientV2.post('/backoffice/reports/session-review', data)
    },
}
