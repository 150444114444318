<template>
    <div class="card">
        <form @submit.prevent="saveOrganization" enctype="multipart/form-data">
            <div class="card-body">
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Name <span class="text-danger">*</span></label>
                    <div class="col">
                        <input class="form-control" type="text" v-model="inputUser.name">
                        <small class="invalid-feedback"></small>
                        <small class="text-muted">Organization Name</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Organization Logo (optional)</label>
                    <div class="col">
                        <div v-if="props.organization && props.organization.logo_url" class="form-group mb-3 row">
                            <label class="form-label col-form-label">Existing Logo</label>
                            <div class="col">
                                <img :src="props.organization.logo_url" alt="Logo" width="50" />
                            </div>
                        </div>
                        <div class="input-group input-group-flat">
                            <input class="form-control"
                                   :class="{ }"
                                   id="logo" type="file"
                                   accept="image/png, image/jpeg, image/jpg"
                                   v-on:change="handleFileUpload($event)"
                                   />
                            <span v-if="inputUser.logo" class="input-group-text cursor-pointer" @click.prevent="clearFile">
                                <icon name="x"></icon>
                            </span>
                        </div>
                        <div class="text-muted pt-2 small">Accepted image formats: png, jpg, jpeg</div>
                    </div>
                </div>

            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit">Save Organization</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications'
import OrganizationService from '@/services/settings/OrganizationService'
import UserInput from '@/types/Input'

const { addToastNotification } = useNotificationsStore()
const router = useRouter()
const route = useRoute()
const props = defineProps({
    organization: { type: Object, required: false },
    id: { type: Number, default: 0 },
})
const mode = route.params.organizationId ? 'update' : 'create'

const inputUser = ref<UserInput>({
    name: '',
})

onMounted(() => {
    if (props.organization !== undefined) {
        inputUser.value = props.organization;   
    }
})

async function saveOrganization() {
    const formattedData = formatData(inputUser.value);
    const organizationService =
        mode === 'create'
            ? OrganizationService.store(formattedData)
            : OrganizationService.update(props.id, formattedData);

    try {
        const response = await organizationService;
        if (response.data) {
            addToastNotification({
                message: `Organization ${inputUser.value.name} ${mode}d`,
                type: 'success',
            });
            router.push({ name: 'organization-index' });
        }
    } catch (error) {
        const typedError = error as any;
        if (typedError.response && typedError.response.status === 422) {
            addToastNotification({
                message: typedError.response.data.errors.general[0],
                type: 'danger',
            });
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            });
        }
    }
}

const formatData = (obj: UserInput) => {
    return {
        name: obj.name,
        logo: obj.logo,
    };
};

function handleFileUpload (event: Event) {
    const target = event.target as HTMLInputElement
    if (target && target.files)
        inputUser.value.logo = target.files[0]
}

function clearFile () {
    const imagePath = document.getElementById('logo') as HTMLInputElement
    imagePath.value = ''
    inputUser.value.logo = ''
}

</script>
