import httpClientV2 from '@/services/HttpClientV2'

export default {
    init() {
        return httpClientV2.post('general/init/1')
    },
    updateProfile(data: object = {}) {
        return httpClientV2.patch('backoffice/profile', data)
    },
}
