import { ref, computed } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import UserInput from '@/types/Input'
import { required, requiredIf, email, sameAs, minLength, maxLength, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = (mode: string, input: UserInput) => {
    return computed(() => {
        return {
            inputUser: {
                email: {
                    required: helpers.withMessage(
                        'This email field is required',
                        required
                    ),
                    email: helpers.withMessage(
                        'This email must be a valid email',
                        email
                    )
                },
                name: {
                    required: helpers.withMessage(
                        'This name field is required',
                        required
                    ),
                    maxLength: helpers.withMessage(
                        'This name field may not be greater than 255 characters',
                        maxLength(255)
                    ),
                },
                chat_nickname: {
                    required: helpers.withMessage(
                        'This chat nickname field is required',
                        required
                    ),
                    minLength: helpers.withMessage(
                        'This chat nickname field may not be less than 3 characters',
                        minLength(3)
                    ),
                    maxLength: helpers.withMessage(
                        'This chat nickname field may not be greater than 80 characters',
                        maxLength(80)
                    ),
                },
                password: {
                    requiredIf: helpers.withMessage(
                        'The password field is required',
                        requiredIf(mode === 'create' || (mode === 'update') && input.password !== '' || input.password_confirmation !== '')
                    ),
                    minLength: helpers.withMessage(
                        'The password field must be at least 8 characters',
                        minLength(8)
                    ),
                    maxLength: helpers.withMessage(
                        'This password field may not be greater than 255 characters',
                        maxLength(255)
                    ),
                },
                password_confirmation: {
                    requiredIf: helpers.withMessage(
                        'This password confirmation field is required',
                        requiredIf(mode === 'create' || (mode === 'update') && input.password !== '' || input.password_confirmation !== '')
                    ),
                    sameAs: helpers.withMessage(
                        'The password confirmation does not match',
                        sameAs(input.password)
                    )
                }
            },
        }
    })
}