import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { required, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        remark: {
            required: helpers.withMessage(
                'This remark field is required',
                required
            ),
        },
    },
})