<template>
    <div class="d-flex justify-content-center bg-dark-lt position-absolute bottom-0 start-0 end-0">
        <span class="pt-3 pb-4">This chat has ended. Click <a @click.prevent="toggleChannel" href="#">here</a> to re-open the chat</span>
    </div>
</template>

<script setup lang="ts">
import ChatService from '@/services/ChatService'

const props = defineProps({
    channelUrl: { required: true, type: String },
    channelType: { required: true, type: String },
})

const toggleChannel = () => {
    ChatService.updateActiveStatus({
        isActive: true,
        channelUrl: props.channelUrl,
        channelType: `${props.channelType}_channels`,
    })
}
</script>
