import { CarerPayoutStatusEnum } from '@/enums/CarerPayoutEnum'
import KeyLabel from '@/types/KeyLabel'

export function getCarerPayoutStatus(key: number, carerPayoutStatuses: Array<KeyLabel>) {
    return carerPayoutStatuses.find((status) => status.key === key)
}

export function getStatusBadgeColor(payoutStatus: number){
    switch (payoutStatus) {
        case CarerPayoutStatusEnum.STATUS_GENERATING:
            return 'bg-yellow'
        case CarerPayoutStatusEnum.STATUS_DRAFT:
            return 'bg-warning'
        case CarerPayoutStatusEnum.STATUS_CONFIRMED:
            return 'bg-success'
        default:
            return 'bg-primary'
    }
}
