<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Booking Details</h4>
            <CopyButton :data="bookingScriptTemplate?.innerText ?? undefined"
                        label="Copy"
            />
        </div>
        <div class="card-body">
<pre style="max-height: 400px" ref="bookingScriptTemplate">
Your session details will be as per below:
Customer: * *
Contact No: * *
Booking no: * *

Session No: * *
Date: * *
Time: * *
Address:
Assigned Carer : * *
Carer Contact No: * *

No of children - ?

Child 1:
Age:
Medical Conditions:
Allergies:
Language:
Religion:

Adult presence:
Pets:
Special Requirements:
</pre>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const bookingScriptTemplate = ref<HTMLElement | null>(null)

</script>
