import { getQueryString } from '@/composable/http'
import httpClientV2 from '@/services/HttpClientV2'

export default {
    index(query: object = {}) {
        return httpClientV2.get(`/notifications?${getQueryString(query)}`)
    },

    markNotificationAsRead(data: object = {}) {
        return httpClientV2.post(`/notifications/read`, data)
    },
}
