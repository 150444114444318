<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">3. Enter Redemptions </h4>
        </div>
        <div class="card-body">
            <div>
                <FormLabel label="Voucher "/>
                <input type="text"
                       v-model="form.voucher_code"
                       class="form-control"
                >
                <ErrorMessage :has-error="!!errors.voucher_code"
                              :message="errors.voucher_code?.[0] ?? 'There is an error with this voucher.'"
                />
            </div>
            <div class="mt-3">
                <FormLabel label="Loyalty points"/>
                <div class="col">
                    <HelperText
                        :text="`Redeemable points: ${selectedCustomer.redeemablePoints ?? 'N/A'}`"/>
                    <input type="number"
                           v-model="form.loyalty_points_amount"
                           class="form-control"
                    >
                </div>
                <ErrorMessage :has-error="!!errors.loyalty_points_amount"
                              :message="errors.loyalty_points_amount?.[0] ?? 'There is an error with the loyalty points.'"
                />
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">

import FormLabel from '@/components/form/FormLabel.vue'
import HelperText from '@/components/form/HelperText.vue'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import {useCreateOrderStore} from '@/stores/createOrder'
import {storeToRefs} from 'pinia'

const createOrderStore = useCreateOrderStore()

const {
    form,
    errors,
    selectedCustomer
} = storeToRefs(createOrderStore)

</script>