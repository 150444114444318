<template>
    <router-link :to="{ 'name': 'coupon-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Coupon</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingCoupon" v-if="isFetchingCoupon" />
    <template v-else>
        <div class="page-header mb-4">
            <div class="row align-items-center">
                <div class="col">
                    <h2 class="page-title">{{ coupon.name }}</h2>
                </div>
                <div class="col-auto d-none d-md-flex">
                    <router-link :to="{ name: 'coupon-edit', params: { couponId: coupon.id } }" type="button" class="btn btn-success me-2">Edit</router-link>
                    <button type="button" class="btn btn-danger" @click="deleteCoupon(coupon)">Delete</button>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Code</span>
                        </p>
                        <span class="fw-bold">{{ coupon.code }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Discount Type</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.type }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Discount</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.isFixed ? toMYR(coupon.discountAmount) : `${coupon.discountAmount}%` }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Started At</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.startedAt ? formatDate(coupon.startedAt, 'DD-MMM-YYYY') : '-' }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Expired At</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon?.expiredAt ? formatDate(coupon?.expiredAt, 'DD-MMM-YYYY') : '-' }}</span>
                    </div>
                </div>
                <hr>
                <h3 class="mb-3">Additional Info</h3>
                <div class="row">
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Number of Max Usage</span>
                        </p>
                        <span class="fw-bold">{{ coupon.extraAttributes.maxUses ?? '&infin;' }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Number of Max Usage Per User</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.extraAttributes.maxUsesUser ?? '&infin;' }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Number of Used</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.extraAttributes.bookingCounts }} / {{ coupon.extraAttributes.maxUses ?? '&infin;' }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Minimum Sessions to Applicable</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.extraAttributes.minSessions ?? '-' }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Minimum Hours to Applicable</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.extraAttributes.minHours ?? '-' }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Applicable in Public Holiday</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.extraAttributes.availableHoliday ? 'Yes' : 'No' }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Increment Discount for Each Bundle Minimum Session</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ coupon.extraAttributes.incrementDiscountEachBundle ? 'Yes' : 'No' }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Booking made with this coupon</h4>
            </div>
            <div class="card-body">
                <table class="table" v-if="coupon.bookings.length">
                    <thead>
                        <tr>
                            <th>Booking No</th>
                            <th>Customer Name</th>
                            <th>Status</th>
                            <th>Booking Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="booking in coupon.bookings" :key="booking.id">
                            <td><router-link target="_blank" :to="{ name: 'booking-show', params: { bookingId: booking.id } }">{{ booking.no }}</router-link></td>
                            <td><router-link target="_blank" :to="{ name: 'customer-show', params: { customerId: booking.customerId } }">{{ booking.customerName }}</router-link></td>
                            <td>{{ booking.status }}</td>
                            <td>{{ formatDate(booking.createdAt, 'DD-MMM-YYYY') }}</td>
                        </tr>
                    </tbody>
                </table>
                <template v-else>
                    <p class="text-center">No Data</p>
                </template>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { toMYR } from '@/composable/useCurrency'

import CouponService from '@/services/settings/CouponService'
import { useNotificationsStore } from '@/stores/notifications'
import Coupon from '@/types/Coupon'
import { formatDate } from '@/composable/useDate'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const isFetchingCoupon = ref<boolean>(false)
const couponId = Number(route.params.couponId)
const coupon = ref<Coupon>()

fetchCoupon()

function fetchCoupon () {
    isFetchingCoupon.value = true

    CouponService.show(couponId)
        .then(({ data: { data } }) => {
            coupon.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingCoupon.value = false)
}

function deleteCoupon (coupon: Coupon) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete coupon ${coupon.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            let couponId = Number(coupon.id)

            CouponService.destroy(couponId)
                .then(() => {
                    fetchCoupon()
                    addToastNotification({
                        message: `Coupon ${coupon.name} deleted`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}
</script>
