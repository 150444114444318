import { getQueryString } from '@/composable/http'
import httpClientV2 from '@/services/HttpClientV2'

export default {
    index(query: Record<string, string | number>) {
        return httpClientV2.get(`backoffice/event-registrations?${getQueryString(query)}`)
    },

    exportRegistrants(platformContentId: number) {
        return httpClientV2.get(`backoffice/reports/platform-contents/${platformContentId}/event-registrations`)
    },
}
