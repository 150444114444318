<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Pending Client Approval</h4>
            <CopyButton :data="scriptPendingClientApproval?.innerText ?? undefined"
                        label="Copy"
            />
        </div>
        
        <div class="card-body">
<pre style="max-height: 400px" ref="scriptPendingClientApproval">
Hi sir/maam,

We would like to update that a carer has already applied for your upcoming booking. Kindly confirm as soon as possible on the carer selection and proceed with the payment if you'd like to secure the carer for the booking.

Thank you for your cooperation. Should you need further assistance, please click the help button and we will attend to your request.
</pre>
    </div>
</div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const scriptPendingClientApproval = ref<HTMLElement | null>(null)

</script>
