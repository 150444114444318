<template>
    <div class="dropdown position-static">
        <button type="button" class="btn dropdown-toggle text-muted"
                data-bs-toggle="dropdown">
            <icon name="dots" class="text-secondary"/>
        </button>
        <div class="dropdown-menu dropdown-menu-end" style="width: 164px;">
            <a class="dropdown-item text-primary" role="button"
               @click.prevent="approve"
            >
                <icon name="check" class="me-2 flex-shrink-0"/>
                Accept Negotiations
            </a>
            <a class="dropdown-item text-danger" role="button"
               @click.prevent="reject"
            >
                <icon name="x" class="me-2 flex-shrink-0"/>
                Decline Negotiations
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">

import {useNotificationsStore} from '@/stores/notifications'
import CarerApplicationNegotiationApprovalService from '@/services/CarerApplicationNegotiationApprovalService'
import {AxiosError} from 'axios'

const {addToastNotification} = useNotificationsStore()

const props = defineProps({
    applicationId: {
        type: Number,
        required: true
    },
    carer: {
        type: Object
    }
})

const emits = defineEmits(['onSuccess'])

const approve = async () => {
    try {
        await CarerApplicationNegotiationApprovalService.store(props.applicationId)

        emits('onSuccess')
        addToastNotification({
            message: `Successfully accepted negotiation for Carer ${props.carer?.name}.`,
            type: 'success'
        })
    } catch (err) {
        const error = err as AxiosError

        let message = `Error while accepting negotiation for Carer ${props.carer?.name}. `

        if (error.response?.status === 422) {
            const responseData = error.response.data as { message: string }
            message += responseData.message
        }

        addToastNotification({
            message: message,
            type: 'danger'
        })

    }
}

const reject = async () => {
    try {
        await CarerApplicationNegotiationApprovalService.destroy(props.applicationId)
        emits('onSuccess')
        addToastNotification({
            message: `Rejected changes for Carer ${props.carer?.name}.`,
            type: 'success'
        })
    } catch (err) {
        addToastNotification({
            message: `Error while rejecting changes for Carer ${props.carer?.name}`,
            type: 'danger'
        })
    }
}
</script>
