import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { required, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        name: {
            required: helpers.withMessage(
                'This name field is required',
                required
            ),
        },
        email: {
            required: helpers.withMessage(
                'This email field is required',
                required
            ),
        },
        carer_type_id: {
            required: helpers.withMessage(
                'This carer type field is required',
                required
            ),
        },
        payout_schedule_type: {
            required: helpers.withMessage(
                'This payout schedule field is required',
                required
            ),
        },
        mobile_no: {
            required: helpers.withMessage(
                'This phone no field is required',
                required
            ),
        },
        nric_passport: {
            required: helpers.withMessage(
                'This NRIC/Passport field is required',
                required
            ),
        },
        gender: {
            required: helpers.withMessage(
                'This NRIC/Passport field is required',
                required
            ),
        },
        birth_date: {
            required: helpers.withMessage(
                'This birth date field is required',
                required
            ),
        },
        race_id: {
            required: helpers.withMessage(
                'This race field is required',
                required
            ),
        },
        religion_id: {
            required: helpers.withMessage(
                'This religion field is required',
                required
            ),
        },
        marital_status: {
            required: helpers.withMessage(
                'This marital status field is required',
                required
            ),
        },
        nationality_id: {
            required: helpers.withMessage(
                'This nationality field is required',
                required
            ),
        },
        languages: {
            required: helpers.withMessage(
                'Please select at least 1 language',
                required
            ),
        },
        application: {
            required: helpers.withMessage(
                'Please select carer application',
                required
            ),
        }
    },
})
