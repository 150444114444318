<template>
    <kc-modal modal-id="inviteCarerToChatModal" modal-title="Invite Carer to Chat">
        <div class="row">
            <label class="form-label col-3 col-form-label">Carer <span class="text-danger">*</span></label>
            <div class="col">
                <SearchSelectUser v-model="selectedUser" :user-role="RoleNameEnum.CARER" :query="{ carerState: 'Approved' }"/>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-light" id="inviteCarerToChatCloseModalButton" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click.prevent="inviteUser" :disabled="isInviting || selectedUser == null">
                {{ isInviting ? 'Inviting' : 'Invite' }}
            </button>
        </template>
    </kc-modal>
</template>

<script setup lang="ts">
import SearchSelectUser from '@/components/SearchSelectUser.vue'
import { RoleNameEnum } from '@/enums/RoleEnum'
import { useNotificationsStore } from '@/stores/notifications'
import User from '@/types/User'
import { Member } from '@sendbird/chat/groupChannel'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps({
    participants: { type: Array<Member>, required: true },
    isSuccessfullyInvited: { type: Boolean, default: false },
})

const emit = defineEmits<{
    (e: 'invite', userId: number, isOperator: boolean): void
}>()

const { addToastNotification } = useNotificationsStore()

const selectedUser = ref<User>()
const isInviting = ref<boolean>(false)

const participantUserIds = computed(() => {
    return props.participants?.map((participant: any) => {
        return Number(participant.userId)
    })
})

const isParticipant = (userId: number) => {
    return participantUserIds.value?.includes(userId)
}

const inviteUser = () => {
    if (selectedUser.value === undefined) {
        return
    }

    if (isParticipant(selectedUser.value.id)) {
        addToastNotification({
            message: 'The carer is already one of the participants',
            type: 'danger'
        })

        return
    }

    isInviting.value = true
    emit('invite', selectedUser.value.id, false)
}

watch(
    () => props.isSuccessfullyInvited,
    (isSuccessfullyInvited) => {
        if (isSuccessfullyInvited) {
            document.getElementById('inviteCarerToChatCloseModalButton')?.click()
        }
    }
)

onMounted(() => {
    const modal = document.getElementById('inviteCarerToChatModal')

    if (modal) {
        modal.addEventListener('hidden.bs.modal', () => {
            selectedUser.value = undefined
            isInviting.value = false
        })
    }
})

</script>