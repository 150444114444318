<template>
    <div class="card" :class="{ 'card-borderless': !showTitle }">
        <div class="card-header">
            <h3 class="card-title" v-if="showTitle">Sessions</h3>
            <div class="ms-auto" v-if="showAction">
                <router-link class="btn btn-primary float-right" :to="{ name: 'session-create' }">
                    <icon name="plus"/>
                    Add New Session
                </router-link>
            </div>
        </div>
        <div class="card-body">

            <!-- Filters -->
            <div class="mb-3 pb-3 border-bottom">
                <div class="row mb-3 gap-3 gap-md-0">
                    <div class="col-12 col-md-3 mb-3">
                        <label class="form-label">Session No</label>
                        <input type="text" placeholder="B202104-01250" class="form-control"
                               v-model="inputFilter.session_no"
                               @keyup.enter="fetchSessions"
                        >
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                        <label class="form-label">Customer ID</label>
                        <input type="email" placeholder="P20216288" class="form-control"
                               v-model="inputFilter.customer_id"
                               @keyup.enter="fetchSessions"
                        >
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                        <label class="form-label">From</label>
                        <div class="input-group">
                            <input type="text" id="inputFilterSessionDateFrom" class="form-control bg-white"
                                   :value="sessionDateFromDisplay"
                                   readonly
                            >
                            <span class="input-group-text"> <icon name="calendar"/> </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                        <label class="form-label">To</label>
                        <div class="input-group">
                            <input type="text" id="inputFilterSessionDateTo" class="form-control bg-white"
                                   :value="sessionDateToDisplay"
                                   readonly>
                            <span class="input-group-text"> <icon name="calendar"/> </span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 col-md-3">
                        <label class="form-label">Status</label>
                        <vue-select
                            :options="[{ label: 'Urgent', key: 'urgent' }, ...sessionStatuses]"
                            label="adminLabel"
                            :reduce="(status: SessionStatus) => status.id"
                            v-model="inputFilter.status"
                        >
                        </vue-select>
                    </div>
                    <div class="col-12 col-md-3 ">
                        <label class="form-label">Session type</label>
                        <vue-select
                            :options="sessionTypes"
                            label="name"
                            :reduce="(sessionType: SessionType) => sessionType.id"
                            v-model="inputFilter.session_type">
                        </vue-select>
                    </div>
                    <div class="col-12 col-md-3 ">
                        <label class="form-label">Service type</label>
                        <vue-select
                            :options="sessionServiceTypes"
                            label="name"
                            :reduce="(sessionServiceType: SessionServiceType) => sessionServiceType.id"
                            v-model="inputFilter.session_service_type">
                        </vue-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-auto ms-auto">
                        <button-filter @reset="resetFilter" @filter="fetchSessions"
                                       :is-loading="isFetchingSessions"></button-filter>
                    </div>
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                              @per-page-updated="updatePerPage" :is-showing-per-page="true"/>

            <div>
                <div class="table-responsive">
                    <table class="table card-table table-vcenter">

                        <template v-if="sessions.length">
                            <tbody v-for="session in sessions" :key="session.id">

                            <!--  Customer Details -->
                            <tr class="w-1">
                                <td colspan="8" class="w-1 bg-dark-lt text-white" style="vertical-align: middle;">
                                    <div class="d-flex align-items-center gap-2">
                                        <div class="">{{ session.createdAt }}</div>
                                        <div class="badge badge-outline text-uppercase "
                                             :class="[isMobileBooking(getBookingPlatform(session.metadata)) ? 'text-teal' : 'text-yellow']"
                                        >
                                            {{ getBookingPlatform(session.metadata) }}
                                        </div>
                                        <div class="">
                                            <CopyText :data="session.no"
                                                      resource-name="Session No"
                                                      style="min-width: 8rem"
                                            >
                                                <strong>{{ session.no }}</strong>
                                            </CopyText>
                                        </div>
                                        <div class="">[{{ session.customer.mainProfile?.onlineId }}]</div>
                                        <div class="">
                                            <router-link
                                                :to="{ 'name': 'customer-show', params: { userId: session.customer.id } }"
                                                target="_blank" class="fw-bold">
                                                {{ session.customer.mainProfile?.fullName }}
                                            </router-link>
                                        </div>
                                        <status-badge :show="session.customer.isBanned" label="Banned"
                                                      color="red"></status-badge>
                                        <status-badge :show="session.customer.isDeleted" label="Deleted"
                                                      color="gray"></status-badge>
                                        <div class="">
                                            ({{ session.customer.mainProfile?.mobileNo ?? 'N/A' }})
                                        </div>
                                    </div>
                                </td>
                                <td class="w-1 fs-5 bg-dark-lt text-white">
                                    <div class="btn-group">
                                        <router-link :to="{name: 'session-show', params: {sessionId: session.id}}"
                                                     class="btn btn-icon btn-primary"
                                        >
                                            <icon name="eye"/>
                                        </router-link>

                                        <CopyButton :data="getShowSessionPath(session.id)"
                                                    resource-name="Session URL"
                                                    icon="link"
                                        />

                                        <session-whatsapp-template :session="session"
                                        ></session-whatsapp-template>

                                    </div>
                                </td>
                            </tr>

                            <!-- Session Details -->
                            <tr>
                                <th class="w-1 text-center">Status</th>
                                <th class="w-1 text-center">Event</th>
                                <th class="w-1 text-center">Service</th>
                                <th class="text-center col-2">Date</th>
                                <th class="text-center col-2">Time</th>
                                <th class="col-2">Address</th>
                                <th class="col-2">Carer</th>
                                <th class="col-2">Dependents</th>
                                <th class="text-center">Action</th>
                            </tr>
                            <tr style="border-bottom-style: hidden;">
                                <td>
                                    <SessionChangeStatus :session="session"
                                                         @session-status-changed="handleSessionStatusChanged"
                                    />
                                </td>
                                <td>
                                    <SessionMonitoring :session-id="session.id"
                                                       :session-no="session.no"
                                                       :session-event="session.event"
                                                       :carer-logs="session.carerLogs"
                                                       :include-image=false
                                    />
                                </td>
                                <td>
                                    <div class="flex-column ">
                                        <SessionTypeBadge :session-type-id="session.sessionTypeId"
                                                          :session-types="sessionTypes"
                                                          class="mb-1"
                                        />
                                        <SessionServiceTypeBadge :session-service-type-id="session.sessionServiceTypeId"
                                                                 :session-service-types="sessionServiceTypes"
                                        />
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div>{{ session.startedAtDate }}</div>
                                    <div>({{ session.startedAtDay }})</div>
                                </td>
                                <td class="text-center">
                                    <div>{{ session.startedAtTime }} - {{ session.endedAtTime }}</div>
                                    <div class="p-1">({{ session.totalHours }} Hours)</div>
                                </td>
                                <td>
                                    {{ getShortAddress(session.address, addressStates, postcodes) }}
                                </td>
                                <td>
                                    <div  class="d-flex flex-column gap-2">
                                        <SessionCarerApplicationFormModal :session="session" class="mt-1"/>
                                        <UserNameId v-if="session.carer"
                                                    :user="session.carer"
                                                    :role="RoleNameEnum.CARER"
                                        />
                                    </div>
                                </td>

                                <td>
                                    {{ getDependentString(session.dependents) }} <strong>({{
                                        session.dependents.length
                                    }})</strong>
                                </td>

                                <td class="text-center">
                                    <div class="row">
                                        <div class="col">
                                            <icon name="pencil" class="text-info me-2" role="button"
                                                  data-bs-toggle="modal" data-bs-target="#modal-edit-session"
                                                  @click.prevent="modalForm = session"
                                            />
                                        </div>
                                        <div class="col">
                                            <icon name="notes" class="me-2" role="button"
                                                  data-bs-toggle="modal" data-bs-target="#modal-session-remarks"
                                                  @click.prevent="modalForm = session;"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="session.remarks || session.tags">
                                <td colspan="6">
                                    <SessionTags :tags="session.tags"/>
                                </td>
                                <td colspan="3" v-if="session.remarks">
                                    <div class="d-flex justify-content-end">Remark: {{ session.remarks }}</div>
                                </td>
                            </tr>

                            </tbody>
                        </template>

                        <!-- If no data available -->
                        <template v-else>
                            <tbody>
                            <tr>
                                <td colspan="11" class="text-center">No data</td>
                            </tr>
                            </tbody>
                        </template>
                    </table>
                    <overlay-spinner :is-showing="isFetchingSessions"/>
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                              @per-page-updated="updatePerPage"/>
        </div>

        <session-edit-form-modal :session="modalForm" @session-updated="fetchSessions"/>
        <session-remarks :session="modalForm"/>
    </div>
</template>


<script setup lang="ts">
import Icon from '@/components/Icon.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import useBooking from '@/composable/useBooking'
import {formatDate} from '@/composable/useDate'
import {getDependentString} from '@/composable/useDependent'
import useMetaPage from '@/composable/useMetaPage'
import {getShortAddress, getShowSessionPath} from '@/composable/useSession'
import SessionChangeStatus from '@/pages/sessions/components/SessionChangeStatus.vue'
import SessionTags from '@/pages/sessions/components/SessionTags.vue'
import SessionEditFormModal from '@/pages/sessions/SessionEditFormModal.vue'
import SessionRemarks from '@/pages/sessions/SessionRemarks.vue'
import SessionWhatsappTemplate from '@/pages/sessions/SessionWhatsappTemplate.vue'
import SessionService from '@/services/SessionService'
import {useResourcesStore} from '@/stores/resources'
import UserFilter from '@/types/Filter'
import Session from '@/types/Session'
import SessionServiceType from '@/types/SessionServiceType'
import SessionStatus from '@/types/SessionStatus'
import SessionType from '@/types/SessionType'
import {AmpPlugin} from '@easepick/amp-plugin'
import {Core, easepick} from '@easepick/core'
import {storeToRefs} from 'pinia'
import {computed, onMounted, ref, watch} from 'vue'
import SessionTypeBadge from '@/pages/sessions/components/SessionTypeBadge.vue'
import SessionServiceTypeBadge from '@/pages/sessions/components/SessionServiceTypeBadge.vue'
import SessionMonitoring from '@/pages/sessions/components/SessionMonitoring.vue'
import {useGeneralStore} from '@/stores/general'
import SessionCarerApplicationFormModal
    from '@/pages/sessions/components/SessionCarerApplication/SessionCarerApplicationModal.vue'
import CopyButton from '@/components/CopyButton.vue'
import CopyText from '@/components/CopyText.vue'
import UserNameId from '@/components/UserNameId.vue'
import {RoleNameEnum} from '@/enums/RoleEnum'

const {
    sessionStatuses,
    sessionTypes,
    sessionServiceTypes,
    addressStates,
    postcodes
} = storeToRefs(useResourcesStore())
const {
    getBookingPlatform,
    isMobileBooking,
} = useBooking()

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()

const generalStore = useGeneralStore()

const isFetchingSessions = ref<boolean>(false)
const sessions = ref<Array<Session>>([])
const modalForm = ref<any>({})
const inputFilter = ref<UserFilter>({
    session_no: '',
    customer_id: '',
    status: '',
    session_type: '',
    session_service_type: '',
    session_date_from: '',
    session_date_to: ''
})

const props = defineProps({
    userId: {type: Number},
    showTitle: {type: Boolean, default: false},
    showAction: {type: Boolean, default: true}
})

let sessionDateFromDatePicker: Core | null = null
let sessionDateToDatePicker: Core | null = null

const sessionDateFromDisplay = computed(() => inputFilter.value.session_date_from ? formatDate(inputFilter.value.session_date_from, 'DD-MMM-YYYY') : '')
const sessionDateToDisplay = computed(() => inputFilter.value.session_date_to ? formatDate(inputFilter.value.session_date_to, 'DD-MMM-YYYY') : '')

const hasFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key) && inputFilter.value[key] !== '') {
            return true
        }
    }

    return false
}

const fetchSessions = async () => {
    isFetchingSessions.value = true
    let query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
            hasFilter: hasFilter()
        },
        ...inputFilter.value
    }

    if (props.userId !== undefined) {
        query = {...query, ...{customer_user_id: props.userId}}
    }

    try {
        const {data: {data, meta}} = await SessionService.index(query)

        sessions.value = data
        updateMetaPage(meta)
    } catch (err) {
    }

    isFetchingSessions.value = false
}


const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchSessions()
}

const handleSessionStatusChanged = ({sessionId, status}: { sessionId: number; status: SessionStatus }) => {
    const rowIndex = sessions.value.findIndex(row => row.id === sessionId)

    if (rowIndex > -1) {
        sessions.value[rowIndex].sessionStatusId = status.id
    }
}


onMounted(() => {
    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 2020,
                maxYear: (new Date().getFullYear())
            }
        }
    }

    sessionDateFromDatePicker = new easepick.create({
        element: <any>document.getElementById('inputFilterSessionDateFrom'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css'
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.session_date_from = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    sessionDateToDatePicker = new easepick.create({
        element: <any>document.getElementById('inputFilterSessionDateTo'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css'
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.session_date_to = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })
})

fetchSessions()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchSessions()
    }
)

watch(
    () => generalStore.isInitCompleted,
    (isCompleted) => {
        if (isCompleted) {
            initChannelListeners()
        }
    }
)

const initChannelListeners = () => {
    window.Echo?.private('backoffice')
        .listen('.SessionCreated', ({model}: any) => {
            if (metaPage.value.currentPage === 1) {
                sessions.value.unshift(model)
                sessions.value.pop()

                if (sessions.value.length === metaPage.value.perPage) {
                    sessions.value.pop()
                }

                let to = metaPage.value.to + 1
                to = Math.min(to, metaPage.value.perPage)

                const total = metaPage.value.total + 1
                const lastPage = Math.ceil(total / metaPage.value.perPage)

                updateMetaPage({
                    ...metaPage.value,
                    current_page: metaPage.value.currentPage,
                    last_page: lastPage,
                    to: to,
                    total: total
                })
            }
        })
        .listen('.SessionUpdated', ({model}: any) => {
            const index = sessions.value.findIndex(session => session.id === model.id)
            if (index !== -1) {
                sessions.value[index] = model
            }
        })
}

</script>


<style scoped>
tr {
    font-size: 13px;
}

tr > td {
    vertical-align: top;
}
</style>
