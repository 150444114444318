<template>
    <div class="d-flex gap-1">
        <button v-for="(status, index) in statuses"
                :key="index"
                class="btn badge-sm py-1 px-3"
                :class="[ isActive(status) ? status.buttonStyle : 'text-black-50' ]"
                @click="emitFilter(status.value)">
            <span class="fw-bolder text-uppercase">{{ status.name }}</span>: {{ ticketType.count?.[status.value] ?? 0 }}
        </button>
        <button v-if="ticketType.status !== null" class="btn badge border-0 text-primary py-1 px-2" @click="emitFilter('')">
            Reset filter
        </button>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const selectedStatus = ref<string>()
const emit = defineEmits(['filter'])

const props = defineProps({
    ticketType: { type: Object as () => any, required: true }
})

const statuses = [
    {
        name: 'New',
        value: 'new',
        buttonStyle: 'bg-primary-lt',
        isDefaultActive: true
    },
    {
        name: 'Attending',
        value: 'attending',
        buttonStyle: 'bg-cyan-lt',
        isDefaultActive: true
    },
    {
        name: 'Follow Up',
        value: 'follow_up',
        buttonStyle: 'bg-warning-lt',
        isDefaultActive: true
    },
    {
        name: 'Resolved',
        value: 'resolved',
        buttonStyle: 'bg-success-lt',
        isDefaultActive: false
    }
]

function isActive(status: any) {
    return selectedStatus.value ? status.value === selectedStatus.value : status.isDefaultActive
}

function emitFilter(status: any) {
    selectedStatus.value = status
    emit('filter', status)
}
</script>

<style scoped>
</style>
