<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Reminder to Customer</h4>
            <CopyButton :data="scriptCustomerReminder?.innerText ?? undefined"
                        label="Copy"
            />
        </div>

        <div class="card-body">
<pre style="max-height: 400px" id="scriptCustomerReminder" ref="scriptCustomerReminder">
COVID19 SOP Reminder
- If you are aware that anyone in the house tests positive with Covid19, kindly inform.
- Have no physical contact with the Kiddocarer.
- Ask the Kiddocarer to change clothes and sanitize regularly.
- Keep social distancing during the session.
- Please keep high risk individuals and away from the area where you plan to use this session.

Before Session
- Kiddocarer will arrive 15 minutes before start of session.
- Allow Kiddocarer to change clothes.
- Kindly help Kiddocarer to update the Service Checklist.
- Ensure that your home is free of safety hazards.
- Keep your valuables somewhere safe.
- In the case of any infectious diseases being present in the session, it is an imperative to inform where hygiene and good health can be assured while session is taking place.

During Session
- You are encouraged to check the Service Channel for hourly updates from the Kiddocarer.
- Please inform the Kiddocarer if you permit photos / videos of your child to be taken for the sole purpose of service updates.
- Please advise other adults present in the house during the session to maintain social distance for health and safety reasons.

Important: There will be ABSOLUTELY no physical contact with the Kiddocarer throughout the session. 

Service Extension
- If you wish to extend the service, please notify Kiddocare Admin in the Service Channel.
- Note that this is, however, dependent on Kiddocarer’s ability to accommodate the extension.
- You have 15 minutes grace period after the scheduled session to get home.
- Take note that an extension fee will be applied after the 15 minutes of grace period.

Complaints and Disputes
- You should make sure to assess the condition of your child(ren), home and valuables before sending the Kiddocarer away.
- It is important to note that upon the check-out of Kiddocarer, the Kiddocarer would be assumed to have completed all tasks.
- Therefore, every complaint, dispute or disagreement should be sorted out before letting go of the Kiddocarer.
- In case of any complaints or disputes, please notify the Kiddocare Admin in the Service Channel.
</pre>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const scriptCustomerReminder = ref<HTMLElement | null>(null)

</script>
