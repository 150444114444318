<template>
    <div class="collapse mb-3 align-items-end gap-3 gap-md-0">
        <div class="row mb-3 gap-3 gap-md-0">
            <div class="col-12 col-md-3">
                <label for="name" class="form-label">Session No</label>
                <input type="text" placeholder="B202104-01250" class="form-control" v-model="inputFilter.session_no">
            </div>
            <div class="col-12 col-md-3">
                <label for="orderNo" class="form-label">Order No</label>
                <input type="text" id="orderNo" class="form-control" v-model="inputFilter.order_no">
            </div>
            <div class="col-12 col-md-3">
                <label class="form-label">Status</label>
                <vue-select
                    :options="[{ adminLabel: 'Urgent', id: 'urgent' }, ...sessionStatuses]"
                    label="adminLabel"
                    :reduce="(status: SessionStatus) => status.id"
                    v-model="inputFilter.status"
                ></vue-select>
            </div>
            <div class="col-12 col-md-3">
                <label class="form-label">Session Type</label>
                <vue-select
                    :options="sessionTypes"
                    label="name"
                    :reduce="(type: SessionType) => type.id"
                    v-model="inputFilter.session_type"
                ></vue-select>
            </div>
        </div>
        <div class="row mb-3 gap-3 gap-md-0">
            <div class="col-12 col-md-3">
                <label class="form-label">Service Type</label>
                <vue-select
                    :options="sessionServiceTypes"
                    label="name"
                    :reduce="(type: SessionServiceType) => type.id"
                    v-model="inputFilter.session_service_type"
                ></vue-select>
            </div>
            <div class="col-12 col-md-3">
                <label for="inputFilterSessionDateFrom" class="form-label">From</label>
                <div class="input-group">
                    <input type="text" id="inputFilterSessionDateFrom" class="form-control bg-white"
                        :value="sessionDateFromDisplay" readonly>
                    <span class="input-group-text">
                        <icon name="calendar" />
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <label for="inputFilterSessionDateTo" class="form-label">To</label>
                <div class="input-group">
                    <input type="text" id="inputFilterSessionDateTo" class="form-control bg-white"
                        :value="sessionDateToDisplay" readonly>
                    <span class="input-group-text">
                        <icon name="calendar" />
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <label for="customer" class="form-label">Customer</label>
                <input type="text" id="customer" class="form-control" placeholder="Email, name, phone number or online ID" v-model="inputFilter.customer">
            </div>
        </div>
        <div class="row mb-3 gap-3 gap-md-0">
            <div class="col-12 col-md-3">
                <label for="carer" class="form-label">Carer</label>
                <input type="text" id="carer" class="form-control" placeholder="Email, name, phone number or online ID" v-model="inputFilter.carer">
            </div>
            <div class="col-12 col-md-3">
                <label for="address" class="form-label">Address</label>
                <input type="text" id="address" class="form-control" v-model="inputFilter.address">
            </div>
            <div class="col-12 col-md-3">
                <label for="addressPostcode" class="form-label">Address Postcode</label>
                <input type="text" id="addressPostcode" class="form-control" v-model="inputFilter.address_postcode">
            </div>
            <div class="col-12 col-md-3">
                <label for="addressState" class="form-label">Address State</label>
                <vue-select
                    :options="addressStates"
                    label="name"
                    :reduce="(state: AddressState) => state.id"
                    v-model="inputFilter.address_state_id"
                ></vue-select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-auto ms-auto">
                <button-filter @reset="resetFilter" @filter="filter" :is-loading="props.isLoading"></button-filter>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import { useResourcesStore } from '@/stores/resources'
import AddressState from '@/types/AddressState'
import UserFilter from '@/types/Filter'
import SessionServiceType from '@/types/SessionServiceType'
import SessionStatus from '@/types/SessionStatus'
import SessionType from '@/types/SessionType'
import { AmpPlugin } from '@easepick/amp-plugin'
import { Core, easepick } from '@easepick/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
    isLoading: {type: Boolean, default: false}
})

const emit = defineEmits(['filter'])

const {
    addressStates,
    sessionServiceTypes,
    sessionStatuses,
    sessionTypes,
} = storeToRefs(useResourcesStore())

const inputFilter = ref<UserFilter>({
    session_no: '',
    order_no: '',
    customer_id: '',
    customer: '',
    carer: '',
    status: '',
    session_type: '',
    session_service_type: '',
    session_date_from: '',
    session_date_to: '',
    live_update: '',
    address: '',
    address_postcode: '',
    address_state: '',
})

const sessionDateFromDisplay = computed(() => inputFilter.value.session_date_from ? formatDate(inputFilter.value.session_date_from, 'DD-MMM-YYYY') : '')
const sessionDateToDisplay = computed(() => inputFilter.value.session_date_to ? formatDate(inputFilter.value.session_date_to, 'DD-MMM-YYYY') : '')

const resetInputFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }
}

const resetFilter = () => {
    resetInputFilter()
    emit('filter', inputFilter.value)
}

const filter = () => {
    emit('filter', inputFilter.value)
}

onMounted(() => {
    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 2020,
                maxYear: (new Date().getFullYear())
            }
        }
    }

    new easepick.create({
        element: <any>document.getElementById('inputFilterSessionDateFrom'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css'
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.session_date_from = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    new easepick.create({
        element: <any>document.getElementById('inputFilterSessionDateTo'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css'
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.session_date_to = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })
})

</script>