<template>
    <div class="card mb-4">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Status</label>
                    <vue-select :options="['all', 'due', 'paid']" class="text-capitalize" v-model="inputFilter.status" />
                </div>

                <div class="col-12 col-md-3">
                    <label class="form-label">Date Range</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range" readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchInvoices" :is-loading="isLoading"></button-filter>
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button class="btn btn-primary" @click="generateReport" :disabled="isLoading">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        <icon v-else name="file-spreadsheet" />
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
        </div>
    </div>
            
    <template v-if="invoices.length">
        <div class="card mb-4" v-for="(invoice, index) in invoices" :key="index">
            <div class="card-header flex space-x-2">
                <h3 class="card-title">Invoice Number {{ invoice.no }}</h3>
                <span class="badge text-uppercase" :class="[invoice.status === 'due' ? 'bg-danger' : 'bg-cyan']">{{ invoice.status }}</span>
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <icon name="calendar" class="me-2" />
                            <span>Date</span>
                        </p>
                        <span class="fw-bold">{{ date.format(invoice.created_at, 'DD-MMM-YYYY') }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <icon name="receipt" class="me-2" />
                            <span>Invoice Number</span>
                        </p>
                        <span class="fw-bold">{{ invoice.no }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <icon name="receipt" class="me-2" />
                            <span>Booking No</span>
                        </p>
                        <span class="fw-bold">{{ invoice.booking.no }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <icon name="user" class="me-2" />
                            <span>Customer</span>
                        </p>
                        <span class="fw-bold">{{ invoice.booking.user.fullName }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <icon name="id-badge" class="me-2" />
                            <span>Online ID</span>
                        </p>
                        <span class="fw-bold">{{ invoice.booking.user.onlineId }}</span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <icon name="cash" class="me-2" />
                            <span>Amount</span>
                        </p>
                        <span class="fw-bold">{{ toMYR(invoice.totalAmount) }}</span>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col">
                        <h4>Invoice Items</h4>

                        <table class="table">
                            <thead>
                            <tr>
                                <th width="15%">Job</th>
                                <th>Item</th>
                                <th class="text-end">Amount</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="item in invoice.items" :key="item.id">
                                <td>{{ item.job?.no }}</td>
                                <td>{{ item.type.type }}</td>
                                <td class="text-end">{{ toMYR(item.amount) }}</td>
                            </tr>
                            <template v-for="(job, index) in invoice.jobs" :key="index">
                                <tr v-for="invoiceItem in job.items">
                                    <td>{{ job.no ?? '' }}</td>
                                    <td>{{ invoiceItem.type }}</td>
                                    <td class="text-end">{{ toMYR(invoiceItem.amount) }}</td>
                                </tr>
                            </template>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td class="text-end space-x-3 fw-bold">
                                    <span>Total Amount</span>
                                    <span>{{ toMYR(invoice.totalAmount) }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="card">
            <div class="card-body">
                <p>No Data. Please change your query</p>
            </div>
        </div>
    </template>

    <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
</template>

<script setup lang="ts">
import { toMYR } from '@/composable/useCurrency'
import { Core, easepick } from '@easepick/core'
import date from '@/utils/date'
import { onMounted, ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import useDateRangePicker from '@/composable/useDateRangePicker'
import ReportService from '@/services/ReportService'

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
const invoices = ref<Array<any>>([])
const inputFilter = ref<UserFilter>({
    status: 'all',
    from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { easePickDefaultOptions } = useDateRangePicker()

const isLoading = ref<boolean>(false)

fetchInvoices()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchInvoices()
    }
)

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.from_date = e.detail.start.format(DEFAULT_DATE_FORMAT)
                inputFilter.value.to_date = e.detail.end.format(DEFAULT_DATE_FORMAT)

                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})

function resetFilter() {
    inputFilter.value = {
        status: 'all',
        from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
    }

    fetchInvoices()
}

function fetchInvoices() {
    isLoading.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    ReportService.getInvoices(query)
        .then(({ data: { data, meta } }) => {
            invoices.value = data.map((invoice: any) => ({
                    ...invoice,
                    jobs: invoice.jobs.map((job: any) => ({
                            ...job,
                            items: job.items.filter((item: any) => item.amount !== 0)
                        }
                    ))
                }
            ))

            updateMetaPage(meta)
        })
        .finally(() => {
            isLoading.value = false
        })
}

function generateReport() {
    isLoading.value = true
    const query = {
        ...inputFilter.value,
    }

    ReportService.generateReport('invoices', query)
        .then(({ data: { downloadUrl } }) => window.open(downloadUrl, '_blank'))
        .finally(() => {
            isLoading.value = false
        })
}
</script>
