<template>
    <div v-if="notification"
         class="toast border-0 show text-white"
         :class="[`bg-${notification.type}`]"
         role="alert"
         aria-live="assertive"
         aria-atomic="true"
         data-bs-autohide="true"
         data-bs-toggle="toast"
    >
        <div class="toast-header">
            <strong class="me-auto">
                {{ notification.title ?? 'Notification' }}
            </strong>
            <button type="button"
                    class="btn-close btn-close-black ms-2"
                    data-bs-dismiss="toast"
                    aria-label="Close"
            ></button>
        </div>
        <div class="toast-body">
            <p>{{ notification.message }}</p>
            <a v-if="notification.actionType === 'link'"
               :href="notification.actionUrl" class="fw-bold"
               :target="notification.actionTarget ?? '_blank'"
            >
                {{ notification.actionText }}
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">

import ToastNotification from '@/types/ToastNotification'
import {ToastNotificationType} from '@/types/ToastNotificationType'
import {onMounted, onUnmounted} from 'vue'
import {useNotificationsStore} from '@/stores/notifications'


const notificationsStore = useNotificationsStore()
const {removeToastNotification} = notificationsStore

const props = defineProps({
    notification: {
        type: Object as () => ToastNotification,
        required: true
    },
    type: {
        type: String as () => ToastNotificationType,
        required: true
    }
})

const emits = defineEmits(['removeNotification'])

let timeout: ReturnType<typeof setTimeout> | null = null

onMounted(() => {
    timeout = setTimeout(() => {
        if (props.notification && props.notification.id !== undefined) {
            emits('removeNotification', props.notification.id)
        }
    }, 3000)
})

onUnmounted(() => {
    if (timeout) {
        clearTimeout(timeout)
    }
})
</script>
