<template>
    <kc-modal :modal-id="modalId">
        <div class="modal-header">
            <h4 class="modal-title">{{ props.mode === 'create' ? 'Add' : 'Edit' }} Postcode</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="savePostcode">
            <div class="modal-body bg-white">
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Value <span class="text-red">*</span></label>
                    <div class="col">
                        <input type="text" class="form-control" :class="{ 'is-invalid': hasError('value') }" v-model="inputUser.value">
                        <small class="invalid-feedback" v-if="hasError('value')">{{ getError('value') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">City <span class="text-red">*</span></label>
                    <div class="col">
                        <input type="text" class="form-control" :class="{ 'is-invalid': hasError('city') }" v-model="inputUser.city">
                        <small class="invalid-feedback" v-if="hasError('value')">{{ getError('city') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">State <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select
                            :class="{ 'is-invalid': hasError('state_id') }"
                            :options="[ ...addressStates ]"
                            label="name"
                            :reduce="(state: AddressState) => state.id"
                            v-model="inputUser.state_id"
                        />
                        <small class="invalid-feedback" v-if="hasError('state_id')">{{ getError('state_id') }}</small>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-2 border-top">
                <button class="btn btn-light" type="button" @click="closeModal" :id="`btnDismissModal${modalId}`" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="submit">
                    <span>Save</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import useFormError from '@/composable/useFormError';
import { $externalResults, PostcodeRule } from '@/rules/PostcodeRule';
import PostcodeService from '@/services/PostcodeService';
import { useNotificationsStore } from '@/stores/notifications';
import { useResourcesStore } from '@/stores/resources';
import AddressState from '@/types/AddressState';
import UserInput from '@/types/Input';
import useVuelidate from '@vuelidate/core';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';

const props = defineProps({
    modalId: { type: String, default: 'modal-add-postcode' },
    mode: { type: String, default: 'create' },
    postcode: { type: Object as () => object | any }
})

const emit = defineEmits(['updated-or-created'])

const { addToastNotification } = useNotificationsStore()

const { addressStates } = storeToRefs(useResourcesStore())

const inputUser = ref<UserInput>({
    value: '',
    city: '',
    state_id: '',
})

const v$ = useVuelidate(PostcodeRule, { inputUser }, { $scope: false, $externalResults })
const { hasError, getError, clearAllErrors } = useFormError(v$)

const savePostcode = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()
    
    if (validated) {
        const data = { ...inputUser.value }

        const postcodeService = props.mode === 'create'
            ? PostcodeService.store(data)
            : PostcodeService.update(props.postcode.id, data)

        postcodeService.then(() => {
            addToastNotification({
                message: `Postcode ${inputUser.value.value} ${inputUser.value.city} ${props.mode}d`,
                type: 'success',
            })
            closeModal()
            emit('updated-or-created')
        }).catch(({ response: { data, status } }) => {
            if (status === 422) {
                Object.assign($externalResults.value.inputUser, data.errors)
            } else {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            }
        })
    }
}

const closeModal = () => {
    document.getElementById(`btnDismissModal${props.modalId}`)?.click()
    clearAllErrors()

    if (props.mode === 'create') resetForm()
}

const resetForm = () => {
    inputUser.value.value = ''
    inputUser.value.city = ''
    inputUser.value.state_id = ''
}

onMounted(() => {
    if (props.postcode !== undefined) {
        Object.keys(props.postcode).forEach((key) => {
            if (key in inputUser.value) {
                inputUser.value[key] = props.postcode[key]
            }

            if (key === 'state') inputUser.value.state_id = props.postcode[key].id
        })
    }
})
</script>