import ErrorBag from '@/types/ErrorBag'
import { helpers, numeric, required } from '@vuelidate/validators'
import { ref } from 'vue'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        value: {
            required: helpers.withMessage(
                'This value field is required',
                required
            ),
            numeric: helpers.withMessage(
                'This value field must be numeric only',
                numeric
            ),
        },
        city: {
            required: helpers.withMessage(
                'This city field is required',
                required
            )
        },
        state_id: {
            required: helpers.withMessage(
                'This state field is required',
                required
            ),
        },
    },
})

export const PostcodeRule = rules()
