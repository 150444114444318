<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-end">
                <div class=" ms-auto me-2">
                    <router-link class="btn btn-primary "
                                 :to="{ name: 'voucher-create' }"
                    >
                        <icon name="plus"/>
                        Add New Voucher
                    </router-link>
                </div>
                <div class="dropdown position-static">
                    <button type="button" class="btn dropdown-toggle text-muted"
                            data-bs-toggle="dropdown">
                        <icon name="dots" class="text-secondary"/>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" style="width: 164px;">
                        <router-link class="dropdown-item text-primary "
                                     :to="{ name: 'voucher-bulk-generate' }"
                        >
                            <icon name="plus"/>
                            Bulk Generate Vouchers
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Voucher Name</label>
                    <input class="form-control" type="text" v-model="inputFilter.name" @keyup.enter="fetchVouchers">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Voucher Code</label>
                    <input class="form-control" type="text" v-model="inputFilter.code" @keyup.enter="fetchVouchers">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="email" v-model="inputFilter.email" @keyup.enter="fetchVouchers">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchVouchers"
                                   :is-loading="isFetching"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                              @per-page-updated="updatePerPage" :is-showing-per-page="true"
            />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Discount</th>
                        <th>User</th>
                        <th>Started Date</th>
                        <th>Expired Date</th>
                        <th>Created At</th>
                        <th>Used</th>
                        <th class="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="vouchers.length > 0">
                    <tr v-for="voucher in vouchers" :key="voucher.id">
                        <td>
                            <p class="mb-0">{{ voucher.name }}</p>
                            <p class="text-secondary mb-0" v-if="voucher.description">{{ voucher.description }}</p>
                        </td>
                        <td>{{ voucher.code }}</td>
                        <td>{{ getDiscountAmountDisplay(voucher.discountAmount, voucher.isFixedDiscount) }}</td>
                        <td>{{ voucher.user?.email }}</td>
                        <td>{{ voucher.startedAt ? formatDate(voucher.startedAt, 'DD-MMM-YYYY') : '-' }}</td>
                        <td>{{ voucher.expiredAt ? formatDate(voucher.expiredAt, 'DD-MMM-YYYY') : '-' }}</td>
                        <td>{{ formatDate(voucher.createdAt, 'DD-MMM-YYYY') }}</td>
                        <td>{{ getUsageDisplay(voucher.extraAttributes) }}</td>
                        <td class="text-center">
                            <div class="btn-group">
                                <router-link class="btn btn-icon btn-primary"
                                             :to="{name : 'voucher-show', params: {voucherId: voucher.id}}"
                                >
                                    <icon name="eye"/>
                                </router-link>
                                <router-link class="btn btn-icon btn-success"
                                             :to="{name : 'voucher-edit', params: {voucherId: voucher.id}}"
                                >
                                    <icon name="pencil"/>
                                </router-link>
                                <button class="btn btn-icon btn-danger" @click="deleteVoucher(voucher)">
                                    <icon name="trash"/>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td colspan="9" class="text-center">No data</td>
                    </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetching"/>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                              @per-page-updated="updatePerPage"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import TablePagination from '@/components/TablePagination.vue'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import { getDiscountAmountDisplay, getUsageDisplay } from '@/composable/useVoucher'
import VoucherService from '@/services/settings/VoucherService'
import { useNotificationsStore } from '@/stores/notifications'
import UserFilter from '@/types/Filter'
import Voucher from '@/types/Voucher'
import VoucherQuery from '@/types/query/VoucherQuery'
import { onMounted, ref, watch } from 'vue'

const {addToastNotification} = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()

const vouchers = ref<Array<Voucher>>([])
const inputFilter = ref<UserFilter>({
    name: ''
})

const isFetching = ref<boolean>(false)
const setIsFetching = (value = true) => isFetching.value = value

onMounted(() => {
    fetchVouchers()
})

watch(
    () => metaPageTriggered.value,
    () => {
        fetchVouchers()
    }
)

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchVouchers()
}

const fetchVouchers = async () => {
    setIsFetching()

    const query: VoucherQuery = {
        perPage: metaPage.value.perPage,
        page: metaPage.value.currentPage,
        name: inputFilter.value?.name,
        code: inputFilter.value?.code,
        user_email: inputFilter.value?.email
    }

    try {
        const {data: {data, meta}} = await VoucherService.index(query)
        vouchers.value = data
        updateMetaPage(meta)
    } catch (e) {
        addToastNotification({
            message: 'Failed to fetch vouchers due to internal server error. Please contact tech team.',
            type: 'danger'
        })
    }

    setIsFetching(false)
}

const deleteVoucher = (voucher: Voucher) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete voucher ${voucher.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then(async (result: any) => {
        if (!result.isConfirmed) {
            return
        }

        try {
            setIsFetching()
            await VoucherService.destroy(voucher.id)
            setIsFetching(false)

            addToastNotification({
                message: `Voucher ${voucher.name} is successfully deleted.`,
                type: 'success'
            })

            fetchVouchers()
        } catch {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    })
}

</script>
