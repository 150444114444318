<template>
    <router-link :to="{name: 'ticket-index'}" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Tickets</span>
    </router-link>

    <template v-if="ticket">
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex space-x-2 align-items-center">
                        <h2 class="page-title">Ticket #{{ ticket?.id }}</h2>
                        <span class="badge badge-pill" :class="status?.class">{{ status?.name }}</span>
                    </div>
                    <div class="page-subtitle">
                        <div class="row">
                            <div class="col-auto d-flex align-items-start">
                                <icon name="user" :stroke-width="1.2" class="pe-2"/>
                                <div class="text-reset d-flex gap-2">
                                    <span> Reporter:</span>
                                    <div>
                                        <template v-if="ticket?.reporter && ticket?.role">
                                            <UserNameId :user="ticket.reporter" :role="ticket.role" inline/>
                                        </template>
                                        <template v-else>
                                            <span class="text-reset">
                                                {{ ticket?.reporter.mainProfile?.fullName ?? ticket.reporter.name }}
                                            </span>
                                        </template>
                                    </div>
                                </div>

                            </div>
                            <div class="col-auto">
                                <icon name="user" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">Assignee: {{ ticket?.assignee?.name ?? 'N/A' }}</span>
                            </div>
                            <div class="col-auto">
                                <icon name="clock" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">Reported At: {{
                                        formatDate(ticket?.createdAt, 'DD-MMM-YYYY hh:mm:ss A')
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cards mt-2 mb-4">
            <div class="card">
                <div class="card-header">
                    <h2>{{ getTitle(ticket?.type) }}</h2>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <h4>Issue</h4>
                        <p>{{ ticket?.issue ?? '-' }}</p>
                    </div>
                    <div class="row mb-3">
                        <h4>Issue Description</h4>
                        <p>{{ ticket?.issueDescription ?? '-' }}</p>
                    </div>
                    <hr/>
                    <div class="row mb-3">
                        <h4>Remark</h4>
                        <p>{{ ticket?.remark ?? '-' }}</p>
                        <a class="fw-bold cursor-pointer d-inline w-auto" data-bs-toggle="modal"
                           :data-bs-target="`#modal-edit-remark-${ticket.id}`">
                            {{ ticket?.remark ? 'Edit Remark' : 'Add Remark' }}
                        </a>
                        <ticket-edit-remark-modal @update-remark="handleUpdateRemark" :ticket-id="ticket.id"
                                                  :remark="ticket.remark"></ticket-edit-remark-modal>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cards mb-4">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Activity Logs</h3>
                </div>
                <div class="card-body">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Assignee</th>
                            <th class="text-center">Status</th>
                            <th class="w-50">Remark</th>
                            <th class="text-end">Timestamp</th>
                        </tr>
                        </thead>
                        <tbody v-if="activityLogs.length">
                        <tr v-for="activityLog in activityLogs" :key="activityLog.id">
                            <td>{{ activityLog.causer.name }}</td>
                            <td class="text-center"><span class="badge badge-pill"
                                                          :class="getStatus(activityLog.properties.attributes.status).class">{{
                                    getStatus(activityLog.properties.attributes.status).name
                                }}</span></td>
                            <td>{{ activityLog.properties.attributes.remark }}</td>
                            <td class="text-end">{{ formatDate(activityLog.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr class="text-center">
                            <td colspan="4">No data</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router'
import {ref, computed} from 'vue'
import {Ticket} from '@/types/Ticket'
import ActivityLog from '@/types/ActivityLog'
import TicketService from '@/services/TicketService'
import {useNotificationsStore} from '@/stores/notifications'
import {formatDate, toLocaleDate} from '@/composable/useDate'
import TicketEditRemarkModal from './TicketEditRemarkModal.vue'
import {getStatuses, getTitle} from '@/composable/useTicket'
import UserNameId from '@/components/UserNameId.vue'

const {addToastNotification} = useNotificationsStore()
const route = useRoute()
const ticketId = Number(route.params.ticketId)
const ticket = ref<Ticket>()
const activityLogs = ref<ActivityLog[]>([])

const init = async () => {
    try {
        const response = await Promise.all([
            TicketService.show(ticketId),
            TicketService.getActivityLogs(ticketId)
        ])

        ticket.value = response[0].data
        activityLogs.value = response[1].data.data
    } catch (error: any) {
        const responseError = error.response.data
        const message = responseError.status === 404 ? 'Ticket not found!' : 'Internal server error. Please contact tech team.'

        addToastNotification({
            message,
            type: 'danger'
        })

        return
    }
}

init()

const status = computed(() => getStatus(ticket.value?.status))

const getStatus = (status: string | undefined) => getStatuses().find((findStatus) => findStatus.value === status)

const handleUpdateRemark = async (remark: string, ticketId: string) => {
    if (ticket.value) {
        ticket.value.remark = remark
    }
}
</script>
