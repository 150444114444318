import {TicketType} from '@/enums/TicketTypeEnum'

export function getPlatform(ticketType: string) {
    switch (ticketType) {
        case 'customer_support':
        case 'booking_coupon_apply':
        case 'booking_coupon_payment':
        case 'booking_payment_pending':
        case 'job_approval_pending':
        case 'booking_payment_made':
        case TicketType.SESSION_NO_REPLACEMENT_CARER:
        case 'job_replacement_carer':
        case 'session_review':
        case TicketType.REQUEST_FOR_CALL:
        case TicketType.CUSTOMER_DECLINED_SESSION_DOCUMENT:
        case TicketType.TYPE_CUSTOMER_PENDING_REPORT_APPROVAL:
            return 'customer'
        case 'carer_support':
        case 'job_carer_cancelled':
        case 'carer_registration':
        case 'carer_profile_update':
        case 'carer_video_upload':
        case 'carer_log_incident':
            return 'carer'
        default:
            return []
    }
}

export function getTitle(ticketType: string) {
    switch (ticketType) {
        case 'customer_support':
            return 'Customer Support'
        case TicketType.REQUEST_FOR_CALL:
            return 'Request for Call'
        case 'carer_support':
            return 'Carer Support'
        case 'booking_coupon_apply':
            return 'Coupon Validation'
        case 'booking_coupon_payment':
            return 'Ready For Payment'
        case 'booking_payment_pending':
            return 'Payment Reminder'
        case 'job_approval_pending':
            return 'Pending Client Approval'
        case 'booking_payment_made':
            return 'Payment Completed'
        case 'job_carer_cancelled':
            return 'Job Cancelled by Carer'
        case TicketType.SESSION_NO_REPLACEMENT_CARER:
            return 'No Replacement Carer'
        case 'job_replacement_carer':
            return 'Replacement Carer Found'
        case 'session_review':
            return 'Service Review'
        case 'carer_registration':
            return 'Registration'
        case 'carer_profile_update':
            return 'Profile Update'
        case 'carer_video_upload_success':
            return 'Video Upload Success'
        case 'carer_video_upload_error':
            return 'Video Upload Failed'
        case TicketType.CARER_TRAINING_ALMOST_COMPLETED:
            return 'Training Almost Completed'
        case TicketType.CORPORATE_INQUIRY:
            return 'Corporate Inquiry'
        case TicketType.CARER_LOG_INCIDENT:
            return 'Carer Log Incident'
        case TicketType.CUSTOMER_DECLINED_SESSION_DOCUMENT:
            return 'Customer Declined Session Document'
        case TicketType.TYPE_CUSTOMER_PENDING_REPORT_APPROVAL:
            return 'Report Pending Customer Approval'
        default:
            return ''
    }
}

export function getDescription(ticketType: string) {
    switch (ticketType) {
        case 'booking_coupon_apply':
        case 'booking_coupon_payment':
            return 'Client applied coupon code purchased from the website e.g. Mantap Deals etc'
        case 'carer_video_upload_success':
            return 'You may go to Carers page and review the video if necessary'
        case TicketType.CUSTOMER_DECLINED_SESSION_DOCUMENT:
            return 'Client declined a session document (checklist or report).'
        case TicketType.TYPE_CUSTOMER_PENDING_REPORT_APPROVAL:
            return 'Client has not approve a session document (report).'
        default:
            return ''
    }
}

export function getColumns(ticketType: string) {
    switch (ticketType) {
        case 'customer_support':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'job', header: 'Session', tdClass: 'col-2'},
                {slotName: 'issue', header: 'Issue & Description', tdClass: 'col-2'}
            ]
        case 'carer_support':
            return [
                {slotName: 'user', header: 'Carer', tdClass: 'col'},
                {slotName: 'job', header: 'Session', tdClass: 'col'},
                {slotName: 'issue', header: 'Issue & Description', tdClass: 'col-2'}
            ]
        case 'booking_coupon_apply':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col'},
                {slotName: 'booking', header: 'Booking', tdClass: 'col'}
            ]
        case 'booking_coupon_payment':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col'},
                {slotName: 'booking', header: 'Booking', tdClass: 'col'}
            ]
        case 'booking_payment_pending':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col'},
                {slotName: 'booking', header: 'Booking', tdClass: 'col'},
                {slotName: 'invoice', header: 'Invoice', tdClass: 'col'}
            ]
        case 'job_approval_pending':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'job', header: 'Session', tdClass: 'col-2'}
            ]
        case 'booking_payment_made':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-1'},
                {slotName: 'session', header: 'Session', tdClass: 'col-1'}
            ]
        case 'job_carer_cancelled':
            return [
                {slotName: 'user', header: 'Carer', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'},
                {slotName: 'reason', header: 'Reason', tdClass: 'col-2'}
            ]
        case TicketType.SESSION_NO_REPLACEMENT_CARER:
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'}
            ]
        case 'job_replacement_carer':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'}
            ]
        case 'session_review':
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'},
                {slotName: 'review', header: 'Review', tdClass: 'col-2'}
            ]
        case TicketType.REQUEST_FOR_CALL:
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'mobile_no', header: 'Phone No', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'}
            ]
        case 'carer_registration':
        case 'carer_profile_update':
        case 'carer_video_upload_success':
        case 'carer_video_upload_error':
        case TicketType.CARER_TRAINING_ALMOST_COMPLETED:
            return [
                {slotName: 'user', header: 'Carer', tdClass: 'col-2'}
            ]
        case TicketType.CORPORATE_INQUIRY:
            return [
                {slotName: 'corporateInquiry', header: 'Inquiry', tdClass: 'col-2'}
            ]
        case TicketType.CARER_LOG_INCIDENT:
            return [
                {slotName: 'user', header: 'Carer', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'},
                {slotName: 'sessionCarerLog', header: 'Carer Log Incident', tdClass: 'col-3'}
            ]
        case TicketType.CUSTOMER_DECLINED_SESSION_DOCUMENT:
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'}
            ]
        case TicketType.TYPE_CUSTOMER_PENDING_REPORT_APPROVAL:
            return [
                {slotName: 'user', header: 'Customer', tdClass: 'col-2'},
                {slotName: 'mobile_no', header: 'Phone No', tdClass: 'col-2'},
                {slotName: 'session', header: 'Session', tdClass: 'col-2'}
            ]
        default:
            return []
    }
}

export function getTasks(ticketType: string) {
    switch (ticketType) {
        case 'customer_support':
            return ['Contact the client and assist their request accordingly.']
        case 'carer_support':
            return ['Contact the carer and assist on the issue accordingly.']
        case TicketType.REQUEST_FOR_CALL:
            return ['Contact customer direct via phone call.']
        case 'booking_coupon_apply':
            return [
                'Validate the coupon code by referring to the Coupon Package Sheet.',
                'Inform the client if the coupon has been fully redeemed.'
            ]
        case 'booking_coupon_payment':
            return [
                'Create an invoice',
                'Apply discount accordingly',
                'Double check the invoice and make sure the amount of traveling incentive is correct'
            ]
        case 'booking_payment_pending':
            return ['Contact the client and remind them to make a payment.']
        case 'job_approval_pending':
            return [
                'Contact the client and remind the them to choose a carer.',
                'If still no response, you may wait or release the carer.'
            ]
        case 'booking_payment_made':
            return ['Invite carer to service channel.']
        case 'job_carer_cancelled':
            return [
                'Remove carer from the booking group.',
                'Refund to the customer, if customer has paid for travel tips.',
                'Remove carer\'s penalty if reason is acceptable.'
            ]
        case TicketType.SESSION_NO_REPLACEMENT_CARER:
            return ['Contact the client to offer credit voucher or refund.']
        case 'job_replacement_carer':
            return [
                'Remove previous carer if you haven\'t',
                'Invite replacement carer to the service channel'
            ]
        case 'session_review':
            return ['For unsatisfactory feedbacks, contact customer to respond to the feedback.']
        case 'carer_registration':
            return [
                'Go to Carers page.',
                'Review the carer\'s profile.',
                'Contact the carer via WhatsApp if you require any supporting document.'
            ]
        case 'carer_profile_update':
            return [
                'Go to Carers Page.',
                'Review the carer\'s profile.',
                'Contact the carer via WhatsApp if you require any supporting document.'
            ]
        case 'carer_video_upload_error':
            return [
                'Go to Carers Page.',
                'Delete or re-upload video.'
            ]
        case TicketType.TYPE_CUSTOMER_PENDING_REPORT_APPROVAL:
            return [
                'Review the checklist.',
                'Contact with client for report approval.'
            ]
        default:
            return []
    }
}

export function getStatuses() {
    return [
        {
            name: 'New',
            value: 'new',
            class: 'bg-primary'
        },
        {
            name: 'Attending',
            value: 'attending',
            class: 'bg-cyan'
        },
        {
            name: 'Follow Up',
            value: 'follow_up',
            class: 'bg-warning'
        },
        {
            name: 'Resolved',
            value: 'resolved',
            class: 'bg-success'
        }
    ]
}
