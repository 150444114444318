<template>
    <ul class="list-unstyled">
        <li v-for="carerLog in carerLogs"
            :key="`carer-log-${carerLog.id}`"
        >
            <div class="row py-3 border-bottom">
                <div class="col-4 text-end text-secondary">
                    <div class="mb-2 gap-2 text-end" style="display: flex; align-items: center; justify-content: flex-end;">
                        
                        <button v-if="carerLog.images?.length > 0 && includeImage" 
                            class="btn btn-icon btn-circle btn-success" data-bs-toggle="modal" 
                            :data-bs-target="`#modal-display-image-${carerLog.id}`"
                            title="Display Images">
                            <icon name="eye" />
                        </button>

                        <DisplayCarerLogImageModal :images="carerLog.images" :id="carerLog.id" />

                         <span class="badge badge-pill"
                               :class="getColor(carerLog.type)"
                         >
                            <template v-if="carerLog.type?.id === SessionCarerLogTypeEnum.ID_ROUTINE">
                                {{ carerLog.type?.name }}{{ getDependentRoutine(carerLog) }}
                            </template>
                            <template v-else>
                                {{ carerLog.type?.name }}
                            </template>
                        </span>
                    </div>
                    <div>
                        {{ formatDate(carerLog.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}
                    </div>
                </div>
                <div class="col-8">
                    <div class="card">
                        <div class="card-header">
                            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
                                <li v-if="carerLog.dependentRoutines?.length" class="nav-item">
                                    <a :href="`#tabs-dependent-routine-${carerLog.id}`" class="nav-link active" data-bs-toggle="tab">Journal Log Info</a>
                                </li>
                                <li class="nav-item">
                                    <a :href="`#tabs-carer-log-${carerLog.id}`" class="nav-link" :class="{ 'active' : carerLog.dependentRoutines?.length === 0}" data-bs-toggle="tab">Carer Log</a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content">
                                <div v-if="carerLog.dependentRoutines?.length" class="tab-pane active show" :id="`tabs-dependent-routine-${carerLog.id}`">
                                    <SessionDependentRoutine :dependent-routines="carerLog.dependentRoutines" />
                                </div>
                                <div class="tab-pane" :class="{ 'active show' : carerLog.dependentRoutines?.length === 0}" :id="`tabs-carer-log-${carerLog.id}`">
                                    <div class="mb-2">
                                        {{ carerLog.description }}
                                    </div>
                                    <div>
                                        <template v-if="(typeof carerLog.data === 'object' && carerLog.data !== null)">
                                            <SessionCarerLogData :carer-log-data="carerLog.data"/>
                                        </template>
                                        <template v-else>
                                            {{ carerLog.data }}
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import { SessionCarerLogTypeEnum } from '@/enums/SessionCarerLogTypeEnum'
import { SessionDependentRoutineTypeEnum } from '@/enums/SessionDependentRoutineTypeEnum'
import DisplayCarerLogImageModal from '@/pages/sessions/components/DisplayCarerLogImageModal.vue'
import SessionCarerLogData from '@/pages/sessions/components/SessionCarerLogData.vue'
import SessionDependentRoutine from '@/pages/sessions/components/SessionDependentRoutine.vue'
import SessionCarerLog from '@/types/SessionCarerLog'
import SessionCarerLogType from '@/types/SessionCarerLogType'

defineProps({
    carerLogs: {type: Object as () => Array<SessionCarerLog>},
    includeImage: {type: Boolean, default: true}
})

const getColor = (logType: SessionCarerLogType) => {
    const id = logType.id

    let color = 'dark'

    switch (id) {
        case SessionCarerLogTypeEnum.ID_ROUTINE:
            color = 'primary'
            break
        case SessionCarerLogTypeEnum.ID_CASUAL:
            color = 'secondary'
            break
        case SessionCarerLogTypeEnum.ID_INCIDENT:
            color = 'danger'
            break
    }

    return `bg-${color}`
}

const getDependentRoutine = (carerLog: SessionCarerLog) => {

    let routineName = ''

    if (carerLog.dependentRoutines && carerLog.dependentRoutines.length > 0) {
        let getRoutineId = carerLog.dependentRoutines[0].sessionDependentRoutineTypeId
        routineName = ` : ${getTypeLabel(getRoutineId)}`
    }

    return routineName
}

const getTypeLabel = (type: number) => {
    let label = ''

    switch (type) {
        case SessionDependentRoutineTypeEnum.ID_FEEDING:
            label = 'Feeding'
            break
        case SessionDependentRoutineTypeEnum.ID_DIAPER:
            label = 'Diaper'
            break
        case SessionDependentRoutineTypeEnum.ID_BATH:
            label = 'Bath'
            break
        case SessionDependentRoutineTypeEnum.ID_NAP:
            label = 'Nap'
            break
        case SessionDependentRoutineTypeEnum.ID_MEDICATION:
            label = 'Medication'
            break
        default:
            break
    }

    return label
}

</script>
