<template>
    <overlay-spinner :is-showing="isFetchingOrganization" v-if="isFetchingOrganization"/>
        <organization-form v-if="!isFetchingOrganization" :organization="organization" :id="organizationId"></organization-form>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import OrganizationForm from './OrganizationForm.vue'
import OrganizationService from '@/services/settings/OrganizationService'
import { useNotificationsStore } from '@/stores/notifications'
import Organization from '@/types/Organization'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const isFetchingOrganization = ref<boolean>(false)
const organizationId = Number(route.params.organizationId)
const organization = ref<Organization>()

onMounted(() => {
    isFetchingOrganization.value = true
    OrganizationService.show(organizationId)
        .then(({ data: { data } }) => {
            organization.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingOrganization.value = false)
})
</script>
