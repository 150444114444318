<template>
    <div class="my-3 d-flex justify-content-end">
        <button @click="openModal" class="btn btn-primary">
            <icon name="user-plus"/>
            Invite
        </button>
    </div>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="md">
            <div class="modal-header">
                <h4 class="modal-title">
                    Invite admin to Chat Channel
                </h4>
                <button type="button" class="btn-close" aria-label="Close"
                        @click="openModal(false)"
                ></button>
            </div>
            <div class="modal-body bg-white">

                <!-- ERRORS -->
                <div v-if="errors.length" class="bg-danger py-1 px-2">
                    <div class="fw-bold">Errors</div>
                    <ul>
                        <li v-for="error in errors">
                            {{ error }}
                        </li>
                    </ul>
                </div>
                <VueSelect
                    :options="availableAdmins"
                    v-model="selectedAdmin"
                    placeholder="Select admin"
                    label="name"
                    required
                    class="w-100 mt-3"
                />
            </div>
            <div class="modal-footer pt-2 border-top">
                <button :id="`${modalId}-close-button`"
                        class="btn btn-light" type="button"
                        @click="openModal(false)"
                >Close
                </button>
                <button @click="handleSubmit" class="btn btn-primary"
                >
                    <span v-if="!isSubmitting">Submit</span>
                    <span v-else>Submitting...</span>
                </button>
            </div>
        </kc-modal>
    </Teleport>
</template>

<script lang="ts" setup>

import {useModal} from '@/composable/useModal'
import {computed, ref} from 'vue'
import VueSelect from 'vue-select'
import SendbirdService from '@/services/SendbirdService'
import {useNotificationsStore} from '@/stores/notifications'
import {AxiosError} from 'axios'
import User from '@/types/User'
import {Member} from '@sendbird/chat/groupChannel'

const {addToastNotification} = useNotificationsStore()

const emits = defineEmits(['adminInvited'])

const props = defineProps({
    channelUrl: {type: String, default: '', required: true},
    admins: {type: Array as () => User[], default: [], required: true},
    participants: {type: Array as () => Member[], default: [], required: true}
})

const isSubmitting = ref(false)

let selectedAdmin = ref<User | null>(null)
let errors = ref<string[]>([])

let {
    modalId,
    openModal,
    onModalShown,
    onModalHidden
} = useModal(`invite-admin-to-chat-channel-modal`)

const availableAdmins = computed(() => {
    return props.admins?.filter((admin: User) => {
        return props.participants?.some((participant: Member) => {
            return parseInt(participant.userId) !== admin.id
        })
    })
})

const handleSubmit = async () => {

    if (!selectedAdmin.value) {
        return
    }

    try {
        await SendbirdService.inviteUser( {
            user_id: selectedAdmin.value.id,
            channel_url: props.channelUrl,
            is_operator: true
        })
        emits('adminInvited')
        openModal(false)
        addToastNotification({
            message: 'Successfully invited admin',
            type: 'success'
        })
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
                const responseData = error.response.data as { errors?: Record<string, string[]> };
                errors.value = Object.keys(responseData.errors || {}).map((errorKey) => {
                    return responseData.errors ? responseData.errors[errorKey][0] : '';
                });
            } else {
                const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
                addToastNotification({
                    message: errorMessage,
                    type: 'danger'
                });
            }
        } else {
            addToastNotification({
                message: 'An unexpected error occurred',
                type: 'danger'
            });
        }
    }
}

</script>
