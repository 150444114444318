<template>
    <kc-modal modal-id="modal-child-form" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">Add Child</h4>
            <button id="btn-child-modal-close" class="btn-close" data-bs-target="#modal-job-form" data-bs-toggle="modal" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body bg-white">
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Name</label>
                    <input type="text" class="form-control" v-model="input.name">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Birth Date</label>
                    <div class="input-group">
                        <input type="text" id="input-modal-child-form-birth-date" class="form-control bg-white" :value="birthDateDisplay" readonly>
                        <span class="input-group-text"><icon name="calendar" /></span>
                    </div>
                </div>
                <div class="col">
                    <label class="form-label col-form-label">Gender</label>
                    <div class="row px-2">
                        <label class="form-check col-3">
                            <input type="radio" name="child_gender" class="form-check-input" value="B" :checked="input.gender === 'B'" @input="input.gender = $event.target.value" /> Boy
                        </label>
                        <label class="form-check col-3">
                            <input type="radio" name="child_gender" class="form-check-input" value="G" :checked="input.gender === 'G'" @input="input.gender = $event.target.value" /> Girl
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Language</label>
                    <div class="row px-2">
                        <template v-for="language in languages" :key="language.id">
                            <label class="form-check col-3">
                                <input type="checkbox" class="form-check-input" :value="language.name" :checked="input.language.includes(language.name)" @input="updateLanguage" /> {{ language.name }}
                            </label>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Religion</label>
                    <div class="row px-2">
                        <template v-for="religion in religions" :key="religion.id">
                            <label class="form-check col-3">
                                <input type="radio" name="child_religion" class="form-check-input" :value="religion.name" :checked="input.religion === religion.name" @input="input.religion = $event.target.value" /> {{ religion.name }}
                            </label>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Allergies</label>
                    <input type="text" class="form-control" v-model="input.allergies">
                </div>
                <div class="col">
                    <label class="form-label col-form-label">Medical Condition</label>
                    <input type="text" class="form-control" v-model="input.medical">
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3 border-top">
            <button class="btn btn-light" data-bs-target="#modal-job-form" data-bs-toggle="modal" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="submit" @click.prevent="saveChild">Save</button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useResourcesStore } from '@/stores/resources'
import { storeToRefs } from 'pinia'
import { formatDate } from '@/composable/useDate'
import { Core, easepick } from '@easepick/core'
import { AmpPlugin } from '@easepick/amp-plugin'
import ChildService from '@/services/ChildService'

const props = defineProps({
    userId: { type: Number, required: true },
})

const { languages, religions } = storeToRefs(useResourcesStore())

const input = ref<any>({
    name: null,
    birth_date: null,
    gender: null,
    language: [],
    religion: null,
    allergies: null,
    medical: null,
    user_id: null,
})

const birthDateDisplay = computed(() => input.value.birth_date ? formatDate(input.value.birth_date, 'DD-MMM-YYYY') : '')
let birthDatePicker: Core | null = null

let btnClose: HTMLElement | null = null

const updateLanguage = (e: any) => {
    const selectedLanguage = e.target.value
    if (e.target.checked) {
        input.value.language.push(selectedLanguage)
    } else {
        input.value.language = input.value.language.filter((language: string) => language !== selectedLanguage)
    }
}

const saveChild = () => {
    input.value.language = input.value.language.join(',')

    ChildService.store(input.value)
        .then(() => {
            btnClose?.click()
        }).catch(() => {})
}

watch(
    () => props.userId,
    (newVal) => input.value.user_id = newVal
)

onMounted(() => {
    btnClose = document.getElementById('btn-child-modal-close')

    const modal = document.getElementById('modal-child-form')
    modal?.addEventListener('hidden.bs.modal', () => {
        input.value.name = null
        input.value.birth_date =  null
        input.value.gender = null
        input.value.language = []
        input.value.religion = null
        input.value.allergies = null
        input.value.medical = null
    })

    const ampPlugin: object = {
        AmpPlugin: {
            resetButton: () => {
                input.value.birth_date = null
                return true
            },
            dropdown: {
                months: true,
                years: true,
            }
        }
    }

    birthDatePicker = new easepick.create({
        element: '#input-modal-child-form-birth-date',
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css',
        ],
        autoApply: false,
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                input.value.birth_date = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        TimePlugin: {
            format12: true,
        },
        ...ampPlugin,
    })
})
</script>
