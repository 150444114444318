<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h3 class="card-title">Order Preview</h3>
        </div>
        <div class="card-body">
            <OverlaySpinner :is-showing="isGeneratingPreview"/>
            <!-- Session Rates  -->
            <div v-if="orderPreview && !isGeneratingPreview">
                <ul class="px-0 w-100">
                    <li v-for="session in orderPreview.sessions"
                        class="list-unstyled mb-3"
                        :key="`session-rates-${session.no}`"
                    >
                        <div class="  py-2">
                            <div class="pb-3 fw-bold">
                                Session {{ session.no }}
                            </div>
                            <table v-if="session.rates.length > 0" class="w-100">
                                <tr v-for="rate in session.rates"
                                    class="border-bottom "
                                >
                                    <td class="py-1 px-1">
                                        {{ rate.description }}
                                    </td>
                                    <td class="text-end px-1">
                                        {{ toMYR(rate.amount) }}
                                    </td>
                                </tr>
                            </table>
                            <div v-else class="row justify-content-start">
                                <p class="text-start fst-italic">
                                    No custom rates added yet.
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- Order Items -->
            <div class="  py-2 mt-4 ">
                <p class="fw-bold">
                    Summary
                </p>
                <table class="w-100">
                    <tr v-for="item in orderPreview.items"
                        class="border-bottom "
                    >
                        <td class="py-1 px-1">
                            {{ item.description }}
                        </td>
                        <td class="text-end px-1">
                            {{ toMYR(item.totalAmount) }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">

import {storeToRefs} from 'pinia'
import {useCreateOrderStore} from '@/stores/createOrder'
import {toMYR} from '@/composable/useCurrency'
import OverlaySpinner from '@/components/OverlaySpinner.vue'

const createOrderStore = useCreateOrderStore()
const {submit} = createOrderStore
const {orderPreview, isGeneratingPreview, errors} = storeToRefs(createOrderStore)

</script>