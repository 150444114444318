<template>
    <kc-modal modal-id="modal-address-form" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">Add Address</h4>
            <button id="btn-address-modal-close" class="btn-close" data-bs-target="#modal-job-form" data-bs-toggle="modal" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body bg-white">
            <div class="row align-items-center">
                <div class="col-8">
                    <label class="form-label col-form-label">Address Name</label>
                    <input type="text" class="form-control" v-model="input.name">
                </div>
                <div class="col-4">
                    <div class="d-flex justify-content-center">
                        <label class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="input.is_primary" /> Is Primary Address?
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Unit No</label>
                    <input type="text" class="form-control" v-model="input.unit_no">
                </div>
                <div class="col">
                    <label class="form-label col-form-label">Floor / Level</label>
                    <input type="text" class="form-control" v-model="input.floor_level">
                </div>
                <div class="col">
                    <label class="form-label col-form-label">Block / Building</label>
                    <input type="text" class="form-control" v-model="input.block_building">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Street</label>
                    <input type="text" class="form-control" v-model="input.address_1">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Postcode</label>
                    <input type="text" maxlength="5" class="form-control" v-model="input.postcode">
                </div>
                <div class="col">
                    <label class="form-label col-form-label">City</label>
                    <span>{{ city }}</span>
                </div>
                <div class="col">
                    <label class="form-label col-form-label">State</label>
                    <span>{{ state }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label col-form-label">Remarks</label>
                    <input type="text" class="form-control" v-model="input.address_2">
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3 border-top">
            <button class="btn btn-light" data-bs-target="#modal-job-form" data-bs-toggle="modal" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="submit" @click.prevent="saveAddress">Save</button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useResourcesStore } from '@/stores/resources'
import { storeToRefs } from 'pinia'
import AddressService from '@/services/AddressService'

const props = defineProps({
    userId: { type: Number, required: true },
})

const { postcodes, addressStates } = storeToRefs(useResourcesStore())

const input = ref<any>({
    name: null,
    unit_no: null,
    floor_level: null,
    block_building: null,
    address_1: null,
    address_2: null,
    postcode: null,
    is_primary: false,
    user_id: null,
})

let btnClose: HTMLElement | null = null

const city = computed(() => input.value.postcode ? postcodes.value.find(postcode => postcode.postcode === input.value.postcode)?.city : '')
const state = computed(() => {
    if (input.value.postcode && input.value.postcode.length === 5) {
        const stateId = postcodes.value.find(postcode => postcode.postcode === input.value.postcode)?.stateId
        if (stateId) {
            return addressStates.value.find(state => state.id === stateId)?.name
        }
    }

    return ''
})

const saveAddress = () => {
    AddressService.store(input.value)
        .then(() => {
            btnClose?.click()
        }).catch(() => {})
}

watch(
    () => props.userId,
    (newVal) => input.value.user_id = newVal
)

onMounted(() => {
    btnClose = document.getElementById('btn-address-modal-close')

    const modal = document.getElementById('modal-address-form')
    modal?.addEventListener('hidden.bs.modal', () => {
        input.value.name = null
        input.value.unit_no = null
        input.value.floor_level = null
        input.value.block_building = null
        input.value.address_1 = null
        input.value.address_2 = null
        input.value.postcode = null
        input.value.is_primary = false
    })
})
</script>
