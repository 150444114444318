import httpClientV2 from './HttpClientV2'

export default {
    lock(carerApplicationId: number, data: { lock_duration_minutes: number }) {
        return httpClientV2.post(`/backoffice/session-carer-applications/${ carerApplicationId }/lock`, data)
    },
    unlock(carerApplicationId: number) {
        return httpClientV2.post(`/backoffice/session-carer-applications/${ carerApplicationId }/unlock`)
    }
}
