<template>
    <kc-modal :modal-id="modalId" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">Add Child</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <form @submit.prevent="saveChild">
            <div class="modal-body bg-white">
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Name <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('name') }" type="text" v-model="inputUser.name" @focus="clearError('name')">
                        <small class="invalid-feedback" v-if="hasError('name')">{{ getError('name') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Date of Birth <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('birth_date') }" type="date" v-model="inputUser.birth_date" @focus="clearError('birth_date')"/>
                        <small class="invalid-feedback" v-if="hasError('birth_date')">{{ getError('birth_date') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Gender <span class="text-red">*</span></label>
                    <div class="col">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.gender" name="gender" value="B" id="male">
                            <span class="form-check-label">Boy</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.gender" name="gender" value="G" id="female">
                            <span class="form-check-label">Girl</span>
                        </label>
                    </div>
                    <small class="text-red" v-if="hasError('gender')">{{ getError('gender') }}</small>
                </div>
                <div class="col form-group mb-3">
                    <label class="form-label col-form-label">Language <span class="text-red">*</span></label>
                    <div class="col">
                        <div class="row">
                            <div class="col-3 py-2" v-for="(language, index) in languages" :key="index">
                                <label class="form-check mb-0" :for="language.language">
                                    <input class="form-check-input" type="checkbox" name="language" v-model="inputUser.language" :value="language.name" :id="language.id">
                                    <span class="form-check-label">{{ language.name }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="text-danger" v-if="hasError('language')">{{ getError('language') }}</small>
                    </div>
                </div>
                <div class="col form-group mb-3">
                    <label class="form-label col-form-label">Religion <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select 
                            :options="religions"
                            :reduce="religion => religion.name"
                            v-model="inputUser.religion"
                            label="name"
                            placeholder="Select Religion"
                            :class="{'is-invalid': hasError('religion')}"
                            @option:change="clearError('religion')">
                        </vue-select>
                        <small class="invalid-feedback" v-if="hasError('religion')">{{ getError('religion') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Allergies</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('allergies') }" type="text" v-model="inputUser.allergies" @focus="clearError('allergies')"/>
                        <small class="invalid-feedback" v-if="hasError('allergies')">{{ getError('allergies') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Medical</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('medical') }" type="text" v-model="inputUser.medical" @focus="clearError('medical')"/>
                        <small class="invalid-feedback" v-if="hasError('medical')">{{ getError('medical') }}</small>
                    </div>
                </div>
            </div>

            <div class="modal-footer pt-2 border-top">
                <button class="btn btn-light" type="button" @click="closeModal" :id="`btnDismissModal${modalId}`" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="submit">
                    <span>Save</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import { onMounted, ref, inject } from 'vue'
import UserInput from '@/types/Input'
import useVuelidate from '@vuelidate/core'
import { useNotificationsStore } from '@/stores/notifications'
import { rules as ChildRule, $externalResults } from '@/rules/ChildRule'
import useFormError from '@/composable/useFormError'
import ChildService from '@/services/ChildService'
import { useResourcesStore } from '@/stores/resources'
import { storeToRefs } from "pinia"

const { languages, religions } = storeToRefs(useResourcesStore())
const { addToastNotification } = useNotificationsStore()
const emit = defineEmits(['updated-or-created'])
const props = defineProps({
    userId: { type: [String, Number] },
    modalId: { type: String, default: 'modal-add-child' },
    child: { type: Object as () => object | any },
    mode: { type: String, default: 'create' },
    componentSlug: { type: String, default: 'customer-children' }
})
const inputUser = ref<UserInput>({
    name: '',
    gender: '',
    birth_date: '',
    language: [],
    religion: 'Islam',
    allergies: '',
    medical: '',
})
const v$ = useVuelidate(ChildRule, { inputUser }, { $scope: false, $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

onMounted(() => {
    if (props.child !== undefined) {
        Object.keys(props.child).forEach((key) => {
            if (key in inputUser.value) {
                inputUser.value[key] = props.child[key]
            }
        })

        inputUser.value.language = props.child.language.split(',')
    }
})

async function saveChild () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        const formattedData = formatData(inputUser.value)

        const childService =
            props.mode === 'create'
                ? ChildService.store(formattedData)
                : ChildService.update(props.child.id, formattedData)

        childService
            .then(() => {
                addToastNotification({
                    message: `Child ${inputUser.value.name} ${props.mode}d`,
                    type: 'success',
                })
                closeModal()
                emit('updated-or-created', props.componentSlug)
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}

function closeModal () {
    document.getElementById(`btnDismissModal${props.modalId}`)?.click()
    clearAllErrors()

    if (props.mode === 'create') resetForm()
}

function resetForm () {
    inputUser.value = {
        name: '',
        gender: '',
        birth_date: '',
        language: [],
        religion: '',
        allergies: '',
        medical: '',
    }
}

function formatData (obj: UserInput) {
    return {
        ...obj,
        language: obj.language.join(','),
        allergies: obj.allergies.length !== 0 ? obj.allergies : 'None',
        medical: obj.medical.length !== 0 ? obj.medical : 'None',
        user_id: props.userId
    }
}
</script>