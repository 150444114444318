<template>
<pre style="display: none; max-height: 400px" :id="`session-script-${session.no}`">
Customer: **{{ `${session.customer.mainProfile?.fullName} (${session.customer.mainProfile?.onlineId})` ?? 'N/A' }}**
Contact No: **{{ session.customer.mainProfile?.mobileNo }}**
<span>
Session No: **{{ session.no ?? 'N/A' }}**
Date: **{{ formatDate(session.startedAt, 'DD MMMM YYYY') }} ({{ formatDate(session.startedAt, 'dddd') }})**
Time: **{{ formatDate(session.startedAt, 'hh:mm A') }} - {{ formatDate(session.endedAt, 'hh:mm A') }} {{ `(${session.totalHours} hours)` }}**
Address: {{ formatAddress(session.address) }}
Assigned Carer : **{{ session.carer != null ? session.carer.mainProfile?.fullName : 'Finding Carer' }}**
Carer Contact No: **{{ session.carer ? session.carer.mainProfile?.mobileNo : 'Finding Carer' }}**

**No of children - {{ session.dependents.length }}**
<span v-for="(child, index) in session.dependents" :key="index">
Child {{index + 1}}: {{ child['name'] }} ({{ child['gender'] === GenderEnum.ID_MALE ? 'Boy' : 'Girl' }})
Age: {{ toAge(child['birthDate']) }}
Medical Conditions : {{ child['medical'] }}
Allergies : {{ child['allergies'] }}
Language: {{ child['languages'].map(language => language.name).join(', ') }}
Religion: {{ child['religion'] }}
</span></span>
Adult presence: {{ session.metadata?.adult_details ?? '-' }}
Pets : {{ session.metadata?.pet_details ?? '-' }}
Special Requirements : {{ session.metadata?.other_details ?? '-' }}
</pre>
</template>

<script setup lang="ts">
import { formatDate, toAge } from '@/composable/useDate'
import { GenderEnum } from '../../enums/GenderEnum'

const props = defineProps({
    session: { type: Object, required: true }
})

function formatAddress(address: any) {
    return [address.unitNo, address.floorLevel, address.blockBuilding, address.address1, address.postcode, address.town, address.state].filter(val => val).join(', ')
}
</script>
