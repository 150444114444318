import httpClient from '@/services/HttpClient'
import UserQuery from '@/types/query/UserQuery'
import { getQueryString } from '@/composable/http'

export default {
    index(query: UserQuery = {}) {
        const queryString = getQueryString(query)
        
        return httpClient.get(`/admin/settings/coupons?${queryString}`)
    },

    store(data: object = {}) {
        return httpClient.post('admin/settings/coupons', data)
    },

    show(id: number) {
        return httpClient.get(`admin/settings/coupons/${id}`)
    },

    update(id: number, data: object = {}) {
        return httpClient.patch(`admin/settings/coupons/${id}`, data)
    },

    destroy(id: number) {
        return httpClient.delete(`admin/settings/coupons/${id}`)
    }
}