<template>
    <table class="table table-sm ">
        <thead>
        <tr>
            <th colspan="1">ID</th>
            <th colspan="2" class="text-start">Name</th>
            <th class="text-center">Status</th>
            <th class="text-center">Travelling Tips</th>
            <th>Applied At</th>
            <th colspan="1" class="text-center">Locked</th>
            <th class="text-center">Action</th>
        </tr>
        </thead>
        <tbody v-if="activeCarerApplications.length">
        <tr v-for="application in activeCarerApplications" :key="application.carer?.id">
            <td colspan="1" class="text-start">
                {{ application.id }}
            </td>
            <td colspan="2">
                <span v-if="application.carer">
                    <UserNameId :user="application.carer" :role="RoleNameEnum.CARER"/>
                </span>
                <span v-if="application.isPreferred" class="ms-1">
                    <icon name="star"/>
                </span>
            </td>
            <td class="text-center" style="width: 15%">
                <SessionCarerApplicationStatus :application="application"
                                               @status-changed="fetchCarerApplications"
                />
            </td>

            <td class="col text-center">
                <a href="#" class="flex flex-row" @click.prevent="editAdditionalFee(application.id)">
                    <span v-if="application.negotiateAdditionalFee && application.additionalFee && application.sessionCarerApplicationStatusId == SessionCarerApplicationStatusEnum.ID_NEGOTIATED">
                        <span>{{ toMYR(application.additionalFee) }} &#8651; {{ toMYR(application.negotiateAdditionalFee) }}</span>
                    </span>
                    <span v-else-if="application.negotiateAdditionalFee && application.additionalFee && application.sessionCarerApplicationStatusId === SessionCarerApplicationStatusEnum.ID_APPLIED_WITH_ACCEPTED_NEGOTIATION">
                        <span style="text-decoration-line: line-through">{{ toMYR(application.additionalFee) }}</span>
                        <span>{{ toMYR(application.negotiateAdditionalFee) }}</span>
                    </span>
                    <span v-else>
                        {{ application.additionalFee ? toMYR(application.additionalFee) : 'N/A' }}
                    </span>
                </a>
                <div>{{ application.additionalFeeReason ? `(${ application.additionalFeeReason })` : '' }}</div>
                
            </td>
            <td>{{ formatDate(application.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>

            <!-- Lock End Time  -->
            <td colspan="1" style="width: 12rem">
                <LockSessionCarerApplication :application="application"
                                             class="my-1"
                />
            </td>

            <!--  ACTION  -->
            <td class="text-end">
                <div class="d-flex btn-group justify-content-end">

                    <!-- If session has changes made by customer and carers need to accept/decline changes -->
                    <template
                        v-if="SessionCarerApplicationStatusEnum.ID_CHECKING_WITH_CARER === application.status?.id">
                        <carer-application-change-approval-form
                            :application-id="application.id"
                            :carer="application.carer"
                            @on-success="fetchCarerApplications"
                        />
                    </template>

                    <!-- Else if is Negotiating, accept/decline negotiation -->
                    <template v-else-if="SessionCarerApplicationStatusEnum.ID_NEGOTIATED === application.status?.id">
                        <carer-application-negotiation-approval-form
                            :application-id="application.id"
                            :carer="application.carer"
                            @on-success="fetchCarerApplications"
                        />
                    </template>

                    <!--  Assign/Unassign Carer -->
                    <template v-if="currentSession?.carer?.id === application.carer?.id">
                        <button class="btn btn-outline-danger"
                                @click.prevent="unassignCarer(application.id)"
                        >
                            Unassign
                        </button>
                    </template>
                    <template v-else>
                        <button class="btn btn-primary"
                                @click.prevent="assignCarer(application.id)"
                        >
                            Assign
                        </button>
                    </template>

                    <!-- Cancel Application -->
                    <button class="btn btn-danger"
                            @click.prevent="cancelApplication(application.id)"
                    >
                        Cancel
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
            <td colspan="8" class="text-center">No data</td>
        </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">

import UserNameId from '@/components/UserNameId.vue'
import {toMYR} from '@/composable/useCurrency'
import {formatDate} from '@/composable/useDate'
import {RoleNameEnum} from '@/enums/RoleEnum'
import {SessionCarerApplicationStatusEnum} from '@/enums/SessionCarerApplicationStatusEnum'
import CarerApplicationChangeApprovalForm
    from '@/pages/sessions/components/SessionCarerApplication/CarerApplicationChangeApprovalForm.vue'
import CarerApplicationNegotiationApprovalForm
    from '@/pages/sessions/components/SessionCarerApplication/CarerApplicationNegotiationApprovalForm.vue'
import LockSessionCarerApplication
    from '@/pages/sessions/components/SessionCarerApplication/LockSessionCarerApplication.vue'
import {useSessionCarerApplicationStore} from '@/stores/sessionCarerApplication'
import {storeToRefs} from 'pinia'
import SessionCarerApplicationStatus from '@/components/SessionCarerApplication/SessionCarerApplicationStatus.vue'

const store = useSessionCarerApplicationStore()
const {
    activeCarerApplications,
    currentSession,
    isPaidSession
} = storeToRefs(store)

const {
    fetchCarerApplications,
    cancelApplication,
    assignCarer,
    unassignCarer,
    editAdditionalFee
} = store


</script>