import httpClientV2 from '@/services/HttpClientV2'

const endpoint = '/backoffice/rates'

export default {
    getHourlyRates() {
        return httpClientV2.get(`${endpoint}/hourly`)
    },

    updateHourlyRate(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/hourly/update/${id}`, data)
    },

    getExtrasRates() {
        return httpClientV2.get(`${endpoint}/extras`)
    },

    updateExtrasRate(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/extras/update/${id}`, data)
    },
}