<template>
    <overlay-spinner :is-showing="isFetchingCarer" v-if="isFetchingCarer" />
    <template v-if="user">
        <carer-form :user="user"></carer-form>
    </template>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import CarerForm from './CarerForm.vue'
import User from '@/types/User'
import CarerService from '@/services/users/CarerService'
import { useNotificationsStore } from '@/stores/notifications'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const userId = Number(route.params.userId)
const user = ref<User>()
const isFetchingCarer = ref<boolean>(false)

const fetchCarer = () => {
    isFetchingCarer.value = true

    CarerService.show(userId)
        .then(({ data: { data } }) => {
            user.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingCarer.value = false)
}

fetchCarer()
</script>