<template>
    <div class="d-flex flex-row justify-content-start">
        <div class="w-100">
            <form v-if="!!cancelApplicationForm.application_id">

                <!--  -->
                <div class="mb-3">
                    <FormLabel label="Application Status"/>
                    <vue-select 
                        :options="filteredSessionCarerApplicationStatuses"
                        :reduce="(status: any) => status.id"
                        v-model="cancelApplicationForm.status"
                        placeholder="Choose Cancel Status">
                        <template v-slot:option="option">
                            {{ option.label }}
                        </template>
                    </vue-select>
                </div>
                <div>
                    <FormLabel label="Reason for cancellation"/>
                    <textarea v-model="cancelApplicationForm.cancel_reason"
                              class="form-control"
                              rows="5"
                    ></textarea>
                </div>
                <div class="mt-4">
                    <div v-if="isPaidSession && isAssignedCarer">
                        <div v-if="replacementBonus" class="form-group col mb-3">
                            <FormLabel label="Add bonus for replacement carer to this session?" class="mb-1"/>
                            <small class="my-0">
                                This session is eligible for a <strong>{{ toMYR(replacementBonus.amount) }}</strong>
                                bonus for replacement carer.
                            </small>
                            <div class="d-flex flex-row mt-2">
                                <RadioInput name="replacement_bonus" :value="true" label="Yes"/>
                                <RadioInput name="replacement_bonus" :value="false" label="No" checked/>
                            </div>
                        </div>

                        <div v-if="canPenaliseCarer" class="form-group mb-3 ">
                            <FormLabel label="Penalise the carer?"/>
                            <div class="d-flex flex-row mt-2">
                                <RadioInput 
                                    v-model="cancelApplicationForm.penalise_carer"
                                    name="penalise"
                                    :value="true"
                                    label="Yes"
                                />
                                <RadioInput
                                    v-model="cancelApplicationForm.penalise_carer"
                                    name="penalise"
                                    :value="false"
                                    label="No"
                                    checked
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-end mt-2">
                    <button @click.prevent="backToMainScreen"
                            class="btn btn-secondary-outline"
                    >
                        Cancel
                    </button>
                    <button @click.prevent="confirmCancelApplication"
                            class="btn btn-primary mx-2"
                    >
                        Confirm
                    </button>
                </div>
            </form>
            <p v-else class="text-danger">
                ERROR: Unable to cancel this application. Please contact tech team.
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">

import {useNotificationsStore} from "@/stores/notifications";
import { useResourcesStore } from '@/stores/resources'
import {useSessionCarerApplicationStore} from "@/stores/sessionCarerApplication";
import {storeToRefs} from "pinia";
import RadioInput from "@/components/form/RadioInput.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import {toMYR} from "@/composable/useCurrency";
import {computed} from "vue";
import { CancellingSessionCarerApplicationStatuses } from "@/enums/SessionCarerApplicationStatusEnum";

const {addToastNotification} = useNotificationsStore()

const store = useSessionCarerApplicationStore()
const {currentSession, isPaidSession, replacementBonus, canPenaliseCarer, cancelApplicationForm} = storeToRefs(store)
const {backToMainScreen, confirmCancelApplication} = store
const {sessionCarerApplicationStatuses} = storeToRefs(useResourcesStore())

const isAssignedCarer = computed(() => cancelApplicationForm.value?.carer_user_id === currentSession.value?.carerUserId)

const filteredSessionCarerApplicationStatuses = computed(() => {
    return sessionCarerApplicationStatuses.value.filter((status) => {
        return CancellingSessionCarerApplicationStatuses.includes(status.id)
    })
})
</script>
