import Address from '@/types/Address'
import JobStatus from '@/types/JobStatus'

export function getJobStatusBadge (status: string, isButton: boolean = false) {
    const prefix = isButton ? 'btn' : 'bg'

    switch (status) {
        case 'new':
            return `${prefix}-warning`
        case 'check_client':
            return `${prefix}-info`
        case 'check_carer':
            return `${prefix}-info`
        case 'carer_approval':
            return `${prefix}-info`
        case 'carer_approved':
            return `${prefix}-info`
        case 'postponed':
            return `${prefix}-primary`
        case 'posted':
            return `${prefix}-primary`
        case 'pending':
            return `${prefix}-primary`
        case 'received':
            return `${prefix}-secondary`
        case 'pending_letter':
            return `${prefix}-light`
        case 'approved':
            return `${prefix}-success`
        case 'in_session':
            return `${prefix}-lime`
        case 'complete':
        case 'complete_review':
            return `${prefix}-teal`
        case 'cancelled':
            return `${prefix}-dark`
        case 'no_carer':
            return `${prefix}-danger`
        case 'replace_carer':
            return `${prefix}-danger`
        case 'reposted':
            return `${prefix}-danger`
        case 'cancel_refund':
            return `${prefix}-dark`
        case 'credit':
            return `${prefix}-orange`
        case 'refunded':
            return `${prefix}-orange`
        default:
            return `${prefix}-dark`
    }
}

export function getStatusLabel (statusKey: string, statuses: Array<JobStatus>) {
    return statuses.find((status: JobStatus) => status.key === statusKey)
}

export function getAddress (address: Address, isFullAddress: boolean = true) {
    const addressArray = [address.unit_no, address.floor_level, address.block_building, address.address_1]
    if (isFullAddress) {
        addressArray.push(address.town, address.state?.name ?? '')
    }
    return addressArray.filter(val => val).join(', ')
}

export function getShortAddress (address: Address) {
    if (address.town === 'Kuala Lumpur' || address.town === 'Putrajaya' || address.town === 'Labuan' || address.town === 'Pulau Pinang' || address.town === 'Melaka') {
        return address.state
    }

    return `${address.town}, ${address.state}`
}
