import httpClient from './HttpClient'
import httpClientV2 from '@/services/HttpClientV2'
import CommonQuery from '@/types/query/CommonQuery'
import { getQueryString } from '@/composable/http'

export default {
    index(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClientV2.get(`backoffice/addresses?${queryString}`)
    },
    
    store(data: object = {}) {
        return httpClientV2.post('/backoffice/addresses', data)
    },

    update(id: number, data: object = {}) {
        return httpClientV2.patch(`/backoffice/addresses/${id}`, data)
    },

    destroy(id: number) {
        return httpClientV2.delete(`/backoffice/addresses/${id}`)
    }
}