<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <a class="btn btn-primary float-right" data-bs-toggle="modal" data-bs-target="#modal-add-postcode" aria-label="Add Postcode">
                        <icon name="plus" />
                        Add Postcode
                    </a>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Postcode</label>
                    <input class="form-control" type="text" v-model="inputFilter.postcode">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">State</label>
                    <vue-select
                        :options="[ ...addressStates ]"
                        label="name"
                        :reduce="(state: AddressState) => state.id"
                        v-model="inputFilter.state_id"
                        @option:selected="fetchPostcodes"
                    />
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchPostcodes"
                        :is-loading="isFetchingPostcodes"></button-filter>
                </div>
            </div>
            <div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Postcode</th>
                                <th>City</th>
                                <th>State</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="postcodes.length > 0">
                            <tr v-for="postcode in postcodes" :key="postcode.id">
                                <td>{{ postcode.value }}</td>
                                <td>{{ postcode.city }}</td>
                                <td>{{ postcode.state.name }}</td>
                                <td class="text-end">
                                    <div class="btn-group">
                                        <button class="btn btn-icon btn-primary" data-bs-toggle="modal" :data-bs-target="`#modal-edit-postcode-${postcode.id}`" aria-label="Edit Postcode">
                                            <icon name="edit" />
                                        </button>
                                        <button class="btn btn-icon btn-danger" @click.prevent="deletePostcode(postcode)">
                                            <icon name="trash" />
                                        </button>
                                    </div>
                                </td>
                                <postcode-form :modal-id="`modal-edit-postcode-${postcode.id}`" mode="update" :postcode="postcode" @updated-or-created="fetchPostcodes"></postcode-form>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <overlay-spinner :is-showing="isFetchingPostcodes" />
                    </table>
                </div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
            </div>
        </div>
    </div>
    <postcode-form modal-id="modal-add-postcode" @updated-or-created="fetchPostcodes"></postcode-form>
</template>

<script setup lang="ts">
import useMetaPage from '@/composable/useMetaPage'
import PostcodeForm from '@/pages/settings/postcode/PostcodeForm.vue'
import PostcodeService from '@/services/PostcodeService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import AddressState from '@/types/AddressState'
import UserFilter from '@/types/Filter'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { addToastNotification } = useNotificationsStore()
const { addressStates } = storeToRefs(useResourcesStore())

interface Postcode {
    id: number
    value: string
    city: string
    state: AddressState
}

const postcodes = ref<Array<Postcode>>([])

const inputFilter = ref<UserFilter>({
    postcode: '',
    state_id: '',
})

const isFetchingPostcodes = ref<boolean>(false)

const fetchPostcodes = () => {
    isFetchingPostcodes.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    } 

    PostcodeService.index(query).then(({ data: { data, meta } }) => {
        postcodes.value = data
        updateMetaPage(meta)
    }).finally(() => {
        isFetchingPostcodes.value = false
    })
}

const deletePostcode = (postcode: Postcode) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete ${postcode.value} postcode?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            PostcodeService.destroy(postcode.id).then(() => {
                fetchPostcodes()
                addToastNotification({
                    message: `Postcode ${postcode.value} deleted`,
                    type: 'success',
                })
            }).catch(() => {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            })
        }
    })
}

const resetFilter = () => {
    inputFilter.value.postcode = ''
    inputFilter.value.state_id = ''

    fetchPostcodes()
}

fetchPostcodes()

watch(
    () => inputFilter.value.state_id === null,
    () => fetchPostcodes()
)

watch(
    () => metaPageTriggered.value,
    () => fetchPostcodes()
)
</script>