<template>
    <div>
        <ChatDetailAccordion id="accordion-admin"
                             title="Admin"
                             :counter="invitedAdmins.length"
        >
            <ul class="list-group mt-3">
                <template v-if="invitedAdmins.length">
                    <li v-for="admin in invitedAdmins" :key="admin.userId"
                        class="d-flex justify-content-between space-x-2 px-3 list-group-item bg-light text-capitalize text-dark"
                        style="padding-top: 12px; padding-bottom: 12px;">
                        <p class="my-0">{{ admin.name }}</p>
                        <button class="btn btn-sm btn-outline-danger rounded" @click.prevent="banUser(admin.userId)">
                            Remove
                        </button>
                    </li>
                </template>
                <template v-if="participantAdmins.length">
                    <li v-for="admin in participantAdmins" :key="admin.userId"
                        class="d-flex justify-content-between space-x-2 px-3 list-group-item bg-light text-capitalize text-dark"
                        style="padding-top: 12px; padding-bottom: 12px;">
                        <p class="my-0">{{ admin.name }}</p>
                        <button class="btn btn-sm btn-outline-primary rounded"
                                @click.prevent="inviteUser(admin.userId, true)">Invite
                        </button>
                    </li>
                </template>
            </ul>
        </ChatDetailAccordion>

        <hr class="my-3"/>

        <ChatDetailAccordion id="accordion-carer"
                             title="Carers"
                             :counter="invitedCarers.length"
        >
            <ul class="list-group mt-3">
                <template v-if="invitedCarers.length">
                    <li v-for="carer in invitedCarers" :key="carer.userId"
                        class="d-flex flex-column justify-content-between px-3 list-group-item bg-light text-dark"
                        style="padding-top: 12px; padding-bottom: 12px;">
                        <div class="d-flex gap-2 justify-content-between align-items-start">
                            <p class="my-0">{{ carer.onlineId }} {{ carer.nickname }}</p>
                            <button class="btn btn-sm btn-outline-danger rounded"
                                    @click.prevent="banUser(carer.userId)">Remove
                            </button>
                        </div>
                    </li>
                </template>
                <template v-else>
                    <li class="space-x-2 px-3 list-group-item bg-light text-dark"
                        style="padding-top: 12px; padding-bottom: 12px;">
                        There is no carer in this chat
                    </li>
                </template>
            </ul>
            <div class="my-3 d-flex justify-content-end">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#inviteCarerToChatModal">
                    <icon name="user-plus" />
                    Invite
                </button>
            </div>
        </ChatDetailAccordion>
    </div>
</template>

<script setup lang="ts">
import {computed, inject} from 'vue'
import ChatDetailAccordion from '@/components/chats/ChatDetailAccordion.vue'

const props = defineProps({
    admins: {type: Array, required: true},
    participants: {type: Array, required: true}
})

const isParticipant = inject<any>('isParticipant')
const inviteUser = inject('inviteUser')
const banUser = inject('banUser')

const emit = defineEmits(['invited', 'banned'])

const currentChannel = inject<any>('currentChannel')
const channelMetadata = inject<any>('channelMetadata')

const customerUserId = computed(() => channelMetadata.value.userId ?? currentChannel.value.url.match(/\d+/)?.[0])

const invitedAdmins = computed(() => {
    const admins = props.admins.filter((admin: any) => {
        return isParticipant(admin.userId)
    }).map((admin: any) => ({
        userId: admin.userId,
        name: admin.userName
    }))

    return sortByName(admins)
})

const invitedCarers = computed(() => {
    return props.participants?.filter((participant: any) => {
        const admin = props.admins.find((admin: any) => admin.userId == participant.userId)

        return !participant.role && !admin && participant.userId !== customerUserId.value
    })
        .map((carer: any) => ({
            userId: carer.userId,
            nickname: carer.nickname
        }))
})

const participantAdmins = computed(() => {
    const admins = props.admins?.filter((admin: any) => {
        return !isParticipant(admin.userId)
    }).map((admin: any) => ({
        userId: admin.userId,
        name: admin.userName
    }))

    return sortByName(admins)
})

const sortByName = (participants: Array<any>) => {
    return participants.sort((a, b) => {
        const nameA = a.name?.toUpperCase()
        const nameB = b.name?.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        return 0
    })
}
</script>

<style scoped>
hr {
    margin-left: -16px;
    margin-right: -16px;
}

.list-group {
    border-radius: 8px;
}
</style>
