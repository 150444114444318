<template>
    <kc-modal modal-id="modal-create-channel" modal-size="md">
        <div class="modal-header">
            <h4 class="modal-title">Create New Channel</h4>
            <button id="btn-modal-create-channel-close" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body bg-white">
            <div class="row">
                <label class="col-3 col-form-label">Channel Type</label>
                <div class="col d-flex align-items-center mt-2">
                    <div v-if="isRecruitment">
                        <label class="form-check form-check-inline">
                            <input type="radio" name="custom_type" :value="SendbirdCustomTypeEnum.ID_RECRUITMENT" class="form-check-input" v-model.number="customTypeId" />
                            <span class="form-check-label">Recruitment</span>
                        </label>
                    </div>
                    <div v-else>
                        <label class="form-check form-check-inline">
                            <input type="radio" name="custom_type" :value="2" class="form-check-input" v-model.number="customTypeId" />
                            <span class="form-check-label">Service</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input type="radio" name="custom_type" :value="1" class="form-check-input" v-model.number="customTypeId" />
                            <span class="form-check-label">Support</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-3 col-form-label">User Type</label>
                <div class="col d-flex align-items-center mt-2">
                    <label class="form-check form-check-inline" v-if="!isRecruitment">
                        <input type="radio" name="user_type" value="customer" class="form-check-input" v-model="userType" />
                        <span class="form-check-label">Customer</span>
                    </label>
                    <label class="form-check form-check-inline">
                        <input type="radio" name="user_type" value="carer" class="form-check-input" v-model="userType" :disabled="customTypeId === 2" />
                        <span class="form-check-label">Carer</span>
                    </label>
                </div>
            </div>
            <div class="row mt-3">
                <label class="col-3 col-form-label">
                    {{ isRecruitment ? 'Carer' : 'User' }}
                </label>
                <div class="col">
                    <vue-select :options="userOptions" @search="onSearchUser" label="name" v-model="selectedUser" :reduce="(user: any) => user.data" />
                </div>
            </div>
            <div class="row mt-3" v-if="isService">
                <label class="col-3 col-form-label">Session</label>
                <div class="col">
                    <vue-select :options="sessions" label="no" :reduce="(session: Session) => session" v-model="selectedSession"></vue-select>
                </div>
            </div>
            <div class="row mt-3" v-if="isSupport">
                <label class="col-3 col-form-label">Issue</label>
                <div class="col">
                    <input type="text" class="form-control" v-model="issue">
                </div>
            </div>
            <div class="row mt-3" v-if="isSupport">
                <label class="col-3 col-form-label">Description</label>
                <div class="col">
                    <textarea v-model="description" class="form-control" rows="2"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3 border-top">
            <button class="btn btn-light" data-bs-dismiss="modal" :disabled="isCreatingChannel">Close</button>
            <button class="btn btn-primary" @click.prevent="createChannel" :disabled="isCreatingChannel">Create Channel</button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import { getMainProfile } from '@/composable/profile'
import { ChatChannelDepartment } from '@/enums/ChatChannelDepartment'
import { SendbirdCustomTypeEnum } from '@/enums/SendbirdCustomTypeEnum'
import SendbirdService from '@/services/SendbirdService'
import SessionService from '@/services/SessionService'
import CarerService from '@/services/users/CarerService'
import CustomerService from '@/services/users/CustomerService'
import { useNotificationsStore } from '@/stores/notifications'
import Session from '@/types/Session'
import User from '@/types/User'
import _ from 'lodash/fp'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const emits = defineEmits(['refreshChannels'])

const route = useRoute()
const { addToastNotification } = useNotificationsStore()

const customTypeId = ref<number>(SendbirdCustomTypeEnum.ID_SERVICE)
const userType = ref<string>('customer')
const userId = ref<number>()
const selectedUser = ref<User>()
const selectedSession = ref<Session>()
const sessions = ref<Array<Session>>([])
const userOptions = ref<Array<User>>([])
const isCreatingChannel = ref<boolean>(false)
const issue = ref<string>('')
const description = ref<string>('')

const isSupport = computed<boolean>(() => customTypeId.value === SendbirdCustomTypeEnum.ID_SUPPORT)
const isService = computed<boolean>(() => customTypeId.value === SendbirdCustomTypeEnum.ID_SERVICE)
const isRecruitment = computed<boolean>(() => route.params.department === ChatChannelDepartment.RECRUITMENT)

const onSearchUser = (search: string, loading: Function) => {
    if (search.length) {
        searchUser(search, loading)
    }
}

const searchUser = _.debounce(350, async (search, loading) => {
    loading(true)

    let query: { search: any; isRecruitment?: boolean } = {
        search
    }

    if (isRecruitment.value) {
        query.isRecruitment = true
    }

    const response = userType.value === 'customer' ?
        await CustomerService.index(query) :
        await CarerService.index(query)

    try {
        userOptions.value = response.data.data.map((user: User) => {
            const mainProfile = getMainProfile(user.profiles)

            return {
                id: user.id,
                name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`,
                data: user,
            }
        })
    } catch (ex: any) {
        addToastNotification({
            message: 'Error while getting user. Please try again.',
            type: 'danger',
        })
    }

    loading(false)
})

const createChannel = () => {
    isCreatingChannel.value = true

    const data = {
        user_id: selectedUser.value?.id,
        custom_type_id: customTypeId.value,
        user_type: userType.value,
        session_id: selectedSession.value?.id,
        session_no: selectedSession.value?.no,
        issue: issue.value,
        description: description.value
    }

    SendbirdService.createChannel(data)
        .then(() => {
            addToastNotification({
                message: 'Channel created.',
                type: 'success',
            })

            if (selectedUser.value) {
                let channelType = null
                let inputSearchText = null

                if (customTypeId.value === 1) {
                    channelType = 'support'
                    inputSearchText = getMainProfile(selectedUser.value.profiles).onlineId
                } else if (customTypeId.value === 2) {
                    channelType = 'service'
                    inputSearchText = selectedSession.value?.no
                }

                emits('refreshChannels', inputSearchText, channelType)
                document.getElementById('btn-modal-create-channel-close')?.click()
            }
        }).catch(({ response: { data }}) => {
            const message = data.hasOwnProperty('message') ? data.message : 'Internal server error. Please contact tech team.'

            addToastNotification({
                message,
                type: 'danger',
            })
        }).finally(() => isCreatingChannel.value = false)
}

const getSessions = (userId: number) => {
    SessionService.index({customer_user_id: userId})
        .then(({data: { data }}) => {
            sessions.value = data
        })
}

watch(
    () => selectedUser.value,
    (user, prevUser) => {
        if (user !== prevUser && (user !== undefined && user !== null)) {
            getSessions(user.id)
        } else {
            sessions.value = []
        }
    }
)

watch(
    () => isRecruitment.value,
    (isRecruitment) => {
        if (isRecruitment) {
            customTypeId.value = SendbirdCustomTypeEnum.ID_RECRUITMENT
            userType.value = 'carer'
        } else {
            customTypeId.value = SendbirdCustomTypeEnum.ID_SERVICE
            userType.value = 'customer'
        }
    }
)

watch(
    () => userType.value,
    () => {
        selectedUser.value = undefined
        userOptions.value = []
    }
)

onMounted(() => {
    if (isRecruitment.value) {
        customTypeId.value = SendbirdCustomTypeEnum.ID_RECRUITMENT
        userType.value = 'carer'
    }

    document.getElementById('modal-create-channel')?.addEventListener('hidden.bs.modal', () => {
        if (isRecruitment.value) {
            customTypeId.value = SendbirdCustomTypeEnum.ID_RECRUITMENT
            userType.value = 'carer'
        } else {
            customTypeId.value = SendbirdCustomTypeEnum.ID_SERVICE
            userType.value = 'customer'
        }

        userOptions.value = []
        selectedUser.value = undefined
        selectedSession.value = undefined
        issue.value = ''
        description.value = ''
    })
})
</script>
