<template>
    <div class="card">
        <form @submit.prevent="saveSession">
            <div class="card-body">

                <!--  If customer id is not provided as props, admin must search and select a customer -->
                <div v-if="!customerId" class="row mb-3">
                    <div class="form-group col-6">
                        <label class="form-label">Customer <span class="text-danger">*</span></label>
                        <vue-select :options="customerOptions"
                                    @search="onSearchCustomer"
                                    v-model="inputUser.customer"
                                    :reduce="(customer: any) => customer.data"
                                    label="name"
                                    :class="{'is-invalid': hasError('customer')}"
                                    @option:change="clearError('customer')"
                                    placeholder="Search customer"/>
                        <small class="invalid-feedback" v-if="hasError('customer')">{{ getError('customer') }}</small>
                    </div>
                </div>


                <div class="row mb-3">
                    <div class="form-group col-6">
                        <label class="form-label">Session Type <span class="text-danger">*</span></label>
                        <vue-select
                            :options="sessionTypes"
                            label="name"
                            :reduce="(sessionType: SessionType) => sessionType.id"
                            v-model="inputUser.session_type">
                        </vue-select>
                        <error-message :has-error="hasError('session_type')"
                                       :message="getError('session_type')"></error-message>
                    </div>
                    <div class="form-group col-6">
                        <label class="form-label">Session Service Type <span class="text-danger">*</span></label>
                        <vue-select
                            :options="sessionServiceTypes"
                            label="name"
                            :reduce="(sessionServiceType: SessionServiceType) => sessionServiceType.id"
                            v-model="inputUser.session_service_type">
                        </vue-select>
                        <error-message :has-error="hasError('session_service_type')"
                                       :message="getError('session_service_type')"></error-message>
                    </div>
                </div>
                <hr/>
                <div class="row mb-3">
                    <h2>Carer Preferences</h2>
                </div>
                <div class="row mb-3 gap-2">
                    <MultiCheckbox
                        v-model="inputUser.carer_language"
                        :options="languages"
                        name="language"
                        label="Language"
                        class="card "
                    />
                    <MultiCheckbox
                        v-model="inputUser.carer_race"
                        :options="races"
                        name="race"
                        label="Race"
                        class="card "
                    />
                    <MultiCheckbox
                        v-model="inputUser.carer_religion"
                        :options="religions"
                        name="religion"
                        label="Religions"
                        class="card "
                    />
                </div>
                <hr/>
                <div class="row mb-3">
                    <h2>Additional Info</h2>
                </div>
                <div class="row mb-3">
                    <div class="form-group col-4">
                        <label class="form-label">Adult Presence (required for child below 6 months)</label>
                        <div class="input-icon mb-2">
                            <textarea class="form-control" rows="3" v-model="inputUser.adult_present"></textarea>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label class="form-label">Pets in the House</label>
                        <div class="input-icon mb-2">
                            <textarea class="form-control" rows="3" v-model="inputUser.pets_present"></textarea>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label class="form-label">Any other details or special requirements</label>
                        <div class="input-icon mb-2">
                            <textarea class="form-control" rows="3" v-model="inputUser.special_requirements"></textarea>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="d-flex justify-content-between mb-3">
                    <h2>Sessions</h2>
                    <a href="#" @click.prevent="() => addSession()" class="fw-bold fs-3 ml-auto" role="button">
                        <icon name="plus" class="me-1"/>
                        Add Session
                    </a>
                </div>
                <session-create-form v-for="(session, index) in inputUser.sessions"
                                     :key="`session-${session.sessionCounter}`"
                                     :index="index" :address-options="addressOptions" :dependents="dependents"
                                     :session="session"
                                     @update-session="updateSession" @copy-session="copySession"
                                     @remove-session="removeSession"
                />
            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit" :disabled="isSubmitting">Create Session</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">

import router from '@/router'
import {useVuelidate} from '@vuelidate/core'
import {onMounted, ref, watch} from 'vue'
import _ from 'lodash/fp'
import CustomerService from '@/services/users/CustomerService'
import {useResourcesStore} from '@/stores/resources'
import {storeToRefs} from 'pinia'
import {getMainProfile} from '@/composable/profile'
import User from '@/types/User'
import UserInput from '@/types/Input'
import Session from '@/types/Session'
import Customer from '@/types/Customer'
import Address from '@/types/Address'
import Dependent from '@/types/Dependent'
import AddressService from '@/services/AddressService'
import SessionService from '@/services/SessionService'
import {useNotificationsStore} from '@/stores/notifications'
import useFormError from '@/composable/useFormError'
import {$externalResults, rules as SessionRule} from '@/rules/SessionRule'
import SessionCreateForm from './SessionCreateForm.vue'
import {dateObject} from '@/composable/useDate'
import {SessionTypeEnum} from '@/enums/SessionTypeEnum'
import {SessionServiceTypeEnum} from '@/enums/SessionServiceTypeEnum'
import DependentService from '@/services/DependentService'
import SessionType from '@/types/SessionType'
import SessionServiceType from '@/types/SessionServiceType'
import {AxiosError} from 'axios'
import MultiCheckbox from '@/components/form/MultiCheckbox.vue'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import { DependentTypeEnum } from '@/enums/DependentTypeEnum'

const {addToastNotification} = useNotificationsStore()
const {
    addressStates,
    postcodes,
    sessionTypes,
    sessionServiceTypes,
    races,
    religions,
    languages
} = storeToRefs(useResourcesStore())

const props = defineProps({
    customerId: {
        type: Number,
        required: false
    },
    redirectOnSuccess: {
        type: Boolean,
        default: true
    }
})

const emits = defineEmits(['submitted'])

const isSubmitting = ref(false)
const customerOptions = ref<Array<Customer>>([])
const addressOptions = ref<Array<Address>>([])
const dependents = ref<Array<Dependent>>([])

const inputUser = ref<UserInput>({
    customer: '',
    adult_present: '',
    pets_present: '',
    special_requirements: '',
    carer_language: [],
    carer_race: [],
    carer_religion: [],
    session_type: SessionTypeEnum.ID_CHILDCARE,
    session_service_type: SessionServiceTypeEnum.ID_ON_DEMAND,
    sessions: [] as Array<Session>

})

const v$ = useVuelidate(SessionRule, {inputUser}, {$externalResults})
const {hasError, getError, clearError, clearAllErrors} = useFormError(v$)

const sessionCounter = ref<number>(0)

const DEFAULT_SESSION_DATA = {
    customer: '',
    started_at: dateObject().add(3, 'h').set('m', 0).set('s', 0).format('YYYY-MM-DD HH:mm:ss'),
    adult_present: '',
    pets_present: '',
    special_requirements: '',
    carer_language: [],
    carer_race: [],
    carer_religion: [],
    total_hours: 2,
    assigned_carer: null,
    address: null,
    dependents: [],
    sessionCounter: sessionCounter.value,
    session_type: SessionTypeEnum.ID_CHILDCARE,
    session_service_type: SessionServiceTypeEnum.ID_ON_DEMAND
}

onMounted(() => {
    addSession()
})

watch(
    () => inputUser.value.customer,
    async (customer: any) => {
        if (customer) {
            fetchAddresses(customer.id)
            fetchDependents(customer.id)
        } else {
            addressOptions.value = []
            dependents.value = []

            const session = Object.assign({}, DEFAULT_SESSION_DATA)
            session.sessionCounter = ++sessionCounter.value
            inputUser.value.sessions = [session]
        }
    }
)

watch(
    () => props.customerId,
    (customerId) => {
        if (customerId) {
            fetchAddresses(customerId)
            fetchDependents(customerId)
        }
    }
)

const addSession = (session?: any) => {
    if (!session) {
        session = Object.assign({}, DEFAULT_SESSION_DATA)
    }

    session.sessionCounter = ++sessionCounter.value
    inputUser.value.sessions.push(session)
}

const findSessionIndexByCounter = (sessionCounter: number) => inputUser.value.sessions.findIndex((session: any) => session.sessionCounter === sessionCounter)

const removeSession = (sessionCounter: number) => {
    if (inputUser.value.sessions.length > 1) {
        const index = findSessionIndexByCounter(sessionCounter)

        if (index !== -1) {
            inputUser.value.sessions.splice(index, 1)
        }
    }
}

const copySession = (sessionCounter: number) => {
    const index = findSessionIndexByCounter(sessionCounter)

    if (index !== -1) {
        const session = Object.assign({}, inputUser.value.sessions[index])
        session.started_at = dateObject(session.started_at).add(1, 'd').format('YYYY-MM-DD HH:mm:ss')

        addSession(session)
    }
}

const updateSession = (updatedSession: any) => {
    const index = findSessionIndexByCounter(updatedSession.sessionCounter)

    if (index !== -1) {
        inputUser.value.sessions[index] = updatedSession
    }
}

const onSearchCustomer = (search: string, loading: Function) => {
    if (search.length) {
        searchCustomers(search, loading)
    }
}

const searchCustomers = _.debounce(350, (search, loading) => {
    loading(true)

    CustomerService.index({search})
        .then(({data: {data}}) => {
            customerOptions.value = data.map((customer: User) => {
                const mainProfile = getMainProfile(customer.profiles)

                return {
                    id: customer.id,
                    name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`,
                    data: customer
                }
            })
        })
        .catch(() => {
        })
        .finally(() => loading(false))
})

async function saveSession() {
    if (isSubmitting.value) {
        return
    }

    $externalResults.value.inputUser = {}
    await v$.value.$validate()

    isSubmitting.value = true

    await createSessions()
    isSubmitting.value = false

}

const createSessions = async () => {
    try {
        await SessionService.store(formattedData())
        addToastNotification({
            message: `Session created`,
            type: 'success'
        })

        if (props.redirectOnSuccess) {
            await router.push({name: 'home-page'})
        }

        emits('submitted')

    } catch (err) {
        const error = err as AxiosError

        if (error.response?.status === 422) {

            const responseData = error.response.data as { errors?: Record<string, string[]> }

            if (responseData.errors) {
                const errors = responseData.errors

                $externalResults.value.inputUser = {
                    customer: errors.customer_user_id,
                    session_type: errors.session_type_id,
                    session_service_type: errors.session_service_type,
                    adult_present: errors.adult_present,
                    pets_present: errors.pets_present,
                    special_requirements: errors.special_requirements,
                    carer_language: errors.carer_language,
                    carer_race: errors.carer_race,
                    carer_religion: errors.carer_religion

                }
            }
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    }
}
const formattedData = () => {

    return {
        customer_user_id: inputUser.value.customer?.id ?? props.customerId ?? '',
        session_type_id: inputUser.value.session_type,
        session_service_type_id: inputUser.value.session_service_type,
        carer_preferences: {
            language_ids: inputUser.value.carer_language,
            race_ids: inputUser.value.carer_race,
            religion_ids: inputUser.value.carer_religion
        },
        metadata: {
            adult_details: inputUser.value.adult_present.length !== 0 ? inputUser.value.adult_present : 'None',
            pet_details: inputUser.value.pets_present.length !== 0 ? inputUser.value.pets_present : 'None',
            other_details: inputUser.value.special_requirements.length !== 0 ? inputUser.value.special_requirements : ''
        },
        sessions: inputUser.value.sessions.map((session: any) => ({
            started_at: session.started_at,
            total_hours: session.total_hours,
            carer_user_id: session.assigned_carer ?? null,
            address: session.address?.id ?? null,
            dependents: session.dependents?.map((child: { id: number }) => child.id) ?? []
        }))
    }

}

function fetchDependents(userId: number) {
    const query = {
        user_id: userId,
        dependent_type_id: DependentTypeEnum.ID_CHILD,
    }

    DependentService.index(query).then(({ data: { data } }) => dependents.value = data)
}

function fetchAddresses(userId: number) {
    AddressService.index({user_id: userId})
        .then(({data: {data, meta}}) => {
            addressOptions.value = data
        })
        .catch(() => {
        })
}

</script>
