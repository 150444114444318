import { LoyaltyPointTypeEnum } from '@/enums/LoyaltyPointTypeEnum'

export const getTypeBadge = (type: number, isButton: boolean = false) => {
    const prefix = isButton ? 'btn' : 'bg'
    let postfix

    switch (type) {
        case LoyaltyPointTypeEnum.TYPE_EARNED:
            postfix = 'success'
            break
        case LoyaltyPointTypeEnum.TYPE_REDEEMED:
            postfix = 'primary'
            break
        case LoyaltyPointTypeEnum.TYPE_EXPIRED:
            postfix = 'danger'
            break
        case LoyaltyPointTypeEnum.TYPE_REFUNDED:
            postfix = 'info'
            break
        default:
            postfix = 'dark'
            break
    }

    return `${prefix}-${postfix}`
}

export const getTypeLabel = (type: number) => {
    let label

    switch (type) {
        case LoyaltyPointTypeEnum.TYPE_EARNED:
            label = 'Earned'
            break
        case LoyaltyPointTypeEnum.TYPE_REDEEMED:
            label = 'Redeemed'
            break
        case LoyaltyPointTypeEnum.TYPE_EXPIRED:
            label = 'Expired'
            break
        case LoyaltyPointTypeEnum.TYPE_REFUNDED:
            label = 'Refunded'
            break
        default:
            label = 'Others'
            break
    }

    return label
}
