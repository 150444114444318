import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { required, minLength, maxLength, numeric, decimal, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        name: {
            required: helpers.withMessage(
                'This name field is required',
                required
            ),
        },
        address_1: {
            required: helpers.withMessage(
                'This address line 1 field is required',
                required
            ),
        },
        postcode: {
            required: helpers.withMessage(
                'This postcode field is required',
                required
            ),
            numeric: helpers.withMessage(
                'This postcode field must be numeric only',
                numeric
            ),
            minLength: helpers.withMessage(
                'This postcode field must be 5 characters',
                minLength(5)
            ),
            maxLength: helpers.withMessage(
                'This postcode field must be 5 characters',
                maxLength(5)
            ),
        }
    },
})
