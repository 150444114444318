<template>
    <template v-if="!displayOnly">
        <div class="dropdown position-static">
            <button type="button"
                    class="btn btn-sm rounded dropdown-toggle text-uppercase w-100"
                    data-bs-toggle="dropdown"
                    :class="getSessionStatusBadge(session.sessionStatusId, true)"
            >
                {{ label }}
            </button>
            <div class="dropdown-menu">
                <a v-for="sessionStatus in nonCancellingStatuses"
                   :key="`non-cancelling-status-${sessionStatus.id}`"
                   class="dropdown-item py-1" role="button"
                   @click.prevent="updateSessionStatus(sessionStatus)"
                >
                    {{ sessionStatus.adminLabel }}
                </a>
                <cancel-session-modal v-for="sessionStatus in cancellingStatuses"
                                      :key="`cancelling-status-${sessionStatus.id}`"
                                      :session-id="session.id"
                                      :cancel-status="sessionStatus"
                                      :is-refundable="session.order?.status === OrderStatusEnum.STATUS_PAID"
                                      @session-cancelled="handleSuccess(sessionStatus)"
                />
            </div>
        </div>
    </template>
    <template v-else>
        <div class="rounded text-center "
                :class="getSessionStatusBadge(session.sessionStatusId, false)"
        >
            {{ label }}
        </div>
    </template>
</template>

<script setup lang="ts">

import { getSessionStatusBadge } from '@/composable/useSession'
import { OrderStatusEnum } from '@/enums/OrderStatusEnum'
import { SessionStatusEnum } from '@/enums/SessionStatusEnum'
import CancelSessionModal from '@/pages/sessions/components/CancelSessionModal.vue'
import SessionService from '@/services/SessionService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import Session from '@/types/Session'
import SessionStatusType from '@/types/SessionStatus'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const {sessionStatuses} = storeToRefs(useResourcesStore())
const {addToastNotification} = useNotificationsStore()

const props = defineProps({
    session: { type: Object as () => Session, required: true},
    displayOnly : {type: Boolean, default: false}
})

const emit = defineEmits(['sessionStatusChanged'])

const label = computed(() => {
    const status = sessionStatuses.value.find(status => status.id === props.session.sessionStatusId)
    return status?.adminLabel ?? 'N/A'
})

const updateSessionStatus = async (status: SessionStatusType) => {
    try {
        await SessionService.updateStatus(props.session.id, {status_id: status.id})
        await handleSuccess(status)
    } catch (err) {
        addToastNotification({
            message: 'Failed to update session status.',
            type: 'danger'
        })
    }
}

const handleSuccess = async (status: SessionStatusType) => {
    addToastNotification({
        message: `Session status changed to ${status.adminLabel}`,
        type: 'success'
    })
    emit('sessionStatusChanged', {
        sessionId: props.session?.id,
        status: status
    })
}

const cancellingStatuses = computed<Array<SessionStatusType>>(() => {
    return sessionStatuses.value.filter((status: SessionStatusType) => checkIfCancelSessionStatus(status))
})

const nonCancellingStatuses = computed<Array<SessionStatusType>>(() => {
    return sessionStatuses.value.filter((status: SessionStatusType) => !checkIfCancelSessionStatus(status))
})

const checkIfCancelSessionStatus = (status: SessionStatusType) => {
    const cancelStatuses = [
        SessionStatusEnum.ID_ADMIN_CANCELLED,
        SessionStatusEnum.ID_CARER_CANCELLED,
        SessionStatusEnum.ID_CUSTOMER_CANCELLED,
        SessionStatusEnum.ID_AUTO_CANCELLED
    ]

    return cancelStatuses.includes(status.id)
}

</script>
