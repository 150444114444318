<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <button class="btn btn-primary float-right" data-bs-toggle="modal" data-bs-target="#bank-form-modal"
                        aria-label="Add New Bank" @click.prevent="clearBank()">
                        <icon name="plus" />
                        Add New Bank
                    </button>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Name</label>
                    <input class="form-control" type="text" v-model="name">
                </div>
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchBanks"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <table class="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Created At</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody v-if="banks.length">
                    <tr v-for="bank in banks" :key="bank.id">
                        <td>{{ bank.name }}</td>
                        <td>{{ formatDate(bank.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                        <td class="text-center">
                            <div class="btn-group">
                                <button class="btn btn-icon btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#bank-form-modal" aria-label="Edit Bank"
                                    @click.prevent="setBank(bank)">
                                    <icon name="edit" />
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="3">No data</td>
                    </tr>
                </tbody>
            </table>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
        <overlay-spinner :is-showing="isFetching"></overlay-spinner>
    </div>
    <BankForm :bank="bank" @save="fetchBanks" />
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import BankForm from '@/pages/settings/banks/BankForm.vue'
import BankService from '@/services/BankService'
import Bank from '@/types/Bank'
import { AxiosError } from 'axios'
import { ref, watch } from 'vue'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const banks = ref<Array<Bank>>([])
const bank = ref<Bank>()
const isFetching = ref<boolean>(false)
const name = ref<string>('')

const fetchBanks = async () => {
    isFetching.value = true

    try {
        const query = {
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
            name: name.value
        }

        const response = await BankService.index(query)
        banks.value = response.data.data
        updateMetaPage(response.data.meta)
    } catch (error) {
        handleError(error)
    }

    isFetching.value = false
}

const handleError = (error: any) => {
    if (error instanceof AxiosError && error.response) {
        console.error(error.response.data.message)
    }
}

const resetFilter = () => {
    name.value = ''
    fetchBanks()
}

const setBank = (bankToBeEdited: Bank) => bank.value = bankToBeEdited

const clearBank = () => bank.value = undefined

fetchBanks()

watch(
    () => metaPageTriggered.value,
    () => fetchBanks()
)
</script>