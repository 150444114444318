<template>
    <div class="card">
        <form @submit.prevent="saveCoupon">
            <div class="card-body">
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Code <span class="text-danger">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('code') }" type="text" v-model="inputUser.code" @focus="clearError('code')">
                        <small class="invalid-feedback" v-if="hasError('code')">{{ getError('code') }}</small>
                        <small class="text-muted">Code that will be used by customer to apply the discount.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Name <span class="text-danger">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('name') }" type="text" v-model="inputUser.name" @focus="clearError('name')">
                        <small class="invalid-feedback" v-if="hasError('name')">{{ getError('name') }}</small>
                        <small class="text-muted">General name for discount. Will be displayed in customer booking confirmation details.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Description</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('description') }" type="text" v-model="inputUser.description" @focus="clearError('description')">
                        <small class="invalid-feedback" v-if="hasError('description')">{{ getError('description') }}</small>
                        <small class="text-muted">Description of the discount.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Discount Amount <span class="text-danger">*</span></label>
                    <div class="col">
                        <div class="input-group">
                            <input class="form-control" :class="{ 'is-invalid': hasError('discount_amount') }" type="number" step="0.01" v-model="inputUser.discount_amount" @focus="clearError('discount_amount')">
                            <select class="form-select flex-grow-0" style="width: 120px" name="is_fixed" v-model="inputUser.is_fixed">
                                <option :value="true">Flat (RM)</option>
                                <option :value="false">%</option>
                            </select>
                        </div>
                        <small class="invalid-feedback" v-if="hasError('discount_amount')">{{ getError('discount_amount') }}</small>
                        <small class="text-muted">Discount amount. Based on type of discount.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Started Date</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('started_at') }" type="date" v-model="inputUser.started_at" @focus="clearError('started_at')">
                        <small class="invalid-feedback" v-if="hasError('started_at')">{{ getError('started_at') }}</small>
                        <small class="text-muted">Date of voucher/coupon start apply to booking.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Expired Date</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('expired_at') }" type="date" v-model="inputUser.expired_at" @focus="clearError('expired_at')">
                        <small class="invalid-feedback" v-if="hasError('expired_at')">{{ getError('expired_at') }}</small>
                        <small class="text-muted">Date of voucher/coupon expired.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">No. of Max Uses</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('max_uses') }" type="number" v-model="inputUser.extra_attributes.max_uses" @focus="clearError('max_uses')">
                        <small class="invalid-feedback" v-if="hasError('max_uses')">{{ getError('max_uses') }}</small>
                        <small class="text-muted">Maximum limit of voucher/coupon usage. Consider unlimited if left blank. </small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">No. of Max Uses per User</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('max_uses_user') }" type="number" v-model="inputUser.extra_attributes.max_uses_user" @focus="clearError('max_uses_user')">
                        <small class="invalid-feedback" v-if="hasError('max_uses_user')">{{ getError('max_uses_user') }}</small>
                        <small class="text-muted">Maximum limit of voucher/coupon usage by per user. Consider unlimited if left blank.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Minimum Sessions to Applicable</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('min_sessions') }" type="number" v-model="inputUser.extra_attributes.min_sessions" @focus="clearError('min_sessions')">
                        <small class="invalid-feedback" v-if="hasError('min_sessions')">{{ getError('min_sessions') }}</small>
                        <small class="text-muted">Minimum number of sessions per booking to received discount. Consider no minimum if left blank.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Minimum Hours to Applicable</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('min_hours') }" type="number" v-model="inputUser.extra_attributes.min_hours" @focus="clearError('min_hours')">
                        <small class="invalid-feedback" v-if="hasError('min_hours')">{{ getError('min_hours') }}</small>
                        <small class="text-muted">Minimum hours per session to received discount. Consider no minimum if left blank.</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Applicable in Public Holiday</label>
                    <div class="col">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.extra_attributes.available_holiday" name="available_holiday" :value="false" id="available_holiday_false">
                            <span class="form-check-label">No</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.extra_attributes.available_holiday" name="available_holiday" :value="true" id="available_holiday_true">
                            <span class="form-check-label">Yes</span>
                        </label>
                    </div>
                    <small class="text-red" v-if="hasError('available_holiday')">{{ getError('available_holiday') }}</small>
                    <small class="text-muted">Discount can be applied in public holiday or not.</small>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Increment Discount for Each Bundle Minimum Session</label>
                    <div class="col">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.extra_attributes.increment_discount_each_bundle" name="increment_discount_each_bundle" :value="false" id="increment_discount_each_bundle_false">
                            <span class="form-check-label">No</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.extra_attributes.increment_discount_each_bundle" name="increment_discount_each_bundle" :value="true" id="increment_discount_each_bundle_true">
                            <span class="form-check-label">Yes</span>
                        </label>
                    </div>
                    <small class="text-red" v-if="hasError('increment_discount_each_bundle')">{{ getError('increment_discount_each_bundle') }}</small>
                    <small class="text-muted">Discount amount will be multiply by discount amount for each bundle session that meet requirement voucher/coupon setting except this field. Minimum Sessions to Applicable field must be filled before set this.</small>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit">Save Coupon</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { ref, onMounted } from 'vue'
import _ from 'lodash'
import date from '@/utils/date'
import { useRouter, useRoute } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications'
import CouponService from '@/services/settings/CouponService'
import UserInput from '@/types/Input'
import Coupon from '@/types/Coupon'
import useFormError from '@/composable/useFormError'
import { rules as CouponRule, $externalResults } from '@/rules/CouponRule'

const { addToastNotification } = useNotificationsStore()
const router = useRouter()
const route = useRoute()
const props = defineProps({
    coupon: { type: Object as () => Coupon, required: true },
    id: { type: Number, default: 0 },
})
const mode = route.params.couponId ? 'update' : 'create'

const inputUser = ref<UserInput>({
    code: '',
    name: '',
    description: '',
    discount_amount: '',
    is_fixed: false,
    started_at: '',
    expired_at: '',
    extra_attributes: {
        max_uses: null,
        max_uses_user: null,
        min_sessions: null,
        min_hours: null,
        available_holiday: false,
        increment_discount_each_bundle: false,
    }
})
const v$ = useVuelidate(CouponRule, { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

onMounted(() => {
    if (props.coupon !== undefined) {
        const { id, created_at, updated_at, ...couponObj } = camelCaseKeysToSnakeCase(props.coupon)
        inputUser.value = couponObj

        inputUser.value = {
            ...inputUser.value,
            is_fixed: !!couponObj.is_fixed,
            discount_amount: couponObj.is_fixed ? couponObj.discount_amount / 100 : couponObj.discount_amount,
            started_at: couponObj.started_at ? date.format(couponObj.started_at, 'YYYY-MM-DD') : null,
            expired_at: couponObj.expired_at ? date.format(couponObj.expired_at, 'YYYY-MM-DD') : null,
        }
    }
})

async function saveCoupon () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        const formattedData = formatData(inputUser.value)

        const couponService =
            mode === 'create'
                ? CouponService.store(formattedData)
                : CouponService.update(props.id, formattedData)

        couponService
            .then(() => {
                addToastNotification({
                    message: `Coupon ${inputUser.value.name} ${mode}d`,
                    type: 'success',
                })
                router.push({ name: 'coupon-index' })
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message: 'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}

// https://stackoverflow.com/questions/30970286/convert-javascript-object-camelcase-keys-to-underscore-case
function camelCaseKeysToSnakeCase (obj: any) {
    if (typeof (obj) != "object") return obj

    for (let oldName in obj) {
        // Camel to underscore
        let newName: string = oldName.replace(/([A-Z])/g, function ($1) { return "_" + $1.toLowerCase() })

        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.
            if (obj.hasOwnProperty(oldName)) {
                obj[newName] = obj[oldName]
                delete obj[oldName]
            }
        }

        // Recursion
        if (typeof (obj[newName]) == "object") {
            obj[newName] = camelCaseKeysToSnakeCase(obj[newName])
        }
    }

    return obj
}

const formatData = (obj: UserInput) => {
    const discount_amount = obj.is_fixed ? (obj.discount_amount * 100) : obj.discount_amount

    return {
        ...obj,
        type: 'coupon',
        discount_amount: discount_amount
    }
}
</script>
