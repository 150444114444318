<template>
    <div>
        <router-link :to="{ 'name': 'organization-index' }" class="d-inline-flex align-items-center" role="button">
            <icon name="chevron-left"/>
            <span>Back to Organization</span>
        </router-link>

        <overlay-spinner :is-showing="isFetchingOrganization" v-if="isFetchingOrganization" />
        <div>
            <div class="page-header mb-4">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="page-title">{{ organization.name }}</h2>
                    </div>
                    <div class="col-auto d-none d-md-flex">
                        <router-link :to="{ name: 'organization-edit', params: { organizationId: organization.id } }" type="button" class="btn btn-success me-2">Edit</router-link>
                        <button type="button" class="btn btn-danger" @click="deleteOrganization(organization)">Delete</button>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Name</span>
                            </p>
                            <span class="fw-bold">{{ organization.name }}</span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Logo</span>
                            </p>
                            <div class="form-group mb-3 row">
                                <div class="col">
                                    <img :src="organization.logo_url" alt="Logo" width="50" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h4 class="card-title">Users in this organization</h4>
                    <button
                        type="button"
                            class="btn btn-primary"
                                @click.prevent="modalUserOrganizationForm = []; modalOrganizationId = organizationId;"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-user-organization-form"
                                >
                            Add Staff
                        </button>
                </div>
                <div class="card-body">
                <table class="table" v-if="organizationUser.length > 0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Registered At</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="user in organizationUser" :key="user.id">
                            <td>{{ user.name }}</td>
                            <td>{{ user.createdAt }}</td>
                            <td>
                                <button class="btn btn-icon btn-danger" @click.prevent="deleteUserOrganization(user)">
                                    <icon name="trash" />
                                </button>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <p class="text-center" v-else>No Data</p>
            </div>
            </div>

        </div>
        <organization-user-form-modal :userOrganization="modalUserOrganizationForm" :organizationId="organizationId" :fetchOrganization="fetchOrganization"/>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import OrganizationUserFormModal from '@/pages/settings/organizationsUser/OrganizationUserFormModal.vue'

import { useRoute, useRouter } from 'vue-router'

import OrganizationService from '@/services/settings/OrganizationService'
import OrganizationUserService from '@/services/settings/OrganizationUserService'
import { useNotificationsStore } from '@/stores/notifications'
import Organization from '@/types/Organization'
import OrganizationUser from '@/types/OrganizationUser'

const modalUserOrganizationForm = ref<any>({})
const modalOrganizationId = ref<number>(0);

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const router = useRouter()
const isFetchingOrganization = ref<boolean>(false)
const organizationId = Number(route.params.organizationId)
const organization = ref<Organization>({
  id: 0,
  name: '',
  createdAt: '',
  logo_url: ''
});
const organizationUser = ref<OrganizationUser[]>([]); 

fetchOrganization()

function fetchOrganization () {
    isFetchingOrganization.value = true

    OrganizationService.show(organizationId)
        .then(({ data: { data } }) => {
            organization.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingOrganization.value = false)

    OrganizationUserService.show(organizationId)
        .then(({ data: { data } }) => {
            organizationUser.value = data;
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' });
        }).finally(() => isFetchingOrganization.value = false);
}

function deleteOrganization (organization: Organization) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete organization ${organization.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            let organizationId = Number(organization.id)

            OrganizationService.destroy(organizationId)
                .then(() => {
                    router.push({ name: 'organization-index' });
                    addToastNotification({
                        message: `Organization ${organization.name} deleted`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}

function deleteUserOrganization (user: OrganizationUser) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete user ${user.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            let userorganizationId = Number(user.id)
            let organizationId = organization.value.id;
            
            OrganizationUserService.destroy(userorganizationId, organizationId)
                .then(() => {
                    fetchOrganization()
                    addToastNotification({
                        message: `Organization ${user.name} deleted`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}
</script>