<template>
    <div class="text-center mb-4">
        <router-link :to="{ name: 'login-page' }" class="navbar-brand navbar-brand-autodark"><img src="/kiddocare-logo.png" height="36" alt=""></router-link>
    </div>
    <form class="card card-md">
        <div class="card-body">
            <div>
                <h2 class="card-title text-center mb-4">Forgot password</h2>
                <div class="mb-3">
                    <label class="form-label">Email address</label>
                    <input type="email" class="form-control" placeholder="Enter email" :class="{ 'is-invalid': hasError('email') }" @focus="clearError('email')" v-model="inputUser.email">
                    <small class="invalid-feedback" v-if="hasError('email')">{{ getError('email') }}</small>
                </div>
                <label class="form-label">Password</label>
                <div class="input-group input-group-flat mb-3">
                    <input :type="passwordType" class="form-control" placeholder="Enter new password" :class="{ 'is-invalid': hasError('password') }" v-model="inputUser.password">
                    <span class="input-group-text cursor-pointer">
                        <a class="link-secondary" title="Show password" data-bs-toggle="tooltip" @click="revealPassword">
                            <icon v-if="isPasswordRevealed" name="eye" />
                            <icon v-else name="eye-off" />
                        </a>
                    </span>
                    <small class="invalid-feedback" v-if="hasError('password')">{{ getError('password') }}</small>
                </div>
                <label class="form-label">Confirm Password</label>
                <div class="input-group input-group-flat mb-3">
                    <input :type="confirmPasswordType" class="form-control" placeholder="Confirm new password" :class="{ 'is-invalid': hasError('confirm_password') }" v-model="inputUser.confirm_password">
                    <span class="input-group-text cursor-pointer">
                        <a class="link-secondary" title="Show password" data-bs-toggle="tooltip" @click="revealConfirmPassword">
                            <icon v-if="isConfirmPasswordRevealed" name="eye" />
                            <icon v-else name="eye-off" />
                        </a>
                    </span>
                    <small class="invalid-feedback" v-if="hasError('confirm_password')">{{ getError('confirm_password') }}</small>
                </div>
                <div class="form-footer">
                    <div class="row">
                        <div class="col">
                            <input placeholder="Enter code" type="code" class="form-control" :class="{ 'is-invalid': hasError('code') }" @focus="clearError('code')" v-model="inputUser.code">
                            <small class="invalid-feedback" v-if="hasError('code')">{{ getError('code') }}</small>
                        </div>
                        <div class="col">
                            <button type="submit" class="btn btn-light w-100" :disabled="isSending" @click.prevent="forgotPassword">
                                <div v-if="! isSending">
                                    <icon name="mail" />
                                    Send code via Email
                                </div>
                                <div class="d-flex align-self-center mx-auto" v-else>
                                    <div class="spinner-border"></div>
                                </div>
                            </button>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                            <p :class="isCodeSentSuccess ? 'text-success' : 'text-danger'" v-if="codeSentStatus">
                                {{ codeSentStatus }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="btn btn-light mt-3 w-100 ">
                            <router-link :to="{ name: 'login-page' }"> <icon name="arrow-left" /> Back to login</router-link>
                        </div>
                    </div>
                    <div class="col">
                        <div class="col mt-3">
                            <button type="button" class="btn btn-primary w-100" :disabled="isResetting||disableResetButton" @click.prevent="resetPassword">
                                <div v-if="! isResetting">
                                    <icon name="lock" />
                                    Reset Password
                                </div>
                                <div class="d-flex align-self-center mx-auto" v-else>
                                    <div class="spinner-border"></div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <p class="text-danger" v-if="resetErrorMessage">{{ resetErrorMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <kc-modal :modal-id="modalId" :modal-backdrop="'static'">
        <div class="modal-body bg-white">
            Password has been succefully reset. You now can proceed to login.
        </div>
        <div class="modal-footer pt-2 border-top">
            <button class="btn btn-primary w-100" data-bs-dismiss="modal" @click.prevent="closeModal">
                Proceed to login
            </button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import Modal from 'bootstrap/js/dist/modal'
import { computed, ref } from 'vue'
import AuthService from '@/services/AuthService'
import useVuelidate from '@vuelidate/core'
import useFormError from '@/composable/useFormError'
import UserInput from '@/types/Input'
import { rules as AuthRule, $externalResults } from '@/rules/AuthRule'
import router from '@/router'
import { email, helpers, required } from '@vuelidate/validators'

const modalId = ref<string>('password-reset-success')
const modalDismissed = ref<boolean>(false)
const isCodeSentSuccess = ref<boolean>()
const isSending = ref<boolean>(false)
const isResetting = ref<boolean>(false)
const isPasswordRevealed = ref<boolean>(false)
const isConfirmPasswordRevealed = ref<boolean>(false)
const codeSentStatus = ref<string>('')
const resetErrorMessage = ref<string>('')
const passwordType = ref<string>('password')
const confirmPasswordType = ref<string>('password')
const inputUser = ref<UserInput>({
    email: '',
    password: '',
    confirm_password: '',
    code: '',
})
const v$ = useVuelidate(
    AuthRule(inputUser.value),
    { inputUser },
    { $scope: false, $externalResults }
)
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

const closeModal = async () => {    
    const modal = document.getElementById(modalId.value)

    await new Promise<void>((resolve) => {
        modal?.addEventListener('hidden.bs.modal', () => {
            modalDismissed.value = true
            resolve()
        });
    });

    if (modalDismissed.value) {
        router.push({ name: 'login-page' })
    }
}

const revealPassword = () => {
    isPasswordRevealed.value = ! isPasswordRevealed.value

    if (isPasswordRevealed.value) {
        passwordType.value = 'text'
    } else {
        passwordType.value = 'password'
    }
}

const revealConfirmPassword = () => {
    isConfirmPasswordRevealed.value = ! isConfirmPasswordRevealed.value

    if (isConfirmPasswordRevealed.value) {
        confirmPasswordType.value = 'text'
    } else {
        confirmPasswordType.value = 'password'
    }
}

const forgotPassword = async () => {
    clearAllErrors()

    const v$ = useVuelidate(
        {
            inputUser: {
                email: {
                    required: helpers.withMessage(
                        'The email field is required',
                        required,
                    ),
                    email: helpers.withMessage(
                        'The email must be a valid email',
                        email,
                    ),
                }
            }
        }, { inputUser: { email: inputUser.value.email } }
    )

    const { getError } = useFormError(v$);

    const validated = await v$.value.$validate();

    if (validated) {
        isSending.value = true
        codeSentStatus.value = ''
        resetErrorMessage.value = ''

        const data = { email: inputUser.value.email }

        AuthService.forgotPassword(data).then(() => {
            isCodeSentSuccess.value = true
            codeSentStatus.value = 'Code has been sent to your email. Please check your email.'
        }).catch(() => {}).finally(() => isSending.value = false)
    } else {
        isCodeSentSuccess.value = false
        codeSentStatus.value = getError('email')
    }
}

const resetPassword = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        isResetting.value = true
        codeSentStatus.value = ''
        resetErrorMessage.value = ''

        const data = { ...inputUser.value }

        await AuthService.resetPassword(data).then(() => {
            const modal = document.getElementById(modalId.value)
            if (modal) {
                new Modal(modal).show()
            }
        }).catch(({ response: { data, status } }) => {
            if (status === 403) {
                resetErrorMessage.value = data.message
            } else {
                resetErrorMessage.value = 'An error has occured.'
            }
        }).finally(() => isResetting.value = false)
    }
}

const disableResetButton = computed(() => {
    return inputUser.value.code.length !== 6 || hasError('code')
})

</script>
