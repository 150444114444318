<template>
    <ul class="nav row ">
        <li class="nav-item col-sm-6 col-md-2  py-1 border-bottom"
            v-for="(menu, index) in items"
            v-show="!menu.hasOwnProperty('show') || menu.show"
            :key="index"
            :class="isActive(menu.slug) ? 'text-primary bg-primary-lt' : ''"
        >
            <a class="nav-link"  role="button" @click="setActiveComponent(menu)">{{ menu.title }}</a>
        </li>
    </ul>
</template>

<script setup lang="ts">
import { inject } from 'vue'

interface SubMenu {
    title: string,
    slug: string,
    component: any,
    show?: boolean
}

const setActiveComponent = inject('setActiveComponent')
const props = defineProps({
    items: { type: Array as () => Array<SubMenu> },
    active: { type: Object },
})

const isActive = (slug: string) => props.active?.slug === slug

</script>
