<template>
    <template v-if="activityLogs.length > 0" >

        <ul class="list list-timeline">
            <li v-for="(activityLog, index) in activityLogs"
                :key="`activity-item-${index}`"
            >
                <div class="list-timeline-icon"
                     :class="getTimelineIconClass(activityLog)"
                >
                    <icon name="circle-check"
                          :name="getIconName(activityLog)"
                          :size="24"
                    />
                </div>
                <div class="list-timeline-content">
                    <div class="list-timeline-time" v-if="activityLog.createdAt">
                        {{ formatDate(activityLog.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}
                    </div>
                    <p class="list-timeline-title">{{ _.startCase(activityLog.label) }}</p>
                    <p class="text-muted">{{ activityLog?.reason ? `Reason: ${activityLog.reason}` : '&nbsp;' }}</p>
                </div>
            </li>
        </ul>
    </template>
    <template v-else>
        <div> No activity yet </div>
    </template>
</template>

<script setup lang="ts">

import { formatDate } from '@/composable/useDate'
import ActivityLogTimelineItem from '@/types/ActivityLogTimelineItem'
import _ from 'lodash'

defineProps({
    activityLogs: {
        type: Array as () => Array<ActivityLogTimelineItem>,
        default: [],
    }
})

const getIconName = (activityLog: ActivityLogTimelineItem) => typeof activityLog === 'object' ? 'circle-check' : 'loader-2'
const getTimelineIconClass = (activityLog: ActivityLogTimelineItem) => typeof activityLog === 'object' ? 'bg-success' : 'bg-muted'

</script>
