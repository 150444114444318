const useCamelToSnake = (data: Record<string, any>): Record<string, any> => {
    const result: Record<string, any> = {}

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
            result[snakeKey] = data[key]
        }
    }

    return result
}

export default useCamelToSnake
