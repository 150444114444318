<template>
    <kc-modal modal-id="modal-job-remark" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">Session {{ job?.no }}</h4>
            <button id="btn-job-remark-modal-close" type="button" class="btn-close" data-bs-dismiss="modal" data-bs-target="#modal-job-remark" aria-label="Close"></button>
        </div>
        <form>
            <div class="modal-body bg-white">
                <div class="row">
                    <div class="col">
                        <div class="col form-group">
                            <label class="form-label col-form-label">Remark</label>
                            <div class="col">
                                <textarea class="form-control" style="resize: none;" name="remark" rows="5" @input="input.remark = $event.target.value">{{ input.remark }}</textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3 border-top">
                <button class="btn btn-light" type="button" data-bs-dismiss="modal" data-bs-target="#modal-job-remark">Close</button>
                <button class="btn btn-primary" @click.prevent="saveRemark">Save</button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import JobService from '@/services/JobService'

const props = defineProps({
    job: { type: Object, required: true },
})

const input = ref<any>({
    remark: null,
})

const saveRemark = () => {
    JobService.updateRemark(props.job.id, input.value)
        .then(({ data }) => {
            input.value.remark = null
            document.getElementById('btn-job-remark-modal-close')?.click()
        }).catch((error) => {
        })
}

watch(
    () => props.job,
    (newVal) => input.value.remark = newVal?.remark
)

</script>
