<template>
    <router-link :to="{ 'name': 'job-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Jobs</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingJob" v-if="isFetchingJob" />
    <template v-else>
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex space-x-2 align-items-center mb-3">
                        <h2 class="page-title">{{ job.no }}</h2>
                        <span class="badge badge-pill" :class="[getJobStatusBadge(job.status)]">{{ getJobStatusLabel(job.status) }}</span>
                        <span class="badge badge-pill bg-red" v-if="job.isUrgent">Urgent</span>
                    </div>
                </div>
                <div class="col-auto d-md-flex space-x-2">
                    <router-link :to="{ name: 'job-edit', params: { jobId: job.id } }" type="button" class="btn btn-success">Edit Session</router-link>
                </div>
            </div>
        </div>

        <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

        <div class="py-4">
            <KeepAlive>
                <component :is="activeComponent.component"  
                    v-bind="activeComponent.props" 
                    :key="`${activeComponent.slug}-${Date.now()}`"
                >
                </component>
            </KeepAlive>
        </div>
    </template>
</template>

<script setup lang="ts">
import { ref, provide, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import JobService from '@/services/JobService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import { getJobStatusBadge } from '@/composable/useJob'
import { storeToRefs } from 'pinia'
import {AxiosError} from "axios";
import CarerService from "@/services/users/CarerService";

const { addToastNotification } = useNotificationsStore()
const { jobStatuses } = storeToRefs(useResourcesStore())

provide('setActiveComponent', setActiveComponent)

const route = useRoute()
const jobId = Number(route.params.jobId)
const job = ref<any>()
const isFetchingJob = ref<boolean>()
const activeComponent = ref<object>({})
const subMenus = ref<Array<any>>([])

const getJobStatusLabel = (status: string) => {
    const jobStatus = jobStatuses.value.find(jobStatus => jobStatus.key === status)
    return jobStatus?.name ?? 'N/A'
}

fetchJob()

function fetchJob (reloadComponentSlug: string = '') {
    isFetchingJob.value = true

    JobService.show(jobId)
        .then(({ data: { data } }) => {
            job.value = data
            configureSubMenu()
            setActiveComponent(reloadComponentSlug)
        })
        .catch((e) => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => {
            isFetchingJob.value = false
        })
}

function setActiveComponent (menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

function configureSubMenu () {
    subMenus.value = [
        {
            title: 'Overview',
            component: defineAsyncComponent(
                () => import('./JobOverview.vue')
            ),
            slug: 'job-overview',
            props: {
                job: job.value,
            },
        },
        {
            title: 'Children',
            component: defineAsyncComponent(
                () => import('./JobChildren.vue')
            ),
            slug: 'job-children',
            props: {
                children: job.value.children,
            },
        },
        {
            title: 'Check In',
            component: defineAsyncComponent(
                () => import('./JobCheckInOut.vue')
            ),
            show: job.value.sessionActivity?.checkinData[0] ? true : false,
            slug: 'job-checkin',
            props: {
                cardTitle: "Check In",
                data: job.value.sessionActivity?.checkinData[0] ? JSON.parse(job.value.sessionActivity?.checkinData[0]) : null
            },
        },
        {
            title: 'Check Out',
            component: defineAsyncComponent(
                () => import('./JobCheckInOut.vue')
            ),
            show: job.value.sessionActivity?.checkoutData[0] ? true : false,
            slug: 'job-checkout',
            props: {
                cardTitle: "Check Out",
                data: job.value.sessionActivity?.checkoutData[0] ? JSON.parse(job.value.sessionActivity?.checkoutData[0]) : null
            },
        },
        {
            title: 'Applied Carers',
            component: defineAsyncComponent(
                () => import('./JobAppliedCarer.vue')
            ),
            slug: 'job-applied-carer',
            props: {
                jobId: job.value.id,
            },
        },
        {
            title: 'Review',
            component: defineAsyncComponent(
                () => import('./JobReview.vue')
            ),
            slug: 'job-review',
            props: {
                jobNo: job.value.no,
                reviewId: job.value.review?.id ?? null
            },
        },
        {
            title: 'Change Logs',
            component: defineAsyncComponent(
                () => import('./JobActivityLog.vue')
            ),
            slug: 'job-activity-logs',
            props: {
                jobId: job.value.id,
                customerId: job.value.booking.user_id,
            },
        }
    ]
}
</script>

<style scoped>
@media screen and (min-width: 768px) {
    .list-timeline:not(.list-timeline-simple)::before {
        width: 3px;
        left: 12.7rem;
    }

    .list-timeline:not(.list-timeline-simple) .list-timeline-icon {
        left: 11.5rem;
    }

    .list-timeline:not(.list-timeline-simple) .list-timeline-time {
        width: auto;
    }

    .list-timeline:not(.list-timeline-simple) .list-timeline-content {
        padding: 0.625rem 0 0 15rem;
    }
}
</style>