<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="updateProfile">
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Email</label>
                    <div class="col">
                        <input class="form-control" type="email" v-model="user.email" disabled>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Name</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('name')}" type="text" v-model="inputUser.name" @focus="clearError('name')">
                        <small class="invalid-feedback" v-if="hasError('name')">{{ getError('name') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Chat Nickname</label>
                    <div class="col">
                        <input class="form-control" type="text" v-model="inputUser.chat_nickname">
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Password</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('password')}" type="password" v-model="inputUser.password" @focus="clearError('password')">
                        <small class="invalid-feedback" v-if="hasError('password')">{{ getError('password') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Confirm Password</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('password_confirmation')}" type="password" v-model="inputUser.password_confirmation" @focus="clearError('password_confirmation')">
                        <small class="invalid-feedback" v-if="hasError('password_confirmation')">{{ getError('password_confirmation') }}</small>
                    </div>
                </div>
                <div class="form-footer">
                    <button class="btn btn-primary">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { ref } from 'vue'
import { useGeneralStore } from '@/stores/general'
import { storeToRefs } from 'pinia'
import UserInput from '@/types/Input'
import GeneralService from '@/services/GeneralService'
import useFormError from '@/composable/useFormError'
import { useNotificationsStore } from '@/stores/notifications'
import { rules as ProfileRule, $externalResults } from '@/rules/ProfileRule'

const generalStore = useGeneralStore()
const { user, sendbird } = storeToRefs(generalStore)
const { addToastNotification } = useNotificationsStore()

const inputUser = ref<UserInput>({
    name: generalStore.user.name,
    chat_nickname: sendbird.value.nickname,
    password: '',
    password_confirmation: '',
})
const v$ = useVuelidate(
    ProfileRule(inputUser.value),
    { inputUser },
    { $externalResults }
)
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

 generalStore.$subscribe((mutation, state) => {
    inputUser.value.name = state.user.name
})

async function updateProfile () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        GeneralService.updateProfile(inputUser.value)
            .then(() => {
                user.value.name = inputUser.value.name
                sendbird.value.nickname = inputUser.value.chat_nickname
                addToastNotification({
                    message: 'Your profile has been updated',
                    type: 'success',
                })
                clearAllErrors()
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                     })
                }
            })
    }
}
</script>
