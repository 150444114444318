<template>
    <table class="w-100 px-1">
        <CustomSessionRateAmountItem
            v-for="(rate, index) in form.additional_rates[sessionId]"
            :key="`session-${sessionId}-additional-rates-${index}}`"
            :session-id="sessionId"
            :index="typeof index === 'number' ? index : parseInt(index)"
        />
    </table>
    <div class="d-flex w-100 justify-content-start align-items-center">
        <button @click="addCustomRateForSession(sessionId)"
                class="btn btn-icon btn-ghost-secondary"
        >
            <icon name="plus"/>
        </button>
    </div>
</template>

<script setup lang="ts">

import {storeToRefs} from 'pinia'
import {useCreateOrderStore} from '@/stores/createOrder'
import CustomSessionRateAmountItem from '@/pages/orders/create/components/CustomSessionRateAmountItem.vue'

const createOrderStore = useCreateOrderStore()
const {form} = storeToRefs(createOrderStore)
const {addCustomRateForSession} = createOrderStore

const props = defineProps({
    sessionId: {
        type: Number,
        required: true
    }
})

</script>