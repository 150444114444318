<template>
    <kc-modal modal-id="modal-session-remarks" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">Session {{ session?.no }}</h4>
            <button id="btn-session-remark-modal-close" type="button" class="btn-close" data-bs-dismiss="modal" data-bs-target="#modal-session-remarks" aria-label="Close"></button>
        </div>
        <form>
            <div class="modal-body bg-white">
                <div class="row">
                    <div class="col">
                        <div class="col form-group">
                            <label class="form-label col-form-label">Remarks</label>
                            <div class="col">
                                <textarea class="form-control" style="resize: none;" name="remarks" rows="5" @input="input.remarks = $event.target.value">{{ input.remarks }}</textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3 border-top">
                <button class="btn btn-light" type="button" data-bs-dismiss="modal" data-bs-target="#modal-session-remarks">Close</button>
                <button class="btn btn-primary" @click.prevent="saveRemarks">Save</button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import SessionService from '@/services/SessionService'
import {useNotificationsStore} from '@/stores/notifications'

const {addToastNotification} = useNotificationsStore()

const props = defineProps({
    session: { type: Object, required: true },
})

const input = ref<any>({
    remark: null,
})

const saveRemarks = () => {
  try {
    SessionService.updateRemarks(props.session.id, input.value)
    input.value.remarks = null
    document.getElementById('btn-session-remark-modal-close')?.click()
    addToastNotification({
      message: `Remarks updated.`,
      type: 'success'
    })

  } catch (e) {
    addToastNotification({
      message: 'Internal server error. Please contact tech team.',
      type: 'danger'
    })
  }
}

watch(
    () => props.session,
    (newVal) => input.value.remarks = newVal?.remarks
)

</script>
