<template>
    <kc-modal :modal-id="modalId" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">{{ $props.mode === 'create' ? 'Add' : 'Edit' }} Address</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <form @submit.prevent="saveAddress">
            <div class="modal-body bg-white">
                <div class="form-group mb-3 row" v-if="!isCarer">
                    <label class="form-label col-form-label">Name <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('name') }" type="text" v-model="inputUser.name" @focus="clearError('name')">
                        <small class="invalid-feedback" v-if="hasError('name')">{{ getError('name') }}</small>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col form-group">
                        <label class="form-label col-form-label">Unit No. <span class="text-red">*</span></label>
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('unit_no') }" type="text" v-model="inputUser.unit_no" @focus="clearError('unit_no')"/>
                            <small class="invalid-feedback" v-if="hasError('unit_no')">{{ getError('unit_no') }}</small>
                        </div>
                    </div>
                    <div class="col form-group">
                        <label class="form-label col-form-label">Floor/Level</label>
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('floor_level') }" type="text" v-model="inputUser.floor_level" @focus="clearError('floor_level')"/>
                            <small class="invalid-feedback" v-if="hasError('floor_level')">{{ getError('floor_level') }}</small>
                        </div>
                    </div>
                    <div class="col form-group">
                        <label class="form-label col-form-label">Block Building</label>
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('block_building') }" type="text" v-model="inputUser.block_building" @focus="clearError('block_building')"/>
                            <small class="invalid-feedback" v-if="hasError('block_building')">{{ getError('block_building') }}</small>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Address Line 1 <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('address_1') }" type="text" v-model="inputUser.address_1" @focus="clearError('address_1')"/>
                        <small class="invalid-feedback" v-if="hasError('address_1')">{{ getError('address_1') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Address Line 2</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('address_2') }" type="text" v-model="inputUser.address_2" @focus="clearError('address_2')"/>
                        <small class="invalid-feedback" v-if="hasError('address_2')">{{ getError('address_2') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Postcode <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('postcode') }" type="text" v-model="inputUser.postcode" @input="findPostcode(($event.target as HTMLInputElement).value)" @focus="clearError('postcode')" maxlength="5"/>
                        <small class="invalid-feedback" v-if="hasError('postcode')">{{ getError('postcode') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">City</label>
                    <div class="col">
                        <input class="form-control" v-model="postcode.city" type="text" disabled/>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">State</label>
                    <div class="col">
                        <input class="form-control" :value="getState(postcode.stateId)" type="text" disabled/>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Remarks</label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('remarks') }" type="text" v-model="inputUser.remarks" @focus="clearError('remarks')"/>
                        <small class="invalid-feedback" v-if="hasError('remarks')">{{ getError('remarks') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row" v-if="!isCarer">
                    <label class="form-check">
                        <input type="checkbox" class="form-check-input" v-model="inputUser.is_primary"/>
                        <span class="form-check-label">Set As Primary Address?</span>
                    </label>
                </div>
            </div>

            <div class="modal-footer pt-2 border-top">
                <button class="btn btn-light" type="button" @click="closeModal" :id="`btnDismissModal${modalId}`" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="submit">
                    <span>Save</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>


<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { onMounted, ref } from 'vue'
import UserInput from '@/types/Input'
import AddressService from '@/services/AddressService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import { rules as AddressRule, $externalResults } from '@/rules/AddressRule'
import useFormError from '@/composable/useFormError'
import { storeToRefs } from 'pinia'
import Postcode from '@/types/Postcode'
import AddressState from '@/types/AddressState'

const { postcodes, addressStates } = storeToRefs(useResourcesStore())
const { addToastNotification } = useNotificationsStore()
const postcode = ref<any>({
    city: '',
    stateId: '',
})
const emit = defineEmits(['updated-or-created'])
const props = defineProps({
    isCarer: { type: Boolean, default: false },
    userId: { type: [String, Number] },
    modalId: { type: String, default: 'modal-add-address' },
    address: { type: Object as () => object | any },
    mode: { type: String, default: 'create' },
    componentSlug: { type: String, required: true }
})
const inputUser = ref<UserInput>({
    name: '',
    unit_no: '',
    floor_level: '',
    block_building: '',
    address_1: '',
    address_2: '',
    town: '',
    postcode: '',
    is_primary: false,
    remarks: '',
})
const v$ = useVuelidate(AddressRule, { inputUser }, { $scope: false, $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

onMounted(() => {
    if (props.address !== undefined) {
        Object.keys(props.address).forEach((key) => {
            if (key in inputUser.value) {
                inputUser.value[key] = props.address[key]
            }
            if (key === 'unitNo') inputUser.value.unit_no = props.address[key]
            if (key === 'floorLevel') inputUser.value.floor_level = props.address[key]
            if (key === 'blockBuilding') inputUser.value.block_building = props.address[key]
            if (key === 'address1') inputUser.value.address_1 = props.address[key]
            if (key === 'address2') inputUser.value.address_2 = props.address[key]
            if (key === 'isPrimary') inputUser.value.is_primary = props.address[key]
        })
        inputUser.value.is_primary = !!+inputUser.value.is_primary

        postcode.value = {
            city: props.address.town,
            stateId: props.address.state_id,
        }
    }

    if (props.isCarer) {
        inputUser.value.name = 'Carer Address'
        inputUser.value.is_primary = true
    }
})

async function saveAddress () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        const data = { ...inputUser.value, ...{ user_id: props.userId } }

        const addressService =
            props.mode === 'create'
                ? AddressService.store(data)
                : AddressService.update(props.address.id, data)

        addressService
            .then((data) => {
                addToastNotification({
                    message: `Address ${inputUser.value.name} ${props.mode}d`,
                    type: 'success',
                })
                closeModal()
                emit('updated-or-created', props.componentSlug)
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}

function closeModal () {
    document.getElementById(`btnDismissModal${props.modalId}`)?.click()
    clearAllErrors()

    if (props.mode === 'create') resetForm()
}

function resetForm () {
    inputUser.value = {
        name: '',
        unit_no: '',
        floor_level: '',
        block_building: '',
        address_1: '',
        address_2: '',
        town: '',
        postcode: '',
        is_primary: false,
    }
}

const findPostcode = (inputPostcode: string) => {
    if (inputPostcode.length === 5) {
        const postcodeIndex = postcodes.value.findIndex((postcode: Postcode) => postcode.postcode === inputPostcode)

        if (postcodeIndex !== -1) {
            postcode.value = postcodes.value[postcodeIndex]
        }
    }
}

const getState = (inputStateId: number) => {
    const stateIndex = addressStates.value.findIndex((state: AddressState) => state.id === inputStateId)

    if (stateIndex !== -1) {
        return addressStates.value[stateIndex].name
    }
}
</script>