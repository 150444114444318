import httpClient from './HttpClient'
import UserQuery from '@/types/query/UserQuery'
import { getQueryString } from '@/composable/http'

export default {
    index(query: UserQuery = {}) {
        const queryString = getQueryString(query)
        return httpClient.get(`admin/jobs?${queryString}`)
    },

    store(data: object = {}) {
        return httpClient.post('/admin/jobs', data)
    },

    show(id: number) {
        return httpClient.get(`admin/jobs/${id}`)
    },

    update(id: number, data: object = {}) {
        return httpClient.patch(`/admin/jobs/${id}`, data)
    },

    updateStatus(id: number, data: object = {}) {
        return httpClient.patch(`/admin/jobs/${id}/status`, data)
    },

    updateRemark(id: number, data: object = {}) {
        return httpClient.patch(`/admin/jobs/${id}/remark`, data)
    },

    getAppliedCarers(id: number) {
        return httpClient.get(`/admin/jobs/${id}/applied-carers`)
    },

    patchAppliedCarer(id: number, data: object = {}) {
        return httpClient.patch(`/admin/jobs/${id}/applied-carers`, data)
    },

    approveNegotiation(id: number, data: object = {}) {
        return httpClient.post(`/admin/jobs/${id}/approve-negotiate`, data)
    },

    approveJobDetailChanges(id: number, data: object = {}) {
        return httpClient.post(`admin/jobs/${id}/approve-changes`, data)
    },

    getActivityLogs(id: number) {
        return httpClient.get(`admin/jobs/${id}/activity-logs`)
    },
}