<template>
    <div v-if="!disabled" class="dropdown">
        <a href="#"
           class="btn dropdown-toggle text-uppercase"
           data-bs-toggle="dropdown"
        >
            {{ mode }}
        </a>
        <div class="dropdown-menu">
            <a class="dropdown-item" @click="selectMode('primary')">
                Primary
            </a>
            <a class="dropdown-item" @click="selectMode('custom')">
                Custom
            </a>
        </div>
    </div>
    <div v-else>
        <button :disabled="disabled" class="btn text-uppercase">
            {{mode}}
        </button>
    </div>
</template>

<script setup lang="ts">

import {useCreateOrderStore} from '@/stores/createOrder'
import {storeToRefs} from 'pinia'

const createOrderStore = useCreateOrderStore()
const {
    selectMode
} = createOrderStore
const {
    mode
} = storeToRefs(createOrderStore)

defineProps({
    disabled: {type: Boolean, default: false}
})
</script>