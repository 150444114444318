export enum RoleIdEnum {
    ID_SUPER = 1,
    ID_USER = 2,
    ID_CARER = 3,
    ID_MDEC = 4,
    ID_KHAZANAH = 5,
    ID_CORPORATE = 6,
}

export enum RoleNameEnum {
    SUPER = 'super',
    USER = 'user',
    CARER = 'carer',
    MDEC = 'mdec',
    KHAZANAH = 'khazanah',
    CORPORATE = 'corporate',
}
