<template>
    <div class="position-absolute bottom-0 start-0 end-0 reply-box" :class="!floating ? 'px-4 mb-0' : 'px-3 mb-0'">
        <form @submit.prevent="sendMessage" class="position-relative" style="border-radius: 8px">
            <textarea rows="1" class="form-control p-3 form-input-reply"
                style="resize: none"
                maxlength="10000"
                v-model="inputMessage"
                @input="handleReplyBoxInput($event)"
                @keyup="handleReplyBoxKeyUp($event)"
                @focus="handleReplyBoxFocus($event)"
                @focusout="handleReplyBoxFocusOut($event)"
                @keydown.enter.exact.prevent="sendMessage"
                ref="textareaReply"
                placeholder="Type your reply here...">
            </textarea>
            <div class="d-flex justify-content-between align-items-center form-control reply-box-action">
                <p class="mb-0 text-muted">{{ inputMessage.length }} / 10000</p>
                <div class="d-flex align-items-center">
                    <template v-if="currentType === ChatChannelType.SERVICE">
                        <button class="btn-link" data-bs-toggle="dropdown">
                            <icon name="notes" :size="24" :stroke-width="1.2" class="text-muted flex-shrink-0"/>
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item justify-content-between border-bottom space-x-3">
                                <span>Introduction</span>
                                <div class="space-x-2">
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('copy', 'scriptIntroduction')">
                                        <icon name="copy" class="m-0"/>
                                    </button>
                                </div>
                            </a>
                            <a class="dropdown-item justify-content-between border-bottom space-x-3">
                                <span>Session details - {{ session?.no }}</span>
                                <div class="space-x-2">
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('copy', `session-script-${session?.no}`)">
                                        <icon name="copy" class="m-0"/>
                                    </button>
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('send', `session-script-${session?.no}`)">
                                        <icon name="send" class="m-0"/>
                                    </button>
                                </div>
                            </a>
                            <a class="dropdown-item justify-content-between border-bottom space-x-3">
                                <span>Reminder to Customer</span>
                                <div class="space-x-2">
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('copy', 'scriptCustomerReminder')">
                                        <icon name="copy" class="m-0"/>
                                    </button>
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('send', 'scriptCustomerReminder')">
                                        <icon name="send" class="m-0"/>
                                    </button>
                                </div>
                            </a>
                            <a class="dropdown-item justify-content-between border-bottom space-x-3">
                                <span>Reminder to Kiddocarer</span>
                                <div class="space-x-2">
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('copy', 'scriptCarerReminder')">
                                        <icon name="copy" class="m-0"/>
                                    </button>
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('send', 'scriptCarerReminder')">
                                        <icon name="send" class="m-0"/>
                                    </button>
                                </div>
                            </a>
                            <a class="dropdown-item justify-content-between border-bottom space-x-3">
                                <span>Location service instruction</span>
                                <div class="space-x-2">
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('copy', 'scriptLocation')">
                                        <icon name="copy" class="m-0"/>
                                    </button>
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('send', 'scriptLocation')">
                                        <icon name="send" class="m-0"/>
                                    </button>
                                </div>
                            </a>
                            <a class="dropdown-item justify-content-between space-x-3">
                                <span>Rating reminder</span>
                                <div class="space-x-2">
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('copy', 'scriptRatingReminder')">
                                        <icon name="copy" class="m-0"/>
                                    </button>
                                    <button class="btn btn-default px-2" @click.prevent="copyOrSendScript('send', 'scriptRatingReminder')">
                                        <icon name="send" class="m-0"/>
                                    </button>
                                </div>
                            </a>
                        </div>
                    </template>
                    <button class="btn-link" @click.prevent="inputFile.click()"><icon name="paperclip" :size="24" :stroke-width="1.2" class="text-muted flex-shrink-0" /></button>
                    <button type="submit" class="btn-link"><icon name="send" :size="24" :stroke-width="1.2" class="text-muted flex-shrink-0"/></button>
                    <input type="file" class="d-none" ref="inputFile" accept="image/png, image/jpeg, image/jpg" @change="uploadFile($event)"/>
                </div>
            </div>
        </form>
    </div>

    <script-introduction hidden />
    <script-location hidden />
    <script-customer-reminder hidden />
    <script-carer-reminder hidden />
    <script-session v-if="session" :session="session" />
    <script-rating-reminder hidden />
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'
import _ from 'lodash'
import { useNotificationsStore } from '@/stores/notifications'
import ScriptIntroduction from '@/pages/scripts/ScriptIntroduction.vue'
import ScriptLocation from '@/pages/scripts/ScriptLocation.vue'
import ScriptCustomerReminder from '@/pages/scripts/ScriptCustomerReminder.vue'
import ScriptCarerReminder from '@/pages/scripts/ScriptCarerReminder.vue'
import ScriptRatingReminder from '@/pages/scripts/ScriptRatingReminder.vue'
import ScriptSession from '@/pages/scripts/ScriptSession.vue'
import {ChatChannelType} from '@/enums/ChatChannelType'

const { addToastNotification } = useNotificationsStore()

const { inputMessage, inputFile, sendMessage, uploadFile } = <any>inject('reply')
const { handleReplyBoxInput, handleReplyBoxKeyUp, handleReplyBoxFocus, handleReplyBoxFocusOut } = <any>inject('handleReplyBoxEvent')
const session = inject('session')
const textareaReply = ref<HTMLElement>()

const props = defineProps({
    floating: { type: Boolean },
    currentType: { type: String, default: 'service' }
})

function copyOrSendScript(action: string, id: string) {
    let wrapper = document.getElementById(id) as HTMLElement
    
    if (!wrapper) return
    
    navigator.clipboard.writeText(wrapper?.innerText).then(() => {
        if (action === 'copy') {
            inputMessage.value += wrapper?.innerText
            addToastNotification({
                message: `${_.startCase(_.camelCase(id))} copied!`,
                type: 'success',
            })

            textareaReply.value?.focus()
        }
        
        if (action === 'send') {
            inputMessage.value = wrapper?.innerText
            sendMessage()
        }
    })
}
</script>

<style scoped>
.reply-box>form {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.reply-box-action {
    border-top: 0 !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    margin-bottom: 24px;
}

.form-input-reply {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
</style>
