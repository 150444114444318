<template>
    <div class="card">
        <form @submit.prevent="saveCarerDetails">
            <div class="modal-body bg-white">
                <h3 class="fw-bold">Personal Details</h3>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Name <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('name') }" type="text" v-model="inputUser.name" @focus="clearError('name')">
                        <small class="invalid-feedback" v-if="hasError('name')">{{ getError('name') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Email <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('email')}" type="email" v-model="inputUser.email" @focus="clearError('email')">
                        <small class="invalid-feedback" v-if="hasError('email')">{{ getError('email') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Online ID</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('online_id')}" type="text" v-model="inputUser.online_id" @focus="clearError('online_id')">
                        <small class="invalid-feedback" v-if="hasError('online_id')">{{ getError('online_id') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Carer Type <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select 
                            :options="carerTypes"
                            :reduce="(type: any) => type.id"
                            v-model="inputUser.carer_type_id"
                            label="name"
                            placeholder="Select Carer Type"
                            :class="{'is-invalid': hasError('carer_type_id')}"
                            @option:change="clearError('carer_type_id')">
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </vue-select>
                        <small class="invalid-feedback" v-if="hasError('carer_type_id')">{{ getError('carer_type_id') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Payout Schedule <span class="text-red">*</span></label>
                    <div class="col">
                        <label class="form-check form-check-inline" v-for="(payoutScheduleType, index) in carerPayoutScheduleTypes" :key="index">
                            <input class="form-check-input" type="radio" v-model="inputUser.payout_schedule_type" name="payout_schedule_type" :value="payoutScheduleType" :id="`payoutScheduleType-${payoutScheduleType}`">
                            <span class="form-check-label text-capitalize">{{ payoutScheduleType }}</span>
                        </label>
                    </div>
                    <small class="text-red" v-if="hasError('payout_schedule_type')">{{ getError('payout_schedule_type') }}</small>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Bank <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select
                            :options="banks"
                            :reduce="(type: any) => type.id"
                            v-model="inputUser.bank_id"
                            label="name"
                            placeholder="Select Bank"
                            :class="{'is-invalid': hasError('bank_id')}"
                            @option:change="clearError('bank_id')">
                            <template v-slot:option="option">
                                {{ _.startCase(option.name) }}
                            </template>
                        </vue-select>
                        <small class="invalid-feedback" v-if="hasError('bank_id')">{{ getError('bank_id') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Bank Account No. <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('bank_details') }" type="text" v-model="inputUser.bank_details" @focus="clearError('bank_details')"/>
                        <small class="invalid-feedback" v-if="hasError('mobile_no')">{{ getError('bank_details') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Phone No. <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('mobile_no') }" type="text" v-model="inputUser.mobile_no" @focus="clearError('mobile_no')"/>
                        <small class="text-muted">example: +6012345789, 0123456789</small>
                        <small class="invalid-feedback" v-if="hasError('mobile_no')">{{ getError('mobile_no') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">NRIC/Passport. <span class="text-red">*</span></label>
                    <div class="col">
                        <input class="form-control" :class="{ 'is-invalid': hasError('nric_passport') }" type="text" v-model="inputUser.nric_passport" @focus="clearError('nric_passport')"/>
                        <smal class="text-muted">(without -)</smal>
                        <small class="invalid-feedback" v-if="hasError('nric_passport')">{{ getError('nric_passport') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Gender <span class="text-red">*</span></label>
                    <div class="col">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.gender" name="gender" value="male" id="male">
                            <span class="form-check-label">Male</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="inputUser.gender" name="gender" value="female" id="female">
                            <span class="form-check-label">Female</span>
                        </label>
                    </div>
                    <small class="text-red" v-if="hasError('gender')">{{ getError('gender') }}</small>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Date of Birth <span class="text-red">*</span></label>
                    <div class="col">
                        <div class="input-group">
                            <input type="text" id="datepickerBirthDate" class="form-control bg-white" :value="birthDateDisplay" placeholder="Select date" readonly>
                            <span class="input-group-text">
                                <icon name="calendar" />
                            </span>
                        </div>
                        <small class="invalid-feedback" v-if="hasError('birth_date')">{{ getError('birth_date') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Race <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select 
                            :options="races"
                            :reduce="(race: any) => race.id"
                            v-model="inputUser.race_id"
                            label="name"
                            placeholder="Select Race"
                            :class="{'is-invalid': hasError('race_id')}"
                            @option:change="clearError('race_id')">
                        </vue-select>
                        <small class="invalid-feedback" v-if="hasError('race_id')">{{ getError('race_id') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Religion <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select 
                            :options="religions"
                            :reduce="(religion: any) => religion.id"
                            v-model="inputUser.religion_id"
                            label="name"
                            placeholder="Select Religion"
                            :class="{'is-invalid': hasError('religion_id')}"
                            @option:change="clearError('religion_id')">
                        </vue-select>
                        <small class="invalid-feedback" v-if="hasError('religion_id')">{{ getError('religion_id') }}</small>
                    </div>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Marital Status <span class="text-red">*</span></label>
                    <div class="col">
                        <label class="form-check form-check-inline" v-for="(maritalStatus, index) in maritalStatuses" :key="index">
                            <input class="form-check-input" type="radio" v-model="inputUser.marital_status" name="marital_status" :value="maritalStatus.name" :id="`maritalStatus-${maritalStatus.name}`">
                            <span class="form-check-label text-capitalize">{{ maritalStatus.name }}</span>
                        </label>
                    </div>
                    <small class="text-red" v-if="hasError('marital_status')">{{ getError('marital_status') }}</small>
                </div>

                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Nationality <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select 
                            :options="nationalities"
                            :reduce="(nationality: any) => nationality.id"
                            v-model="inputUser.nationality_id"
                            label="name"
                            placeholder="Select Nationality"
                            :class="{'is-invalid': hasError('nationality_id')}"
                            @option:change="clearError('nationality_id')">
                        </vue-select>
                        <small class="invalid-feedback" v-if="hasError('nationality_id')">{{ getError('nationality_id') }}</small>
                    </div>
                </div>

                <div class="col form-group mb-3">
                    <label class="form-label col-form-label">Language <span class="text-red">*</span></label>
                    <div class="col">
                        <div class="row">
                            <div class="col-3 py-2" v-for="(language, index) in languages" :key="index">
                                <label class="form-check mb-0" :for="language.id">
                                    <input class="form-check-input" type="checkbox" name="languages" v-model.number="inputUser.languages" :value="language.id" :id="language.id">
                                    <span class="form-check-label">{{ language.name }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="text-danger" v-if="hasError('languages')">{{ getError('languages') }}</small>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Application <span class="text-red">*</span></label>
                        <div class="col">
                            <vue-select
                                :options="carerApplicationType"
                                v-model="inputUser.application"
                                label="name"
                                placeholder="Select Application"
                                :class="{'is-invalid': hasError('application')}"
                                @option:change="clearError('application')">
                            </vue-select>
                            <small class="invalid-feedback" v-if="hasError('application')">{{ getError('application') }}</small>
                        </div>
                    </div>

                    <hr />
                    <h3 class="fw-bold">Employment Details</h3>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Kiddocare Work Status</label>
                        <div class="col">
                            <vue-select
                                :options="carerWorkStatuses"
                                :reduce="(workStatus: any) => workStatus.key"
                                v-model="inputUser.work_status"
                                label="label">
                            </vue-select>
                        </div>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Employment Status</label>
                        <div class="col">
                            <vue-select
                                :options="carerEmploymentStatuses"
                                :reduce="(employmentStatus: any) => employmentStatus.key"
                                v-model="inputUser.employment_status"
                                label="label">
                            </vue-select>
                        </div>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Is Household Breadwinner?</label>
                        <div class="col">
                            <label class="form-check form-check-inline" v-for="(breadwinnerStatus, index) in carerBreadwinnerStatuses" :key="index">
                                <input class="form-check-input" type="radio" v-model="inputUser.is_household_breadwinner" name="is_household_breadwinner" :value="breadwinnerStatus.key" :id="`breadwinnerStatus-${breadwinnerStatus.key}`">
                                <span class="form-check-label text-capitalize">{{ breadwinnerStatus.label }}</span>
                            </label>
                        </div>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">House Income Category</label>
                        <div class="col">
                            <vue-select
                                :options="carerIncomeCategories"
                                :reduce="(incomeCategory: any) => incomeCategory.key"
                                v-model="inputUser.household_income_category"
                                label="label">
                            </vue-select>
                        </div>
                    </div>

                    <hr>
                    <h3 class="fw-bold">Education</h3>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Highest education</label>
                        <div class="col">
                            <vue-select
                                :options="carerHighestEducation"
                                :reduce="(highestEducation: any) => highestEducation.key"
                                v-model="inputUser.highest_education"
                                label="label">
                            </vue-select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Education status</label>
                        <div class="col">
                            <vue-select
                                :options="carerEducationStatuses"
                                :reduce="(educationStatus: any) => educationStatus.key"
                                v-model="inputUser.education_status"
                                label="label">
                            </vue-select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Institution</label>
                        <div class="col">
                            <vue-select
                                :options="institutions"
                                :reduce="institution => institution.id"
                                v-model="inputUser.institution_id"
                                label="name">
                            </vue-select>
                        </div>
                    </div>
                    <hr>
                    <h3 class="fw-bold">BAU</h3>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">BCT Training Method</label>
                        <div class="col">
                            <vue-select
                                :options="carerBctTrainingMethods"
                                :reduce="(trainingMethod: any) => trainingMethod.key"
                                v-model="inputUser.bct_training_method"
                                label="label">
                            </vue-select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">BCT Trainer</label>
                        <div class="col">
                            <vue-select
                                :options="carerTrainers"
                                :reduce="(carerTrainer: any) => carerTrainer.key"
                                v-model="inputUser.bct_trainer"
                                label="label">
                            </vue-select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">BCT Attendance Date</label>
                        <div class="col">
                            <div class="input-group">
                                <input type="text" id="datepickerBctAttendanceDate" class="form-control bg-white" :value="bctAttendanceDateDisplay" placeholder="Select date" readonly>
                                <span class="input-group-text">
                                <icon name="calendar" />
                            </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">BCT Assessment Method</label>
                        <div class="col">
                            <vue-select
                                :options="carerBctAssessmentMethods"
                                :reduce="(assessmentMethod: any) => assessmentMethod.key"
                                v-model="inputUser.bct_assessment_method"
                                label="label">
                            </vue-select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">BCT Assessment Passed Date</label>
                        <div class="col">
                            <div class="input-group">
                                <input type="text" id="datepickerBctAssessmentDate" class="form-control bg-white" :value="bctAssessmentPassedDateDisplay" placeholder="Select date" readonly>
                                <span class="input-group-text">
                                <icon name="calendar" />
                            </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">SOP Training Method</label>
                        <div class="col">
                            <vue-select
                                :options="carerSopTrainingMethods"
                                :reduce="(trainingMethod: any) => trainingMethod.key"
                                v-model="inputUser.sop_training_method"
                                label="label">
                            </vue-select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">SOP Trainer</label>
                        <div class="col">
                            <vue-select
                                :options="carerTrainers"
                                :reduce="(trainer: any) => trainer.key"
                                v-model="inputUser.sop_trainer"
                                label="label">
                            </vue-select>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">SOP Attendance Date</label>
                        <div class="col">
                            <div class="input-group">
                                <input type="text" id="datepickerSopAttendanceDate" class="form-control bg-white" :value="sopAttendanceDateDisplay" placeholder="Select date" readonly>
                                <span class="input-group-text">
                                <icon name="calendar" />
                            </span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h3 class="fw-bold">Additional Information</h3>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Experience</label>
                        <div class="col">
                            <textarea rows="3" class="form-control" :class="{'is-invalid': hasError('experience_by_user')}" v-model="inputUser.experience_by_user" @focus="clearError('experience_by_user')"></textarea>
                            <small class="invalid-feedback" v-if="hasError('experience_by_user')">{{ getError('experience_by_user') }}</small>
                        </div>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Children & Safety Course Attended</label>
                        <div class="col">
                            <textarea rows="3" class="form-control" :class="{'is-invalid': hasError('qualification_by_user')}" v-model="inputUser.qualification_by_user" @focus="clearError('qualification_by_user')"></textarea>
                            <small class="invalid-feedback" v-if="hasError('qualification_by_user')">{{ getError('qualification_by_user') }}</small>
                        </div>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Course (Old)</label>
                        <div class="col">
                            <input class="form-control" :class="{'is-invalid': hasError('course')}" type="text" v-model.trim="inputUser.course" @focus="clearError('course')">
                            <small class="invalid-feedback" v-if="hasError('course')">{{ getError('course') }}</small>
                        </div>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Institution (Old)</label>
                        <div class="col">
                            <input class="form-control" :class="{'is-invalid': hasError('institution')}" type="text" v-model.trim="inputUser.institution" @focus="clearError('institution')">
                            <small class="invalid-feedback" v-if="hasError('institution')">{{ getError('institution') }}</small>
                        </div>
                    </div>

                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">Notes</label>
                        <div class="col">
                            <textarea rows="3" class="form-control" :class="{'is-invalid': hasError('notes')}" v-model="inputUser.notes" @focus="clearError('notes')"></textarea>
                            <small class="invalid-feedback" v-if="hasError('notes')">{{ getError('notes') }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer pt-2 border-top">
                <router-link :to=" redirectTo ?? { name: 'carer-show', params: { userId: props.user.id } }" class="btn btn-light" type="button">Cancel</router-link>
                <button class="btn btn-primary" type="submit">
                    <span>Save</span>
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { formatDate } from '@/composable/useDate'
import useVuelidate from '@vuelidate/core'
import { ref, computed, onMounted } from 'vue'
import UserInput from '@/types/Input'
import { getMainProfile } from '@/composable/profile'
import { useNotificationsStore } from '@/stores/notifications'
import { rules as CarerRule, $externalResults } from '@/rules/CarerRule'
import useFormError from '@/composable/useFormError'
import CarerService from '@/services/users/CarerService'
import { useResourcesStore } from '@/stores/resources'
import { storeToRefs } from 'pinia'
import User from '@/types/User'
import router from '@/router'
import { AmpPlugin } from '@easepick/amp-plugin'
import { Core, easepick } from '@easepick/core'

const { addToastNotification } = useNotificationsStore()
const {
    races,
    religions,
    maritalStatuses,
    nationalities,
    languages,
    carerTypes,
    carerApplicationType,
    carerPayoutScheduleTypes,
    banks,
    institutions,
    carerHighestEducation,
    carerEducationStatuses,
    carerWorkStatuses,
    carerEmploymentStatuses,
    carerBreadwinnerStatuses,
    carerIncomeCategories,
    carerBctTrainingMethods,
    carerBctAssessmentMethods,
    carerSopTrainingMethods,
    carerTrainers,
} = storeToRefs(useResourcesStore())

const emit = defineEmits(['updated-or-created'])
const props = defineProps({
    user: { type: Object as () => User, required: true },
    redirectTo: { type: String },
})
const inputUser = ref<UserInput>({
    name: '',
    email: '',
    online_id: '',
    carer_type_id: '',
    payout_schedule_type: '',
    mobile_no: '',
    nric_passport: '',
    gender: '',
    birth_date: '',
    race_id: '',
    religion_id: '',
    marital_status: '',
    nationality_id: '',
    application: 'admin',
    languages: [],
    remarks: '',
    notes: '',
    course: '',
    institution: '',
    education_by_user: '',
    experience_by_user: '',
    qualification_by_user: '',
    highest_education: '',
    education_status: '',
    institution_id: '',
    work_status: '',
    employment_status: '',
    is_household_breadwinner: '',
    household_income_category: '',
    bct_training_method: '',
    bct_trainer: '',
    bct_attendance_date: '',
    bct_assessment_method: '',
    bct_assessment_passing_date: '',
    sop_training_method: '',
    sop_trainer: '',
    sop_attendance_date: '',
})
const v$ = useVuelidate(CarerRule, { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)
let pickerBirthDate: Core | null = null
let pickerBctAttendanceDate: Core | null = null
let pickerBctAssessmentDate: Core | null = null
let pickerSopAttendanceDate: Core | null = null

onMounted(() => {
    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 1950,
                maxYear: (new Date().getFullYear()) - 18,
            }
        }
    }

    pickerBirthDate = new easepick.create({
        element: <any>document.getElementById('datepickerBirthDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.0/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputUser.value.birth_date = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    pickerBctAttendanceDate = new easepick.create({
        element: <any>document.getElementById('datepickerBctAttendanceDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.0/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputUser.value.bct_attendance_date = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    pickerBctAssessmentDate = new easepick.create({
        element: <any>document.getElementById('datepickerBctAssessmentDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.0/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputUser.value.bct_assessment_passing_date = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    pickerSopAttendanceDate = new easepick.create({
        element: <any>document.getElementById('datepickerSopAttendanceDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.0/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputUser.value.sop_attendance_date = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    populateForm()
})
// console.log("NATIONALITY", nationalities)

async function saveCarerDetails () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        const formattedData = formatData(inputUser.value)

        CarerService.update(props.user.id, formattedData)
            .then(() => {
                addToastNotification({
                    message: `Carer ${inputUser.value.name} updated`,
                    type: 'success',
                })
                router.push({ name: 'carer-show', params: { userId: props.user.id } })
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}

const formatData = (obj: UserInput) => {
    return {
        ...obj,
        education_by_user: `${obj.course}|${obj.institution}`,
    }
}

const populateForm = () => {
    const user = props.user
    const userProfile = user?.mainProfile
    const education = user.carer.education ? user.carer.education.split('|') : ['', '']

    inputUser.value = {
        name: userProfile?.fullName,
        email: user.email,
        online_id: userProfile?.onlineId,
        carer_type_id: user.carer.carerTypeId,
        payout_schedule_type: user.carer.payoutScheduleType,
        bank_id: user.carer.bankId,
        bank_details: user.carer.bankDetails,
        mobile_no: userProfile?.mobileNo,
        nric_passport: userProfile?.nricPassport,
        gender: userProfile?.gender,
        birth_date: userProfile?.birthDate,
        race_id: userProfile?.raceId,
        religion_id: userProfile?.religionId,
        marital_status: userProfile?.maritalStatus,
        nationality_id: userProfile?.nationalityId,
        languages: user.carer.languages.map((lang: any) => lang.id),
        remarks: user.carer.remarks,
        notes: user.carer.notes,
        course: education[0],
        institution: education[1],
        experience_by_user: user.carer.experience,
        qualification_by_user: user.carer.safetyCourse,
        application: user.carer.application,
        highest_education: user.carer.highestEducation,
        education_status: user.carer.educationStatus,
        institution_id: user.carer.institutionId,
        work_status: user.carer.workStatus,
        employment_status: user.carer.employmentStatus,
        is_household_breadwinner: user.carer.isHouseholdBreadwinner,
        household_income_category: user.carer.householdIncomeCategory,
        bct_training_method: user.carer.bctTrainingMethod,
        bct_trainer: user.carer.bctTrainer,
        bct_attendance_date: user.carer.bctAttendanceDate,
        bct_assessment_method: user.carer.bctAssessmentMethod,
        bct_assessment_passing_date: user.carer.bctAssessmentPassingDate,
        sop_training_method: user.carer.sopTrainingMethod,
        sop_trainer: user.carer.sopTrainer,
        sop_attendance_date: user.carer.sopAttendanceDate,
    }

    pickerBirthDate?.setDate(inputUser.value.birth_date)
    pickerBirthDate?.gotoDate(inputUser.value.birth_date)

    if (inputUser.value.bct_attendance_date) {
        pickerBctAttendanceDate?.setDate(inputUser.value.bct_attendance_date)
        pickerBctAttendanceDate?.gotoDate(inputUser.value.bct_attendance_date)
    }

    if (inputUser.value.bct_assessment_passing_date) {
        pickerBctAssessmentDate?.setDate(inputUser.value.bct_assessment_passing_date)
        pickerBctAssessmentDate?.gotoDate(inputUser.value.bct_assessment_passing_date)
    }

    if (inputUser.value.sop_attendance_date) {
        pickerSopAttendanceDate?.setDate(inputUser.value.sop_attendance_date)
        pickerSopAttendanceDate?.gotoDate(inputUser.value.sop_attendance_date)
    }
}

const birthDateDisplay = computed<string>(() => formatDate(inputUser.value.birth_date, 'DD-MMM-YYYY'))
const bctAttendanceDateDisplay = computed<string>(() => inputUser.value.bct_attendance_date ? formatDate(inputUser.value.bct_attendance_date, 'DD-MMM-YYYY') : '')
const bctAssessmentPassedDateDisplay = computed<string>(() => inputUser.value.bct_assessment_passing_date ? formatDate(inputUser.value.bct_assessment_passing_date, 'DD-MMM-YYYY') : '')
const sopAttendanceDateDisplay = computed<string>(() => inputUser.value.sop_attendance_date ? formatDate(inputUser.value.sop_attendance_date, 'DD-MMM-YYYY') : '')

</script>