<template>
    <kc-modal modal-id="modal-deactivate-user" modal-size="md">
        <div class="modal-header">
            <h4 class="modal-title">Deactivate {{ props.userType }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body bg-white">
            <p>Please select deactivate reason by user:</p>
            <form id="formDeactivateUser" @submit.prevent="deactivateUser">
                <div v-for="option in deactivateAccountOptions[props.userType]">
                    <label class="form-check" v-if="option.type === 'radio'">
                        <input class="form-check-input" type="radio" name="delete_reason" v-model="deleteReason" :value="option.label">
                        <span class="form-check-label">{{ option.label }}</span>
                    </label>
                </div>
                <p class="mt-3">Any additional reason by user (if any):</p>
                <div class="form-group mb-3 row">
                    <div class="col">
                        <textarea class="form-control" v-model="deleteReason2" rows="5" maxlength="200"></textarea>
                        <div class="d-flex justify-content-end mt-1">
                            <span>{{ deleteReason2.length }} / 200</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal-footer pt-2 border-top">
            <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="submit" form="formDeactivateUser">
                <span>Confirm</span>
            </button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import router from '@/router'
import UserService from '@/services/UserService'
import CarerService from '@/services/users/CarerService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import { AxiosError } from 'axios'
import Modal from 'bootstrap/js/dist/modal'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const { addToastNotification } = useNotificationsStore()
const { deactivateAccountOptions } = storeToRefs(useResourcesStore())
const deleteReason = ref<string>('')
const deleteReason2 = ref<string>('')

const props = defineProps({
    userType: {
        type: String,
        required: true,
    },
    userId: {
        type: Number,
        required: true,
    },
    carerId: {
        type: Number,
    },
    modal: {
        type: Modal
    }
})

const deactivateUser = () => {
    if (deleteReason.value === '') {
        return
    }

    const routeName = props.userType === 'carer' ? 'carer-index' : 'customer-index'

    UserService.deactivate(props.userId, {
        deleteReason: deleteReason.value,
        deleteReason2: deleteReason2.value,
    }).then(() => {
        addToastNotification({
            message: `User has been deactivated`,
            type: 'success',
        })

        if (routeName === 'carer-index') {
            updateCarerStatus()
        }

    }).catch(() => {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    }).finally(() => {
        deleteReason.value = ''
        deleteReason2.value = ''
        props.modal?.hide()

        router.push({ name: routeName })
    })

}

const updateCarerStatus = async () => {
    try {
        if (props.carerId === undefined) {
            return
        }

        await CarerService.updateState(props.carerId, { state: 'Deactivated' })
        addToastNotification({
            message: `Carer state changed to Deactivated`,
            type: 'success',
        })
    } catch (err) {
        const error = err as AxiosError

        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    }
}
</script>
