import { InactiveSessionCarerApplicationStatuses } from '@/enums/SessionCarerApplicationStatusEnum'
import {SessionStatusEnum} from '@/enums/SessionStatusEnum'
import Address from '@/types/Address'
import AddressState from '@/types/AddressState'
import {Contacts} from '@/types/CheckinData'
import Postcode from '@/types/Postcode'
import SessionStatus from '@/types/SessionStatus'

export function getSessionStatusBadge(statusId: number, isButton: boolean = false) {
    const prefix = isButton ? 'btn' : 'bg'
    let postfix

    switch (statusId) {
        case SessionStatusEnum.ID_POSTED:
            postfix = 'primary'
            break
        case SessionStatusEnum.ID_CHECK_CLIENT:
        case SessionStatusEnum.ID_CHECK_CARER:
        case SessionStatusEnum.ID_CARER_APPROVED:
            postfix = 'info'
            break
        case SessionStatusEnum.ID_PAID:
        case SessionStatusEnum.ID_IN_SESSION:
            postfix = 'lime'
            break
        case SessionStatusEnum.ID_COMPLETE:
        case SessionStatusEnum.ID_COMPLETE_REVIEW:
            postfix = 'teal'
            break
        case SessionStatusEnum.ID_CARER_CANCELLED:
        case SessionStatusEnum.ID_CUSTOMER_CANCELLED:
        case SessionStatusEnum.ID_ADMIN_CANCELLED:
        case SessionStatusEnum.ID_AUTO_CANCELLED:
            postfix = 'dark'
            break
        case SessionStatusEnum.ID_REPOSTED:
            postfix = 'danger'
            break
        default:
            postfix = 'dark'
            break
    }

    return `${ prefix }-${ postfix }`
}

export function getSessionStatusLabel(statusId: number, sessionStatuses: Array<SessionStatus>) {
    const sessionStatus = sessionStatuses.find((sessionStatus: SessionStatus) => sessionStatus.id === statusId)
    return sessionStatus?.adminLabel ?? 'N/A'
}


export function getSessionTagBadge(tag: string) {
    const prefix = 'bg'
    let postfix

    switch (tag) {
        case 'isUrgent':
            postfix = 'danger'
            break
        case 'isOffHour':
        case 'isOvernight':
            postfix = 'dark'
            break
        case 'isFull':
            postfix = 'primary'
            break
        case 'isPaid':
            postfix = 'teal'
            break
        case 'isPublicHoliday':
        case 'isExtended':
            postfix = 'info'
            break
        case 'isLegacy':
            postfix = 'gray'
            break
        default:
            postfix = 'lime'
            break
    }

    return `${ prefix }-${ postfix }`
}

export function getSessionTagLabel(tag: string) {
    switch (tag) {
        case 'isUrgent':
            return 'Urgent'
        case 'isOffHour':
            return 'Overnight'
        case 'isOvernight':
            return 'Off Hour'
        case 'isFull':
            return 'Full'
        case 'isPaid':
            return 'Paid'
        case 'isPublicHoliday':
            return 'Public Holiday'
        case 'isExtended':
            return 'Extended'
        case 'isLegacy':
            return 'Legacy Booking'
        default:
            return 'N/A'
    }
}

export function getRoutineCompletionBadge(isCompleted?: boolean) {
    let classes = {
        'me-1 p-1 rounded': true
    }

    return {
        ...classes,
        'bg-danger opacity-50': isCompleted == null,
        'bg-primary': isCompleted === true,
        'bg-dark': isCompleted === false
    }
}

export function getRoutineCompletionIcon(isCompleted?: boolean) {
    if (isCompleted == null)
        return 'info-square-rounded'

    return isCompleted ? 'checkbox' : 'square-x'
}

export function getRoutineCompletionLabel(isCompleted?: boolean) {
    if (isCompleted == null)
        return 'No action yet'

    return isCompleted ? 'Completed' : 'Dismissed'
}

export function getFullAddress(address: Address, states: AddressState[], postcodes: Postcode[]) {
    const postcode = postcodes.find((findPostcode: Postcode) => findPostcode.postcode === address.postcode)
    const state = states.find((findState: AddressState) => findState.id === address.stateId)

    return [address.unitNo, address.floorLevel, address.blockBuilding, address.address1, address.address2, address.postcode, postcode?.city, state?.name].filter(val => val).join(', ')
}

export function getShortAddress(address: Address, states: AddressState[], postcodes: Postcode[]): string {
    const postcode = postcodes.find((findPostcode: Postcode) => findPostcode.postcode === address.postcode)

    if (postcode) {
        if (postcode.city === 'Kuala Lumpur' || postcode.city === 'Putrajaya' || postcode.city === 'Labuan' || postcode.city === 'Pulau Pinang' || postcode.city === 'Melaka') {
            return postcode.city
        }

        const state = states.find((findState: AddressState) => findState.id === address?.stateId)

        return `${ postcode.city }, ${ state?.name }`
    }

    return 'N/A'
}

export function checkIfReadyToOrder(sessionStatusId: number, doesNotBelongToOrder: boolean): boolean {
    const isStatusValid = [
        SessionStatusEnum.ID_CHECK_CLIENT,
        SessionStatusEnum.ID_CARER_APPROVED
    ].includes(sessionStatusId)

    return isStatusValid && doesNotBelongToOrder
}

export function getContactThroughBadge(contactFlag: boolean) {
    let classes = {
        'me-1 p-1 rounded': true
    }

    return {
        ...classes,
        'bg-primary': contactFlag,
        'opacity-25': !contactFlag
    }
}

export function getContactThroughIcon(contactThrough: string) {
    switch (contactThrough) {
        case 'call':
            return 'phone-call'
        case 'whatsApp':
            return 'brand-whatsapp'
        case 'inPerson':
            return 'user-check'
    }

    return ''
}

export function getContactThrough(contact: Contacts) {
    let through = []

    if (contact.call) through.push('Call')
    if (contact.whatsApp) through.push('WhatsApp')
    if (contact.inPerson) through.push('In-person')

    return through.length > 0 ? '(' + through.join(', ') + ')' : ''
}

export function getLabel(item: any, defaultItems: any) {
    if (typeof (item) === 'string') {
        return defaultItems?.find((defaultItem: any) => defaultItem.key === item).label
    }

    const checkedItem = defaultItems?.find((defaultItem: any) => defaultItem.key === item.name)

    if (checkedItem && item.isSelected === true) {
        return checkedItem.label
    }
}

export function checkCancelledSession(sessionStatusId: number) {
    switch (sessionStatusId) {
        case SessionStatusEnum.ID_CARER_CANCELLED:
        case SessionStatusEnum.ID_CUSTOMER_CANCELLED:
        case SessionStatusEnum.ID_ADMIN_CANCELLED:
            return true
        default:
            return false
    }
}

export function checkCancelledSessionCarerApplication(carerApplicationStatusId: number) {
    return InactiveSessionCarerApplicationStatuses.includes(carerApplicationStatusId)
}

export function getShowSessionPath(sessionId: string | number) {
    let url = ''

    const baseURL = import.meta.env?.VITE_APP_BASE_URL

    if (baseURL) {
        url = `${ baseURL }/sessions/${ sessionId }`
    }

    return url
}