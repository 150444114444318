<template>
    <div class="dropdown position-static">
        <button type="button" class="btn btn-sm rounded dropdown-toggle text-uppercase" :class="[getJobStatusBadge(status, true)]" data-bs-toggle="dropdown">
            {{ statusLabel }}
        </button>
        <div class="dropdown-menu">
            <a class="dropdown-item py-1" role="button" @click.prevent="updateJobStatus(jobStatus.key)" v-for="(jobStatus, index) in jobStatuses" :key="index">
                {{ jobStatus.name }}
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import JobService from '@/services/JobService'
import { useNotificationsStore } from '@/stores/notifications'
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'
import { getJobStatusBadge } from '@/composable/useJob'


const { addToastNotification } = useNotificationsStore()
const { jobStatuses } = storeToRefs(useResourcesStore())

const props = defineProps({
    status: { type: String, required: true },
    jobId: { type: Number, required: true }
})

const statusLabel = computed(() => {
    const status = jobStatuses.value.find(jobStatus => jobStatus.key === props.status)
    return status?.name ?? 'N/A'
})

const updateJobStatus = (status: string) => {
    if (status !== props.status) {
        let data = <any>{ status: status }

        if (status === 'cancelled') {
            window.Swal.fire({
                title: 'Cancellation Reason',
                text: 'Please write the cancellation reason',
                input: 'textarea',
                showCancelButton: true
            }).then((result: any) => {
                if (result.value) {
                    data = {
                        ...data,
                        reason: result.value
                    }

                    JobService.updateStatus(props.jobId, data)
                        .then(() => {
                            const statusLabel = jobStatuses.value.find(jobStatus => jobStatus.key === status) ?? { name: status }
                            addToastNotification({
                                message: `Job status changed to ${statusLabel.name}`,
                                type: 'success',
                            })
                        })
                        .catch(() => {
                            addToastNotification({
                                message: 'Internal server error. Please contact tech team.',
                                type: 'danger',
                            })
                        })
                }
            })
        } else {
            JobService.updateStatus(props.jobId, data)
                .then(() => {
                    const statusLabel = jobStatuses.value.find(jobStatus => jobStatus.key === status) ?? { name: status }
                    addToastNotification({
                        message: `Job status changed to ${statusLabel.name}`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message: 'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    }
}
</script>