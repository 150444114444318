<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex mb-3">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right me-2" :to="{ name: 'carer-create' }">
                        <icon name="plus" />
                        Add New Carer
                    </router-link>

                    <button type="button" class="btn btn-primary dropdown-toggle float-right" data-bs-toggle="dropdown">
                        Action
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" style="width: 280px;">
                        <button class="dropdown-item" @click="generateReport">
                            <icon name="file-spreadsheet"/>
                            <span>Export Carer List</span>
                        </button>
                        <button class="dropdown-item" @click="exportMVD">
                            <icon name="pdf"/>
                            <span class="align-middle">Export MVD</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label for="name" class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="inputFilter.name" @keyup.enter="fetchUser">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Email</label>
                    <input type="email" class="form-control" v-model="inputFilter.email" @keyup.enter="fetchUser">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Online ID</label>
                    <input type="text" class="form-control" v-model="inputFilter.onlineId" @keyup.enter="fetchUser">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Phone No</label>
                    <input type="text" class="form-control" v-model="inputFilter.phoneNo" @keyup.enter="fetchUser">
                </div>

                <div class="col-12 col-md">
                    <label class="form-label">State</label>
                    <vue-select
                        :options="addressStates"
                        label="name"
                        :reduce="(state: AddressState) => state.id"
                        v-model="inputFilter.addressStateId"
                    />
                </div>
            </div>

            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Type</label>
                    <vue-select
                        :options="carerTypes"
                        label="name"
                        :reduce="(carerType: CarerType) => carerType.id"
                        v-model="inputFilter.carerType"
                    />
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Status</label>
                    <vue-select :options="carerStates" v-model="inputFilter.carerState" />
                </div>
                <div class="col-12 col-md">
                    <label for="onboardedStartDate" class="form-label">Onboarded Start Date</label>
                    <div class="input-group">
                        <input type="text" id="onboardedStartDate" class="form-control bg-white" :value="onboardedStartDateDisplay" readonly>
                        <span class="input-group-text"><icon name="calendar"/></span>
                    </div>
                </div>
                <div class="col-12 col-md">
                    <label for="onboardedEndDate" class="form-label">Onboarded End Date</label>
                    <div class="input-group">
                        <input type="text" id="onboardedEndDate" class="form-control bg-white" :value="onboardedEndDateDisplay" readonly>
                        <span class="input-group-text"><icon name="calendar"/></span>
                    </div>
                </div>
                <div class="col-12 col-md">
                    <button-filter @reset="resetFilter" @filter="fetchUser" :is-loading="isFetchingUsers"></button-filter>
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter">
                    <thead>
                        <tr>
                            <th class="w-25">Name</th>
                            <th style="width: 20%;">Email</th>
                            <th>Type</th>
                            <th>Online ID</th>
                            <th style="width: 10% !important;">Phone No</th>
                            <th>Status</th>
                            <th style="width: 20%;">Registered At</th>
                            <th style="width: 20%;">Onboarded At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="users.length">
                        <tr v-for="user in users" :key="user.id">
                            <td class="fw-bold d-flex align-items-center space-x-3">
                                <img :src="user.avatarUrl" class="avatar avatar-md rounded-circle" @error="fallbackAvatar" loading="lazy"/>
                                <router-link :to="{ name: 'carer-show', params: { userId: user.id } }">{{ getMainProfile(user.profiles) ? getMainProfile(user.profiles).fullName : user.name }}</router-link>
                                <status-badge :show="user.isDeleted" label="Deleted" color="gray"></status-badge>
                            </td>
                            <td>{{ user.email }} <span v-if="user.emailVerifiedAt" class="text-green"><icon name="circle-check" /></span></td>
                            <td>{{ carerTypes.find((findCarerType: CarerType) => findCarerType.id === user.carer?.carerTypeId)?.name }}</td>
                            <td>{{ getMainProfile(user.profiles)?.onlineId ?? '-' }}</td>
                            <td>{{ getMainProfile(user.profiles)?.mobileNo ?? '-' }}</td>
                            <td>
                                <span class="badge badge-outline text-blue" v-if="user?.carer?.state">{{ user?.carer?.state }}</span>
                            </td>
                            <td>{{ formatDate(user.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                            <td>{{ user.carer?.onboardedAt ? formatDate(user.carer?.onboardedAt, 'DD-MMM-YYYY hh:mm:ss A') : 'N/A' }}</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-blue" :to="{ name: 'carer-show', params: { userId: user.id } }">
                                        <icon name="eye" />
                                    </router-link>
                                    <button class="btn btn-icon btn-info" @click="switchRole(user)">
                                        <icon name="arrows-left-right" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingUsers" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import StatusBadge from '@/components/StatusBadge.vue'
import { fallbackAvatar, getMainProfile } from '@/composable/profile'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import CarerService from '@/services/users/CarerService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import AddressState from '@/types/AddressState'
import CarerType from '@/types/CarerType'
import UserFilter from '@/types/Filter'
import User from '@/types/User'
import { AmpPlugin } from '@easepick/amp-plugin'
import { Core, easepick } from '@easepick/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import VueSelect from 'vue-select'

const { addToastNotification } = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { addressStates, carerStates, carerTypes } = storeToRefs(useResourcesStore())
const users = ref<Array<User>>([])

const DEFAULT_DATE_FORMAT = 'DD-MMM-YYYY'

const inputFilter = ref<UserFilter>({
    name: '',
    email: '',
    onlineId: '',
    phoneNo: '',
    carerState: '',
    carerType: '',
    onboardedStartDate: '',
    onboardedEndDate: '',
    addressStateId: '',
})

const isFetchingUsers = ref<boolean>(false)

const onboardedStartDateDisplay = computed(() => inputFilter.value.onboardedStartDate ? formatDate(inputFilter.value.onboardedStartDate, DEFAULT_DATE_FORMAT) : '')
const onboardedEndDateDisplay = computed(() => inputFilter.value.onboardedEndDate ? formatDate(inputFilter.value.onboardedEndDate, DEFAULT_DATE_FORMAT) : '')

const isNotAllowedToExportMdv = computed(() => {
    return inputFilter.value.onboardedStartDate === '' || inputFilter.value.onboardedEndDate === ''
})

fetchUser()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchUser()
    }
)

function resetFilter () {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }
    fetchUser()
}

function fetchUser () {
    isFetchingUsers.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    CarerService.index(query)
        .then(({ data: { data, meta } }) => {
            users.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingUsers.value = false
        })
}

function switchRole (user: User) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to change this user ${user.name} to Customer?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            CarerService.updateRole(user.id)
                .then(() => {
                    fetchUser()
                    addToastNotification({
                        message: `User ${user.name} has been switch to Customer role`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}

function generateReport () {
    isFetchingUsers.value = true
    const data = {
        ...inputFilter.value,
    }

    CarerService.generateReport(data).then(({ data: { message }}) => {
        const response = message
        addToastNotification({
            message: response,
            type: 'success',
        })
    }).catch(() => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger',
        })
    }).finally(() => isFetchingUsers.value = false)
}

const exportMVD = () => {
    if (isNotAllowedToExportMdv.value) {
        addToastNotification({
            title: 'Action Not Allowed',
            message: 'Please set onboarded start and end date',
            type: 'danger',
        })

        return
    }

    isFetchingUsers.value = true

    CarerService.exportMVD(inputFilter.value)
        .then(({ data: { message }}) => {
            const response = message
            addToastNotification({
                message: response,
                type: 'success',
            })
        })
        .catch(() => {
            addToastNotification({
                message: `Internal server error. Please contact tech team if the error persists.`,
                type: 'danger',
            })
        })
        .finally(() => isFetchingUsers.value = false)
}

onMounted(() => {
    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 2020,
                maxYear: (new Date().getFullYear())
            },
            darkMode: false,
        }
    }

    new easepick.create({
        element: <any>document.getElementById('onboardedStartDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.onboardedStartDate = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
                fetchUser()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    new easepick.create({
        element: <any>document.getElementById('onboardedEndDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.onboardedEndDate = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
                fetchUser()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })
})
</script>
