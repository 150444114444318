import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { required, numeric, maxLength, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        description: {
            required: helpers.withMessage(
                'This description field is required',
                required
            ),
            maxLength: helpers.withMessage(
                'This description field may not be greater than 255 characters',
                maxLength(255)
            ),
        },
        holiday_date: {
            required: helpers.withMessage(
                'This holiday date field is required',
                required
            )
        },
        state_ids: {
            required: helpers.withMessage(
                'This state field is required',
                required
            ),
        },
    },
})