<template>
    <div class="py-3 px-3" aria-labelledby="dropdownChatDetailsService">
        <h4>Service Details</h4>
        <hr class="my-3"/>

        <chat-sessions
            :session="session"
            label="Ongoing"
            empty-template="No session found"
            :should-monitor="true"
        />

        <chat-participant
            :admins="admins"
            :participants="participants"
            @invited="inviteUser"
            @banned="banUser">
        </chat-participant>
    </div>
</template>

<script setup lang="ts">
import ChatParticipant from '@/components/chats/ChatParticipant.vue'
import ChatSessions from '@/components/chats/ChatSessions.vue'
import Session from '@/types/Session'
import { computed, inject, provide } from 'vue'

const props = defineProps({
    participants: { type: Array, required: true },
    admins: { type: Array, required: true },
})

const participantUserIds = computed(() => {
    return props.participants?.map((participant: any) => {
        return Number(participant.userId)
    })
})

const emit = defineEmits(['invited', 'banned'])

const session = inject<Session | undefined>('session')

const isParticipant = (userId: number) => {
    return participantUserIds.value?.includes(userId)
}

const inviteUser = (userId: number) => {
    emit('invited', userId)
}

const banUser = (userId: number) => {
    emit('banned', userId)
}

provide('isParticipant', isParticipant)
provide('inviteUser', inviteUser)
provide('banUser', banUser)

</script>

<style scoped>
hr {
    margin-left: -16px;
    margin-right: -16px;
}
</style>
