import httpClientV2 from '@/services/HttpClientV2'
import BulkGenerateVoucherFormData from '@/types/formData/BulkGenerateVoucherFormData'

const endpoint = '/backoffice/vouchers/bulk-generate'

export default {
    store(data: BulkGenerateVoucherFormData) {
        return httpClientV2.post(endpoint, data)
    }
}
