<template>
    <template v-if="sessionEvent">
        <div class="w-10-rem">
            <button @click="openModal()" class="btn btn-sm btn-green normal-whitespace">
                {{ label }}
            </button>
        </div>
        <Teleport to="body">
            <kc-modal :modal-id="modalId" modal-size="xl">
                <div class="modal-header">
                    <h4 class="modal-title">
                        Monitoring Session {{ sessionNo }}
                    </h4>
                    <button type="button" class="btn-close" aria-label="Close"
                            @click="openModal(false)"
                    ></button>
                </div>
                <div class="modal-body bg-white">
                    <div class="row gap-4" >
                        <div class="col">
                            <h4 class="mb-2">Events</h4>
                            <div class="px-3"  style="max-height: 70vh; overflow-y:scroll">
                                <ActivityLogTimeline :activity-logs="activityLogs"/>
                            </div>
                        </div>
                        <div class="col">
                            <h4 class="mb-2">Carer Logs</h4>
                            <div class="px-3" style="max-height: 70vh; overflow-y:scroll">
                                <SessionCarerLogs :carer-logs="carerLogs" :includeImage="includeImage"/>
                            </div>
                        </div>
                    </div>
                </div>
            </kc-modal>
        </Teleport>
    </template>
    <template v-else>
        <button class="btn btn-sm btn-secondary w-100" disabled>
            Not Started
        </button>
    </template>
</template>

<script setup lang="ts">

import {computed, onMounted} from 'vue'
import SessionEvent from '@/types/SessionEvent'
import Modal from 'bootstrap/js/dist/modal'
import ActivityLogTimeline from '@/components/ActivityLogTimeline.vue'
import {storeToRefs} from 'pinia'
import {useResourcesStore} from '@/stores/resources'
import SessionEventLog from '@/types/SessionEventLog'
import SessionCarerLog from '@/types/SessionCarerLog'
import ActivityLogTimelineItem from '@/types/ActivityLogTimelineItem'
import SessionCarerLogs from '@/pages/sessions/components/SessionCarerLogs.vue'

const props = defineProps({
    sessionId: {type: Number, required: true},
    sessionNo: {type: String, required: true},
    sessionEvent: {type: Object as () => SessionEvent},
    carerLogs: {type: Array as () => Array<SessionCarerLog>},
    includeImage: {type: Boolean, default: true}
})

let modalElement: HTMLElement | null = null
let modal: Modal | null = null

const {sessionEventTypes} = storeToRefs(useResourcesStore())

const modalId = computed<string>(() => `session-${props.sessionId}-event-logs-modal`)

const label = computed(() => {
    const status = sessionEventTypes.value.find(type => type.id === props.sessionEvent?.sessionEventTypeId)
    return status?.adminLabel ?? 'N/A'
})

const openModal = (open = true) => open ? modal?.show() : modal?.hide()

const activityLogs = computed<Array<ActivityLogTimelineItem>>(() => {
    const logs = props.sessionEvent?.eventLogs?.map((eventLog: SessionEventLog) => {
        return {
            label: sessionEventTypes.value.find(eventType => eventLog.sessionEventTypeId === eventType.id)?.adminLabel ?? 'Missing label',
            createdAt: eventLog.createdAt
        }
    })

    return logs ?? []
})

onMounted(() => {
    modalElement = document.getElementById(modalId.value)

    if (!modalElement) {
        return
    }

    modal = new Modal(modalElement)
})

</script>

<style scoped>
.w-10-rem {
    width: 10rem;
}

.normal-whitespace {
    white-space: normal;
}
</style>
