<template>
    <div class="px-3 d-flex justify-content-between">
        <template v-if="props.isActive && props.currentType === 'support'">
            <span>Chat is currently <span class="badge badge-pill bg-success">{{ statusLabel }}</span></span>
            <button @click="openModal()" style="background-color: white; color: blue; border: 0px;">Set to Inactive</button>
        </template>

        <template v-else>
            <span>Chat is currently <span class="badge badge-pill" :class="props.isActive ? 'bg-success' : 'bg-danger'">{{ statusLabel }}</span></span>
            <a href="#" @click.prevent="toggleChannel">{{ props.isActive ? 'Set to Inactive' : 'Set to Active' }}</a>
        </template>
    </div>

    <Teleport to="body">
        <kc-modal :modal-id="modalId">
            <div class="modal-header">
                <h4 class="modal-title">Notification Remark</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
            </div>
            <form @submit.prevent="updateRemark">
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="form-label">Remark</label>
                        <textarea v-model="input.remark" class="form-control" name="remark" rows="3"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-light" type="button" @click="closeModal" :id="`btnDismissModal${modalId}`" data-bs-dismiss="modal">Cancel</button>
                    <SaveButton :is-saving="isUpdatingRemark" />
                </div>
            </form>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">
import SaveButton from '@/components/form/SaveButton.vue'
import ChatService from '@/services/ChatService'
import TicketService from '@/services/TicketService'
import { useNotificationsStore } from '@/stores/notifications'
import Modal from 'bootstrap/js/dist/modal'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
    isActive: { type: Boolean, default: true },
    channelUrl: { type: String },
    channelType: { type: String },
    ticketId: { type: Number, required: true },
    currentType: { type: String }
})

let modalElement: HTMLElement | null = null
let modal: Modal | null = null

const emit = defineEmits(['updateRemark'])
const { addToastNotification } = useNotificationsStore()
const isUpdatingRemark = ref<boolean>(false)
const input = ref<any>({
    remark: null
})

const statusLabel = computed<string>(() => props.isActive ? ' Active' : 'Inactive')
const modalId = computed<string>(() => `modal-edit-remark-${props.ticketId}`)
const openModal = (open = true) => open ? modal?.show() : modal?.hide()

const toggleChannel = () => {
    ChatService.updateActiveStatus({
        isActive: !props.isActive,
        channelUrl: props.channelUrl ?? '',
        channelType: `${props.channelType}_channels`,
    })
}

const updateRemark = () => {
    if (input.value.remark.length > 0) {
        isUpdatingRemark.value = true

        TicketService.updateRemark(props.ticketId.toString(), { remark: input.value.remark })
            .then(() => {
                addToastNotification({ message: `Ticket remark has been updated`, type: 'success' })
                emit('updateRemark', input.value.remark, props.ticketId.toString())
            })
            .catch(() => {
                addToastNotification({
                    message: 'Internal server error when updating ticket remark. Please contact tech team if error persists.',
                    type: 'danger'
                })
            })
            .finally(() => {
                isUpdatingRemark.value = false

                // Proceed to close the chat anyway
                toggleChannel()
                closeModal()
            })
    }
}

onMounted(() => {
    modalElement = document.getElementById(modalId.value)

    if (!modalElement) {
        return
    }

    modal = new Modal(modalElement)
    closeModal ()
})

function closeModal () {
    document.getElementById(`btnDismissModal${modalId.value}`)?.click()

    input.value.remark = null
}

</script>
