<template>
    <router-link :to="redirectTo" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Order</span>
    </router-link>
    
    <overlay-spinner :is-showing="isFetchingOrder" v-if="isFetchingOrder" />
    
    <template v-else>
        <div class="page-header mb-3">
            <p class="text-muted mb-1">Add New Session to Order</p>
            <h2 class="page-title" v-if="order">{{ order.no }}</h2>
        </div>

        <session-create-form :order-id="orderId" :order="order" :redirect-to="redirectTo"></session-create-form>

    </template>
</template>

<script setup lang="ts">
import sessionCreateForm from './OrderSessionCreateForm.vue'
import { useRoute } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications'
import { ref } from 'vue'
import _ from 'lodash/fp'
import OrderService from '@/services/OrderService'
import Order from '@/types/Order'

const route = useRoute()
const {addToastNotification} = useNotificationsStore()

const isFetchingOrder = ref<Boolean>()
const orderId = Number(route.params.orderId)
const order = ref<Order>()
const redirectTo = {
    name: 'order-show',
    params: { orderId: orderId },
}

function fetchOrder() {
    isFetchingOrder.value = true;
    OrderService.show(orderId)
        .then(async ({data: {data}}) => {
            order.value = data;
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => {
            isFetchingOrder.value = false
        })
}

fetchOrder()

</script>