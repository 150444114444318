<template>
    <div class="card">
        <div class="card-body">
            <div class="row pt-3 gx-4">
                <overlay-spinner :is-showing="isFetchingJobs" />
                <div class="col-md-9" v-if="!isFetchingJobs">
                    <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
                </div>
                <div class="col-md-3">
                    <h1 class="pt-1 pb-2">Sessions on {{ date.format(selectedDate, 'DD MMMM YYYY') }}</h1>
                    <ul class="list-unstyled" v-if="jobsOnSelectedDate.length > 0">
                        <li v-for="(job, index) in jobsOnSelectedDate" :key="index" class="d-flex align-items-start space-x-3 py-3 border-bottom">
                            <icon name="calendar" class="text-blue"/>
                            <div>
                                <h4 class="mb-0">{{ job.job_no }}</h4>
                                <p class="mb-2">{{ job.job_start_time }}</p>

                                <router-link class="fw-bold" target="_blank" :to="{ name: 'job-show', params: { jobId: job.job_id }}">View Job</router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import date from '@/utils/date'
import DashboardService from '@/services/DashboardService'

const isFetchingJobs = ref<boolean>(false)
const jobs = ref<Array<any>>([])
const jobsOnSelectedDate = ref<Array<any>>([])
const selectedDate = ref<string>()
const fullCalendar = ref(null)

fetch()

let calendarOptions = <any>{
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    weekends: false, // initial value
    dateClick: selectDate,
    events: [
        // { title: 'event 1', date: '2022-08-16' }
    ],
}

onMounted(() => {
    selectedDate.value = date.today('YYYY-MM-DD')
    selectDate(selectedDate)
})

function fetch () {
    isFetchingJobs.value = true

    DashboardService.getJobCalendar()
        .then(({ data }) => {
            jobs.value = data
            calendarOptions.events = jobs.value.map((job: any) => {
                return {
                    title: job.job_no,
                    date: job.job_start_date,
                }
            })
        })
        .finally(() => {
            isFetchingJobs.value = false
        })
}

function selectDate (date: any) {
    selectedDate.value = date.dateStr
    jobsOnSelectedDate.value = jobs.value.filter((job: any) => {
        return job.job_start_date === selectedDate.value
    })
}
</script>