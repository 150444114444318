<template>
    <div class="flex w-100 ">
        <vue-select :options="userOptions"
                    @search="onSearchUser"
                    :filterable="false"
                    label="name"
                    v-model="selectedUser"
                    :reduce="(user: any) => user.data"
                    placeholder="Search user"
        >
            <template v-slot:list-footer
                      v-if="hasPrevPage || hasNextPage"
            >
                <div class="d-flex px-2 pt-2 pb-1 border-top">
                    <button @click.prevent="handlePrevPage"
                            class="btn btn-sm btn-outline-secondary col"
                            :disabled="isSearching || !hasPrevPage"
                    >
                        Prev
                    </button>
                    <div class="px-2 col text-center py-1">
                        Page {{metaPage.currentPage}} / {{metaPage.lastPage}}
                    </div>
                    <button @click.prevent="handleNextPage"
                            class="btn  btn-sm btn-outline-secondary col"
                            :disabled="isSearching || !hasNextPage"
                    >
                        Next
                    </button>
                </div>
            </template>
        </vue-select>
    </div>
</template>

<script setup lang="ts">
import {getMainProfile} from '@/composable/profile'
import {RoleNameEnum} from '@/enums/RoleEnum'
import UserService from '@/services/UserService'
import CarerService from '@/services/users/CarerService'
import CustomerService from '@/services/users/CustomerService'
import {useNotificationsStore} from '@/stores/notifications'
import User from '@/types/User'
import _ from 'lodash/fp'
import {computed, ref, watch} from 'vue'
import useMetaPage from '@/composable/useMetaPage'

const {addToastNotification} = useNotificationsStore()

const {
    metaPage,
    metaPageTriggered,
    hasNextPage,
    hasPrevPage,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()

const selectedUser = ref<User | null>()
const userOptions = ref<Array<User>>([])
const searchText = ref<string>('')

const isSearching = ref(false)
const setIsSearching = (value: boolean = true) => isSearching.value = value

const props = defineProps<{
    userRole?: RoleNameEnum.CARER | RoleNameEnum.USER
    modelValue: User | null | undefined
    query: object | undefined
}>()

const emit = defineEmits(['update:modelValue'])

const searchServices = {
    [RoleNameEnum.CARER]: CarerService.index,
    [RoleNameEnum.USER]: CustomerService.index,
    default: UserService.index
}

watch(() => props.modelValue, (user) => {
    if (user == null) {
        selectedUser.value = null
    }
})

watch(selectedUser, (user) => {
    emit('update:modelValue', user)
})

const onSearchUser = (search: string, loading: Function) => {
    searchText.value = search

    if (searchText.value) {
        searchUser()
    }
}

const searchUser = _.debounce(350, async () => {

    setIsSearching()

    let query = {
        perPage: metaPage.value.perPage,
        page: metaPage.value.currentPage,
        search: searchText.value
    }

    if (props.query !== undefined) {
        query = {...query, ...props.query}
    }

    const serviceFunction = props.userRole ? searchServices[props.userRole] : searchServices.default

    const response = await serviceFunction(query)

    try {
        userOptions.value = response.data.data.map((user: User) => {
            const mainProfile = getMainProfile(user.profiles)

            return {
                id: user.id,
                name: `[${ mainProfile?.onlineId }] ${ mainProfile?.fullName }`,
                data: user
            }
        })

        updateMetaPage(response.data.meta)
    } catch (ex: any) {
        addToastNotification({
            message: 'Error while getting user. Please try again.',
            type: 'danger'
        })
    }

    setIsSearching(false)

})

const handlePrevPage = () => {
    prevPage()
    searchUser()

}

const handleNextPage = () => {
    nextPage()
    searchUser()
}

</script>

<style scoped>
:deep(.vs__search::placeholder) {
    color: rgba(60, 60, 60, .5);
    font-size: 0.9rem;
    padding-left: 0.2rem;
}
</style>
