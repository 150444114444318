import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { helpers, requiredIf } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = (mode: string) => ({
    inputUser: {
        customer: {
            requiredIf: helpers.withMessage(
                'This customer field is required',
                requiredIf(mode === 'create')
            ),
        },
        status: {
            requiredIf: helpers.withMessage(
                'Status field is required',
                requiredIf(mode === 'update')
            )
        },
        adult_present: {
            requiredIf: helpers.withMessage(
                'Presence of Adult field is required',
                requiredIf(mode === 'update')
            )
        },
        pets_present: {
            requiredIf: helpers.withMessage(
                'Presence of Pets field is required',
                requiredIf(mode === 'update')
            )
        },
        special_requirements: {
            requiredIf: helpers.withMessage(
                'Special Requirements field is required',
                requiredIf(mode === 'update')
            )
        }
    },
})