<template>
    <div class="card">
        <form @submit.prevent="saveUser">
            <div class="card-body">
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Email</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('email')}" type="text" v-model="inputUser.email" @focus="clearError('email')">
                        <small class="invalid-feedback" v-if="hasError('email')">{{ getError('email') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Name</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('name')}" type="text" v-model="inputUser.name" @focus="clearError('name')">
                        <small class="invalid-feedback" v-if="hasError('name')">{{ getError('name') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Chat Nickname</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('chat_nickname')}" type="text" v-model="inputUser.chat_nickname" @focus="clearError('chat_nickname')">
                        <small class="invalid-feedback" v-if="hasError('chat_nickname')">{{ getError('chat_nickname') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Password</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('password')}" type="password" v-model="inputUser.password" @focus="clearError('password')">
                        <small class="invalid-feedback" v-if="hasError('password')">{{ getError('password') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Confirm Password</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('password_confirmation')}" type="password" v-model="inputUser.password_confirmation" @focus="clearError('password_confirmation')">
                        <small class="invalid-feedback" v-if="hasError('password_confirmation')">{{ getError('password_confirmation') }}</small>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit">Save Admin</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications'
import AdminService from '@/services/users/AdminService'
import UserInput from '@/types/Input'
import User from '@/types/User'
import useFormError from '@/composable/useFormError'
import { rules as AdminRule, $externalResults } from '@/rules/AdminRule'

const { addToastNotification } = useNotificationsStore()
const router = useRouter()
const route = useRoute()
const props = defineProps({
    user: Object as () => User,
    id: { type: Number, default: 0 },
})
const mode = route.params.id ? 'update' : 'create'

const inputUser = ref<UserInput>({
    email: '',
    name: '',
    chat_nickname: '',
    password: '',
    password_confirmation: '',
})
const v$ = useVuelidate(
    AdminRule(mode, inputUser.value),
    { inputUser },
    { $externalResults }
)
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

watch(
    () => props.user,
    (user) => {
        if (user !== undefined) {
            inputUser.value.email = user.email
            inputUser.value.name = user.name
            inputUser.value.chat_nickname = user.chatNickname
        }
    }
)

async function saveUser() {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        const adminService =
            mode === 'create'
                ? AdminService.store(inputUser.value)
                : AdminService.update(props.id, inputUser.value)

        adminService
            .then(() => {
                addToastNotification({
                    message: `Admin ${inputUser.value.name} ${mode}d`,
                    type: 'success',
                })
                router.push({ name: 'admin-index' })
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}
</script>
