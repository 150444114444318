<template>
    <button @click="copy"
            class="btn"
            :class="btnClassName"
    >
        <template v-if="canCopy">
            <icon :name="icon" id="copy"/>
            <span v-if="label">{{ label }}</span>
        </template>
        <template v-else>
            <icon name="clipboard-check"/>
            <span v-if="label"> COPIED! </span>
        </template>
    </button>
</template>

<script setup lang="ts">
import {copyToClipboard} from '@/composable/useGeneral'
import {computed, ref, useSlots} from 'vue'
import {useNotificationsStore} from '@/stores/notifications'
import date from '@/utils/date'

const isShowingSuccess = ref(false)
const setIsShowingSuccess = (value = true) => isShowingSuccess.value = value

const props = defineProps({
    data: {type: String},
    resourceName: {type: String, required: false},
    transparent: {type: Boolean, default: false},
    label: {type: String, required: false},
    icon: {type: String, default: 'copy'}
})

const {addToastNotification} = useNotificationsStore()

const slots = useSlots()

const canCopy = computed(() => !isShowingSuccess.value && !!props.data)

const btnClassName = computed(() => {
    let className = ''

    if (!slots.default && !props.label) {
        className += ' btn-icon '
    }

    if (props.transparent) {
        className += ' bg-transparent p-1 border-0'
    } else {
        className += ' btn-info'
    }

    return className
})

const copy = async () => {

    setIsShowingSuccess(false) // reset

    if (!props.data) {
        addToastNotification({
            type: 'danger',
            message: 'Oops. Nothing copied!'
        })
        return
    }

    const success = await copyToClipboard(props.data)

    if (success) {
        setIsShowingSuccess()

        const message = props.resourceName
            ? `${ props.resourceName } copied!`
            : 'Copied!'

        addToastNotification({
            type: 'success',
            message: message
        })

        setTimeout(() => {
            setIsShowingSuccess(false)
        }, 1000)
    }
}

</script>