export enum TicketType {
    CARER_SUPPORT = 'carer_support',
    BOOKING_PAYMENT_MADE = 'booking_payment_made',
    JOB_CARER_CANCELLED = 'job_carer_cancelled',
    SESSION_NO_REPLACEMENT_CARER = 'session_no_replacement_carer',
    CARER_REGISTRATION = 'carer_registration',
    CARER_PROFILE_UPDATE = 'carer_profile_update',
    CARER_VIDEO_UPLOAD_SUCCESS = 'carer_video_upload_success',
    CARER_VIDEO_UPLOAD_ERROR = 'carer_video_upload_error',
    CARER_TRAINING_ALMOST_COMPLETED = 'carer_training_almost_completed',
    CORPORATE_INQUIRY = 'corporate_inquiry',
    REQUEST_FOR_CALL = 'request_for_call',
    CARER_LOG_INCIDENT = 'carer_log_incident',
    CUSTOMER_DECLINED_SESSION_DOCUMENT = 'customer_declined_session_document',
    TYPE_CUSTOMER_PENDING_REPORT_APPROVAL = 'customer_pending_report_approval',
}