<template>
    <div class="card">
        <div class="card-body">
            <table class="table table-striped">
                <tr>
                    <th class="py-2">Description</th>
                    <th>Value</th>
                </tr>
                <tr v-for="detail in sessionSettingsDetails">
                    <td class="py-2">
                        {{ detail.label }}
                    </td>
                    <td>
                        {{ detail.value }} {{ detail.unit }}
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>

<script setup lang="ts">

import {storeToRefs} from 'pinia'
import {useResourcesStore} from '@/stores/resources'

const {sessionSettingsDetails} = storeToRefs(useResourcesStore())


</script>