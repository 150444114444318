<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right" :to="{ name: 'job-create' }">
                        <icon name="plus" />
                        Add New Session
                    </router-link>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label for="name" class="form-label">Booking No/Job Id</label>
                    <input type="text" placeholder="B202104-01250" class="form-control" v-model="inputFilter.job_no" @keyup.enter="fetchJob">
                </div>
                <!--<div class="col-12 col-md">
                    <label for="email" class="form-label">From</label>
                    <input type="date" class="form-control" v-model="inputFilter.job_date_from">
                </div>
                <div class="col-12 col-md">
                    <label for="email" class="form-label">To</label>
                    <input type="date" class="form-control" v-model="inputFilter.job_date_to">
                </div> -->
                <div class="col-12 col-md">
                    <label for="email" class="form-label">Status</label>
                    <vue-select 
                        :options="[{ name: 'Urgent', key: 'urgent' }, ...jobStatuses]"
                        label="name"
                        :reduce="(jobStatus: any) => jobStatus.key" 
                        v-model="inputFilter.status">
                    </vue-select>
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchJob" :is-loading="isFetchingJobs"></button-filter>
                </div>
            </div>

            <ul class="nav nav-bordered mb-3">
                <li class="nav-item">
                    <a role="button" @click="filterJobDate('all')" class="nav-link active" data-bs-toggle="tab">All</a>
                </li>
                <li class="nav-item">
                    <a role="button" @click="filterJobDate('today')" class="nav-link" data-bs-toggle="tab">Today</a>
                </li>
                <li class="nav-item">
                    <a role="button" @click="filterJobDate('upcoming')" class="nav-link" data-bs-toggle="tab">Upcoming</a>
                </li>
                <li class="nav-item">
                    <a role="button" @click="filterJobDate('past')" class="nav-link" data-bs-toggle="tab">Past</a>
                </li>
            </ul>
            
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />

            <div class="table-responsive">
                <table class="table table-vcenter">
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Booking No</th>
                            <th class="text-center">Date</th>
                            <th class="text-center">Time</th>
                            <th>Address</th>
                            <th>Customer</th>
                            <th>Carer</th>
                            <th class="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="jobs.length > 0">
                        <tr v-for="job in jobs" :key="job.id" :style="[job.is_urgent ? 'background-color: #fbebeb' : '']">
                            <td>
                                <div class="d-flex space-x-2">
                                    <job-change-status :job-id="job.id" :status="job.status"></job-change-status>
                                    <span class="badge bg-red text-uppercase flex-shrink-0" v-if="job.is_urgent">Urgent</span>
                                    <span class="badge bg-green text-uppercase flex-shrink-0" v-if="job.is_paid">Paid</span>
                                </div>
                            </td>
                            <td>{{ job.no }}</td>
                            <td class="text-center">
                                <div>{{ formatDate(job.startedAt, 'DD-MMM-YYYY') }}</div>
                                <div>{{ formatDate(job.startedAt, '(dddd)') }}</div>
                            </td>
                            <td class="text-center">
                                <div>{{ formatDate(job.startedAt, 'hh:mm A') }} - {{ formatDate(job.endedAt, 'hh:mm A') }}</div>
                                <div>({{ `${job.duration} hours` }})</div>
                            </td>
                            <td>{{ getShortAddress(job.address) }}</td>
                            <td>
                                <div>{{ `[${job.booking.user.mainProfile?.onlineId}]` }}</div>
                                <router-link class="fw-bold" :to="{ name: 'customer-show', params: { customerId: job.booking.user.id } }">{{ job.booking.user.mainProfile?.fullName }}</router-link>
                            </td>
                            <td v-if="job.carer">
                                <div>{{ `[${job.carer.mainProfile?.onlineId}]` }}</div>
                                <router-link class="fw-bold" :to="{ name: 'carer-show', params: { userId: job.carer.id } }">{{ job.carer?.mainProfile?.fullName }}</router-link>
                            </td>
                            <td v-else>N/A</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-primary" :to="{ name: 'job-show', params: { jobId: job.id } }">
                                        <icon name="eye" />
                                    </router-link>
                                    <router-link class="btn btn-icon btn-success" :to="{ name: 'job-edit', params: { jobId: job.id } }">
                                        <icon name="pencil" />
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="8" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingJobs" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import date from '@/utils/date'
import { ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import JobChangeStatus from './JobChangeStatus.vue'
import JobService from '@/services/JobService'
import useBooking from '@/composable/useBooking'
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'
import Job from '@/types/Job'
import { formatDate } from '@/composable/useDate'
import { getShortAddress } from '@/composable/useJob'

const { jobStatuses } = storeToRefs(useResourcesStore())
const jobs = ref<Array<Job>>([])
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { getChildrenString } = useBooking()
const inputFilter = ref<UserFilter>({
    job_no: '',
    customer_id: '',
    status: '',
    job_date_from: '',
    job_date_to: '',
})

const isFetchingJobs = ref<boolean>(false)

fetchJob()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchJob()
    }
)

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchJob()
}

function fetchJob () {
    isFetchingJobs.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    JobService.index(query)
        .then(({ data: { data, meta } }) => {
            jobs.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingJobs.value = false
        })
}

function filterJobDate (jobDate: string) {
    switch (jobDate) {
        case 'all':
            resetFilter()
            metaPage.value.currentPage = 1
            break
        case 'today':
            inputFilter.value = {
                job_date_from: date.today('YYYY-MM-DD'),
                job_date_to: date.today('YYYY-MM-DD')
            }
            break
        case 'upcoming':
            inputFilter.value = {
                job_date_from: date.today('YYYY-MM-DD')
            }
            break
        case 'past':
            inputFilter.value = {
                job_date_to: date.today('YYYY-MM-DD')
            }
            break
        default:
            inputFilter.value = {
                job_date_from: '',
                job_date_to: ''
            }
            break
    }

    fetchJob()
}
</script>

<style scoped>

</style>
