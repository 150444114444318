
<template>
    <div v-for="(value, tag) in sessionTags"  class="mb-1 me-1 badge text-center "
        :class="getSessionTagBadge(tag)">
        {{ getSessionTagLabel(tag) }}
    </div>
</template>


<script setup lang="ts">

import { getSessionTagBadge, getSessionTagLabel } from '@/composable/useSession'
import SessionTag from '@/types/SessionTag'
import { computed } from 'vue'

const props = defineProps({
    tags: { type: Object as () => SessionTag, required: true}
})

// Filter only some of the session tag value is true
const sessionTags = computed(() => {
    return Object.fromEntries(Object.entries(props.tags).filter(([key, val]) => val));
})
</script>
