import { defineStore } from 'pinia'
import { SendbirdGroupChat } from '@sendbird/chat/groupChannel'

export const useSendbirdStore = defineStore('sendbird', {
    state: () => {
        return {
            sendbird: {} as SendbirdGroupChat,
            isSendbirdConnected: false,
            errors: [] as Array<any>,
        }
    },
})
