<template>
    <div v-if="userMetadata"
         class="border-bottom fixed px-4 py-3"
    >
        <div class="d-flex align-items-center  ">
            <div class="flex-grow-1">
                <h2> {{ channel.name }}</h2>
                <ChatUserDetails :user-metadata="userMetadata" :user-type="userType"/>
            </div>
            <button class="btn btn-dark btn-pill p-0" style="width: 36px; height: 36px" type="button"
                    @click="toggleSideDetail">
                <icon name="info-circle" class="ms-0 me-0"/>
            </button>
        </div>
        <div v-if="!isMainChatUserInvited"
             class="bg-danger px-2 py-1 mt-2 rounded"
             style="font-size: 0.8rem"
        >
            User is not a participant of this channel
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, inject} from 'vue'
import {GroupChannel, Member} from '@sendbird/chat/groupChannel'
import ChatUserDetails from '@/components/chats/ChatUserDetails.vue'
import ChatUserMetadata from '@/types/ChatUserMetadata'


const toggleSideDetail = <any>inject('toggleSideDetail')
const currentChannel = <any>inject('currentChannel')

type cachedMetaData = {
    userType?: 'customer' | 'carer'
}
const userType = computed(() => {
    const metadata = props.channel?.cachedMetaData as cachedMetaData
    return metadata.userType
})


const props = defineProps({
    currentType: {type: String},
    floating: {type: Boolean},
    channel: {type: GroupChannel, required: true},
    userMetadata: {type: Object as () => ChatUserMetadata},
    participants: {type: Array as () => Member[], required: true}
})

const isMainChatUserInvited = computed(() => {
    return props.participants?.some((member: Member) => {
        return member.userId
            ? props.userMetadata?.id === parseInt(member.userId) :
            false
    })
})
</script>
