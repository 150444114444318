import { DependentTypeEnum } from '@/enums/DependentTypeEnum'
import { GenderEnum } from '@/enums/GenderEnum'
import { ReligionEnum } from '@/enums/ReligionEnum'
import Dependent from '@/types/Dependent'

export function getDependentString(dependents: Dependent[]) {
    const arr = []

    const boys = dependents.filter((dependent: Dependent) => dependent.dependentTypeId === DependentTypeEnum.ID_CHILD && dependent.gender === GenderEnum.ID_MALE)
    const girls = dependents.filter((dependent: Dependent) => dependent.dependentTypeId === DependentTypeEnum.ID_CHILD && dependent.gender === GenderEnum.ID_FEMALE)
    const elderMale = dependents.filter((dependent: Dependent) => dependent.dependentTypeId === DependentTypeEnum.ID_ELDER && dependent.gender === GenderEnum.ID_MALE)
    const elderFemale = dependents.filter((dependent: Dependent) => dependent.dependentTypeId === DependentTypeEnum.ID_ELDER && dependent.gender === GenderEnum.ID_FEMALE)

    if (boys.length) {
        arr.push(`${boys.length} Boy`)
    }

    if (girls.length) {
        arr.push(`${girls.length} Girl`)
    }

    if (elderMale.length) {
        arr.push(`${elderMale.length} Male`)
    }

    if (elderFemale.length) {
        arr.push(`${elderFemale.length} Female`)
    }

    return arr.join(', ')
}

export function getGenderByType(
    options:{
        dependent?: Dependent;
        dependentTypeId?: number;
        genderId?: number;
    }
): string {

    const dependentTypeId = options.dependent ? options.dependent.dependentTypeId : options.dependentTypeId
    const genderId = options.dependent ? options.dependent.gender : options.genderId

    if(genderId == null)
        return ''

    switch (dependentTypeId) {
        case DependentTypeEnum.ID_CHILD:
            return genderId === GenderEnum.ID_MALE ? 'Boy' : 'Girl';
        case DependentTypeEnum.ID_ELDER:
        case DependentTypeEnum.ID_PARTNER:
        case DependentTypeEnum.ID_FRIEND:
        case DependentTypeEnum.ID_OTHER:
            return genderId === GenderEnum.ID_MALE ? 'Male' : 'Female';
        default:
            return 'N/A';
    }
}
