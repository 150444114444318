<template>
    <teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="lg">
            <div class="modal-header">
                <h4 class="modal-title text-truncate">{{ message.name }}</h4>
                <button id="btn-address-modal-close" class="btn-close" data-bs-target="#modal-job-form" data-bs-toggle="modal" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body bg-white">
                <div class="d-flex justify-content-center">
                    <img :src="`${message.plainUrl}?auth=${sendbird.ekey}`"
                        class="my-2 rounded d-block"
                        style="max-height: 580px"
                        :alt="message.name"
                        loading="lazy"/>
                </div>
            </div>
            <div class="modal-footer pt-3 border-top">
                <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
                <a role="button" :href="`${message.plainUrl}?auth=${sendbird.ekey}`" class="btn btn-primary" download>Download</a>
            </div>
        </kc-modal>
    </teleport>
</template>

<script setup lang="ts">
import { FileMessage } from '@sendbird/chat/message'
import { storeToRefs } from 'pinia'
import { useSendbirdStore } from '@/stores/sendbird'
const { sendbird } = storeToRefs(useSendbirdStore())
const props = defineProps({
    modalId: { type: String, required: true },
    message: { type: FileMessage, required: true }
})

</script>