<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Terms & Conditions</h4>
            <CopyButton :data="scriptTnc?.innerText ?? undefined" label="Copy"/>
        </div>

        <div class="card-body">
<pre style="max-height: 400px" ref="scriptTnc">
Dear customers, please take time to read our **Terms and Conditions**, and reminders below:

**KIDDOCARE’S TERMS OF SERVICE AND REMINDERS FOR THE CLIENT**

**Acknowledgement**
You understand that we, Kiddocare, are a platform that facilitates the contract of caring services between you and the carer directly, and we are not the provider of the service to you or your child and we will not be liable to you directly for such services. By making payment and subscribing to the services provided by the carer, you agree to these terms and the full Terms of Use (including the Frequently Asked Questions) available on our website at www.kiddocare.my.

**Carer’s Jobscope**
Carers are babysitters, they will keep your child company and keep them safe, as well as ensure they are fed, clothed, bathed and oversee their daily routine (as per the your instruction), toilet use, bedtime and playtime. The carer may engage in activities such as reading, arts, and playing in ways that is safe, and time that is suitable to the child.
Carers do not do housework, do not cook or prepare meals (but will prepare milk, cereals, heat up prepared food). They do not provide tuition or teaching, do not take care of pets, the elderly or infirm. They also do not pick up or send off children.

**Booking Time Blocks**
Bookings made and paid for are for specific hourly time blocks (2,4,6,8 hours etc), and cannot be broken into segments. The carer’s service must be used at one go and any rescheduling postponement and replacement service must also be of the same number of time block.

**Cancellation & Refunds**
If you cancel the job after payment but before we assign a carer to you, we will give a full refund. If you cancel more than 24 hours before the job is scheduled to start, we will give a 70% refund. If you cancel 24 hours or less before the job is scheduled to start, there will be no refund. If a carer cancels her service after payment is made, we will attempt to find a reasonable replacement carer for you. If such replacement is not available, we will provide a full refund to you. However, if we find a replacement carer , and you chose not to accept, you will receive 70% refund.

All refunds are for service fee and/or bonus/transport allowance/tips (whichever applies) only, it does not include the 7% admin & processing charge.

**Rescheduling**
You may reschedule the carer’s service with more than 24 hours’ notice, subject to a rescheduling charge of RM20. However, the duration of the carer’s service cannot be changed. If you decide to change duration to a shorter time, no refund or credit will be given. For time extensions upon rescheduling, extension rates will apply at RM25/hour.
No rescheduling will be allowed with less than 24 hours’ notice before the job is scheduled to start. If you decide to cancel after rescheduling, the cancellation terms will apply.

**Extension**
Do inform us if you will be late, or in need of extension. We will issue you a payment link for the requested extension hour. Extension requests should be made at least 1 hour before the job is over. Carer is not obliged to extend her  service if the payment is not received, or if she has prior commitments, or for whatever reason.
Extension rate payable by you is RM25/hour during service hours of 7am to 12 midnight. For service beyond those hours, extension rate will be charged at RM40/hour.

**Change of location**
Service by the carer is only performed at the location specified during booking. Any change in location must be notified to us with at least 24 hours’ notice. We will try to accommodate your request, subject to the carer’s availability and consent, but you may have to top up additional transportation cost as reasonably determined by us. If you notify the change in location with less than 24 hours’ notice, we may not be able to accommodate your request. If you decide to cancel for this reason, cancellation terms will apply.

**Refusal of service**
If asked to perform a task beyond their jobscope stated in the booking form, carers may refuse the task. If the requested task involves false information from the client, harassment, intimidation or danger to any person, or the child in her care, she can also decline to continue the service immediately, and the job will be cancelled without replacement or refund.
Carers may refuse duties or locations that contravene or violate religious sensitivities. Clients that do not adhere to these conditions will be barred from our services.

**Absent/Uncontactable  Client**
If, after the carer arrives at the service address, the client is not available to grant access or meet up with the carer, and fails to respond to query via phone call or WhatsApp, the carer will wait for 45 minutes, in excess of 45 minutes the carer can leave the service address and, the service is considered forfeit, with no refunds given.

**Collection and Processing of Personal Data**
You agree and consent to us and/or the carer using and processing the personal data (including sensitive personal data) of yours and your child. By making payment and subscribing to the service provided by the carer, you are deemed to have consented to our collection and processing of personal data in accordance to our personal data protection policy available at www.kiddocare.my.

**Services Booked During the Lockdown Period**
We will endeavour to continue to deliver our service to you, but failing that, either due to strict roadblocks, or carer calling in sick or any other reason that does not permit the service, we will hold your suspended service as credit, to be redeemed within two months after the lockdown is lifted. Booked services are not allowed to be cancelled, and no refund will be facilitated.

**Services During the Pelan Pemulihan Negara (PPN)**
I hereby acknowledge that I am taking the carer's service during the Pelan Pemulihan Negara (PPN), I affirm that all declarations  I made during booking are true to the best of my knowledge.

I also understand that Kiddocare has taken reasonable precautions at best efforts to mitigate risks, and to ensure the safety of the carer as well as myself and my family.

I hereby declare that I too will take such reasonable precautions according to Kiddocare's SOP and MOH guidelines, and affirm that I undertake the service in full understanding of possible risks and contingencies, and will not hold responsible any parties involved in case of Covid19 infection or any other untoward incidents.

**In  the case of a new Covid-19 Lockdown**
In the event of a new lockdown being declared, we will endeavour to continue to deliver our service to you, but failing that, we will hold your suspended service as credit, to be redeemed within two months after the lockdown is lifted.
</pre>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const scriptTnc = ref<HTMLElement | null>(null)

</script>
