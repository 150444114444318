<template>
    <kc-modal modal-id="modal-job-form" modal-size="xl">
        <div class="modal-header">
            <h4 class="modal-title" v-if="mode === 'create'">Add Session {{ booking.no }}</h4>
            <h4 class="modal-title" v-if="mode === 'update'">Update Session {{ job?.no }}</h4>
            <button id="btn-job-modal-close" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body bg-white">
            <template v-if="mode === 'update'">
                <div class="row">
                    <div class="col">
                        <h3>Original</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="form-label col-form-label">Date</label>
                        <div class="mt-2">{{ originalStartDateDisplay }}</div>
                        <div>({{ originalStartDayDisplay }})</div>
                    </div>
                    <div class="col-2">
                        <label class="form-label col-form-label">Time</label>
                        <div class="mt-2">{{ originalStartTimeDisplay }} - {{ originalEndTimeDisplay }}</div>
                        <div>({{ job.duration }} hours)</div>
                    </div>
                    <div class="col-4">
                        <label class="form-label col-form-label">Address</label>
                        <p class="mt-2">{{ currentAddressDisplay }}</p>
                        <p v-if="job.address">Remarks: {{ job.address.address_2 }}</p>
                    </div>
                    <div class="col-4">
                        <label class="form-label col-form-label">Children</label>
                        <ol>
                            <li v-for="child in job.children" :key="`child-${child.id}`">{{ child.name }}</li>
                        </ol>
                    </div>
                </div>
                <hr />
            </template>
            <div class="row" v-if="mode === 'update'">
                <div class="col">
                    <h3>Changes</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="form-label col-form-label">Date</label>
                    <div class="input-group">
                        <input type="text" id="input-modal-job-form-date" class="form-control bg-white" :class="{'is-invalid': !!errors.date}" :value="updatedDateDisplay" readonly>
                        <span class="input-group-text"><icon name="calendar" /></span>
                    </div>
                    <div v-if="errors.date"><small class="text-red">Please put session date</small><br></div>
                    <small v-if="mode === 'update'" class="fst-italic">Leave blank if no changes needed</small>

                    <div class="mt-3">
                        <label class="form-label col-form-label">Duration (Hours)</label>
                        <div class="input-group">
                            <input class="form-control" :class="{'is-invalid': !!errors.duration}" @input="errors.duration = null" type="number" v-model.number="input.duration" min="2" max="24">
                        </div>
                        <div v-if="errors.duration"><small class="text-red">{{ errors.duration }}</small><br></div>
                        <small v-if="mode === 'update'" class="fst-italic">Leave blank if no changes needed</small>
                    </div>
                </div>
                <div class="col-4">
                    <div class="d-flex justify-content-between">
                        <label class="form-label col-form-label">Address</label>
                        <icon name="address-book" role="button" class="text-cyan" @click.prevent="openAddressModal"/>
                    </div>
                    <vue-select :options="addressOptions" label="name" v-model="input.address_id" :reduce="(address: any) => address.id" />
                    <div class="input-group">
                        <textarea class="form-control bg-white mt-2" style="resize: none" rows="5" readonly :value="updatedAddressDisplay"></textarea>
                    </div>
                    <small v-if="mode === 'update'" class="fst-italic">Leave blank if no changes needed</small>
                </div>
                <div class="col-4">
                    <div class="d-flex justify-content-between">
                        <label class="form-label col-form-label">Children</label>
                        <icon name="mood-kid" class="text-cyan" role="button" @click.prevent="openChildModal" />
                    </div>
                    <template v-for="child in childOptions" :key="`input-child-${child.id}`">
                        <label class="form-check">
                            <input type="checkbox" class="form-check-input" @input="updateChild" :value="child.id" /> {{ child.name }}
                        </label>
                    </template>
                    <small v-if="mode === 'update'" class="fst-italic">Leave unchecked if no changes needed</small>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3 border-top">
            <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" @click.prevent="saveJob">Save</button>
        </div>
    </kc-modal>
    <child-modal :user-id="booking.user_id" />
    <address-modal :user-id="booking.user_id" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { formatDate } from '@/composable/useDate'
import { Core, easepick } from '@easepick/core'
import { TimePlugin } from '@easepick/time-plugin'
import AddressService from '@/services/AddressService'
import ChildService from '@/services/ChildService'
import JobService from '@/services/JobService'
import Address from '@/types/Address'
import Child from '@/types/Child'
import { getAddress } from '@/composable/useJob'
import { AmpPlugin } from '@easepick/amp-plugin'
import Modal from 'bootstrap/js/dist/modal'
import ChildModal from '@/pages/child/ChildModal.vue'
import AddressModal from '@/pages/address/AddressModal.vue'
import { useNotificationsStore } from '@/stores/notifications'

const { addToastNotification } = useNotificationsStore()

const props = defineProps({
    mode: { type: String, required: true },
    job: { type: Object, required: false },
    booking: { type: Object, required: true },
})

const input = ref<any>({
    booking_id: null,
    started_at: null,
    duration: null,
    address_id: null,
    child_ids: [],
})

const errors = ref<any>({
    date: null,
    duration: null,
})

const addressOptions = ref<Array<Address>>([])
const childOptions = ref<Array<Child>>([])

let modalElement: HTMLElement | null = null
let btnCloseModal: HTMLElement | null = null
let modalChildBootstrap: Modal | null = null
let modalAddressBootstrap: Modal | null = null
let jobDatePicker: Core | null = null

const originalStartDateDisplay = computed(() => props.job?.started_at ? formatDate(props.job.started_at, 'DD-MMM-YYYY') : '')
const originalStartDayDisplay = computed(() => props.job?.started_at ? formatDate(props.job.started_at, 'dddd') : '')
const originalStartTimeDisplay = computed(() => props.job?.started_at ? formatDate(props.job.started_at, 'hh:mm A') : '')
const originalEndTimeDisplay = computed(() => props.job?.ended_at ? formatDate(props.job.ended_at, 'hh:mm A') : '')
const updatedDateDisplay = computed(() => input.value.started_at ? formatDate(input.value.started_at, 'DD-MMM-YYYY hh:mm A') : '')
const currentAddressDisplay = computed(() => props.job?.address ? getAddress(props.job?.address) : '')
const updatedAddressDisplay = computed(() => {
    const address = addressOptions.value.find(address => address.id === input.value.address_id)

    if (address) {
        const fullAddress = getAddress(address)
        return `${fullAddress}\n\nRemarks: ${address.address_2 ?? ''}`
    }

    return ''
})

const isFetchingAddresses = ref<boolean>(false)
const isFetchingChildren = ref<boolean>(false)

const updateChild = (e: any) => {
    const selectedId = parseInt(e.target.value)
    if (e.target.checked) {
        input.value.child_ids.push(selectedId)
    } else {
        input.value.child_ids = input.value.child_ids.filter((childId: number) => childId !== selectedId)
    }
}

const fetchCustomerData = () => {
    if (! isFetchingAddresses.value) {
        isFetchingAddresses.value = true
        AddressService.index({ user_id: props.booking.user_id })
            .then(({ data: { data } }) => {
                addressOptions.value = data
            }).finally(() => isFetchingAddresses.value = false)
    }

    if (! isFetchingChildren.value) {
        isFetchingChildren.value = true
        ChildService.index({ user_id: props.booking.user_id })
            .then(({ data: { data } }) => {
                childOptions.value = data
            }).finally(() => isFetchingChildren.value = false)
    }
}


const saveJob = () => {
    if (props.mode === 'update') {
        if (input.value.started_at && ! input.value.duration) {
            errors.value.duration = 'Please put your duration'
            return
        }

        if (! input.value.started_at && input.value.duration) {
            errors.value.date = 'Please put your session date'
            return
        }

        if (input.value.started_at && input.value.duration && input.value.duration < 2) {
            errors.value.duration = 'Please put your duration at least 2 hours'
            return
        }
    }

    let jobService = props.mode === 'update' ? JobService.update(props.job?.id, input.value)
        : JobService.store(input.value)

    jobService.then(({ data }) => {
        btnCloseModal?.click()
        addToastNotification({
            message: `Session ${props.mode}d`,
            type: 'success',
        })
    }).catch(({ response: { data }}) => {
        const message = data.hasOwnProperty('message') ? data.message : 'Internal server error. Please contact tech team.'
        addToastNotification({
            message,
            type: 'danger',
        })
    })
}

const openAddressModal = () => {
    btnCloseModal?.click()
    modalAddressBootstrap?.show()
}

const openChildModal = () => {
    btnCloseModal?.click()
    modalChildBootstrap?.show()
}

watch(
    () => props.booking,
    (newVal) => input.value.booking_id = newVal.id
)

onMounted(() => {
    modalElement = document.getElementById('modal-job-form')
    btnCloseModal = document.getElementById('btn-job-modal-close')

    if (! modalElement) {
        return
    }

    modalElement?.addEventListener('shown.bs.modal', () => {
        fetchCustomerData()
    })

    modalElement?.addEventListener('hidden.bs.modal', () => {
        input.value.started_at = null
        input.value.duration = null
        input.value.address_id = null
        input.value.child_ids = []

        addressOptions.value = []
        childOptions.value = []
    })

    const ampPlugin: object = {
        AmpPlugin: {
            resetButton: () => {
                input.value.started_at = null
                return true
            },
        }
    }

    modalChildBootstrap = new Modal('#modal-child-form')
    modalAddressBootstrap = new Modal('#modal-address-form')

    jobDatePicker = new easepick.create({
        element: '#input-modal-job-form-date',
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css',
        ],
        autoApply: false,
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                errors.value.date = null
                input.value.started_at = e.detail.date.format('YYYY-MM-DD HH:mm')
                picker.hide()
            })
        },
        plugins: [AmpPlugin, TimePlugin],
        TimePlugin: {
            format12: true,
        },
        ...ampPlugin,
    })
})
</script>
