<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right" :to="{ name: 'coupon-create' }">
                        <icon name="plus" />
                        Add New Coupon
                    </router-link>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Coupon Name</label>
                    <input class="form-control" type="text" v-model="inputFilter.name" @keyup.enter="fetchCoupon">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Coupon Code</label>
                    <input class="form-control" type="text" v-model="inputFilter.code" @keyup.enter="fetchCoupon">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="email" v-model="inputFilter.email" @keyup.enter="fetchCoupon">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchCoupon" :is-loading="isFetchingCoupons"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Code</th>
                            <th>Discount</th>
                            <th>Started Date</th>
                            <th>Expired Date</th>
                            <th>Created At</th>
                            <th>Used</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="coupons.length > 0">
                        <tr v-for="coupon in coupons" :key="coupon.id">
                            <td>
                                <p class="mb-0">{{ coupon.name }}</p>
                                <p class="text-secondary mb-0" v-if="coupon.description">{{ coupon.description }}</p>
                            </td>
                            <td class="text-capitalize">{{ coupon.type }}</td>
                            <td>{{ coupon.code }}</td>
                            <td>{{ coupon.isFixed ? toMYR(coupon.discountAmount) : `${coupon.discountAmount}%` }}</td>
                            <td>{{ coupon.startedAt ? formatDate(coupon.startedAt, 'DD-MMM-YYYY') : '-' }}</td>
                            <td>{{ coupon.expiredAt ? formatDate(coupon.expiredAt, 'DD-MMM-YYYY') : '-' }}</td>
                            <td>{{ formatDate(coupon.createdAt, 'DD-MMM-YYYY') }}</td>
                            <td>{{ coupon.extraAttributes.bookingCounts }} / {{ coupon.extraAttributes.maxUses ?? '&infin;' }}</td>
                            <td class="text-center">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-primary" :to="{ name: 'coupon-show', params: { couponId: coupon.id } }">
                                        <icon name="eye" />
                                    </router-link>
                                    <router-link class="btn btn-icon btn-success" :to="{ name: 'coupon-edit', params: { couponId: coupon.id } }">
                                        <icon name="pencil" />
                                    </router-link>
                                    <button class="btn btn-icon btn-danger" @click.prevent="deleteCoupon(coupon)">
                                        <icon name="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingCoupons" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { toMYR } from '@/composable/useCurrency'
import { formatDate } from '@/composable/useDate'
import UserFilter from '@/types/Filter'

import { useNotificationsStore } from '@/stores/notifications'
import useMetaPage from '@/composable/useMetaPage'
import CouponService from '@/services/settings/CouponService'
import Coupon from '@/types/Coupon'

const { addToastNotification } = useNotificationsStore()
const coupons = ref<Array<Coupon>>([])
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const inputFilter = ref<UserFilter>({
    name: '',
    email: '',
})

const isFetchingCoupons = ref<boolean>(false)

fetchCoupon()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchCoupon()
    }
)

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchCoupon()
}

function fetchCoupon () {
    isFetchingCoupons.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    CouponService.index(query)
        .then(({ data: { data, meta } }) => {
            coupons.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingCoupons.value = false
        })
}

function deleteCoupon (coupon: Coupon) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete coupon ${coupon.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            let couponId = Number(coupon.id)

            CouponService.destroy(couponId)
                .then(() => {
                    fetchCoupon()
                    addToastNotification({
                        message: `Coupon ${coupon.name} deleted`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}
</script>
