<template>
    <div class="text-end mb-3">
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#content-type-form-modal"
            @click.prevent="contentType = undefined">
            Add Content Type
        </button>
    </div>
    <div class="card">
        <overlay-spinner :is-showing="isLoading"></overlay-spinner>
        <div class="card-body">
            <form id="update-content-type-form" @submit.prevent="updateRows">
                <ul v-if="contentTypes.length" class="list-unstyled border rounded">
                    <li class="p-3 d-flex align-items-start justify-content-between"
                        :class="[{ 'border-bottom': index !== contentTypes.length - 1 }]"
                        v-for="(type, index) in contentTypes" :key="type.id">
                        <div class="d-flex align-items-center w-100">
                            <span>{{ index + 1 }}</span>
                            <div class="btn-group mx-3">
                                <button class="btn btn-default btn-sm" :class="{ disabled: index === 0 }"
                                    @click.prevent="moveToTop(index)">
                                    <icon name="arrow-up" class="mx-auto text-muted" />
                                </button>
                                <button class="btn btn-default btn-sm"
                                    :class="{ disabled: index === contentTypes.length - 1 }"
                                    @click.prevent="moveToBottom(index)">
                                    <icon name="arrow-down" class="mx-auto text-muted" />
                                </button>
                            </div>
                            {{ type.name }}
                        </div>
                        <div class="d-flex gap-4 align-items-center">
                            <label class="form-check form-switch mb-0 ms-auto">
                                <input class="form-check-input" type="checkbox" v-model="type.isEnabled">
                                <span class="form-check-label" v-if="type.isEnabled">Enabled</span>
                                <span class="form-check-label" v-else>Disabled</span>
                            </label>
                            <div class="btn-group">
                                <button type="button" class="btn btn-icon" data-bs-toggle="modal"
                                    data-bs-target="#content-type-form-modal" @click="setType(type)">
                                    <icon name="pencil" class="text-primary" />
                                </button>
                                <button class="btn btn-icon" @click.prevent="deleteType(type.id, type.name)">
                                    <icon name="trash" class="text-danger" />
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
        <div class="card-footer">
            <div class="d-flex gap-2">
                <button type="button" class="btn btn-light" @click="fetchContentTypes">Reset</button>
                <button class="btn btn-primary" form="update-content-type-form">Save Changes</button>
            </div>
        </div>
    </div>

    <ContentTypeForm :type="contentType" @save="fetchContentTypes" />
</template>

<script setup lang="ts">
import ContentTypeForm from '@/pages/settings/content-types/ContentTypeForm.vue'
import PlatformContentTypeService from '@/services/PlatformContentTypeService'
import { useNotificationsStore } from '@/stores/notifications'
import PlatformContentType from '@/types/PlatformContentType'
import { ref } from 'vue'

const contentTypes = ref<Array<PlatformContentType>>([])
const contentType = ref<PlatformContentType>()

const emits = defineEmits<{
    (e: 'save'): void
}>()

const { addToastNotification } = useNotificationsStore()

const isLoading = ref<boolean>(false)

const fetchContentTypes = async () => {
    isLoading.value = true

    try {
        const response = await PlatformContentTypeService.index()
        contentTypes.value = response.data.data
    } catch (error) {
        console.log(error)
    } finally {
        isLoading.value = false
    }
}

const moveToTop = (index: number) => {
    if (index === 0) return null

    let prevItem = contentTypes.value[index - 1]
    let currentItem = contentTypes.value[index]

    contentTypes.value[index - 1] = currentItem
    contentTypes.value[index] = prevItem
}

const moveToBottom = (index: number) => {
    if (index === contentTypes.value.length - 1) return null

    let nextItem = contentTypes.value[index + 1]
    let currentItem = contentTypes.value[index]

    contentTypes.value[index + 1] = currentItem
    contentTypes.value[index] = nextItem
}

const updateRows = async () => {
    isLoading.value = true

    try {
        await PlatformContentTypeService.updateRows(formattedData())

        addToastNotification({
            message: 'Content type is successfully updated.',
            type: 'success',
        })

        document.getElementById('close-content-type-modal-button')?.click()
        fetchContentTypes()
    } catch {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    } finally {
        isLoading.value = false
    }
}

const formattedData = () => {
    return {
        types: contentTypes.value.map((type: PlatformContentType, index: number) => ({
            id: type.id,
            sequence: index + 1,
            is_enabled: type.isEnabled
        }))
    }
}

const setType = (type: PlatformContentType) => contentType.value = type

const deleteType = (typeId: number, typeName: string) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete content type ${typeName}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (!result.isConfirmed) {
            return
        }

        isLoading.value = true

        PlatformContentTypeService.destroy(typeId)
            .then(() => {
                addToastNotification({
                    message: `Content type ${typeName} deleted`,
                    type: 'success'
                })

                fetchContentTypes()
            })
            .catch(() => {
                addToastNotification({
                    message: `Internal server error. Please contact Tech if error persists.`,
                    type: 'danger'
                })

                isLoading.value = false
            })

    })
}

fetchContentTypes()

</script>