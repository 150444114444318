<template>
    <template v-for="(log, index) in sessionCarerLogs" :key="index">
        <div v-if="index === 'injuredPersons'">
            <span class="fw-bold">Injured Persons:</span>
            <template v-if="typeof log === 'object'">
                <ul class="mb-2">
                    <li v-if="log.dependents">Dependents</li>
                    <li v-if="log.carer">Carer</li>
                </ul>
            </template>
        </div>
        <div v-else-if="index === 'currentCondition'">
            <span class="fw-bold">Current Condition:</span> {{ log }}
        </div>
    </template>
</template>

<script setup lang="ts">
import SessionCarerLog from '@/types/SessionCarerLog'
import { Ticket } from '@/types/Ticket'
import { computed } from 'vue'

const props = defineProps<{
    ticket: Ticket
}>()

const sessionCarerLogs = computed(() => {
    const sessionCarerLog = props.ticket.session.carerLogs
        .find((sessionCarerLog: SessionCarerLog) => sessionCarerLog.id === props.ticket.data.session_carer_log_id)

    if (sessionCarerLog && sessionCarerLog.data) {
        const { data: { injuredPersons, currentCondition } } = sessionCarerLog
        return { injuredPersons, currentCondition }
    }

    return null
})
</script>