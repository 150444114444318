<template>
    <table class="table table-borderless">
        <tbody>
            <tr>
                <td class="fw-bold">Status</td>
                <td width="60%">
                    <span class="badge" :class="[dependentRoutine.isCompleted ? 'bg-green' : 'bg-secondary']">
                        {{ dependentRoutine.isCompleted ? 'Completed' : 'Dismissed' }}
                    </span>
                </td>
            </tr>
            <tr>
                <td class="fw-bold">Client Notes</td>
                <td>{{ dependentRoutine.customerNotes ?? '-' }}</td>
            </tr>
            <tr>
                <td class="fw-bold">Kiddocarer Notes</td>
                <td>{{ dependentRoutine.carerNotes ?? '-' }}</td>
            </tr>
            <tr>
                <td class="fw-bold">Child</td>
                <td>{{ dependentRoutine.sessionDependent?.name }}</td>
            </tr>
            <tr>
                <td class="fw-bold">Scheduled Time</td>
                <td>{{ formatDate(dependentRoutine.scheduledAt, 'h.mma') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import SessionDependentRoutine from '@/types/SessionDependentRoutine'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
    dependentRoutines: Array<SessionDependentRoutine>
}>(), {
    dependentRoutines: () => [],
})

const dependentRoutine = computed<SessionDependentRoutine>(() => props.dependentRoutines[0])
</script>
