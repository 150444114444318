import { getQueryString } from '@/composable/http';
import httpClientV2 from '@/services/HttpClientV2';

export default {
    index(query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`/backoffice/postcodes?${queryString}`)
    },
    store(data: object = {}) {
        return httpClientV2.post('/backoffice/postcodes', data)
    },
    update(id: number, data: object = {}) {
        return httpClientV2.patch(`/backoffice/postcodes/${id}`, data)
    },
    destroy(id: number) {
        return httpClientV2.delete(`/backoffice/postcodes/${id}`)
    },
};
