import {defineStore} from 'pinia'
import {computed, Ref, ref, watch} from 'vue'
import OrderService from '@/services/OrderService'
import User from '@/types/User'
import GenerateOrderPreviewFormData from '@/types/formData/GenerateOrderPreviewFormData'
import {useNotificationsStore} from '@/stores/notifications'
import {SessionRateAmount} from '@/types/formData/SessionRateAmount'
import StoreOrderFormData, {ExtraRate} from '@/types/formData/StoreOrderFormData'

import {AxiosError} from 'axios'
import Order from '@/types/Order'

interface Form {
    session_ids: number[],
    voucher_code: string | null,
    loyalty_points_amount: number,
    additional_rates: { [key: string]: AdditionalRate[] }
    mark_as_primary: boolean
}

interface AdditionalRate {
    type: SessionRateAmount | null
    quantity: number
    amount: number
}

type Mode = 'primary' | 'custom'

interface ErrorBag {
    session_ids?: string[]
    voucher_code?: string[]
    loyalty_points_amount?: string[]
}

interface SubmitResponse {
    success: boolean
    data?: Order
}

export const useCreateOrderStore = defineStore('createOrder', () => {

    const {addToastNotification} = useNotificationsStore()

    const isGeneratingPreview = ref(false)
    const setIsGeneratingPreview = (value = true) => isGeneratingPreview.value = value

    const isSubmitting = ref(false)
    const setIsSubmitting = (value = true) => isSubmitting.value = value

    const selectedCustomer = ref<User | null>(null)

    const mode: Ref<Mode | null> = ref(null)
    const selectMode = (value: Mode) => mode.value = value

    const form: Ref<Form> = ref({
        session_ids: [],
        voucher_code: null,
        loyalty_points_amount: 0,
        additional_rates: {},
        mark_as_primary: mode.value == 'primary'
    })

    const errors: Ref<ErrorBag> = ref({})

    const hasSelectedSessions = computed(() => {
        return selectedCustomer.value?.id && form.value.session_ids?.length > 0
    })

    let orderPreview = ref()

    const addSessionIdToForm = (sessionId: number) => {
        form.value.session_ids.push(sessionId)
    }

    const addCustomRateForSession = (
        sessionId: number
    ) => {
        if (!form.value.additional_rates[sessionId]) {
            form.value.additional_rates[sessionId] = []
        }

        form.value.additional_rates[sessionId].push({
            type: null,
            quantity: 1,
            amount: 0
        })
    }

    const generatePreview = async (): Promise<void> => {

        resetErrors()

        if (!selectedCustomer.value?.id) {
            return
        }

        const payload: GenerateOrderPreviewFormData = {
            customer_user_id: selectedCustomer.value?.id,
            session_ids: form.value.session_ids
        }

        if (mode.value === 'custom') {
            payload.is_custom = true
        }

        if (form.value.voucher_code) {
            payload.voucher_code = form.value.voucher_code
        }

        if (form.value.loyalty_points_amount) {
            payload.loyalty_points_amount = form.value.loyalty_points_amount
        }

        if (form.value.mark_as_primary) {
            payload.mark_as_primary = form.value.mark_as_primary
        }

        // Format extra rates
        if (Object.keys(form.value.additional_rates).length > 0) {
            payload.extra_rates = getFormattedExtraRatesForPayload()
        }

        try {
            setIsGeneratingPreview()

            const {data} = await OrderService.generatePreview(payload)
            orderPreview.value = data.data ?? []
        } catch (e) {

            const error = e as AxiosError

            if (error.response && error.response?.status === 422) {
                formatErrorMessages(error)
            } else {

                addToastNotification({
                    message: 'Failed to generate order preview. Please contact tech team.',
                    type: 'danger'
                })
            }
        }

        setIsGeneratingPreview(false)
    }

    const formatErrorMessages = (error: AxiosError) => {

        if (!error.response) return

        const errorData = error.response.data as { errors?: Record<string, string[]> }

        if (!errorData.errors) return

        Object.keys(errorData.errors).forEach((key: string) => {

            // format error messages for Sessions
            if (key.includes('session_ids')) {
                errors.value.session_ids = errorData.errors ? errorData.errors[key] : []
            } else if (key.includes('voucher_code')) {
                errors.value.voucher_code = errorData.errors ? errorData.errors[key] : []
            } else if (key.includes('loyalty_points')) {
                errors.value.loyalty_points_amount = errorData.errors ? errorData.errors[key] : []
            } else {
                addToastNotification({
                    type: 'danger',
                    message: 'There is an error with the form. Please contact tech team.'
                })
            }
        })
    }

    const removeSelectedRate = (sessionId: any, index: any) => {
        // remove the selected additional rate
        form.value.additional_rates[sessionId].splice(index, 1)

        // if the additional rate have been remove all, then delete the object property (src: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/delete)
        if (form.value.additional_rates[sessionId].length == 0)
            delete form.value.additional_rates[sessionId]
    }

    const submit = async (): Promise<SubmitResponse> => {

        if (!selectedCustomer.value?.id) {
            return {success: false}
        }

        const payload: StoreOrderFormData = {
            customer_user_id: selectedCustomer.value.id,
            session_ids: form.value.session_ids
        }

        if (mode.value === 'custom') {
            payload.is_custom = true
        }

        if (form.value.voucher_code) {
            payload.voucher_code = form.value.voucher_code
        }

        if (form.value.loyalty_points_amount) {
            payload.loyalty_points_amount = form.value.loyalty_points_amount
        }

        if (form.value.mark_as_primary) {
            payload.mark_as_primary = form.value.mark_as_primary
        }

        // Format extra rates
        if (Object.keys(form.value.additional_rates).length > 0) {
            payload.extra_rates = getFormattedExtraRatesForPayload()
        }

        resetErrors()

        setIsSubmitting()
        let response: SubmitResponse = {success: false}

        try {
            const {data: {data}} = await OrderService.store(payload)
            resetAll()
            response = {
                success: true,
                data: data
            }
        } catch (err) {
            const error = err as AxiosError

            if (error.response?.status === 422) {
                formatErrorMessages(error)
            } else {
                addToastNotification({
                    message: 'Failed to create Order. Please contact tech team.',
                    type: 'danger'
                })
            }

            response.success = false
        }

        setIsSubmitting(false)

        return response
    }

    const getFormattedExtraRatesForPayload = () => {
        let array: ExtraRate[] = []
        Object.keys(form.value.additional_rates).forEach(sessionId => {

            const rows = form.value.additional_rates[sessionId]

            let rates = []
            for (const row of rows) {
                if (row.type?.id && row.amount > 0 && row.quantity > 0) {
                    rates.push({
                        type: row.type.id,
                        quantity: row.quantity,
                        amount: row.amount
                    })
                }
            }

            if (rates.length > 0) {
                array?.push({
                    session_id: parseInt(sessionId),
                    rates: rates
                })
            }
        })

        return array
    }


    const resetAll = () => {

        selectedCustomer.value = null

        form.value = {
            session_ids: [],
            voucher_code: null,
            loyalty_points_amount: 0,
            additional_rates: {},
            mark_as_primary: false
        }

        orderPreview.value = null
        resetErrors()
    }

    const resetErrors = () => errors.value = {}

    watch(() => form.value.session_ids, generatePreview)
    watch(() => form.value.mark_as_primary, generatePreview)

    return {
        mode,
        form,
        errors,
        selectedCustomer,
        hasSelectedSessions,
        orderPreview,
        isGeneratingPreview,
        isSubmitting,
        addSessionIdToForm,
        resetAll,
        selectMode,
        submit,
        addCustomRateForSession,
        generatePreview,
        removeSelectedRate
    }
})