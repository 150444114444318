<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Pending Payment</h4>
            <CopyButton :data="scriptPendingPayment?.innerText ?? undefined"
                        label="Copy"
            />
        </div>
        
        <div class="card-body">
<pre style="max-height: 400px" ref="scriptPendingPayment">
Hi sir/maam,

We noticed that more than 1 hour has passed since invoice is created. To secure the carer and to avoid the carer to cancel the session applied, we hope you can proceed with the payment to confirm the booking.

Should you need further assistance, please click the help button and we will attend to your request.
</pre>
    </div>
</div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const scriptPendingPayment = ref<HTMLElement | null>(null)

</script>
