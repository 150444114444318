<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Rating Reminder</h4>
            <CopyButton :data="scriptRatingReminder?.innerText ?? undefined"
                        label="Copy"
            />
        </div>
        
        <div class="card-body">
<pre style="max-height: 400px" id="scriptRatingReminder" ref="scriptRatingReminder">
Dear **customer**,

Thank you for trusting Kiddocare.

Your feedback is crucial as our Kiddocarers receive incentives based on your ratings and reviews.

As a valued customer, book your next service and enjoy RM15 OFF with code BIGSAVINGS at checkout. Limited-time offer!

For assistance, click on the Chat button. Our team is available from 7 AM to 12 AM.

Thank you for choosing Kiddocare. We look forward to serving you again!
</pre>
    </div>
</div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const scriptRatingReminder = ref<HTMLElement | null>(null)

</script>
