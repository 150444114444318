export default interface CarerPayout {
    id: number
    totalAmount: number
    totalRecipients: number
    totalSessions: number
    totalHours: number
    status: number
    type: number
    schedule: number
    cutOffAt: string
    confirmedAt: string
    paymentAt: string
    recipients: Array<any>
    createdAt: string
    updatedAt: string
}

export const PayoutInit: CarerPayout = {
    id: 0,
    totalAmount: 0,
    totalHours: 0,
    totalRecipients: 0,
    totalSessions: 0,
    status: 0,
    type: 0,
    schedule: 0,
    cutOffAt: "",
    confirmedAt: "",
    paymentAt: "",
    recipients: [],
    createdAt: "",
    updatedAt: ""
}
