import httpClient from './HttpClient'
import BookingQuery from '@/types/query/BookingQuery'

export default {
    index(query: BookingQuery = {}) {
        const queryString = encodeURI(Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&'))

        return httpClient.get(`admin/bookings?${queryString}`)
    },

    store(data: object = {}) {
        return httpClient.post('/admin/bookings', data)
    },

    show(id: number) {
        return httpClient.get(`admin/bookings/${id}`)
    },

    update(id: number, data: object = {}) {
        return httpClient.patch(`admin/bookings/${id}`, data)
    },

    getInvoiceDraft(id: number) {
        return httpClient.get(`admin/bookings/${id}/invoice-draft`)
    }
}
