<template>
    <overlay-spinner :is-showing="isFetchingStats" v-if="isFetchingStats" />
    <template v-else>
        <div class="row row-cards mb-4">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>New Job Today</h4>
                                <h1 class="fw-normal mb-0">{{ stats.created.today }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="clock" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Jobs Starting Today</h4>
                                <h1 class="fw-normal mb-0">{{ stats.urgent }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="users" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>All Time Jobs</h4>
                                <h1 class="fw-normal mb-0">{{ stats.total.count.toLocaleString() }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="users" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>

                        <div class="progress w-100 my-3 bg-secondary">
                            <div class="progress-bar" role="progressbar" :style="`width: ${calcPercentage(stats.total.breakdown.complete, stats.total.count)}%`" aria-valuemax="100"></div>
                            <div class="progress-bar bg-red" role="progressbar" :style="`width: ${calcPercentage(stats.total.breakdown.cancelled, stats.total.count)}%`" aria-valuemax="100"></div>
                            <div class="progress-bar bg-yellow" role="progressbar" :style="`width: ${calcPercentage(stats.total.breakdown.no_carer, stats.total.count)}%`" aria-valuemax="100"></div>
                        </div>

                        <ul class="list-unstyled d-flex justify-content-between">
                            <li class="d-inline-flex align-items-center">
                                <div style="height: 16px; width: 16px" class="bg-blue rounded me-2"></div>
                                <span>Completed</span>
                                <p class="mb-0 ms-2">{{ calcPercentage(stats.total.breakdown.complete, stats.total.count) }}%</p>
                            </li>
                            <li class="d-inline-flex align-items-center">
                                <div style="height: 16px; width: 16px" class="bg-red rounded me-2"></div>
                                <span>Cancelled</span>
                                <p class="mb-0 ms-2">{{ calcPercentage(stats.total.breakdown.cancelled, stats.total.count) }}%</p>
                            </li>
                            <li class="d-inline-flex align-items-center">
                                <div style="height: 16px; width: 16px" class="bg-yellow rounded me-2"></div>
                                <span>No Carer</span>
                                <p class="mb-0 ms-2">{{ calcPercentage(stats.total.breakdown.no_carer, stats.total.count) }}%</p>
                            </li>
                            <li class="d-inline-flex align-items-center">
                                <div style="height: 16px; width: 16px" class="bg-secondary rounded me-2"></div>
                                <span>Others</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </template>

    <div class="row row-deck row-cards mb-4">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <div class="btn-group">
                        <button class="btn" :class="[isActiveFilter('daily') ? 'btn-primary' : 'btn-default']" @click="fetchJobData('daily')">Daily</button>
                        <button class="btn" :class="[isActiveFilter('weekly') ? 'btn-primary' : 'btn-default']" @click="fetchJobData('weekly')">Weekly</button>
                        <button class="btn" :class="[isActiveFilter('monthly') ? 'btn-primary' : 'btn-default']" @click="fetchJobData('monthly')">Monthly</button>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Total Job</th>
                                <th>Total Invoiced to Client</th>
                                <th>Total Amount paid to Carer</th>
                            </tr>
                        </thead>
                        <overlay-spinner :is-showing="isFetchingJobs" v-if="isFetchingJobs"></overlay-spinner>
                        <template v-else>
                            <tbody v-if="jobs.length">
                                <tr v-for="(job, index) in jobs" :key="`job-${index}`">
                                    <td>{{ job.date }}</td>
                                    <td>{{ job.total.toLocaleString() }}</td>
                                    <td>RM {{ job.total_amount.toLocaleString() }}</td>
                                    <td>RM {{ job.total_amount_pay.toLocaleString() }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4" class="text-center">No Data</td>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import DashboardService from '@/services/DashboardService'
import { useNotificationsStore } from '@/stores/notifications'
import OverlaySpinner from '@/components/OverlaySpinner.vue'

const { addToastNotification } = useNotificationsStore()
const stats = ref<object>({})
const isFetchingStats = ref<boolean>(false)
const isFetchingJobs = ref<boolean>(false)
const jobs = ref<Array<any>>([])
const activeFilter = ref<string>('daily')

onBeforeMount(() => {
    fetchStats()
    fetchJobData('daily')
})

function fetchStats () {
    isFetchingStats.value = true

    DashboardService.getJobs()
        .then(({ data }) => {
            stats.value = data.stats
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => isFetchingStats.value = false)
}

function fetchJobData (filterType: string = 'daily') {
    isFetchingJobs.value = true
    activeFilter.value = filterType

    const query = { filterType: filterType }

    DashboardService.getJobData(query)
        .then(({ data }) => {
            jobs.value = Object.keys(data.jobs).map((key) => data.jobs[key]) // convert object to array
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => isFetchingJobs.value = false)
}

function isActiveFilter (filter: string) {
    return activeFilter.value === filter
}

function calcPercentage (count: number, total: number) {
    if (!count) return 0

    return ((count / total) * 100).toFixed(2)
}

</script>