<template>
    <router-link :to="index" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left" />
        <span>Back to Promotions List</span>
    </router-link>

    <overlay-spinner :is-showing="isFetching"></overlay-spinner>
    <template v-if="promotion">
        <div class="page-header mb-4">
            <div class="row align-items-center">
                <div class="col">
                    <h2 class="page-title">{{ promotion.name }}</h2>
                </div>
                <div class="col-auto d-none d-md-flex">
                    <router-link :to="edit" type="button" class="btn btn-primary">
                        <icon name="pencil" />
                        Edit
                    </router-link>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-12 col-md">
                        <p class="text-muted mb-1">
                            Code Prefix
                        </p>
                    </div>
                    <div class="col-12 col-md-10">
                        <span class="fw-bold">{{ promotion.codePrefix }}</span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 col-md">
                        <p class="text-muted mb-1">
                            Description
                        </p>
                    </div>
                    <div class="col-12 col-md-10">
                        <span class="fw-bold">{{ promotion.description }}</span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 col-md">
                        <p class="text-muted mb-1">
                            Start Date
                        </p>
                    </div>
                    <div class="col-12 col-md-10">
                        <span class="fw-bold">{{ formatDate(promotion.startDate, 'DD-MMM-YYYY') }}</span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 col-md">
                        <p class="text-muted mb-1">
                            End Date
                        </p>
                    </div>
                    <div class="col-12 col-md-10">
                        <span class="fw-bold">{{ formatDate(promotion.endDate, 'DD-MMM-YYYY') }}</span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 col-md">
                        <p class="text-muted mb-1">
                            Content
                        </p>
                    </div>
                </div>
                <div class="row mb-3">
                    <MdPreview :modelValue="promotion.content ?? ''" class="border" />
                </div>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import PromotionService from '@/services/settings/PromotionService'
import { useNotificationsStore } from '@/stores/notifications'
import Promotion from '@/types/Promotion'
import { MdPreview } from 'md-editor-v3'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()

const isFetching = ref<boolean>(false)
const promotionId = Number(route.params.promotionId)
const promotion = ref<Promotion>()

const index = {
    name: 'promotion-index',
}
const edit = {
    name: 'promotion-edit',
    params: { promotionId: promotionId },
}

onMounted(() => fetchPromotion())

const fetchPromotion = async () => {
    isFetching.value = true

    await PromotionService.show(promotionId)
        .then(({ data: { data } }) => {
            promotion.value = data
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => isFetching.value = false)
}

</script>