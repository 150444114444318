import { computed, ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { email, helpers, maxLength, minLength, required, sameAs } from '@vuelidate/validators'
import UserInput from '@/types/Input'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = (input: UserInput) => {
    return computed(() => {
        return {
            inputUser: {
                email: {
                    required: helpers.withMessage(
                        'This email field is required',
                        required,
                    ),
                    email: helpers.withMessage(
                        'The email must be a valid email',
                        email,
                    ),
                },
                password: {
                    required: helpers.withMessage(
                        'This password field is required',
                        required,
                    ),
                    minLength: helpers.withMessage(
                        'The password field must be at least 8 characters',
                        minLength(8)
                    ),
                    maxLength: helpers.withMessage(
                        'This password field may not be greater than 255 characters',
                        maxLength(255)
                    ),
                },
                confirm_password: {
                    required: helpers.withMessage(
                        'This confirm password field is required',
                        required,
                    ),
                    sameAs: helpers.withMessage(
                        'The password confirmation does not match',
                        sameAs(input.password)
                    )
                },
                code: {
                    required: helpers.withMessage(
                        'This code field is required',
                        required,
                    ),
                    maxLength: helpers.withMessage(
                        'This code field may not be greater than 6 characters',
                        maxLength(6)
                    ),
                },
            }
        }
    })
}
