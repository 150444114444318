<template>
<teleport to="body">
<pre :id="`booking-whatsapp-template-${booking.no}`" :class="`d-${display}`" style="white-space: pre-wrap;">
Good morning afternoon evening Mr/Mrs {{ getMainProfile(booking.user.profiles).full_name }}. We are from Kiddocare. We have received your booking as shown below:

User ID: **{{ getMainProfile(booking.user.profiles).online_id ?? 'N/A' }}**
Booking no: **{{ booking.no ? booking.no : 'N/A' }}**
<span v-for="(job, index) in booking.jobs" :key="index">
Session No: **{{ job.no ? job.no : 'N/A' }}**
Date: **{{ formatDate(job.started_at, 'DD MMMM YYYY') }} ({{ formatDate(job.started_at, 'dddd') }})**
Time: {{ formatDate(job.started_at, 'hh:mm A') }} - {{ formatDate(job.ended_at, 'hh:mm A') }}
Duration: **{{ job.total_hours }} hours**
Address: {{ formatAddress(job.address) }}
Assigned Carer : **{{ job.carer != null ? getMainProfile(job.carer.profiles).full_name : 'Finding Carer' }}**

**No of children - {{ job.children.length }}**
<span v-for="(child, index) in job.children" :key="index">
Child {{index + 1}}: {{ child['name'] }} ({{ child['gender'] === 'B' ? 'Boy' : 'Girl' }})
Age: {{ toAge(child['birth_date']) }}
Medical Conditions : {{ child['medical'] }}
Allergies : {{ child['allergies'] }}
Language: {{ child['language'] }}
Religion: {{ child['religion'] }}
</span></span>
Adult presence: {{ booking.adult_present ? booking.adult_present : '-' }}
Pets : {{ booking.pets_present ? booking.pets_present : '-' }}
Special Requirements : {{ booking.special_requirements ? booking.special_requirements : '-' }}
Discount Code: **{{ booking.discount_code ?? '-' }}**

Carer Preference -
Language: {{ booking.tempPrefferedLanguages == null ? 'N/A' : booking.tempPrefferedLanguages.toString() }}
Race: {{ booking.carer_race == null ? 'N/A' : booking.carer_race.toString() }}
Religion: {{ booking.carer_religion == null ? 'N/A' : booking.carer_religion.toString() }}
Name: {{ booking.carer_name ? booking.carer_name : '-' }}

Please review and confirm details above
</pre>
</teleport>
</template>

<script setup lang="ts">
import { formatDate, toAge } from '@/composable/useDate'
import { getMainProfile } from '@/composable/profile'

const props = defineProps({
    booking: { type: Object, default: {} },
    display: { type: String },
})

function formatAddress(address: any) {
    return [address.unit_no, address.floor_level, address.block_building, address.address_1, address.postcode, address.town, address.state].filter(val => val).join(', ')
}

</script>
