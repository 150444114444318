<template>
    <router-link :to="{ 'name': 'booking-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Bookings</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingBooking" v-if="isFetchingBooking" />
    <template v-else>
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex space-x-2 align-items-center">
                        <h2 class="page-title">{{ booking.no }}</h2>
                        <span class="badge badge-pill bg-primary">{{ _.startCase(booking.status) }}</span>
                    </div>
                    <div class="page-subtitle">
                        <div class="row">
                            <div class="col-auto">
                                <icon name="calendar" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">{{ toLocaleDate(booking.created_at, 'DD-MMM-YYYY hh:mm:ss A') }}</span>
                            </div>
                            <div class="col-auto">
                                <icon name="user" :stroke-width="1.2" class="pe-2"/>
                                <span>
                                    <router-link target="_blank" :to="{ name: 'customer-show', params: { 'customerId': booking.user.id } }">({{ getMainProfile(booking.user.profiles).online_id }}) {{ getMainProfile(booking.user.profiles).full_name }}</router-link>
                                </span>
                            </div>
                            <div class="col-auto">
                                <icon name="phone" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">{{ getMainProfile(booking.user.profiles).mobile_no ?? 'N/A' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto d-none d-md-flex">
                    <div class="dropdown position-static">
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                            Action
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" style="width: 280px;">
                            <a class="dropdown-item" role="button" @click="showWhatsAppTemplate('booking-template', booking.no)"><icon name="brand-whatsapp" class="me-2"/>Booking Whatsapp Template</a>
                            <booking-whatsapp-template :booking="booking" display="none"></booking-whatsapp-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cards mt-2 mb-3">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Children</h4>
                                <h1 class="fw-normal mb-0">{{ booking.total_children }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="ad-2" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Session</h4>
                                <h1 class="fw-normal mb-0">{{ booking.total_jobs }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="ad-2" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Amount Invoiced</h4>
                                <h1 class="fw-normal mb-0">{{ toMYR(booking.total_amount_invoiced) }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="file-invoice" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Amount Paid</h4>
                                <h1 class="fw-normal mb-0">{{ toMYR(booking.total_amount_invoice_paid) }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="file-dollar" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

        <div class="py-4">
            <KeepAlive>
                <component :is="activeComponent.component" 
                    @deleted="fetchBooking" 
                    @updated-or-created="fetchBooking" 
                    v-bind="activeComponent.props" 
                    :key="`${activeComponent.slug}-${Date.now()}`">
                </component>
            </KeepAlive>
        </div>
    </template>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent, provide } from 'vue'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import { toLocaleDate } from '@/composable/useDate'
import BookingService from '@/services/BookingService'
import { useNotificationsStore } from '@/stores/notifications'
import { toMYR } from '@/composable/useCurrency'
import SubMenu from '@/components/SubMenu.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import { getMainProfile } from '@/composable/profile'
import BookingWhatsappTemplate from './BookingWhatsappTemplate.vue'
import useBooking from '@/composable/useBooking'

const { addToastNotification } = useNotificationsStore()
const { showWhatsAppTemplate } = useBooking()

provide('setActiveComponent', setActiveComponent)

const route = useRoute()
const booking = ref<any>()
const isFetchingBooking = ref<boolean>()
const bookingId = Number(route.params.bookingId)
const activeComponent = ref<object>({})
const subMenus = ref<Array<any>>([])

fetchBooking()

function fetchBooking (reloadComponentSlug: string = '') {
    isFetchingBooking.value = (reloadComponentSlug === '') // this will partially reload the async component

    BookingService.show(bookingId)
        .then(({ data: data }) => {
            booking.value = data
            configureSubMenu()
            setActiveComponent(reloadComponentSlug)
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => {
            isFetchingBooking.value = false
        })
}

function setActiveComponent (menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

function configureSubMenu () {
    subMenus.value = [
        {
            title: 'Overview',
            component: defineAsyncComponent(
                () => import('./BookingOverview.vue')
            ),
            slug: 'booking-overview',
            props: { booking: booking.value },
        },
        {
            title: 'Sessions',
            component: defineAsyncComponent(() => import('./BookingJob.vue')),
            slug: 'booking-session',
            props: { booking: booking.value, jobs: booking.value.jobs },
        },
        {
            title: 'Invoices',
            component: defineAsyncComponent(
                () => import('./BookingInvoice.vue')
            ),
            slug: 'booking-invoice',
            props: { booking: booking.value, invoices: booking.value.invoices },
        },
        {
            title: 'Payments',
            component: defineAsyncComponent(
                () => import('./BookingPayment.vue')
            ),
            slug: 'booking-payment',
            props: {
                invoices: booking.value.invoices,
            },
        },
    ]
}
</script>