<template>
    <div class="text-center mb-4">
        <router-link :to="{ name: 'login-page' }" class="navbar-brand navbar-brand-autodark"><img src="/kiddocare-logo.png" height="36" alt=""></router-link>
    </div>
    <form class="card card-md" @submit.prevent="resetPassword">
        <div class="card-body">
            <div v-if="! isPasswordResetSuccessful">
                <h2 class="card-title text-center mb-4">Reset password</h2>
                <p class="text-muted text-center mb-4">Enter your new password to reset your password.</p>
                <div class="mb-2">
                    <label class="form-label">Password</label>
                    <div class="input-group input-group-flat">
                        <input type="password" class="form-control" autocomplete="off" ref="inputPassword" :disabled="isResetting" @focus="clearError">
                        <span class="input-group-text cursor-pointer">
                            <a class="link-secondary" title="Show password" data-bs-toggle="tooltip" @click="revealPassword">
                                <icon v-if="isPasswordRevealed" name="eye" />
                                <icon v-else name="eye-off" />
                            </a>
                        </span>
                    </div>
                </div>
                <div class="mb-2">
                    <label class="form-label">Password Confirmation</label>
                    <div class="input-group input-group-flat">
                        <input type="password" class="form-control" autocomplete="off" ref="inputPasswordConfirmation" :disabled="isResetting" @focus="clearError">
                        <span class="input-group-text cursor-pointer">
                            <a class="link-secondary" title="Show password" data-bs-toggle="tooltip" @click="revealPassword">
                                <icon v-if="isPasswordRevealed" name="eye" />
                                <icon v-else name="eye-off" />
                            </a>
                        </span>
                    </div>
                </div>
                <div class="form-footer">
                    <button type="submit" class="btn btn-primary w-100" :disabled="isResetting">
                        <div v-if="! isResetting">Reset Password</div>
                        <div class="d-flex align-self-center mx-auto" v-else>
                            <div class="spinner-border"></div>
                        </div>
                    </button>
                    <div class="d-flex justify-content-center mt-2">
                        <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="text-center">Your password has been successfully reset.</p>
                <p class="text-center"><router-link :to="{ name: 'login-page' }">Click here to login</router-link></p>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import AuthService from '@/services/AuthService'

const route = useRoute()
const isResetting = ref<boolean>(false)
const isPasswordRevealed = ref<boolean>(false)
const isPasswordResetSuccessful = ref<boolean>(false)
const inputPassword = ref<any>(null)
const inputPasswordConfirmation = ref<any>(null)
const errorMessage = ref<string>('')

function revealPassword() {
    isPasswordRevealed.value = ! isPasswordRevealed.value

    if (isPasswordRevealed.value) {
        inputPassword.value.type = 'text'
        inputPasswordConfirmation.value.type = 'text'
    } else {
        inputPassword.value.type = 'password'
        inputPasswordConfirmation.value.type = 'password'
    }
}

function resetPassword() {
    clearError()

    const password = inputPassword.value.value
    const passwordConfirmation = inputPasswordConfirmation.value.value

    if (password === '' && passwordConfirmation === '') {
        return
    }

    if (password !== passwordConfirmation) {
        errorMessage.value = 'The password confirmation does not match.'
        return
    }

    if (password.length < 8) {
        errorMessage.value = 'The password must be at least 8 characters.'
        return
    }

    isResetting.value = true

    const inputData = {
        password: password,
        password_confirmation: passwordConfirmation,
        email: route.query.email,
        token: route.query.token,
    }

    AuthService.resetPassword(inputData)
        .then(({ data: { status, message }}) => {
            if (status === 'success') {
                isPasswordResetSuccessful.value = true
            } else {
                errorMessage.value = message
            }
        }).catch(({ response: { data } }) => {
            if (data.hasOwnProperty('errors')) {
                errorMessage.value = data.errors.password[0]
            } else {
                errorMessage.value = data.message
            }
        }).finally(() => {
            isResetting.value = false
        })
}

function clearError() {
    errorMessage.value = ''
}
</script>
