import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'
import { getQueryString } from '@/composable/http'

const endpoint = '/backoffice/users/carers'

export default {
    index(query: UserQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    store(data: object = {}) {
        return httpClientV2.post(endpoint, data)
    },
    
    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },

    update(userId: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${userId}`, data)
    },

    updateRole(userId: number) {
        return httpClientV2.patch(`${endpoint}/${userId}/switch-role`)
    },

    updateState(userId: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${userId}/state`, data)
    },

    updateAvatar(userId: number, data: object = {}) {
        return httpClientV2.post(`${endpoint}/${userId}/avatar`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    },

    getJobs(userId: number, query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}/${userId}/jobs?${queryString}`)
    },

    getAppliedJobs(userId: number, query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}/${userId}/jobs/applied?${queryString}`)
    },

    getPayouts(userId: number, query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}/${userId}/payouts?${queryString}`)
    },

    getReviews(userId: number, query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}/${userId}/reviews?${queryString}`)
    },

    getStats(userId: number) {
        return httpClientV2.get(`${endpoint}/${userId}/stats`)
    },

    postQuizRetake(userId: number) {
        return httpClientV2.patch(`${endpoint}/${userId}/assessments/retake-quiz`)
    },

    getAppliedSessions(query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`backoffice/applied/session/carer?${queryString}`)
    },

    generateReport(data: object = {}) {
        return httpClientV2.post(`${endpoint}/report`, data)
    },

    exportMVD(data: object = {}) {
        return httpClientV2.post(`${endpoint}/export-mvd`, data)
    },
}