import httpClientV2 from '@/services/HttpClientV2'

export default {
    store(data: object = []) {
        return httpClientV2.post('/backoffice/organizations/users', data);
    },    

    show(id: number) {
        return httpClientV2.get(`/backoffice/organizations/users/${id}`);
    },
    
    destroy(id: number, id2: number) {
        return httpClientV2.post(`/backoffice/organizations/users/${id}/${id2}`);
    },

    allUser() {
        return httpClientV2.get(`/backoffice/organizations/users/allUser`);
    },
}
