export enum SessionStatusEnum {
    ID_POSTED = 1,
    ID_CHECK_CLIENT = 2,
    ID_CHECK_CARER = 3,
    ID_CARER_APPROVED = 4,
    ID_PAID = 5,
    ID_IN_SESSION = 6,
    ID_COMPLETE = 7,
    ID_COMPLETE_REVIEW = 8,
    ID_CARER_CANCELLED = 9,
    ID_CUSTOMER_CANCELLED = 10,
    ID_ADMIN_CANCELLED = 11,
    ID_AUTO_CANCELLED = 12,
    ID_REPOSTED = 13
}
