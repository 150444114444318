import { getQueryString } from '@/composable/http'
import httpClient from '@/services/HttpClient'
import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'

export default {
    index(query: UserQuery = {}) {
        return httpClientV2.get(`backoffice/users?${getQueryString(query)}`)
    },

    show(id: number) {
        if (id === undefined) {
            return {data: {}}
        }
        return httpClientV2.get(`backoffice/users/${id}`)
    },

    updateBan(id: number, data: object = {}) {
        return httpClient.post(`admin/users/${id}/ban`, data)
    },

    deactivate(id: number, data: object = {}) {
        return httpClient.post(`admin/users/${id}/deactivate`, data)
    }
}
