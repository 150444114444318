import { toMYR } from '@/composable/useCurrency'
import { VoucherExtraAttributes } from '@/types/Voucher'

export const getDiscountAmountDisplay = (discountAmount: number, isFixedDiscount: boolean): string => {
    return isFixedDiscount ? toMYR(discountAmount) : `${discountAmount}%`
}

export const getUsageDisplay = (extraAttributes: VoucherExtraAttributes): string => {
    const usesCount = extraAttributes.ordersCount
    const maxUses = extraAttributes.maxUses ?? '\u221E' // Infinity symbol

    return `${usesCount} / ${maxUses}`
}
