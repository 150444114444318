<template>
    <div class="dropdown position-static">
        <button type="button" class="btn dropdown-toggle text-muted"
                data-bs-toggle="dropdown">
            <icon name="dots" class="text-secondary"/>
        </button>
        <div class="dropdown-menu dropdown-menu-end" style="width: 164px;">
            <a class="dropdown-item text-primary" role="button"
               @click.prevent="acceptChanges"
            >
                <icon name="check" class="me-2 flex-shrink-0"/>
                Accept Changes
            </a>
            <a class="dropdown-item text-danger" role="button"
               @click.prevent="rejectChanges"
            >
                <icon name="x" class="me-2 flex-shrink-0"/>
                Decline Changes
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">

import {useNotificationsStore} from '@/stores/notifications'
import CarerApplicationChangeApprovalService from '@/services/CarerApplicationChangeApprovalService'

const {addToastNotification} = useNotificationsStore()

const props = defineProps({
    applicationId: {
        type: Number,
        required: true
    },
    carer: {
        type: Object
    }
})

const emits = defineEmits(['onSuccess'])

const acceptChanges = async () => {
    try {
        await CarerApplicationChangeApprovalService.store(props.applicationId)

        emits('onSuccess')
        addToastNotification({
            message: `Successfully accepted changes for Carer ${props.carer?.name}.`,
            type: 'success'
        })
    } catch (err) {
        addToastNotification({
            message: `Error while accepting changes for Carer ${props.carer?.name}`,
            type: 'danger'
        })
    }
}

const rejectChanges = async () => {
    try {
        await CarerApplicationChangeApprovalService.destroy(props.applicationId)
        emits('onSuccess')
        addToastNotification({
            message: `Rejected changes for Carer ${props.carer?.name}.`,
            type: 'success'
        })
    } catch (err) {
        addToastNotification({
            message: `Error while rejecting changes for Carer ${props.carer?.name}`,
            type: 'danger'
        })
    }
}
</script>
