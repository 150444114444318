<template>
    <div class="card">
        <form @submit.prevent="saveHoliday">
            <div class="card-body">
                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">Description</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('description')}" type="text" v-model="inputUser.description" @focus="clearError('description')">
                        <small class="invalid-feedback" v-if="hasError('description')">{{ getError('description') }}</small>
                    </div>
                </div>
                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">Holiday Date</label>
                    <div class="col">
                        <input class="form-control" :class="{'is-invalid': hasError('holiday_date')}" type="date" v-model="inputUser.holiday_date" @focus="clearError('holiday_date')">
                        <small class="invalid-feedback" v-if="hasError('holiday_date')">{{ getError('holiday_date') }}</small>
                    </div>
                </div>
                <div class="form-group mb-4 row">
                    <label class="form-label col-3 col-form-label">State</label>
                    <div class="col">
                        <div v-if="mode === 'create'" class="form-check">
                            <input class="form-check-input" type="checkbox" id="selectAllOption" v-model="selectAll">
                            <label class="form-check-label" for="selectAllOption">
                                Select All
                            </label>
                        </div>
                        <vue-select
                            :multiple="mode === 'create'"
                            :class="{'is-invalid': hasError('state_ids')}"
                            :options="[ ...addressStates ]"
                            label="name"
                            :reduce="(state: AddressState) => state.id"
                            v-model="inputUser.state_ids"
                            :disabled="selectAll"
                        />
                        <small class="invalid-feedback" v-if="hasError('state_ids')">{{ getError('state_ids') }}</small>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex space-x-2">
                    <button class="btn btn-light" @click.prevent="$router.go(-1)">Cancel</button>
                    <button class="btn btn-primary" type="submit">Save Holiday</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { ref, watch } from 'vue'
import { useResourcesStore } from '@/stores/resources'
import { useRouter, useRoute } from 'vue-router'
import UserInput from '@/types/Input'
import AddressState from '@/types/AddressState'
import HolidayService from '@/services/settings/HolidayService'
import { useNotificationsStore } from '@/stores/notifications'
import Holiday from '@/types/Holiday'
import { rules as HolidayRule, $externalResults } from '@/rules/HolidayRule'
import useFormError from '@/composable/useFormError'
import { storeToRefs } from 'pinia'

const inputUser = ref<UserInput>({
    description: '',
    holiday_date: '',
    state_id: '',
    state_ids: [],
})
const selectAll = ref<boolean>(false)
const router = useRouter()
const route = useRoute()
const { addressStates } = storeToRefs(useResourcesStore())
const { addToastNotification } = useNotificationsStore()
const props = defineProps({
    holiday: Object as () => Holiday,
    id: { type: Number, default: 0 },
})
const v$ = useVuelidate(HolidayRule, { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)
const mode = route.params.holidayId ? 'update' : 'create'

watch(
    () => props.holiday,
    (holiday) => {
        if (holiday !== undefined) {
            inputUser.value.description = holiday.description
            inputUser.value.holiday_date = holiday.date
            inputUser.value.state_ids = holiday.state.id
        }
    }
)

watch(
    () => selectAll.value,
    (selectAll) => {
        if (selectAll) {
            inputUser.value.state_ids = addressStates.value.map((state: AddressState) => state.id)
        } else {
            inputUser.value.state_ids = []
        }
    }
)

async function saveHoliday() {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {

        if (mode === 'update') {
            inputUser.value.state_id = inputUser.value.state_ids
        }        

        const holidayService =
            mode === 'create'
                ? HolidayService.store(inputUser.value)
                : HolidayService.update(props.id, inputUser.value)

        holidayService
            .then(() => {
                addToastNotification({
                    message: `Holiday ${inputUser.value.description} ${mode}d`,
                    type: 'success',
                })
                router.push({ name: 'holiday-index' })
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}
</script>