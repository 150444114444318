<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex mb-3">
                <div class="ms-auto">
                    <router-link class="btn btn-primary" :to="{ name: 'promotion-create' }">
                        <icon name="plus"/>
                        Add New Promotion
                    </router-link>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Promotion Name</label>
                    <input type="text" class="form-control" v-model="inputFilter.name">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Code Prefix</label>
                    <input type="text" class="form-control" v-model="inputFilter.code_prefix">
                </div>
                <div class="col-12 col-md">
                    <label for="startDate" class="form-label">Start Date</label>
                    <div class="input-group">
                        <input type="text" id="startDate" class="form-control bg-white" :value="startDate" readonly>
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md">
                    <label for="endDate" class="form-label">End Date</label>
                    <div class="input-group">
                        <input type="text" id="endDate" class="form-control bg-white" :value="endDate" readonly>
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchPromotions"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true"></table-pagination>
            <div class="table-responsive">
                <table class="table table-vcenter">
                    <thead>
                        <tr>
                            <th>Promotion Name</th>
                            <th>Code Prefix</th>
                            <th>Description</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th class="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="promotions.length > 0">
                        <tr v-for="promotion in promotions" :key="promotion.id">
                            <td>{{ promotion.name }}</td>
                            <td>{{ promotion.codePrefix }}</td>
                            <td>{{ promotion.description }}</td>
                            <td>{{ formatDate(promotion.startDate, 'DD-MMM-YYYY') }}</td>
                            <td>{{ formatDate(promotion.endDate, 'DD-MMM-YYYY') }}</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-primary" :to="{ name: 'promotion-show', params: { promotionId: promotion.id } }">
                                        <icon name="eye"/>
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text-center">
                            <td colspan="6">No data</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage"></table-pagination>
        </div>
        <overlay-spinner :is-showing="isFetching"></overlay-spinner>
    </div>
</template>

<script setup lang="ts">
import TablePagination from '@/components/TablePagination.vue'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import PromotionService from '@/services/settings/PromotionService'
import UserFilter from '@/types/Filter'
import Promotion from '@/types/Promotion'
import { AmpPlugin } from '@easepick/amp-plugin'
import { Core, easepick } from '@easepick/core'
import { computed, onMounted, ref, watch } from 'vue'

const isFetching = ref<boolean>(false)
const promotions = ref<Array<Promotion>>([])

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()

const inputFilter = ref<UserFilter>({
    name: '',
    code_prefix: '',
    start_date: '',
    end_date: '',
})

const startDate = computed(() => inputFilter.value.start_date ? formatDate(inputFilter.value.start_date, 'DD-MMM-YYYY') : '')
const endDate = computed(() => inputFilter.value.end_date ? formatDate(inputFilter.value.end_date, 'DD-MMM-YYYY') : '')

onMounted(() => {
    fetchPromotions()

    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 2020,
                maxYear: (new Date().getFullYear() + 1)
            }
        }
    }

    new easepick.create({
        element: <any>document.getElementById('startDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css'
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.start_date = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    new easepick.create({
        element: <any>document.getElementById('endDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css'
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.end_date = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })
})

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchPromotions()
}

const fetchPromotions = async () => {
    isFetching.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value
    }

    await PromotionService.index(query)
        .then(({ data: { data, meta } }) => {
            promotions.value = data
            updateMetaPage(meta)
        })
        .finally(() => isFetching.value = false)
}

watch(
    () => metaPageTriggered.value,
    () => fetchPromotions()
)
</script>