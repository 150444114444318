<template>

    <div class="mb-3 d-flex justify-content-start align-items-center gap-3 ">
        <div class="fw-bold">
            MODE:
        </div>
        <ToggleMode/>
    </div>

    <SelectCustomerSession/>
    <SessionPreview v-if="hasSelectedSessions  && orderPreview && mode === 'custom'" class="mt-3"/>
    <VoucherPointRedemption v-if="hasSelectedSessions" class="mt-3"/>
    <OrderPreview v-if="orderPreview" class="mt-3"/>
    <MarkOrderAsPrimary class="mt-3" v-if="mode === 'custom' && !!orderPreview"/>
    <OrderCreateFormErrorMessages />

    <div v-if="orderPreview"
         class="d-flex justify-content-end align-items-center mt-3 "
    >
        <button @click="handleSubmit" class="btn btn-primary"
        >
            <span v-if="!isSubmitting">Submit</span>
            <span v-else>Submitting...</span>
        </button>
    </div>

</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useModal} from '@/composable/useModal'
import {useResourcesStore} from '@/stores/resources'
import {useCreateOrderStore} from '@/stores/createOrder'
import OrderPreview from '@/pages/orders/create/components/OrderPreview.vue'
import SelectCustomerSession from '@/pages/orders/create/components/SelectCustomerSession.vue'
import VoucherPointRedemption from '@/pages/orders/create/components/RedeemVouchersAndPoints.vue'
import SessionPreview from '@/pages/orders/create/components/ReviewSessionDetails.vue'
import ToggleMode from '@/pages/orders/create/components/ToggleMode.vue'
import {onMounted} from 'vue'
import {useRouter} from 'vue-router'
import MarkOrderAsPrimary from '@/pages/orders/create/components/MarkOrderAsPrimary.vue'
import OrderCreateFormErrorMessages from '@/pages/orders/create/components/OrderCreateFormErrorMessages.vue'

const router = useRouter()

const {
    addressStates,
    postcodes,
    sessionTypes,
    sessionServiceTypes
} = storeToRefs(useResourcesStore())

const {
    modalId,
    openModal
} = useModal('create-sessions-for-new-order')

const createOrderStore = useCreateOrderStore()
const {
    submit,
    selectMode,
    resetAll
} = createOrderStore
const {
    mode,
    form,
    errors,
    hasSelectedSessions,
    orderPreview,
    selectedCustomer,
    isSubmitting
} = storeToRefs(createOrderStore)

onMounted(() => {
    selectMode('primary')
})

const handleSubmit = async () => {
    const {success, data} = await submit()

    if (success && data?.id) {
        await router.push({
            name: 'order-show',
            params: {
                orderId: data?.id
            }
        })
    }
}

</script>