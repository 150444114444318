import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const DEFAULT_FORMAT = 'DD-MMM-YYYY h:mm:ss A'
const DEFAULT_TIMEZONE = 'Asia/Kuala_Lumpur'

export default {
    today(format: string = DEFAULT_FORMAT) {
        return dayjs().format(format)
    },

    toLocale(date: string|number, format: string = DEFAULT_FORMAT) {
        return dayjs(date).tz(DEFAULT_TIMEZONE).format(format)
    },

    toAge(date: string|number) {
        let month: number = dayjs(dayjs().format('YYYY-MM-DD')).diff(date, 'months')
        return month > 12
            ? `${Math.floor(month / 12)} years old`
            : `${month} months`
    },

    format(date: string|number, format: string) {
        return dayjs(date).format(format)
    },

    utc(date: string|number, format: string) {
        return dayjs(date).utc().format(format)
    },

    object(date?: string) {
        return dayjs(date)
    },
}
