<template>
    <div class="d-flex flex-row">
        <form class="w-100">
            <div>
                <FormLabel label="Carer"/>
                <SearchSelectUser v-model="createApplicationForm.carer"
                    :user-role="RoleNameEnum.CARER"
                />
            </div>
            <div class="mt-4">
                <FormLabel label="Travelling Tips"/>
                <input type="number" min="0" max="50"
                       class=" form-control"
                       v-model.number="createApplicationForm.additional_fee"
                />
            </div>
            <div class="d-flex flex-row justify-content-end mt-4 space-x-1">
                <button class=" btn btn-secondary"
                        @click.prevent="backToMainScreen"
                >
                    Cancel
                </button>
                <button class="btn btn-info" :class="{ disabled: isSavingCarerApplication }"
                        @click.prevent="confirmCreateApplication"
                >
                    {{ isSavingCarerApplication ? 'Saving' : 'Save' }}
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import SearchSelectUser from '@/components/SearchSelectUser.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import { RoleNameEnum } from '@/enums/RoleEnum'
import { useSessionCarerApplicationStore } from '@/stores/sessionCarerApplication'
import { storeToRefs } from 'pinia'

const store = useSessionCarerApplicationStore()
const { createApplicationForm, isSavingCarerApplication } = storeToRefs(store)
const { backToMainScreen, confirmCreateApplication } = store

</script>
