<template>
    <overlay-spinner :is-showing="isFetchingStats" v-if="isFetchingStats" />
    <template v-else>
        <div class="row row-cards mb-4">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>New Booking Today</h4>
                                <h1 class="fw-normal mb-0">{{ stats.created.today }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="clock" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Urgent Jobs Today</h4>
                                <h1 class="fw-normal mb-0">{{ stats.urgent }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="users" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Bookings</h4>
                                <h1 class="fw-normal mb-0">{{ stats.total.count.toLocaleString() }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="users" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Total Invoiced Amount</h4>
                                <h1 class="fw-normal mb-0">RM {{ stats.total.invoiced.toLocaleString() }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="users" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>

    <div class="row row-deck row-cards mb-4">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <div class="btn-group">
                        <button class="btn" :class="[isActiveFilter('daily') ? 'btn-primary' : 'btn-default']" @click="fetchBookingData('daily')">Daily</button>
                        <button class="btn" :class="[isActiveFilter('weekly') ? 'btn-primary' : 'btn-default']" @click="fetchBookingData('weekly')">Weekly</button>
                        <button class="btn" :class="[isActiveFilter('monthly') ? 'btn-primary' : 'btn-default']" @click="fetchBookingData('monthly')">Monthly</button>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Total Bookings</th>
                                <th>Total Jobs</th>
                                <th>Grand Total</th>
                                <th>Total Paid</th>
                            </tr>
                        </thead>
                        <overlay-spinner :is-showing="isFetchingBooking" v-if="isFetchingBooking"></overlay-spinner>
                        <template v-else>
                            <tbody v-if="bookings.length">
                                <tr v-for="(booking, index) in bookings" :key="`booking-${index}`">
                                    <td>{{ booking.date }}</td>
                                    <td>{{ booking.total.toLocaleString() }}</td>
                                    <td>{{ booking.total_job.toLocaleString() }}</td>
                                    <td>RM {{ booking.grand_total.toLocaleString() }}</td>
                                    <td>RM {{ booking.total_paid.toLocaleString() }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5" class="text-center">No Data</td>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import DashboardService from '@/services/DashboardService'
import { useNotificationsStore } from '@/stores/notifications'
import OverlaySpinner from '@/components/OverlaySpinner.vue'

const { addToastNotification } = useNotificationsStore()
const stats = ref<object>({})
const isFetchingStats = ref<boolean>(false)
const isFetchingBooking = ref<boolean>(false)
const bookings = ref<Array<any>>([])
const activeFilter = ref<string>('daily')

onBeforeMount(() => {
    fetchStats()
    fetchBookingData('daily')
})

function fetchStats () {
    isFetchingStats.value = true

    DashboardService.getBookingsAndJobs()
        .then(({ data }) => {
            stats.value = data.stats
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => isFetchingStats.value = false)
}

function fetchBookingData (filterType: string = 'daily') {
    isFetchingBooking.value = true
    activeFilter.value = filterType

    const query = { filterType: filterType }

    DashboardService.getBookingData(query)
        .then(({ data }) => {
            bookings.value = Object.keys(data.bookings).map((key) => data.bookings[key]) // convert object to array
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => isFetchingBooking.value = false)
}

function isActiveFilter (filter: string) {
    return activeFilter.value === filter
}

</script>