import {createRouter, createWebHistory} from 'vue-router'
import AuthLayout from './layouts/AuthLayout.vue'
import DashboardLayout from './layouts/DashboardLayout.vue'
import LoginPage from './pages/auth/LoginPage.vue'
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage.vue'
import ResetPasswordPage from './pages/auth/ResetPasswordPage.vue'
import HomePage from './pages/HomePage.vue'
import ProfilePage from './pages/ProfilePage.vue'
import AdminIndex from './pages/users/admin/AdminIndex.vue'
import AdminCreate from './pages/users/admin/AdminCreate.vue'
import AdminEdit from './pages/users/admin/AdminEdit.vue'
import CustomerIndex from './pages/users/customers/CustomerIndex.vue'
import CustomerShow from './pages/users/customers/CustomerShow.vue'
import CarerIndex from './pages/users/carers/CarerIndex.vue'
import CarerCreate from './pages/users/carers/CarerCreate.vue'
import CarerEdit from './pages/users/carers/CarerEdit.vue'
import CarerShow from './pages/users/carers/CarerShow.vue'
import CarerPayoutIndex from './pages/payouts/CarerPayoutIndex.vue'
import CarerPayoutShow from './pages/payouts/CarerPayoutShow.vue'
import CarerPayoutRecipientShow from './pages/payouts/CarerPayoutRecipientShow.vue'
import BookingIndex from './pages/bookings/BookingIndex.vue'
import BookingCreate from './pages/bookings/BookingCreate.vue'
import BookingJobCreate from './pages/bookings/BookingJobCreate.vue'
import BookingShow from './pages/bookings/BookingShow.vue'
import SessionCreate from './pages/sessions/SessionCreate.vue'
import AuthService from './services/AuthService'
import RateLayout from './pages/settings/rates/RateLayout.vue'
import RateHourly from './pages/settings/rates/RateHourly.vue'
import RateExtras from './pages/settings/rates/RateExtras.vue'
import HolidayIndex from './pages/settings/holiday/HolidayIndex.vue'
import HolidayCreate from './pages/settings/holiday/HolidayCreate.vue'
import HolidayEdit from './pages/settings/holiday/HolidayEdit.vue'
import JobCreate from './pages/jobs/JobCreate.vue'
import JobEdit from './pages/jobs/JobEdit.vue'
import JobIndex from './pages/jobs/JobIndex.vue'
import JobShow from './pages/jobs/JobShow.vue'
import SessionIndex from '@/pages/sessions/SessionIndex.vue'
import SessionShow from '@/pages/sessions/show/SessionShow.vue'
import SessionEdit from './pages/sessions/SessionEdit.vue'
import ReportSessionReview from './pages/reports/ReportSessionReview.vue'
import ReportBooking from './pages/reports/ReportBooking.vue'
import ReportEvent from '@/pages/reports/ReportEvent.vue'
import ReportInvoice from './pages/reports/ReportInvoice.vue'
import ReportInvoiceWeekly from './pages/reports/ReportInvoiceWeekly.vue'
import ReportJob from './pages/reports/ReportJob.vue'
import ReportPayout from './pages/reports/ReportPayout.vue'
import ReportPayoutExtra from './pages/reports/ReportPayoutExtra.vue'
import ReportLoyaltyPointTransaction from '@/pages/reports/ReportLoyaltyPointTransaction.vue'
import OrderIndex from '@/pages/reports/order/OrderIndex.vue'
import OrderShow from '@/pages/reports/order/OrderShow.vue'
import OrderSessionCreate from '@/pages/reports/order/OrderSessionCreate.vue'
import TicketLayout from './pages/tickets/TicketLayout.vue'
import TicketShow from './pages/tickets/TicketShow.vue'
import DashboardBooking from './pages/dashboard/DashboardBooking.vue'
import DashboardMarketing from './pages/dashboard/DashboardMarketing.vue'
import DashboardFinancial from './pages/dashboard/DashboardFinancial.vue'
import DashboardJob from './pages/dashboard/DashboardJob.vue'
import DashboardJobOverview from './pages/dashboard/DashboardJobOverview.vue'
import DashboardJobCalendar from './pages/dashboard/DashboardJobCalendar.vue'
import BankIndex from '@/pages/settings/banks/BankIndex.vue'
import CouponIndex from './pages/settings/coupons/CouponIndex.vue'
import CouponCreate from './pages/settings/coupons/CouponCreate.vue'
import CouponEdit from './pages/settings/coupons/CouponEdit.vue'
import CouponShow from './pages/settings/coupons/CouponShow.vue'
import ContentTypeIndex from './pages/settings/content-types/ContentTypeIndex.vue'
import CustomerContentTypeIndex from './pages/settings/customer-dashboard/ContentTypeIndex.vue'
import VoucherIndex from './pages/settings/vouchers/VoucherIndex.vue'
import VoucherCreate from './pages/settings/vouchers/VoucherCreate.vue'
import VoucherShow from './pages/settings/vouchers/VoucherShow.vue'
import VoucherEdit from './pages/settings/vouchers/VoucherEdit.vue'
import VoucherBulkGenerate from './pages/settings/vouchers/VoucherBulkGenerate.vue'
import OrganizationIndex from './pages/settings/organizations/OrganizationIndex.vue'
import OrganizationCreate from './pages/settings/organizations/OrganizationCreate.vue'
import OrganizationEdit from './pages/settings/organizations/OrganizationEdit.vue'
import OrganizationShow from './pages/settings/organizations/OrganizationShow.vue'
import PostcodeIndex from './pages/settings/postcode/PostcodeIndex.vue'
import PromotionCreate from '@/pages/settings/promotions/PromotionCreate.vue'
import PromotionEdit from '@/pages/settings/promotions/PromotionEdit.vue'
import PromotionIndex from '@/pages/settings/promotions/PromotionIndex.vue'
import PromotionShow from './pages/settings/promotions/PromotionShow.vue'
import ScriptIndex from './pages/scripts/ScriptIndex.vue'
import ReviewQuestionIndex from './pages/settings/ReviewQuestionIndex.vue'
import AnnouncementIndex from './pages/announcements/AnnouncementIndex.vue'
import AnnouncementCreate from './pages/announcements/AnnouncementCreate.vue'
import AnnouncementEdit from '@/pages/announcements/AnnouncementEdit.vue'
import ChatIndex from '@/pages/chats/ChatIndex.vue'
import ChatAdminIndex from './pages/settings/chat-admins/ChatAdminIndex.vue'
import {ChatChannelDepartment} from "@/enums/ChatChannelDepartment";
import OrderCreate from "@/pages/orders/create/OrderCreate.vue";
import SessionSettingsIndex from '@/pages/settings/sessions/SessionSettingsIndex.vue'

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return {el: to.hash, behavior: 'smooth'}
        } else {
            window.scrollTo(0, 0)
        }
    },
    routes: [
        {
            path: '/auth',
            name: 'auth-layout',
            component: AuthLayout,
            children: [
                {
                    path: '/auth/login',
                    name: 'login-page',
                    component: LoginPage,
                    meta: {
                        title: 'Login'
                    }
                },
                {
                    path: '/auth/forgot-password',
                    name: 'forgot-password-page',
                    component: ForgotPasswordPage,
                    meta: {
                        title: 'Forgot Password'
                    }
                },
                {
                    path: '/auth/reset-password',
                    name: 'reset-password-page',
                    component: ResetPasswordPage,
                    meta: {
                        title: 'Reset Password'
                    }
                }
            ]
        },
        {
            path: '/',
            name: 'dashboard-layout',
            component: DashboardLayout,
            children: [
                {
                    path: '/',
                    name: 'home-page',
                    component: HomePage,
                    meta: {
                        title: 'Kiddocare Dashboard'
                    }
                },
                {
                    path: '/dashboard/booking',
                    name: 'dashboard-booking',
                    component: DashboardBooking,
                    meta: {
                        title: 'Bookings & Sessions Stats'
                    }
                },
                {
                    path: '/dashboard/job',
                    name: 'dashboard-job',
                    component: DashboardJob,
                    meta: {
                        title: 'Sessions Dashboard'
                    }
                },
                {
                    path: '/dashboard/job-overview',
                    name: 'dashboard-job-overview',
                    component: DashboardJobOverview,
                    meta: {
                        title: 'Session Overview Dashboard'
                    }
                },
                {
                    path: '/dashboard/job-calendar',
                    name: 'dashboard-job-calendar',
                    component: DashboardJobCalendar,
                    meta: {
                        title: 'Session Calendar'
                    }
                },
                {
                    path: '/dashboard/marketing',
                    name: 'dashboard-marketing',
                    component: DashboardMarketing,
                    meta: {
                        title: 'Marketing Dashboard'
                    }
                },
                {
                    path: '/dashboard/financial',
                    name: 'dashboard-financial',
                    component: DashboardFinancial,
                    meta: {
                        title: 'Financial Dashboard'
                    }
                },
                {
                    path: '/profile',
                    name: 'profile-page',
                    component: ProfilePage,
                    meta: {
                        title: 'Profile'
                    }
                },
                {
                    path: '/bookings',
                    name: 'booking-index',
                    component: BookingIndex,
                    meta: {
                        title: 'List of Bookings'
                    }
                },
                {
                    path: '/bookings/create',
                    name: 'booking-create',
                    component: BookingCreate,
                    meta: {
                        title: 'Add New Booking'
                    }
                },
                {
                    path: '/bookings/:bookingId',
                    name: 'booking-show',
                    component: BookingShow
                },
                {
                    path: '/bookings/:bookingId/jobs/create',
                    name: 'booking-job-create',
                    component: BookingJobCreate
                },
                {
                    path: '/jobs',
                    name: 'job-index',
                    component: JobIndex,
                    meta: {
                        title: 'List of Session'
                    }
                },
                {
                    path: '/jobs/create',
                    name: 'job-create',
                    component: JobCreate
                },
                {
                    path: '/jobs/:jobId/edit',
                    name: 'job-edit',
                    component: JobEdit
                },
                {
                    path: '/jobs/:jobId',
                    name: 'job-show',
                    component: JobShow
                },
                {
                    path: '/sessions',
                    name: 'session-index',
                    component: SessionIndex,
                    meta: {
                        title: 'List of Session'
                    }
                },
                {
                    path: '/sessions/:sessionId',
                    name: 'session-show',
                    component: SessionShow
                },
                {
                    path: '/sessions/:sessionId',
                    name: 'session-edit',
                    component: SessionEdit
                },
                {
                    path: '/sessions/create',
                    name: 'session-create',
                    component: SessionCreate,
                    meta: {
                        title: 'Add New Session'
                    }
                },
                {
                    path: '/users/admin',
                    name: 'admin-index',
                    component: AdminIndex,
                    meta: {
                        title: 'List of Admin'
                    }
                },
                {
                    path: '/users/admin/create',
                    name: 'admin-create',
                    component: AdminCreate,
                    meta: {
                        title: 'Create Admin'
                    }
                },
                {
                    path: '/users/admin/:id/edit',
                    name: 'admin-edit',
                    component: AdminEdit,
                    meta: {
                        title: 'Edit Admin'
                    }
                },
                {
                    path: '/users/customers',
                    name: 'customer-index',
                    component: CustomerIndex,
                    meta: {
                        title: 'List of Customers'
                    }
                },
                {
                    path: '/users/customer/:userId',
                    name: 'customer-show',
                    component: CustomerShow
                },
                {
                    path: '/scripts',
                    name: 'script-index',
                    component: ScriptIndex,
                    meta: {
                        title: 'Script Template'
                    }
                },
                {
                    path: '/users/carer',
                    name: 'carer-index',
                    component: CarerIndex,
                    meta: {
                        title: 'List of Carers'
                    }
                },
                {
                    path: '/users/carer/create',
                    name: 'carer-create',
                    component: CarerCreate,
                    meta: {
                        title: 'Create Carer'
                    }
                },
                {
                    path: '/users/carer/:userId/edit',
                    name: 'carer-edit',
                    component: CarerEdit,
                    meta: {
                        title: 'Edit Carer'
                    }
                },
                {
                    path: '/users/carer/:userId',
                    name: 'carer-show',
                    component: CarerShow
                },
                {
                    path: '/reports/invoices',
                    name: 'report-invoice',
                    component: ReportInvoice,
                    meta: {
                        title: 'Invoice Report'
                    }
                },
                {
                    path: '/reports/reviews',
                    name: 'report-review',
                    component: ReportSessionReview,
                    meta: {
                        title: 'Reviews'
                    }
                },
                {
                    path: '/reports/bookings',
                    name: 'report-booking',
                    component: ReportBooking,
                    meta: {
                        title: 'Booking Report'
                    }
                },
                {
                    path: '/reports/invoice-weekly',
                    name: 'report-invoice-weekly',
                    component: ReportInvoiceWeekly,
                    meta: {
                        title: 'Invoice Weekly Report'
                    }
                },
                {
                    path: '/reports/jobs',
                    name: 'report-job',
                    component: ReportJob,
                    meta: {
                        title: 'Sessions Report'
                    }
                },
                {
                    path: '/reports/events',
                    name: 'report-event',
                    component: ReportEvent,
                    meta: {
                        title: 'Events'
                    }
                },
                {
                    path: '/reports/payouts',
                    name: 'report-payout',
                    component: ReportPayout,
                    meta: {
                        title: 'Payout Report'
                    }
                },
                {
                    path: '/reports/payouts-extra',
                    name: 'report-payout-extra',
                    component: ReportPayoutExtra,
                    meta: {
                        title: 'Payout Extra Report'
                    }
                },
                {
                    path: '/loyalty-points',
                    name: 'loyalty-points-index',
                    component: ReportLoyaltyPointTransaction,
                    meta: {
                        title: 'Loyalty Points Transaction'
                    }
                },
                {
                    path: '/orders',
                    name: 'orders-index',
                    component: OrderIndex,
                    meta: {
                        title: 'Orders Transaction'
                    }
                },
                {
                    path: '/orders/:orderId',
                    name: 'order-show',
                    component: OrderShow
                },
                {
                    path: '/orders/create',
                    name: 'order-create',
                    component: OrderCreate,
                    meta: {
                        title: 'Add New Order'
                    }
                },
                {
                    path: '/orders/:orderId/sessions/create',
                    name: 'order-session-create',
                    component: OrderSessionCreate,
                },
                {
                    path: '/payouts',
                    name: 'payout-index',
                    component: CarerPayoutIndex,
                    meta: {
                        title: 'Payouts'
                    }
                },
                {
                    path: '/payouts/:payoutId',
                    name: 'payout-show',
                    component: CarerPayoutShow,
                    meta: {
                        title: 'Payout Details'
                    }
                },
                {
                    path: '/payouts/:carerPayoutId/recipients/:userId',
                    name: 'payout-recipient',
                    component: CarerPayoutRecipientShow,
                    meta: {
                        title: 'Payout Recipient Details'
                    }
                },
                {
                    path: '/settings/content-types',
                    name: 'settings-content-types',
                    component: ContentTypeIndex,
                    meta: {
                        title: 'Content Types'
                    }
                },
                {
                    path: '/settings/customer-dashboard',
                    name: 'customer-dashboard-index',
                    component: CustomerContentTypeIndex
                },
                {
                    path: '/settings/session-settings',
                    name: 'settings-session-index',
                    component: SessionSettingsIndex,
                    meta: {
                        title: 'Session Settings'
                    },
                },
                {
                    path: '/settings/rates',
                    name: 'rates',
                    component: RateLayout,
                    redirect: {name: 'rate-hourly'},
                    meta: {
                        title: 'Rates'
                    },
                    children: [
                        {
                            path: 'hourly',
                            name: 'rate-hourly',
                            component: RateHourly
                        },
                        {
                            path: 'extras',
                            name: 'rate-extras',
                            component: RateExtras
                        }
                    ]
                },
                {
                    path: '/settings/holidays',
                    name: 'holiday-index',
                    component: HolidayIndex,
                    meta: {
                        title: 'Holidays'
                    }
                },
                {
                    path: '/settings/holidays/create',
                    name: 'holiday-create',
                    component: HolidayCreate,
                    meta: {
                        title: 'Add New Holiday'
                    }
                },
                {
                    path: '/settings/holidays/:holidayId/edit',
                    name: 'holiday-edit',
                    component: HolidayEdit,
                    meta: {
                        title: 'Edit Holiday'
                    }
                },
                {
                    path: '/settings/coupons',
                    name: 'coupon-index',
                    component: CouponIndex,
                    meta: {
                        title: 'Voucher & Coupon'
                    }
                },
                {
                    path: '/settings/coupons/create',
                    name: 'coupon-create',
                    component: CouponCreate,
                    meta: {
                        title: 'Create Coupon'
                    }
                },
                {
                    path: '/settings/coupons/:couponId/edit',
                    name: 'coupon-edit',
                    component: CouponEdit,
                    meta: {
                        title: 'Edit Coupon'
                    }
                },
                {
                    path: '/settings/coupons/:couponId',
                    name: 'coupon-show',
                    component: CouponShow
                },
                {
                    path: '/settings/vouchers',
                    name: 'voucher-index',
                    component: VoucherIndex,
                    meta: {
                        title: 'Voucher & Coupon'
                    }
                },
                {
                    path: '/settings/vouchers/create',
                    name: 'voucher-create',
                    component: VoucherCreate,
                    meta: {
                        title: 'Create Voucher'
                    }
                },
                {
                    path: '/settings/vouchers/:voucherId',
                    name: 'voucher-show',
                    component: VoucherShow
                },
                {
                    path: '/settings/vouchers/:voucherId/edit',
                    name: 'voucher-edit',
                    component: VoucherEdit
                },
                {
                    path: '/settings/vouchers/bulk-generate',
                    name: 'voucher-bulk-generate',
                    component: VoucherBulkGenerate,
                    meta: {
                        title: 'Bulk Generate Voucher'
                    }
                },
                {
                    path: '/settings/organizations',
                    name: 'organization-index',
                    component: OrganizationIndex,
                    meta: {
                        title: 'Organization'
                    }
                },
                {
                    path: '/settings/organizations/create',
                    name: 'organization-create',
                    component: OrganizationCreate,
                    meta: {
                        title: 'Create Organization'
                    }
                },
                {
                    path: '/settings/organizations/:organizationId/edit',
                    name: 'organization-edit',
                    component: OrganizationEdit,
                    meta: {
                        title: 'Edit Organization'
                    }
                },
                {
                    path: '/settings/organizations/:organizationId',
                    name: 'organization-show',
                    component: OrganizationShow
                },
                {
                    path: '/settings/postcodes',
                    name: 'postcode-index',
                    component: PostcodeIndex,
                    meta: {
                        title: 'Postcodes'
                    },
                },
                {
                    path: '/settings/promotions',
                    name: 'promotion-index',
                    component: PromotionIndex,
                    meta: {
                        title: 'Promotions'
                    },
                },
                {
                    path: '/settings/promotions/create',
                    name: 'promotion-create',
                    component: PromotionCreate,
                    meta: {
                        title: 'Create Promotion'
                    },
                },
                {
                    path: '/settings/promotions/:promotionId',
                    name: 'promotion-show',
                    component: PromotionShow,
                },
                {
                    path: '/settings/promotions/:promotionId/edit',
                    name: 'promotion-edit',
                    component: PromotionEdit,
                    meta: {
                        title: 'Edit promotion'
                    }
                },
                {
                    path: '/settings/reviews/',
                    name: 'review-index',
                    component: ReviewQuestionIndex,
                    meta: {
                        title: 'Review Question'
                    }
                },
                {
                    path: '/settings/chat-admins',
                    name: 'chat-admin-index',
                    component: ChatAdminIndex,
                    meta: {
                        title: 'Chat Admins'
                    }
                },
                {
                    path: '/settings/banks',
                    name: 'bank-index',
                    component: BankIndex,
                    meta: {
                        title: 'Banks'
                    }
                },
                {
                    path: '/tickets',
                    name: 'ticket-index',
                    component: TicketLayout,
                    meta: {
                        title: 'Tickets'
                    }
                },
                {
                    path: '/tickets/:ticketId',
                    name: 'ticket-show',
                    component: TicketShow
                },
                {
                    path: '/announcements/',
                    name: 'announcement-index',
                    component: AnnouncementIndex,
                    meta: {
                        title: 'List of Announcement'
                    }
                },
                {
                    path: '/announcements/create',
                    name: 'announcement-create',
                    component: AnnouncementCreate,
                    meta: {
                        title: 'Create Announcement'
                    }
                },
                {
                    path: '/announcements/edit/:announcementId',
                    name: 'announcement-edit',
                    component: AnnouncementEdit,
                    meta: {
                        title: 'Edit Announcement'
                    }
                },
                {
                    path: '/chats/:department?',
                    name: 'chat-index',
                    component: ChatIndex,
                    beforeEnter: (to, from, next) => {
                        const department = to.params.department;

                        const validDepartment = department
                            && department !== ChatChannelDepartment.RECRUITMENT
                            && department !== ChatChannelDepartment.SUPPORT

                        if (validDepartment) {
                            next({path: '/chats'});
                        } else {
                            next();
                        }
                    }
                }
            ]
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(match => match.name === 'dashboard-layout')) {
        AuthService.ping()
            .then(() => {
                next()
            }).catch(() => {
            next({name: 'login-page'})
        })
    } else {
        next()
    }
})

export default router
