import {ref, computed} from 'vue'
import ErrorBag from '@/types/ErrorBag'
import {required, helpers, minLength, maxLength, minValue} from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{}
})

export const rules = () => {
    const validateDiscountAmount = () =>
        helpers.withParams(
            {type: 'validateDiscountAmount'},
            (value: number, siblings) => {
                let validated

                if (siblings.is_fixed_discount === true) {
                    const minValue = 1
                    validated = value >= minValue
                } else {
                    const minValue = 1
                    const maxValue = 100
                    validated = (value >= minValue && value <= maxValue)
                }
                return validated
            }
        )

    return computed(() => {
        return {
            inputUser: {
                code_prefix: {
                     required: helpers.withMessage(
                         'This prefix field is required',
                         required
                     ),
                     minLengthValue: helpers.withMessage(
                         ({$params}) => `This prefix field requires a min length of ${$params.min}`,
                         minLength(1)
                     ),
                     maxLengthValue: helpers.withMessage(
                         ({$params}) => `This prefix field requires a max length of ${$params.max}`,
                         maxLength(5)
                     )
                },
                name: {
                     required: helpers.withMessage(
                         'This name field is required',
                         required
                     )
                },
                quantity: {
                     required: helpers.withMessage(
                         'This quantity field is required',
                         required
                     ),
                     minValueValue: helpers.withMessage(
                         ({$params}) => `This prefix field requires a min length of ${$params.min}`,
                         minValue(1)
                     )
                },
                discount_amount: {
                     required: helpers.withMessage(
                         'This discount amount field is required',
                         required
                     ),
                     validateDiscountAmount: helpers.withMessage(
                         'This discount amount is invalid.',
                         validateDiscountAmount()
                     )
                },
                is_fixed_discount: {
                    required
                }
            }
        }
    })
}
