import httpClientV2 from '@/services/HttpClientV2'

const endpoint = '/backoffice/review-questions'

export default {
    index () {
        return httpClientV2.get(endpoint)
    },

    store (data: object = {}) {
        return httpClientV2.post(endpoint, data)
    },
}