import httpClientV2 from './HttpClientV2'
import DestroySessionCarerApplicationFormData from "@/types/formData/DestroySessionCarerApplicationFormData";
import StoreSessionCarerApplicationFormData from "@/types/formData/StoreSessionCarerApplicationFormData";
import UpdateSessionCarerApplicationFormData from '@/types/formData/UpdateSessionCarerApplicationFormData'

export default {
    index(sessionId: number) {
        return httpClientV2.get(`/backoffice/sessions/${sessionId}/carer-applications`)
    },
    store(sessionId: number, data: StoreSessionCarerApplicationFormData) {
        return httpClientV2.post(`/backoffice/sessions/${sessionId}/carer-applications`, data)
    },
    update(carerApplicationId: number, data: UpdateSessionCarerApplicationFormData){
        return httpClientV2.patch(`/backoffice/carer-applications/${carerApplicationId}`, data)
    },
    destroy(carerApplicationId: number, data: DestroySessionCarerApplicationFormData = {}) {
        return httpClientV2.delete(`/backoffice/carer-applications/${carerApplicationId}`, {data})
    }
}
