<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Status</label>
                    <vue-select :options="['all', 'due', 'paid']" class="text-capitalize" v-model="inputFilter.status" />
                </div>

                <div class="col-12 col-md-3">
                    <label class="form-label">Date Range</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range" readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchInvoices" :is-loading="isLoading"></button-filter>
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button class="btn btn-primary" @click="generateReport" :disabled="isLoading" >
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        <icon v-else name="file-spreadsheet" />
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Invoice No</th>
                            <th>Booking No</th>
                            <th>Customer</th>
                            <th>Online Id</th>
                            <th>Job No</th>
                            <th>Job Status</th>
                            <th>Assigned Carer</th>
                            <th>Online Id</th>
                            <th class="border-start">Total Amount</th>
                            <th v-for="invoiceItemType in invoiceItemTypes" :key="invoiceItemType.id" class="border-start">{{ invoiceItemType.name }}</th>
                        </tr>
                    </thead>
                    <tbody v-if="invoices.length > 0">
                        <template v-for="(invoice, index) in invoices" :key="invoice.id">
                            <tr class="bg-cyan-lt text-dark">
                                <td>{{ index + 1 }}</td>
                                <td>{{ date.toLocale(invoice.createdAt, 'YYYY-MM-DD hh:mm A') }}</td>
                                <td>{{ invoice.no }}</td>
                                <td>{{ invoice.booking.no }}</td>
                                <td>{{ invoice.booking.user.fullName }}</td>
                                <td>{{ invoice.booking.user.onlineId }}</td>
                                <td colspan="4">&nbsp;</td>
                                <td class="fw-bold border-start text-end">{{ toMYR(invoice.totalAmount) }}</td>
                                <td v-for="invoiceItemType in invoiceItemTypes" class="fw-bold border text-end">
                                    {{ getTotalAmount(invoice.totalAmounts, invoiceItemType.name) > 0 ? toMYR(getTotalAmount(invoice.totalAmounts, invoiceItemType.name)) : '' }}
                                </td>
                            </tr>
                            <tr v-for="(job, index) in invoice.jobs" :key="index">
                                <template v-if="job.id">
                                    <td colspan="6">&nbsp;</td>
                                    <td>{{ job.no }}</td>
                                    <td>{{ job.status }}</td>
                                    <td>{{ job.assignedCarer.fullName }}</td>
                                    <td>{{ job.assignedCarer.onlineId }}</td>
                                </template>
                                <template v-else>
                                    <td colspan="6">&nbsp;</td>
                                    <td colspan="4">Others (Admin Fee & Discount)</td>
                                </template>
                                <td class="border fw-bold text-black-50 text-end">{{ toMYR(job.itemsTotalAmount) }}</td>
                                <td v-for="invoiceItemType in invoiceItemTypes" class="border text-end" :class="[ getTotalAmount(job.items, invoiceItemType.name) !== 0 ? '' : 'bg-dark-lt' ]">
                                    {{ getTotalAmount(job.items, invoiceItemType.name) ? toMYR(getTotalAmount(job.items, invoiceItemType.name)) : '' }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="28">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isLoading" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { Core, easepick } from '@easepick/core'
import date from '@/utils/date'
import { toMYR } from '@/composable/useCurrency'
import { onMounted, ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import useDateRangePicker from '@/composable/useDateRangePicker'
import ReportService from '@/services/ReportService'
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'

const { invoiceItemTypes } = storeToRefs(useResourcesStore())
const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
const invoices = ref<Array<any>>([])
const inputFilter = ref<UserFilter>({
    status: 'all',
    from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { easePickDefaultOptions } = useDateRangePicker()

const isLoading = ref<boolean>(false)

fetchInvoices()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchInvoices()
    }
)

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.from_date = e.detail.start.format(DEFAULT_DATE_FORMAT)
                inputFilter.value.to_date = e.detail.end.format(DEFAULT_DATE_FORMAT)

                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})

function resetFilter() {
    inputFilter.value = {
        status: 'all',
        from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
    }

    fetchInvoices()
}

function fetchInvoices() {
    isLoading.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    ReportService.getInvoices(query)
        .then(({ data: { data, meta } }) => {
            invoices.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isLoading.value = false
        })
}

function generateReport() {
    isLoading.value = true
    const query = {
        ...inputFilter.value,
    }

    ReportService.generateReport('invoices', query)
        .then(({ data: { downloadUrl } }) => window.open(downloadUrl, '_blank'))
        .finally(() => {
            isLoading.value = false
        })
}

function getTotalAmount(source: any, itemType: string) {
    return source.find((data: any) => data.type === itemType)?.amount
}
</script>

<style scoped>
td {
    color: #1e293b;
    font-size: 13px;
}
</style>