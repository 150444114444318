import {ref, computed} from 'vue'
import ErrorBag from '@/types/ErrorBag'
import {required, helpers} from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{}
})

export const rules = () => {
    const validateDiscountAmount = () =>
        helpers.withParams(
            {type: 'validateDiscountAmount'},
            (value: number, siblings) => {
                let validated

                if (siblings.is_fixed_discount === true) {
                    const minValue = 1
                    validated = value >= minValue
                } else {
                    const minValue = 1
                    const maxValue = 100
                    validated = (value >= minValue && value <= maxValue)
                }
                return validated
            }
        )

    return computed(() => {
        return {
            inputUser: {
                code: {
                    required: helpers.withMessage(
                        'This code field is required',
                        required
                    )
                },
                name: {
                    required: helpers.withMessage(
                        'This name field is required',
                        required
                    )
                },
                discount_amount: {
                    required: helpers.withMessage(
                        'This discount amount field is required',
                        required
                    ),
                    validateDiscountAmount: helpers.withMessage(
                        'This discount amount is invalid.',
                        validateDiscountAmount()
                    )
                },
                is_fixed_discount: {
                    required
                }
            }
        }
    })
}
