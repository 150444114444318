import BookingStatus from '@/types/BookingStatus'

export default function useBooking () {
    function getBookingPlatform(bookingExtraAttribute: any) {
        if (bookingExtraAttribute?.hasOwnProperty('platform')) {
            return bookingExtraAttribute.platform
        }

        return 'web'
    }

    function isMobileBooking(platform: string) {
        return platform === 'mobile'
    }

    function getChildrenString(children: Array<any>) {
        const arr = []

        if (children.filter((c) => c.gender == 'B').length > 0)
            arr.push(children.filter((c) => c.gender == 'B').length + ' Male')
        if (children.filter((c) => c.gender == 'G').length > 0)
            arr.push(children.filter((c) => c.gender == 'G').length + ' Female')

        return arr.join(', ')
    }

    function showWhatsAppTemplate(type: string, id: string) {
        let wrapper: HTMLElement | null
        let title = ''

        if (type === 'booking-template') {
            title = `WhatsApp Template Booking - ${id}`,
            wrapper = document.getElementById(`booking-whatsapp-template-${id}`)
        } else if (type === 'session-template') {
            title = `WhatsApp Template Session - ${id}`,
            wrapper = document.getElementById(`session-whatsapp-template-${id}`)
        } else if (type === 'booking-invoice-template') {
            title = `WhatsApp Template Booking Invoice - ${id}`,
            wrapper = document.getElementById(`booking-invoice-whatsapp-template-${id}`)
        } else {
            title = `WhatsApp Template Booking - ${id}`,
            wrapper = document.getElementById(`booking-job-whatsapp-template-${id}`)
        }

        window.Swal.fire({
            title: title,
            html: `<pre class="text-start" style="font-size: 16px">${wrapper?.innerHTML}</pre>`,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Copy to Clipboard',
            confirmButtonAriaLabel: 'Copy to Clipboard',
            cancelButtonText: 'Close',
            cancelButtonAriaLabel: 'Close',
        }).then((result: any) => {
            if (result.isConfirmed) {
                copyWhatsappTemplate(wrapper)
            }
        })
    }

    function copyWhatsappTemplate(wrapper: HTMLElement | null) {
        if (!wrapper) return

        navigator.clipboard.writeText(wrapper?.innerText).then(() => {})
    }

    function getAddressString(address: any) {
        let addressString = ''

        if (address.unit_no) {
            addressString = address.unit_no
        }

        if (address.floor_level) {
            addressString = `${addressString}, ${address.floor_level}`
        }

        if (address.block_building) {
            addressString = `${addressString}, ${address.block_building}`
        }

        return`${addressString}, ${address.address_1}, ${address.postcode} ${address.town}, ${address.state}`
    }

    function getBookingStatusLabel(statusKey: string, statuses: BookingStatus[]) {
        return statuses.find((status: BookingStatus) => status.key === statusKey)
    }

    function getBookingStatusBadge(status: string) {
        const prefix = 'bg'

        switch (status) {
            case 'new':
                return `${prefix}-green`
            case 'processing':
                return `${prefix}-yellow`
            case 'processing_pick_carer':
                return `${prefix}-teal`
            case 'processing_no_carer':
                return `${prefix}-red`
            case 'processing_payment':
                return `${prefix}-purple`
            case 'processing_carer_confirmation':
                return `${prefix}-pink`
            case 'approved':
                return `${prefix}-lime`
            case 'approved_in_session':
                return `${prefix}-lime`
            case 'complete':
                return `${prefix}-teal`
            case 'cancelled':
                return `${prefix}-red`
            default:
                return `${prefix}-dark`
        }
    }

    return {
        getBookingPlatform,
        isMobileBooking,
        getChildrenString,
        showWhatsAppTemplate,
        copyWhatsappTemplate,
        getAddressString,
        getBookingStatusBadge,
        getBookingStatusLabel,
    }
}
