<template>
    <div class="space-y-4">
        <script-tnc/>

        <script-booking-template />

        <script-customer-reminder/>

        <script-carer-reminder/>

        <script-pending-client-approval/>

        <script-pending-payment/>

        <script-introduction/>

        <script-rating-reminder/>
    </div>
</template>

<script setup lang="ts">
import ScriptBookingTemplate from './ScriptBookingTemplate.vue'
import ScriptTnc from './ScriptTnc.vue'
import ScriptCustomerReminder from './ScriptCustomerReminder.vue'
import ScriptCarerReminder from './ScriptCarerReminder.vue'
import ScriptPendingClientApproval from './ScriptPendingClientApproval.vue'
import ScriptPendingPayment from './ScriptPendingPayment.vue'
import ScriptIntroduction from './ScriptIntroduction.vue'
import ScriptRatingReminder from './ScriptRatingReminder.vue'
import { useNotificationsStore } from '@/stores/notifications'

const { addToastNotification } = useNotificationsStore()

</script>
