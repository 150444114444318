<template>
    <div class="card border "
    :class="form.mark_as_primary ? 'border-primary' : ''"
    >
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <FormLabel label="Mark as Primary Order?"
                           :class="form.mark_as_primary ? 'text-primary' : ''"
                />
                <label class="form-check form-switch">
                    <input v-model="form.mark_as_primary"
                           class="form-check-input"
                           type="checkbox"
                           :checked="form.mark_as_primary"
                           :disabled="mode === 'primary'"
                    >
                </label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import FormLabel from '@/components/form/FormLabel.vue'
import {useCreateOrderStore} from '@/stores/createOrder'
import {storeToRefs} from 'pinia'

const createOrderStore = useCreateOrderStore()

const {form, mode} = storeToRefs(createOrderStore)
</script>
