<template>
    <div class="d-none d-md-flex">
        <div class="nav-item dropdown d-none d-md-flex me-3">
            <a href="#" class="nav-link px-0" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" tabindex="-1" aria-label="Show notifications">
                <icon name="bell" />
                <span class="badge bg-red" v-show="hasUnreadNotification"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card" style="width: 500px">
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <h3 class="card-title">Recent notifications</h3>
                        <a href="#" @click.prevent="markAsRead(null)">Mark all as read</a>
                    </div>
                    <div class="list-group list-group-flush list-group-hoverable" v-if="webNotifications.length">
                        <div class="list-group-item" v-for="webNotification in webNotifications" :key="webNotification.id">
                            <div class="row align-items-center" :class="{'cursor-pointer': webNotification.readAt === null}"  @click="markAsRead(webNotification)">
                                <div class="col-auto">
                                    <span class="status-dot d-block" :class="[webNotification.readAt === null ? 'status-dot-animated bg-red' : 'bg-muted']"></span>
                                </div>
                                <div class="col text-wrap">
                                    <span href="#" class="text-body d-block">{{ webNotification.data.title }}</span>
                                    <span class="d-block text-muted text-wrap mt-n1">
                                        {{ webNotification.data.body }}
                                    </span>
                                </div>
                                <div class="col-auto">
                                    <span class="fs-5">{{ fromNow(webNotification.createdAt) }}</span>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="webNotification.data.actionType !== null">
                                <div class="col-auto">
                                    <span class="status-dot d-block invisible"></span>
                                </div>
                                <div class="col">
                                    <a v-if="webNotification.data.actionType === 'link'" :href="webNotification.data.actionUrl" target="_blank">{{ webNotification.data.actionText }}</a>
                                    <template v-if="webNotification.data.actionType === 'backofficeLink'">
                                        <router-link :to="webNotification.data.actionData">{{ webNotification.data.actionText }}</router-link>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-group list-group-flush list-group-hoverable" v-else>
                        <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col d-flex justify-content-center">
                                    <span>Empty notifications</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { fromNow } from '@/composable/useDate'
import NotificationService from '@/services/NotificationService'
import { useNotificationsStore } from '@/stores/notifications'
import WebNotification from '@/types/WebNotification'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const notificationsStore = useNotificationsStore()
const { webNotifications } = storeToRefs(notificationsStore)
const { markWebNotificationAsRead } = notificationsStore

const fetchNotifications = () => {
    const query = {
        perPage: 10,
        page: 1,
    }

    NotificationService.index(query)
        .then(({ data: { data } }) => {
            webNotifications.value = data
        })
}

const markAsRead = (notification: WebNotification|null) => {
    const notificationId = notification !== null ? notification.id : null
    markWebNotificationAsRead(notificationId)
}

const hasUnreadNotification = computed(() => {
    return webNotifications.value.some((notification => notification.readAt === null))
})

fetchNotifications()

</script>
