<template>
    <div class="toast-container position-fixed p-3"
         :class="positionClassName"
         style="z-index: 1056"
    >
        <div v-for="notification in toastNotifications"
             :key="notification.id"
        >
            <ToastNotificationItem :notification="notification"
                                   :type="props.type"
                                   @remove-notification="handleRemoveNotification"
            />
        </div>
    </div>
</template>


<script setup lang="ts">
import {useNotificationsStore} from '@/stores/notifications'
import ToastNotificationItem from '@/components/ToastNotificationItem.vue'
import {ToastNotificationType} from '@/types/ToastNotificationType'
import ToastNotification from '@/types/ToastNotification'
import {computed} from 'vue'

const notificationsStore = useNotificationsStore()
const {removeToastNotification} = notificationsStore

const props = defineProps({
    toastNotifications: {
        type: Array as () => ToastNotification[],
        required: true
    },
    type: {
        type: String as () => ToastNotificationType,
        default: 'general'
    },
    position: {
        type: String as () => 'top' | 'top-end',
        default: 'top'
    }
})

const positionClassName = computed(() => {
    let classname = ''
    switch (props.position) {
        case 'top':
            classname = 'start-0 end-0 mx-auto'
            break
        case 'top-end':
            classname = 'top-0 end-0'
            break
    }
    return classname
})

const handleRemoveNotification = (id: number) => {
    removeToastNotification(id, props.type)
}


</script>
