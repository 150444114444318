<template>
    <kc-modal modal-id="modal-edit-avatar" modal-size="md" modal-backdrop="static"
              :modal-keyboard="false" :modal-title="'Update profile photo'">
        <div class="d-flex flex-column align-items-center">
            <img height="360" width="360" :src="avatar" alt="Profile photo" class="border rounded">
            <form id="formAvatar" @submit.prevent="save" class="mt-3">
                <label for="avatar" class="btn btn-outline-primary">
                    Upload photo
                    <input type="file" name="avatar" id="avatar" class="d-none" @change="handleFileUpload">
                </label>
            </form>
        </div>
        <template v-slot:footer>
            <button id="btn-modal-edit-avatar-close" class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" type="submit" form="formAvatar" :class="{ disabled: isSaving }">
                <span v-if="isSaving" class="spinner-border spinner-border-sm me-2"></span>
                {{ isSaving ? 'Saving' : 'Save' }}
            </button>
        </template>
    </kc-modal>
</template>

<script setup lang="ts">
import User from "@/types/User";
import {onMounted, ref} from "vue";
import UserInput from "@/types/Input";
import carerService from "@/services/users/CarerService";
import {useNotificationsStore} from "@/stores/notifications";

const { addToastNotification } = useNotificationsStore()

let btnCloseModal: HTMLElement | null = null

const isSaving = ref(false)
const avatar = ref('')

const props = defineProps({
    user: { type: Object as () => User }
})

const emit = defineEmits(['updated'])

const input = ref<UserInput>({
    avatar: '',
})

onMounted(() => {
    initModalElement()
    avatar.value = props.user?.avatarUrl ?? "../../avatar-default.png"
})

function initModalElement() {
    btnCloseModal = document.getElementById(`btn-modal-edit-avatar-close`)
}

function handleFileUpload(event: Event) {
    const target = event.target as HTMLInputElement
    if (target && target.files)
        input.value.avatar = target.files[0]
        avatar.value = URL.createObjectURL(input.value.avatar)
}

function save() {
    isSaving.value = true

    carerService.updateAvatar(props.user?.id!, {
        avatar: input.value.avatar
    }).then(() => {
        addToastNotification({
            message: 'Profile photo updated',
            type: 'success'
        })
        btnCloseModal?.click()
        emit('updated')
    }).catch(() => {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    }).finally(() => isSaving.value = false)
}
</script>