import {ref, computed} from 'vue'
import {helpers, required} from "@vuelidate/validators";

export const $externalResults = ref({
    inputUser: {}
})

export const rules = (answerIds: []) => {
    return computed(() => {
            return {
                inputUser: {

                    rating: {
                        required: helpers.withMessage(
                            'This rating field is required',
                            required
                        )
                    },
                    is_public_displayed: {
                        required: helpers.withMessage(
                            'This display to public field is required',
                            required
                        )
                    }
                }
            }
        }
    )
}