<template>
    <kc-modal :modal-id="`modal-edit-remark-${ticketId}`">
        <div class="modal-header">
            <h4 class="modal-title">Notification Remark</h4>
            <button id="button-edit-remark-modal-close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="updateRemark">
            <div class="modal-body">
                <div class="mb-3">
                    <label class="form-label">Remark</label>
                    <textarea v-model="input.remark" class="form-control" name="remark" rows="3"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-light" type="button" data-bs-dismiss="modal">Cancel</button>
                <button class="btn btn-primary" type="submit" :disabled="isUpdatingRemark">
                    <span class="spinner-border spinner-border-sm me-2" v-if="isUpdatingRemark"></span>
                    <span>{{ isUpdatingRemark ? 'Saving...' : 'Save' }}</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import TicketService from '@/services/TicketService'
import { useNotificationsStore } from '@/stores/notifications'

let btnClose: HTMLElement | null = null

const emit = defineEmits(['updateRemark'])
const { addToastNotification } = useNotificationsStore()
const isUpdatingRemark = ref<Boolean>(false)
const props = defineProps({
    ticketId: { type: Number, required: true },
    remark: { type: String, required: true },
})
const input = ref<any>({
    remark: null
})

const updateRemark = () => {
    if (input.value.remark.length > 0 && input.value.remark !== props.remark) {
        TicketService.updateRemark(props.ticketId.toString(), { remark: input.value.remark })
            .then(() => {
                addToastNotification({ message: `Ticket remark has been updated`, type: 'success' })
                emit('updateRemark', input.value.remark, props.ticketId.toString())
                btnClose?.click()
            })
            .catch(() => {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team if error persists.',
                    type: 'danger'
                })
            })
            .finally(() => {
                isUpdatingRemark.value = false
            })
    }
}

onMounted(() => {
    btnClose = document.getElementById('button-edit-remark-modal-close')
    input.value.remark = props.remark
})
</script>