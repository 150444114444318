<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Event Name</label>
                    <input type="text" class="form-control" v-model="inputFilter.name" @keyup.enter="fetchEvents">
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Event Date</label>
                    <div class="input-group">
                        <input type="text" id="inputFilterStartedAt" class="form-control bg-white" :value="eventDateDisplay" readonly>
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Event Location</label>
                    <input type="text" class="form-control" v-model="inputFilter.location" @keyup.enter="fetchEvents">
                </div>
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchEvents" :is-loading="false"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Event Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Location</th>
                            <th class="col-1 text-center">Participants</th>
                        </tr>
                    </thead>
                    <tbody v-if="events.length">
                        <tr class="align-middle" v-for="event in events" :key="event.id">
                            <td>{{ event.title }}</td>
                            <td>{{ formatDate(event.event?.startedAt ?? '', 'DD-MMM-YYYY') }} ({{ formatDate(event.event?.startedAt ?? '', 'dddd') }})</td>
                            <td>{{ formatDate(event.event?.startedAt ?? '', 'hh:mm A') }} - {{ formatDate(event.event?.endedAt ?? '', 'hh:mm A') }} ({{ difference(event.event?.endedAt ?? '', event.event?.startedAt ?? '', 'h') }} hours)</td>
                            <td>{{ event.event?.location }}</td>
                            <td class="text-center">
                                <button class="btn btn-primary btn-icon" data-bs-toggle="modal"
                                    data-bs-target="#listOfParticipantsModal"
                                    @click="loadModal(event)">
                                    <icon name="users" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="5" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
        <overlay-spinner :is-showing="loading"></overlay-spinner>
    </div>

    <kc-modal modal-id="listOfParticipantsModal" modal-size="lg" modal-title="List of Registrants">
        <div class="row align-items-center mb-3">
            <div class="col-12 col-md-2">
                <span class="fw-bold">Event Name</span>
            </div>
            <div class="col-12 col-md-2">
                {{ eventName }}
            </div>
            <div class="col-12 col-md-auto ms-auto">
                <button class="btn btn-primary" @click="download">
                    <icon name="file-spreadsheet"/>
                    <span>Download</span>
                </button>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="eventParticipants.length">
                    <tr v-for="(participant, index) in eventParticipants" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ participant.name }}</td>
                        <td>{{ participant.email }}</td>
                        <td>{{ participant.phone }}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr class="text-center">
                        <td colspan="4">No user registered yet</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </kc-modal>
</template>

<script setup lang="ts">
import { difference, formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import EventRegistrationService from '@/services/EventRegistrationService'
import { useNotificationsStore } from '@/stores/notifications'
import EventRegistration from '@/types/EventRegistration'
import UserFilter from '@/types/Filter'
import PlatformContent from '@/types/PlatformContent'
import { AmpPlugin } from '@easepick/amp-plugin'
import { Core, easepick } from '@easepick/core'
import { computed, onMounted, ref, watch } from 'vue'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const { addToastNotification } = useNotificationsStore()

const loading = ref<boolean>(false)
const events = ref<Array<PlatformContent>>([])
const eventId = ref<number>()
const eventName = ref<string>('')
const eventParticipants = ref<Array<EventRegistration>>([])
const inputFilter = ref<UserFilter>({
    name: '',
    startedAt: '',
    location: '',
})

const eventDateDisplay = computed(() => inputFilter.value.startedAt ? formatDate(inputFilter.value.startedAt, 'DD-MMM-YYYY') : '')

const fetchEvents = async () => {
    loading.value = true

    try {
        const params = {
            ...{
                perPage: metaPage.value.perPage,
                page: metaPage.value.currentPage
            },
            ...inputFilter.value
        }

        const response = await EventRegistrationService.index(params)

        events.value = response.data.data
        updateMetaPage(response.data.meta)
    } catch (error) {
        addToastNotification({
            message: 'Internal server error. Please contact tech team if the error persists.',
            type: 'danger',
        })
    } finally {
        loading.value = false
    }
}

const loadModal = (event: PlatformContent) => {
    eventId.value = event.id
    eventName.value = event.title
    eventParticipants.value = event.eventRegistrations
}

const download = async () => {
    if (!eventId.value) {
        return
    }

    try {
        const response = await EventRegistrationService.exportRegistrants(eventId.value)

        addToastNotification({
            message: response.data.message,
            type: 'success',
        })
    } catch (error) {
        addToastNotification({
            message: 'Internal server error. Please contact tech team if the error persists.',
            type: 'danger',
        })
    }
}

const resetFilter = () => {
    inputFilter.value.name = ''
    inputFilter.value.startedAt = ''
    inputFilter.value.location = ''

    fetchEvents()
}

fetchEvents()

onMounted(() => {
    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 2020,
                maxYear: (new Date().getFullYear())
            },
            darkMode: false
        }
    }

    new easepick.create({
        element: <any>document.getElementById('inputFilterStartedAt'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.startedAt = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })
})

watch(
    () => metaPageTriggered.value,
    () => fetchEvents()
)
</script>
