import { getQueryString } from '@/composable/http';
import httpClientV2 from '@/services/HttpClientV2'

export default {
    index(query: object = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`/backoffice/loyalty-points?${queryString}`)
    },

    store(data: object = {}) {
        return httpClientV2.post('/backoffice/loyalty-points', data)
    },

    generateReport(data: object = {}) {
        return httpClientV2.post('/backoffice/reports/loyalty-points', data)
    },
}
