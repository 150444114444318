import {getQueryString} from '@/composable/http'
import UserQuery from '@/types/query/UserQuery'
import httpClientV2 from '@/services/HttpClientV2'

const endpoint = 'backoffice/chat-admins'

export default {
    index(query: UserQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    store(data: object = {}) {
        return httpClientV2.post(endpoint, data)
    },

    destroy(userId: number) {
        return httpClientV2.delete(`${endpoint}/${userId}`)
    }
}
