import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'
import {getQueryString} from '@/composable/http'

const endpoint = '/backoffice/users/customers'

export default {
    index(query: UserQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    store(data: object = {}) {
        return httpClientV2.post(endpoint, data)
    },

    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },

    update(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },

    updateProfile(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${id}/profile`, data)
    },

    updateRole(id: number) {
        return httpClientV2.patch(`${endpoint}/${id}/switch-role`)
    },

    generateReport(data: object = {}) {
        return httpClientV2.post(`${endpoint}/report`, data)
    },

    generateOnlineId(id: number) {
        return httpClientV2.patch(`${endpoint}/${id}/generate-online-id`)
    },
}
