import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { required, numeric, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        date: {
            required: helpers.withMessage(
                'This date field is required',
                required
            ),
        },
        start_time: {
            required: helpers.withMessage(
                'This start time field is required',
                required
            ),
        },
        duration: {
            required: helpers.withMessage(
                'This duration field is required',
                required
            ),
            numeric: helpers.withMessage(
                'This duration field must be a number',
                numeric
            ),
        },
        address: {
            required: helpers.withMessage(
                'This address field is required',
                required
            ),
        },
        children: {
            minLength: helpers.withMessage(
                'This children field is required at least 1',
                required
            ),
        },
    },
})