<template>
    <div class="dropdown position-static" v-if="! displayOnly">
        <button type="button"
                class="btn btn-sm rounded dropdown-toggle text-uppercase w-100"
                data-bs-toggle="dropdown"
                :class="getApplicationStatusBadge(application.sessionCarerApplicationStatusId)"
        >
            {{ getApplicationStatusLabel(application.sessionCarerApplicationStatusId, sessionCarerApplicationStatuses) }}
        </button>

        <div class="dropdown-menu">
            <a v-for="status in sessionCarerApplicationStatuses"
               :key="`non-cancelling-status-${status.id}`"
               class="dropdown-item py-1" role="button"
               @click.prevent="updateApplicationStatus(status)"
            >
                {{ status.label }}
            </a>
        </div>
    </div>
    <div v-else class="text-center badge badge-pill"
         :class="getApplicationStatusBadge(application.sessionCarerApplicationStatusId)"
    >
        {{ getApplicationStatusLabel(application.sessionCarerApplicationStatusId, sessionCarerApplicationStatuses) }}
    </div>

</template>
<script setup lang="ts">

import { getApplicationStatusBadge, getApplicationStatusLabel } from '@/composable/useSessionCarerApplicationStatus'
import { CancellingSessionCarerApplicationStatuses } from '@/enums/SessionCarerApplicationStatusEnum'
import SessionCarerApplicationService from '@/services/SessionCarerApplicationService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import CarerApplication from '@/types/CarerApplication'
import SessionCarerApplicationStatus from '@/types/SessionCarerApplicationStatus'
import { storeToRefs } from 'pinia'

const {addToastNotification} = useNotificationsStore()
const {sessionCarerApplicationStatuses} = storeToRefs(useResourcesStore())

const emits = defineEmits(['statusChanged'])

const props = defineProps({
    application: {type: Object as () => CarerApplication, required: true},
    displayOnly : {type: Boolean, default: false}
})

const updateApplicationStatus = async (status: SessionCarerApplicationStatus) => {
    if(CancellingSessionCarerApplicationStatuses.includes(status.id)) {
        addToastNotification({
            message: 'Please cancel using Cancel action button',
            type: 'danger'
        })
        return
    }

    if (!props.application) {
        addToastNotification({
            message: 'Something went wrong with this request. Please contact tech team.',
            type: 'danger'
        })
        return
    }

    try {
        await SessionCarerApplicationService.update(props.application.id, {session_carer_application_status_id: status.id})
        await handleSuccess(status)
    } catch (err) {
        addToastNotification({
            message: 'Failed to update session status.',
            type: 'danger'
        })
    }
}

const handleSuccess = async (status: SessionCarerApplicationStatus) => {
    addToastNotification({
        message: `Application's status has been updated to ${ status.admin_label }`,
        type: 'success'
    })
    emits('statusChanged', {
        sessionId: props.application?.id,
        status: status
    })
}

</script>