<template>
    <admin-form :user="user" :id="userId"></admin-form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import AdminForm from './AdminForm.vue'
import User from '@/types/User'
import AdminService from '@/services/users/AdminService'
import { useNotificationsStore } from '@/stores/notifications'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const userId = Number(route.params.id)
const user = ref<User>()

AdminService.show(userId)
    .then(({ data: { data, chatNickname }}) => {
        user.value = Object.assign(data, { chatNickname })
    }).catch(() => {
        addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
    })
</script>
