<template>
    <kc-modal :modal-id="modalId" modal-size="xl">
        <div>
            <div class="modal-header">
                <h4 class="modal-title">Applied Carers for Job {{ props.jobNo }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" :data-bs-target="`#${modalId}`" aria-label="Close"></button>
            </div>
            <div class="modal-body bg-white">
                <div class="row mb-3">
                    <div v-if="preferredCarer" class="col">
                        <div class="card card-sm">
                            <div class="card-status-start bg-info"></div>
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <span class="bg-info-lt avatar"><icon name="info-circle" size="32"/></span>
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium">Preferred Carer by Customer</div>
                                        <div class="text-muted">[{{ preferredCarer.online_id }}] {{ preferredCarer.full_name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Travelling Tips</th>
                        <th>Applied At</th>
                        <th>Cancelled At</th>
                        <th>Cancel Reason</th>
                        <th class="text-end">Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="appliedCarers.length">
                        <tr v-for="appliedCarer in appliedCarers" :key="appliedCarer.carer?.id">
                            <td>
                                <span v-if="appliedCarer.carer">[{{ appliedCarer.carer.user.mainProfile?.onlineId }}] {{ appliedCarer.carer.user.name }}</span>
                                <vue-select v-else :options="carersOptions" @search="onSearchCarer" v-model.number="inputNewUserId" :reduce="(carer: any) => carer.id" label="name" />
                            </td>
                            <td class="text-center"><span :class="`badge bg-${getStatusBadge(appliedCarer.status)}`">{{ appliedCarer.statusLabel ?? 'N/A' }}</span></td>
                            <td>
                                <span v-if="appliedCarer.carer">{{ appliedCarer.addonFee ? toMYR(appliedCarer.addonFee) : 'N/A' }} {{ appliedCarer.addonFor ? `(${appliedCarer.addonFor})` : '' }}</span>
                                <span v-else><input type="number" min="0" max="50" class="form-control" v-model.number="inputAddon"/></span>
                            </td>
                            <td>{{ appliedCarer.createdAt ? formatDate(appliedCarer.createdAt, 'DD-MMM-YYYY hh:mm:ss A') : '' }}</td>
                            <td>{{ appliedCarer.cancelledAt ? formatDate(appliedCarer.cancelledAt, 'DD-MMM-YYYY hh:mm:ss A') : '' }}</td>
                            <td>{{ appliedCarer.reason }}</td>
                            <td class="text-end">
                                <div class="d-flex justify-content-end">
                                    <div class="dropdown position-static" v-if="appliedCarer.status === 'reconfirmation' || appliedCarer.status === 'negotiating'">
                                        <button type="button" class="btn dropdown-toggle text-muted" data-bs-toggle="dropdown">
                                            <icon name="dots" class="text-secondary" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end" style="width: 164px;">
                                            <a class="dropdown-item text-primary" role="button" @click.prevent="updateApplication(appliedCarer.carer ?? null, appliedCarer.status, true)" ><icon name="check" class="me-2 flex-shrink-0" />Accept {{ appliedCarer.status === 'negotiating' ? 'Negotiation' : 'Changes' }}</a>
                                            <a class="dropdown-item text-danger" role="button" @click.prevent="updateApplication(appliedCarer.carer ?? null, appliedCarer.status, false)"><icon name="x" class="me-2 flex-shrink-0" />Decline {{ appliedCarer.status === 'negotiating' ? 'Negotiation' : 'Changes' }}</a>
                                        </div>
                                    </div>
                                    <div class="btn-group" v-else>
                                        <button v-if="! appliedCarer.carer" class="btn btn-info" @click.prevent="patchAppliedCarer(null, 'saved')">Save</button>
                                        <button v-else-if="appliedCarer.status === 'accepted'" class="btn btn-secondary" @click.prevent="patchAppliedCarer(appliedCarer.carer?.user.id ?? null, 'unassigned')">Unassign</button>
                                        <button v-else class="btn btn-primary" @click.prevent="patchAppliedCarer(appliedCarer.carer?.user.id ?? null, 'assigned')">Assign</button>
                                        <button v-if="appliedCarer.status !== 'accepted'" class="btn btn-danger" @click.prevent="patchAppliedCarer(appliedCarer.carer?.user.id ?? null, 'removed')">Delete</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-center">
                    <button class="btn btn-primary" @click.prevent="addCarer"><icon name="plus" /> Add Carer</button>
                </div>
            </div>

            <div class="modal-footer pt-2 border-top">
                <button class="btn btn-light" type="button" role="button" data-bs-toggle="modal" :data-bs-target="`#${modalId}`" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
        <overlay-spinner :is-showing="isFetchingAppliedCarers"/>
    </kc-modal>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import JobService from '@/services/JobService'
import { formatDate } from '@/composable/useDate'
import { toMYR } from '@/composable/useCurrency'
import _ from 'lodash/fp'
import CarerService from '@/services/users/CarerService'
import User from '@/types/User'
import { getMainProfile } from '@/composable/profile'
import { useNotificationsStore } from '@/stores/notifications'

const { addToastNotification } = useNotificationsStore()

const props = defineProps({
    jobId: { type: Number, default: 0 },
    jobNo: { type: String, default: null },
    preferredCarer: { type: Object, default: null }
})

const modalId = ref<string>('modal-applied-carer')
const isFetchingAppliedCarers = ref<boolean>(false)
const appliedCarers = ref<Array<any>>([])
const carersOptions = ref<Array<User>>([])
const inputNewUserId = ref<number>(0)
const inputAddon = ref<number>(0)

const onSearchCarer = (search: string, loading: Function) => {
    if (search.length) {
        searchCarers(search, loading)
    }
}

const searchCarers = _.debounce(350, (search, loading) => {
    loading(true)

    CarerService.index({ search })
        .then(({ data: { data } }) => {
            carersOptions.value = data.map((carer: User) => {
                const mainProfile = getMainProfile(carer.profiles)
                return {
                    id: carer.id,
                    name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`,
                }
            })
        })
        .catch(() => addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' }))
        .finally(() => loading(false))
})

const addCarer = () => {
    appliedCarers.value.push({ status: 'applied', statusLabel: 'Applied' })
}

const fetchAppliedCarer = () => {
    isFetchingAppliedCarers.value = true

    JobService.getAppliedCarers(props.jobId)
        .then(({ data: { data } }) => {
            appliedCarers.value = data
        })
        .catch(() => addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' }))
        .finally(() => isFetchingAppliedCarers.value = false)
}

const getStatusBadge = (status: string) => {
    switch (status) {
        case 'applied':
            return 'blue'
        case 'accepted':
            return 'green'
        case 'cancelled':
        case 'client_cancelled':
            return 'red'
        case 'declined':
            return 'orange'
        default:
            return 'secondary'
    }
}

const patchAppliedCarer = (userId: number|null, action: string) => {
    if (userId === null) {
        if (action === 'removed') {
            appliedCarers.value.pop()
            return
        }

        if (inputNewUserId.value === 0) {
            return
        }
    }

    const data: any = { action }

    data.userId = userId === null ? inputNewUserId.value : userId
    data.addonFee = inputAddon.value > 0 ? inputAddon.value : null

    isFetchingAppliedCarers.value = true

    JobService.patchAppliedCarer(props.jobId, data)
        .then(({ data: { data } }) => {
            appliedCarers.value = data
            addToastNotification({ message: `Carer has been ${action}`, type: 'success' })
        })
        .catch(() => addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' }))
        .finally(() => {
            inputNewUserId.value = 0
            inputAddon.value = 0
            carersOptions.value = []

            isFetchingAppliedCarers.value = false
        })
}

const updateApplication = (carer: any, applicationStatus: string, isAccept: boolean) => {
    isFetchingAppliedCarers.value = true
    const jobService =
        applicationStatus === 'negotiating'
            ? JobService.approveNegotiation(props.jobId,{ carerId: carer.id, carerFullName: carer.user.name, isAccept })
            : JobService.approveJobDetailChanges(props.jobId, { carerId: carer.id, carerFullName: carer.user.name, isAccept })
    
    jobService
        .then(({ data: { data }}) => {
            appliedCarers.value = data
            addToastNotification({ message: `Carer application has been updated`, type: 'success' })
        })
        .catch(() => addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' }))
        .finally(() => {
            isFetchingAppliedCarers.value = false
        })
}

onMounted(() => {
    const modalAppliedCarer = document.getElementById(modalId.value)

    modalAppliedCarer?.addEventListener('shown.bs.modal', () => fetchAppliedCarer())

    modalAppliedCarer?.addEventListener('hidden.bs.modal', () => {
        inputNewUserId.value = 0
        inputAddon.value = 0
        carersOptions.value = []
        appliedCarers.value = []
    })
})

</script>
