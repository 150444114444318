<template>
    <template v-if="isSendbirdConnected">
        <div class="card chat-container" :style="chatContainerStyle">

            <div class="row g-0 h-100">
                <div class="overflow-auto" ref="channelList"
                     :class="!floating ? hasCollapsedSidebar ? 'col-sm-12 col-md-1' :'col-sm-12 col-md-3' : ''"
                     @scroll="channelListScroll"
                >
                    <div class="d-flex justify-content-between my-1 mb-2 px-3 pt-3">
                        <button class="btn btn-icon btn-outline-secondary" @click.prevent="toggleCollapseLeftSidebar">
                            <icon
                                :name="hasCollapsedSidebar ? 'layout-sidebar-left-expand' : 'layout-sidebar-left-collapse' "/>
                        </button>
                        <div v-if="!hasCollapsedSidebar" class="btn-group ms-1">
                            <button class="btn btn-icon btn-success" @click.prevent="refreshChannels()">
                                <icon name="refresh"/>
                            </button>
                            <button class="btn btn-icon btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#modal-create-channel">
                                <icon name="plus"/>
                            </button>
                        </div>
                    </div>

                    <div id="left-sidebar-content"
                         v-if="!hasCollapsedSidebar"
                    >
                        <div class="border-bottom p-3" v-if="hideOnFloatActiveChannel">

                            <!-- Filter: Channel Custom Type -->
                            <div class="d-flex my-1 mb-2">
                                <div class="btn-group w-100 " role="group">
                                    <template v-for="channelTab in channelTabs" :key="channelTab.type">
                                        <input type="radio" class="btn-check"
                                               name="channel-type"
                                               :id="`channel-type-${channelTab.type}`" autocomplete="off"
                                               @click="updateChannelType(channelTab.type)">
                                        <label :for="`channel-type-${channelTab.type}`" type="button" class="btn"
                                               :class="{ 'btn-primary': currentType === channelTab.type }">
                                            <span>{{ channelTab.label }}</span>
                                            <span class="badge badge-pill bg-red ms-2"
                                                  v-show="channelTab.unreadChannels > 0"
                                            >
                                            {{ channelTab.unreadChannels }}
                                             </span>
                                        </label>
                                    </template>
                                </div>

                            </div>

                            <!-- Search box -->
                            <div class="d-flex   mb-2">

                                <div v-if="searchChannelsMode === 'channelName'"
                                     class="position-relative flex-grow-1"
                                >
                                    <icon name="search"
                                          class="text-muted position-absolute left-0 top-0 bottom-0 my-auto ms-2"
                                          :stroke-width="1.2"/>
                                    <input class="form-control ps-4" v-model="inputSearch" @input="searchChannel"
                                           type="text"
                                           placeholder="Search chat name">
                                    <icon name="x"
                                          class="text-muted position-absolute end-0 top-0 bottom-0 my-auto me-2 cursor-pointer"
                                          v-if="inputSearch.length > 0"
                                          @click="inputSearch = ''; fetchChannels(currentType);"
                                          :stroke-width="1.2"/>
                                </div>

                                <SearchSelectUser v-else-if="searchChannelsMode === 'user'"
                                                  v-model="searchSelectedUser"
                                />

                                <button class="btn btn-icon btn-light ms-2" @click.prevent="toggleSearchChannelsMode">
                                    <icon name="switch-horizontal"/>
                                </button>
                            </div>

                            <!-- Toggle view archived channels  -->
                            <div class="d-flex">
                                <button @click.prevent="toggleViewingArchivedChannels"
                                        class="w-100 btn btn-sm  border-x-0 text-secondary py-1 rounded-0"
                                        :class="isViewingArchivedChannels ? ' border-secondary' : ''"
                                >
                                    {{ isViewingArchivedChannels ? '← Back to main' : 'Archived' }}
                                </button>
                            </div>
                        </div>

                        <div class="card-body pt-0 pb-3" v-if="hideOnFloatActiveChannel"
                             :style="{ 'height': !floating ? 'calc(100vh - 300px)' : 'calc(100vh - 300px)' }">


                            <!-- Fetching channels -->
                            <template v-if="isFetchingChannels">
                                <div class="spinner-border"></div>
                            </template>

                            <!--  Chat search result message  -->
                            <template v-else-if="chatSearchResultMessage">
                                <div
                                    class="list-group list-group-transparent py-2 px-3 mb-0 bg-light text-muted border-bottom">
                                    <p class="mb-0">{{ chatSearchResultMessage }}</p>
                                </div>
                            </template>

                            <!-- All results -->
                            <template v-if="channels.length > 0">
                                <div class="list-group list-group-transparent" v-for="channel in channels">
                                    <channel-item
                                        :channel="channel"
                                        :current-channel="currentChannel">
                                    </channel-item>
                                </div>
                            </template>
                            <template v-else>
                                <p class="text-center mt-3 text-muted">No available chats</p>
                            </template>
                        </div>
                    </div>
                </div>

                <!-- CURRENT CHANNEL -->
                <div class="col border-start flex-column"
                     :class="{ 'd-flex': currentChannel && !(sideDetailOpen && floating) }"
                     v-show="currentChannel && !(sideDetailOpen && floating)"
                >
                    <div v-if="currentChannel" class="card-body p-0 position-relative">
                        <chat-header
                            :current-type="currentType"
                            :floating="floating"
                            :channel="currentChannel"
                            :user-metadata="userMetadata"
                            :participants="channelMembers ?? []"
                        >
                        </chat-header>
                        <!-- chat box -->
                        <div class="chat-box pt-4 overflow-auto"
                             :class="[{ 'd-flex flex-column-reverse': currentChannel?.unreadMessageCount === 0 }, !floating ? 'px-4' : 'px-3']"
                             :style="{ 'height': !floating ? 'calc(100vh - 320px)' : 'calc(100vh - 300px)' }"
                             @scroll="chatBoxScroll"
                             ref="chatBox"
                        >
                            <ul class="list-unstyled space-y-3"
                                :style="{ 'margin-bottom': floating ? '64px' : '0' }">
                                <li v-if="isFetchingPreviousMessages">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border"></div>
                                    </div>
                                </li>
                                <template v-if="messages.length && currentChannel">
                                    <chat-message v-for="message in messages"
                                                  :key="message.messageId"
                                                  :user="user"
                                                  :floating="floating"
                                                  :my-last-read="getMyLastUnread(currentChannel.myLastRead)"
                                                  :unread-message-count="currentChannel?.unreadMessageCount"
                                                  :message="message"/>
                                </template>
                                <li v-show="currentChannel?.isTyping">
                                    <p class="mb-0 fw-bold text-blue fst-italic">
                                        {{ currentChannel?.getTypingUsers()[0]?.nickname }} is typing...</p>
                                </li>
                            </ul>
                        </div>

                        <!-- reply box -->
                        <template v-if="currentChannel">
                            <chat-reply v-if="isCurrentChannelActive" :floating="floating"
                                        :current-type="currentType"
                            />
                            <chat-reply-inactive-channel v-else
                                                         :channel-type="currentChannel.channelType.toString()"
                                                         :channel-url="currentChannel.url"/>
                        </template>
                    </div>
                    <div class="position-relative">
                        <overlay-spinner :is-showing="isLoadingChannel && !isLoadingMain"/>
                    </div>
                </div>


                <div class="border-start overflow-scroll" v-if="sideDetailOpen"
                     :class="!(sideDetailOpen && floating) ? 'col-3' : 'col'"
                >
                    <div :style="{ height: !floating ? 'calc(100vh - 300px)' : '80vh' }">
                        <div class="px-3 pt-3 d-inline-flex space-x-2 cursor-pointer" role="button"
                             @click="toggleSideDetail"
                             v-if="floating">
                            <span>Close</span>
                            <icon name="x" :stroke-width="1.2"/>
                        </div>
                        <chat-details-service
                            v-if="currentType === 'service'"
                            :channel="currentChannel"
                            :ticket-id="parseInt(currentChannel?.cachedMetaData.ticketId)"
                            :participants="channelMembers"
                            :admins="chatAdmins"
                            @invited="inviteUser"
                            @banned="banUser"/>
                        <chat-details-support
                            v-else-if="currentType === 'support'"
                            :ticket-id="parseInt(currentChannel?.cachedMetaData.ticketId)"
                            :user-id="currentChannel?.cachedMetaData.userId"
                            :admins="admins"
                            :participants="channelMembers?? []"
                            @admin-invited="getChannelMembers"
                        />
                        <chat-details-toggle
                            v-if="currentChannel?.customType !== 'internal'"
                            :is-active="isCurrentChannelActive"
                            :channel-type="currentChannel?.channelType.toString()"
                            :channel-url="currentChannel?.url"
                            :ticket-id="parseInt(currentChannel?.cachedMetaData.ticketId)"
                            :current-type="currentType"
                        />
                    </div>
                </div>
                <overlay-spinner :is-showing="isLoadingMain"/>
            </div>
        </div>
        <ChatInviteCarerModal
            :participants="channelMembers ?? []"
            :is-successfully-invited="isSuccessfullyInvited"
            @invite="inviteUser"
        />
        <chat-create-channel-modal @refresh-channels="refreshChannels"/>
    </template>
    <template v-else>
        <div v-if="sendbirdErrors.length > 0 " class="bg-danger  rounded p-2 text-center">
            <p class="text-uppercase fw-bold">Error</p>
            <ul class="list-unstyled">
                <li v-for="error in sendbirdErrors">
                    <p class="">
                        {{ error }}
                    </p>
                </li>
            </ul>
            <p>Please contact tech team.</p>
        </div>
    </template>
</template>

<script setup lang="ts">
import _ from 'lodash/fp'
import {computed, nextTick, onBeforeMount, onMounted, provide, ref, watch} from 'vue'
import {BaseChannel, MetaData, RestrictedUser} from '@sendbird/chat'
import {ChatChannelType} from '@/enums/ChatChannelType'
import {ChatChannelDepartment} from '@/enums/ChatChannelDepartment'
import {SendbirdCustomTypeEnum} from '@/enums/SendbirdCustomTypeEnum'
import {
    GroupChannel,
    GroupChannelHandler,
    GroupChannelListOrder,
    GroupChannelListQuery,
    GroupChannelListQueryParams,
    HiddenChannelFilter,
    Member,
    MemberListOrder,
    MemberListQueryParams,
    MyMemberStateFilter
} from '@sendbird/chat/groupChannel'
import {
    BaseMessage,
    MessageListParams,
    PreviousMessageListQuery,
    PreviousMessageListQueryParams,
    PushNotificationDeliveryOption,
    UserMessageCreateParams
} from '@sendbird/chat/message'
import {storeToRefs} from 'pinia'
import router from '@/router'
import * as Sentry from '@sentry/browser'
import {useGeneralStore} from '@/stores/general'
import {useNotificationsStore} from '@/stores/notifications'
import {useSendbirdStore} from '@/stores/sendbird'
import ChatMessage from '@/components/chats/ChatMessage.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import ChatDetailsToggle from '@/components/chats/ChatDetailsToggle.vue'
import ChatDetailsSupport from '@/components/chats/ChatDetailsSupport.vue'
import ChatDetailsService from '@/components/chats/ChatDetailsService.vue'
import ChatHeader from '@/components/chats/ChatHeader.vue'
import ChatInviteCarerModal from '@/components/chats/ChatInviteCarerModal.vue'
import ChatReply from '@/components/chats/ChatReply.vue'
import ChatReplyInactiveChannel from '@/components/chats/ChatReplyInactiveChannel.vue'
import ChannelItem from '@/components/chats/ChannelItem.vue'
import ChatCreateChannelModal from '@/components/chats/ChatCreateChannelModal.vue'
import ChannelTab from '@/types/ChannelTab'
import Session from '@/types/Session'
import TicketService from '@/services/TicketService'
import SessionService from '@/services/SessionService'
import UserService from '@/services/UserService'
import ChatAdminService from '@/services/settings/ChatAdminService'
import SendbirdService from '@/services/SendbirdService'
import SearchSelectUser from '@/components/SearchSelectUser.vue'
import User from '@/types/User'
import SendbirdChannel from '@/types/SendbirdChannel'
import {useRoute} from 'vue-router'
import AdminService from '@/services/users/AdminService'

const DEFAULT_LIMIT = 20


const serviceChannelTabs = [
    {type: ChatChannelType.SERVICE, label: 'Service', channels: [], unreadChannels: 0},
    {type: ChatChannelType.SUPPORT, label: 'Support', channels: [], unreadChannels: 0}
]

const recruitmentChannelTabs = [
    {type: ChatChannelType.RECRUITMENT, label: 'Recruitment', channels: [], unreadChannels: 0}
]

const channelTabs = ref<Array<ChannelTab>>(serviceChannelTabs)

const props = defineProps({
    floating: {type: Boolean, default: false}
})

const emit = defineEmits(['updateUnread'])

const route = useRoute()
const {addToastNotification} = useNotificationsStore()
const {user} = storeToRefs(useGeneralStore())
const {sendbird, isSendbirdConnected, errors: sendbirdErrors} = storeToRefs(useSendbirdStore())
const messages = ref<BaseMessage[]>([])
const currentChannel = ref<GroupChannel | null>(null)
const isCurrentChannelActive = ref<boolean>(true)
const bannedUsers = ref<RestrictedUser[]>([])
const currentType = ref<string>(channelTabs.value[0].type)
const isLoadingMain = ref<boolean>(false)
const isLoadingChannel = ref<boolean>(false)
const isFetchingPreviousMessages = ref<boolean>(false)
const isFetchingChannels = ref<boolean>(false)
const isMarkingAsRead = ref<boolean>(false)
const inputFile = ref<HTMLInputElement>()
const inputMessage = ref<string>('')
const userMetadata = ref<any>()
const channelMetadata = ref<any>()
const chatBox = ref<HTMLElement>()
const channelList = ref<HTMLElement>()
const chatAdmins = ref<Array<any>>([])
const admins = ref<Array<any>>([])
const bookings = ref<Array<any>>([])
const session = ref<Session>()
const ongoingSessions = ref<Array<any>>([])
const upcomingSessions = ref<Array<any>>([])
const completedSessions = ref<Array<any>>([])
const channelMembers = ref<Member[]>()
const sideDetailOpen = ref<Boolean>(false)
const groupChannelQuery = ref<GroupChannelListQuery>()
const isSuccessfullyInvited = ref<boolean>(false)

const inputSearch = ref<string>('')
const searchSelectedUser = ref<User>()
const searchChannelsMode = ref<'channelName' | 'user'>('channelName')
const isViewingArchivedChannels = ref(false)


onBeforeMount(() => {
    initChannelTypeForDepartment()
})

const hasCollapsedSidebar = ref(false)
const toggleCollapseLeftSidebar = () => hasCollapsedSidebar.value = !hasCollapsedSidebar.value

const channels = computed<GroupChannel[]>(() => getChannelTabByType(currentType.value).channels)

const setIsFetchingChannels = (value = true) => isFetchingChannels.value = value
const toggleViewingArchivedChannels = () => isViewingArchivedChannels.value = !isViewingArchivedChannels.value

const chatSearchResultMessage = computed(function () {

    let message

    if (!isFetchingChannels.value) {
        if (searchChannelsMode.value === 'user' && !searchSelectedUser.value) {
            message = 'Please search and select a user'
        } else if (channels.value.length > 0 && (!!inputSearch.value || !!searchSelectedUser.value)) {
            message = `${ channels.value.length } chat${ channels.value.length > 1 ? 's' : '' } found`
        } else if (channels.value.length === 0 && !!inputSearch.value) {
            message = 'No chat found. Please try other keyword.'
        } else if (channels.value.length === 0 && !!searchSelectedUser.value) {
            message = 'No chat found. Please try other user.'
        }
    }

    return message
})

const toggleSearchChannelsMode = () => {

    resetCurrentTabChannels()

    const prev = searchChannelsMode.value

    if (prev === 'channelName') {
        searchChannelsMode.value = 'user'
        inputSearch.value = ''
    } else {
        searchChannelsMode.value = 'channelName'
        searchSelectedUser.value = undefined
        fetchChannels(currentType.value)
    }
}

const resetCurrentTabChannels = () => {
    const channelTab = getChannelTabByType(currentType.value)
    channelTab.channels = []
}

const handleSearchSelectedUser = (user: User | undefined) => {

    if (!user) {
        resetCurrentTabChannels()
        return
    }

    fetchChannelsForUser(user)
}

const updateChannelType = async (channelType: string) => {
    currentType.value = channelType

    await fetchChannels(channelType)
    if (channels.value.length && !props.floating && !route.query.channelType) {
        await setCurrentChannel(channels.value[0])
    } else {
        currentChannel.value = null
    }

    router.replace({'query': {}}) // this will clear the query params
}

const updateUnreadChannels = () => {
    let hasUnread = false
    channelTabs.value.forEach((channelTab: ChannelTab, index: number) => {
        const unreadChannels: number = channelTab.channels.reduce((accumulator: number, channel: GroupChannel) => {
            return accumulator + (channel.unreadMessageCount > 0 ? 1 : 0)
        }, 0)

        channelTabs.value[index].unreadChannels = unreadChannels

        if (unreadChannels > 0) {
            hasUnread = true
        }
    })

    emit('updateUnread', hasUnread)
}

const initSendbird = async (isWindowFocus: boolean = false) => {
    if (!isWindowFocus) {
        isLoadingMain.value = true
    }

    const groupChannelHandler: GroupChannelHandler = new GroupChannelHandler({
        onMessageReceived: (channel: BaseChannel, message: BaseMessage) => {
            if (channel.url === currentChannel.value?.url) {
                messages.value.push(message)
            }

            const channelTabIndex = getChannelTabIndexByType(channel.customType)
            const channelIndex = channelTabs.value[channelTabIndex].channels.findIndex((findChannel: GroupChannel) => findChannel.url === channel.url)

            if (channelIndex !== -1) {
                channelTabs.value[channelTabIndex].channels.splice(channelIndex, 1)
                channelTabs.value[channelTabIndex].channels.unshift(channel as GroupChannel)
            }

            if (
                import.meta.env.VITE_CHAT_SUPPORT_NOTIFICATION_ENABLED === 'true'
                && channel.customType === ChatChannelType.SUPPORT
            ) {

                addToastNotification({
                    message: `New messages in Support Channel ${ channel.name }`,
                    type: 'info',
                    actionType: 'link',
                    actionUrl: `/chats?channelType=${ ChatChannelType.SUPPORT }&channelUrl=${ channel.url }`,
                    actionText: 'View Chat',
                    actionTarget: '_self'
                }, 'chat')

            }
        },

        onTypingStatusUpdated: (channel: GroupChannel) => {
            if (currentChannel.value?.url === channel.url) {
                currentChannel.value = channel
            }

            if (currentType.value === channel.customType) {
                const channelTabIndex = getChannelTabIndexByType(channel.customType)
                const channelIndex = channelTabs.value[channelTabIndex].channels.findIndex((findChannel: GroupChannel) => findChannel.url === channel.url)

                if (channelIndex !== -1) {
                    channelTabs.value[channelTabIndex].channels[channelIndex] = channel as GroupChannel
                }
            }
        },

        onUnreadMemberStatusUpdated: (channel: GroupChannel) => {

        },

        onMessageDeleted: (channel: BaseChannel, messageId: number) => {
            messages.value = messages.value.filter((findMessage => findMessage.messageId !== messageId))
        },

        onChannelChanged: (channel: BaseChannel) => {
            const channelTabIndex = getChannelTabIndexByType(channel.customType)
            const channelIndex = channelTabs.value[channelTabIndex].channels.findIndex((findChannel: GroupChannel) => findChannel.url === channel.url)

            if (channelIndex !== -1) {
                channelTabs.value[channelTabIndex].channels[channelIndex] = channel as GroupChannel
            }
        },

        onMetaDataCreated: (channel: BaseChannel, metaData: MetaData) => {
            if (currentChannel.value?.url === channel.url) {
                isCurrentChannelActive.value = setCurrentChannelActive(metaData)
            }
        },

        onMetaDataUpdated: (channel: BaseChannel, metaData: MetaData) => {
            if (currentChannel.value?.url === channel.url) {
                isCurrentChannelActive.value = setCurrentChannelActive(metaData)
            }
        }
    })

    sendbird.value.groupChannel.addGroupChannelHandler('group-handler', groupChannelHandler)

    await fetchChannels('internal')
    await fetchChannels('support')
    await fetchChannels('service')
    await fetchChannels(ChatChannelType.RECRUITMENT)

    if (channels.value.length && !props.floating) {
        if (currentChannel.value === undefined) {
            await setCurrentChannel(channelTabs.value[0].channels[0])
        } else {
            const channel = channels.value.find((findChannel: BaseChannel) => findChannel.url === currentChannel.value?.url)
            if (channel !== undefined) {
                await setCurrentChannel(channel)
            }
        }

        if (route.query.channelType) {
            const channelTab = channelTabs.value.find((channelTab: ChannelTab) => channelTab.type === route.query.channelType as string)
            if (channelTab !== undefined) {
                await updateChannelType(route.query.channelType as string)

                // If channel url is provided
                if (route.query.channelUrl && channels.value.length) {
                    const channel = channels.value.find((findChannel: BaseChannel) => findChannel.url === route.query.channelUrl as string)
                    if (channel !== undefined) {
                        await setCurrentChannel(channel)
                    }
                }
            }
        }
    }

    isLoadingMain.value = false
}

const searchChannel = _.debounce(350, () => {
    fetchChannels(currentType.value)
})

const getSearchChannelParams = (channelType: string): GroupChannelListQueryParams => {
    return {
        includeEmpty: true,
        includeMetaData: true,
        myMemberStateFilter: MyMemberStateFilter.ALL,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        customTypesFilter: [channelType],
        limit: DEFAULT_LIMIT,
        hiddenChannelFilter: isViewingArchivedChannels.value ? HiddenChannelFilter.HIDDEN : HiddenChannelFilter.UNHIDDEN
    }
}

const fetchChannelsForUser = async (user: User | undefined) => {

    if (!user) {
        return
    }

    setIsFetchingChannels()

    const userId = user.id

    const channelTab = getChannelTabByType(currentType.value)

    // Fetch list of user's Sendbird Channels
    const sendbirdCustomTypeId = currentType.value === ChatChannelType.SERVICE ? SendbirdCustomTypeEnum.ID_SERVICE : SendbirdCustomTypeEnum.ID_SUPPORT
    const response = await SendbirdService.index({user_id: userId, custom_type: sendbirdCustomTypeId})

    // Pluck user's Sendbird Channel Urls
    const channelUrls = response.data.data?.map((channel: SendbirdChannel) => channel.channel_url)

    // If user has channel urls, fetch channels
    if (channelUrls.length) {
        let searchChannelParams = getSearchChannelParams(currentType.value)
        searchChannelParams.channelUrlsFilter = channelUrls

        groupChannelQuery.value = sendbird.value?.groupChannel.createMyGroupChannelListQuery(searchChannelParams)

        if (groupChannelQuery.value.hasNext) {
            channelTab.channels = await groupChannelQuery.value.next()
        }
    } else {
        channelTab.channels = []
    }

    setIsFetchingChannels(false)
}

const fetchChannels = async (channelType: string) => {

    let searchChannelParams = getSearchChannelParams(channelType)

    if (inputSearch.value) {
        searchChannelParams.channelNameContainsFilter = inputSearch.value
    }

    if (isSendbirdConnected.value && sendbird.value?.groupChannel) {
        groupChannelQuery.value = sendbird.value?.groupChannel.createMyGroupChannelListQuery(searchChannelParams)

        if (groupChannelQuery.value.hasNext) {
            const channelTab = getChannelTabByType(channelType)
            channelTab.channels = await groupChannelQuery.value.next()
        }
    }

}

const getChannelTabByType = (type: string) => {
    const channelTab = channelTabs.value.find((channelTab: any) => channelTab.type === type)
    if (channelTab) {
        return channelTab
    }

    return {
        type: '',
        label: '',
        channels: []
    }
}

const getChannelTabIndexByType = (type: string) => channelTabs.value.findIndex((channelTab: any) => channelTab.type === type)

const initChannelTypeForDepartment = () => {
    if (route.params.department === ChatChannelDepartment.RECRUITMENT) {
        channelTabs.value = recruitmentChannelTabs
    } else {
        channelTabs.value = serviceChannelTabs
    }

    updateChannelType(channelTabs.value[0].type)
}

const fetchPreviousMessages = async () => {
    isFetchingPreviousMessages.value = true

    if (messages.value.length) {
        const params: MessageListParams = {
            prevResultSize: 20,
            nextResultSize: 0
        }

        const prevMessages = await currentChannel.value?.getMessagesByTimestamp(messages.value[0].createdAt, params)
        if (prevMessages) {
            messages.value = prevMessages.concat(messages.value)
        }
    }

    isFetchingPreviousMessages.value = false
}

const setCurrentChannel = async (channel: GroupChannel) => {
    if (currentChannel.value?.url === channel.url) {
        return
    }

    isLoadingChannel.value = true

    currentChannel.value = channel

    const params: PreviousMessageListQueryParams = {
        limit: DEFAULT_LIMIT,
        reverse: false
    }
    const query: PreviousMessageListQuery = channel.createPreviousMessageListQuery(params)

    channelMetadata.value = currentChannel.value?.cachedMetaData as any

    // Get userId from channel url
    const userId = currentChannel.value.url.match(/\d+/)?.[0]

    const result = await Promise.all([
        query.load(),
        UserService.show(channelMetadata.value.userId ?? parseInt(userId ?? ''))
    ])

    messages.value = result[0]
    userMetadata.value = result[1]?.data?.data

    if (currentChannel.value?.customType === 'service') {
        const sessionId = currentChannel.value.url.match(/\d+$/)?.[0]

        await SessionService.show(parseInt(sessionId ?? ''))
            .then(({data: {data}}) => {
                session.value = data
            })
            .catch(() => {
                addToastNotification({
                    message: `Failed to fetch session. Please try again.`,
                    type: 'danger'
                })
            })
    }

    await getChannelMembers()

    isCurrentChannelActive.value = setCurrentChannelActive(currentChannel.value?.cachedMetaData)
    isLoadingChannel.value = false

    nextTick(() => {
        const el = document.getElementById('unread-divider') as HTMLElement || null
        el?.scrollIntoView({behavior: 'smooth'})
    })
}

const refreshChannels = (inputSearchText?: string, channelType?: string) => {
    if (inputSearchText) {
        inputSearch.value = inputSearchText
    }

    if (channelType) {
        router.replace({query: {channelType}})
    } else {
        channelType = currentType.value
    }

    updateChannelType(channelType)
}

const endTypingTimer = ref<any>()

const sendMessage = async () => {
    if (inputMessage.value) {
        const params: UserMessageCreateParams = {
            message: inputMessage.value,
            customType: currentChannel.value?.customType,
            pushNotificationDeliveryOption: PushNotificationDeliveryOption.DEFAULT
        }

        currentChannel.value?.sendUserMessage(params)
            .onSucceeded((message: BaseMessage) => {
                messages.value.push(message)
                inputMessage.value = ''
            })
            .onFailed((err) => {
                console.log('sentry err:', err)

                Sentry.captureException(err)

                addToastNotification({
                    message: 'Failed to send message. Please try again.',
                    type: 'danger'
                })
            })
    }
}

const uploadFile = async (event: Event) => {
    const input = event.target as HTMLInputElement

    if (!input || !input.files) {
        return
    }

    const file = input.files[0]
    if (file.size > 2000000) {
        addToastNotification({
            message: 'The image may not be greater than 2 MB.',
            type: 'danger'
        })

        return
    }

    isLoadingChannel.value = true
    await currentChannel.value?.sendFileMessage({
        file,
        fileName: file.name,
        mimeType: file.type,
        pushNotificationDeliveryOption: PushNotificationDeliveryOption.DEFAULT
    }).onSucceeded((message) => {
        messages.value.push(message)
    }).onFailed(() => {
        addToastNotification({
            message: 'Failed to upload file. Please try again',
            type: 'danger'
        })
    })

    isLoadingChannel.value = false
}

const getMyLastUnread = (myLastRead: number) => {
    if (currentChannel.value?.unreadMessageCount) {
        const lastReadIndex: number = messages.value.findIndex((message: BaseMessage) => message.createdAt === myLastRead)
        const nextReadIndex = messages.value.length - 1 > lastReadIndex ? lastReadIndex + 1 : lastReadIndex

        return messages.value[nextReadIndex].createdAt
    } else {
        return myLastRead
    }
}

const chatContainerStyle = computed(() => {
    if (!props.floating) {
        return ['position: fixed', 'top: 132px']
    }
})

const hideOnFloatActiveChannel = computed(() => {
    if (props.floating) {
        return (currentChannel.value === undefined || currentChannel.value === null)
    } else {
        return true
    }
})

async function getChannelMembers() {
    const queryParams: MemberListQueryParams = {
        limit: 40,
        order: MemberListOrder.OPERATOR_THEN_MEMBER_ALPHABETICAL
    }
    const query = currentChannel?.value?.createMemberListQuery(queryParams)

    channelMembers.value = await query?.next()
}

function inviteUser(userId: number, isOperator: boolean) {
    const data = {
        channelUrl: currentChannel.value?.url,
        memberUserId: userId,
        customerOnlineId: currentType.value == 'service' ? userMetadata.value?.user?.mainProfile?.fullName : null,
        customType: currentType.value,
        isOperator
    }

    TicketService.inviteUser(data)
        .then(() => {
            addToastNotification({message: 'User has been invited to this channel', type: 'success'})
            getChannelMembers()
            isSuccessfullyInvited.value = true
        })
        .catch((err) => {
            addToastNotification({message: err, type: 'danger'})
        })
        .finally(() => isSuccessfullyInvited.value = false)
}

function banUser(userId: number) {
    currentChannel?.value?.banUserWithUserId(userId.toString(), 2, 'Remove from channel')
        .then(() => {
            addToastNotification({message: 'User has been removed from this channel', type: 'success'})
            getChannelMembers()
        })
        .catch((err) => {
            addToastNotification({message: err, type: 'danger'})
        })
}

async function deleteMessage(message: BaseMessage) {
    await currentChannel?.value?.deleteMessage(message)
}

const handleReplyBoxInput = (event: Event) => {
    clearTimeout(endTypingTimer.value)
    currentChannel.value?.startTyping()
}

const handleReplyBoxKeyUp = (event: Event) => {
    clearTimeout(endTypingTimer.value)
    endTypingTimer.value = setTimeout(() => {
        currentChannel.value?.endTyping()
    }, 250)
}

const handleReplyBoxFocus = (event: Event) => {
    if (currentChannel.value && currentChannel.value.unreadMessageCount > 0 && !isMarkingAsRead.value) {
        isMarkingAsRead.value = true
        currentChannel.value.markAsRead()
        isMarkingAsRead.value = false
    }

    const el = event.target as HTMLInputElement
    el.setAttribute('rows', '6')
    chatBox.value?.style.setProperty('padding-bottom', '72px')
}

const handleReplyBoxFocusOut = (event: Event) => {
    clearTimeout(endTypingTimer.value)
    endTypingTimer.value = setTimeout(() => {
        currentChannel.value?.endTyping()
    }, 250)

    if (inputMessage.value.length === 0) {
        chatBox.value?.style.setProperty('padding-bottom', '0')

        const el = event.target as HTMLInputElement
        el.setAttribute('rows', '1')
    }
}

const toggleSideDetail = () => {
    sideDetailOpen.value = !sideDetailOpen.value
}

const setCurrentChannelActive = (metaData: any) => !metaData.hasOwnProperty('isActive') || metaData.isActive === '1'

const getChatAdmins = async () => {
    try {
        const {data} = await ChatAdminService.index()
        chatAdmins.value = data.data
    } catch (e) {
        addToastNotification({
            message: `Failed to fetch chat admins. Please try again.`,
            type: 'danger'
        })
    }
}


// Get admins for support channels participants
const getAdmins = async () => {
    try {
        const {data} = await AdminService.index()
        admins.value = data.data
    } catch (e) {
        addToastNotification({
            message: `Failed to fetch chat admins. Please try again.`,
            type: 'danger'
        })
    }
}

const chatBoxScroll = async (e: Event) => {
    const element: HTMLElement = e.target as HTMLElement
    const maxTop = -(element.scrollHeight - element.clientHeight)

    if (Math.floor(element.scrollTop) <= maxTop && !isFetchingPreviousMessages.value) {
        await fetchPreviousMessages()
    }

    if (element.scrollTop >= -50) {
        if (currentChannel.value && currentChannel.value.unreadMessageCount > 0 && !isMarkingAsRead.value) {
            isMarkingAsRead.value = true
            await currentChannel.value.markAsRead()
            isMarkingAsRead.value = false
        }
    }
}

const channelListScroll = async (e: Event) => {
    const element: HTMLElement = e.target as HTMLElement
    const maxTop = element.scrollHeight - element.clientHeight

    if (element.scrollTop - maxTop >= 0 && groupChannelQuery.value?.hasNext && !isFetchingChannels.value) {
        isFetchingChannels.value = true
        const channelTabIndex = getChannelTabIndexByType(currentType.value)
        channelTabs.value[channelTabIndex].channels = channelTabs.value[channelTabIndex].channels.concat(await groupChannelQuery.value?.next())
        isFetchingChannels.value = false
    }
}

onMounted(() => {
    window.onfocus = () => {
        if (!isLoadingMain.value) {
            initSendbird(true)
        }
    }

    getChatAdmins()
    getAdmins()
})

watch(
    () => isSendbirdConnected.value,
    () => {
        if (isSendbirdConnected.value) {
            initSendbird()
        }
    }
)

watch(
    () => channelTabs.value,
    () => updateUnreadChannels(),
    {deep: true}
)

watch(() => route.params.department, initChannelTypeForDepartment)

watch(searchSelectedUser, handleSearchSelectedUser)
watch(isViewingArchivedChannels, () => {

        if (searchChannelsMode.value === 'channelName') {
            fetchChannels(currentType.value)
        } else if (searchChannelsMode.value === 'user') {
            fetchChannelsForUser(searchSelectedUser.value)
        }
    }
)

if (isSendbirdConnected.value) {
    initSendbird()
}

provide('deleteMessage', deleteMessage)
provide('session', session)
provide('ongoingSessions', ongoingSessions)
provide('upcomingSessions', upcomingSessions)
provide('completedSessions', completedSessions)
provide('channelMetadata', channelMetadata)
provide('toggleSideDetail', toggleSideDetail)
provide('currentChannel', currentChannel)
provide('setCurrentChannel', setCurrentChannel)
provide('reply', {inputMessage, inputFile, sendMessage, uploadFile})
provide('handleReplyBoxEvent', {
    handleReplyBoxInput,
    handleReplyBoxKeyUp,
    handleReplyBoxFocus,
    handleReplyBoxFocusOut
})

</script>

<style scoped>
.list-group-item {
    border-left: 2px solid transparent !important;
}

.chat-box {
    padding-bottom: 0;
    margin-bottom: 80px;
}

.chat-container {
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: -20px;
    margin-bottom: -20px;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-body > :last-child {
    margin-bottom: 40px;
}
</style>
