import httpClient from './HttpClient'
import httpClientV2 from './HttpClientV2'
import CommonQuery from '@/types/query/CommonQuery'
import { getQueryString } from '@/composable/http'

export default {
    getCustomers(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/customers?${queryString}`)
    },

    getCarers(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/carers?${queryString}`)
    },

    getBookings(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/bookings?${queryString}`)
    },

    getInvoices(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/invoices?${queryString}`)
    },

    getInvoiceWeekly(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/invoice-weekly?${queryString}`)
    },

    getJobs(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/jobs?${queryString}`)
    },

    getPayouts(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/payouts?${queryString}`)
    },

    getPayoutExtra(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/payout-extra?${queryString}`)
    },

    getReviews(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClientV2.get(`backoffice/session-reviews?${queryString}`)
    },

    generateReport(type: string, query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/reports/generate/${type}?${queryString}`)
    }
}
