<template>
    <div class="row mb-3 align-items-end gap-3 gap-md-0">
        <div class="col-12 col-md">
            <label class="form-label">Description</label>
            <input class="form-control" placeholder="Search description" type="text" v-model="inputFilter.description" @keyup.enter="fetch">
        </div>
        <div class="col-12 col-md">
            <label class="form-label">State</label>
            <select 
                class="form-select"
                v-model.number="inputFilter.state">
                <option disabled>Filter state</option>
                <option v-for="state in addressStates" :value="state.id" :key="state.id">{{ state.name }}</option>
            </select>
        </div>
        <div class="col-12 col-md">
            <label class="form-label">Start Date</label>
            <input class="form-control" placeholder="Start date" type="date" v-model="inputFilter.startDate" @keyup.enter="fetch">
        </div>
        <div class="col-12 col-md">
            <label class="form-label">End Date</label>
            <input class="form-control" placeholder="End date" type="date" v-model="inputFilter.endDate" @keyup.enter="fetch">
        </div>

        <div class="col-12 col-md-auto ms-auto">
            <button-filter @reset="resetFilter" @filter="fetch" :is-loading="isFetchingHolidays"></button-filter>
        </div>
    </div>

    <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Holiday</th>
                    <th>State</th>
                    <th class="text-end">Action</th>
                </tr>
            </thead>
            <tbody v-if="holidays.length > 0">
                <tr v-for="holiday in holidays" :key="holiday.id">
                    <td>{{ formatDate(holiday.date, 'DD-MMM-YYYY') }}</td>
                    <td>{{ holiday.description }}</td>
                    <td>{{ holiday.state.name }}</td>
                    <td class="text-end">
                        <div class="btn-group">
                            <router-link class="btn btn-icon btn-primary" :to="{ name: 'holiday-edit', params: { holidayId: holiday.id } }">
                                <icon name="edit" />
                            </router-link>
                            <button class="btn btn-icon btn-danger" @click.prevent="deleteHoliday(holiday)">
                                <icon name="trash" />
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="6" class="text-center">No data</td>
                </tr>
            </tbody>
            <overlay-spinner :is-showing="isFetchingHolidays" />
        </table>
    </div>
    <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import HolidayService from '@/services/settings/HolidayService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import AddressState from '@/types/AddressState'
import UserFilter from '@/types/Filter'
import Holiday from '@/types/Holiday'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

const { addToastNotification } = useNotificationsStore()
const { addressStates } = storeToRefs(useResourcesStore())
const addressStateOptions = ref<Array<AddressState>>([])

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const isFetchingHolidays = ref<boolean>(false)
const holidays = ref<Array<Holiday>>([])

const inputFilter = ref<UserFilter>({
    description: '',
    state: '',
    startDate: '',
    endDate: '',
})

function deleteHoliday (holiday: Holiday) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete ${holiday.description} holiday?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            HolidayService.destroy(holiday.id)
                .then(() => {
                    fetch()
                    addToastNotification({
                        message: `Holiday ${holiday.description} deleted`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetch()
    }
)

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetch()
}

function fetch () {
    isFetchingHolidays.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    HolidayService.index(query)
        .then(({ data: { data, meta } }) => {
            holidays.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingHolidays.value = false
        })
}

fetch()
</script>