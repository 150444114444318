import httpClient from '@/services/HttpClient'
import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'

export default {
    index(query: UserQuery = {}) {
        const queryString = encodeURI(Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&'))
        
        return httpClient.get(`/admin/settings/holidays?${queryString}`)
    },

    store(data: object = {}) {
        return httpClientV2.post('backoffice/holidays', data)
    },

    update(id: number, data: object = {}) {
        return httpClientV2.patch(`backoffice/holidays/${id}`, data)
    },

    show(id: number) {
        return httpClient.get(`admin/settings/holidays/${id}`);
    },

    destroy(id: number) {
        return httpClient.delete(`admin/settings/holidays/${id}`)
    },

    upload(data: object = {}) {
        return httpClientV2.post('backoffice/holidays/upload', data)
    },
}