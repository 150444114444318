<template>
    <kc-modal :modal-id="`modal-add-remark-${jobId}`" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">Add Remark to Job {{ jobNo }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <form @submit.prevent="saveRemark">
            <div class="modal-body bg-white">
                <div class="col form-group mb-3">
                    <label class="form-label col-form-label">Remark</label>
                    <div class="col">
                        <textarea v-model="inputUser.remark" 
                            class="form-control" 
                            :class="{ 'is-invalid': hasError('remark') }" 
                            name="remark" rows="5" 
                            @focus="clearError('remark')">
                        </textarea>
                        <small class="invalid-feedback" v-if="hasError('remark')">{{ getError('remark') }}</small>
                    </div>
                </div>
            </div>

            <div class="modal-footer pt-3 border-top">
                <button class="btn btn-light" type="button" @click="closeModal" :id="`btnDismissModal${jobId}`" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="submit">
                    <span>Save</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { ref, onMounted } from 'vue'
import JobService from '@/services/JobService'
import UserInput from '@/types/Input'
import useFormError from '@/composable/useFormError'
import { useNotificationsStore } from '@/stores/notifications'
import { rules as JobRemarkRule, $externalResults } from '@/rules/JobRemarkRule'

const { addToastNotification } = useNotificationsStore()
const props = defineProps({
    jobId: { type: Number, required: true },
    jobNo: { type: String },
    currentRemark: { type: String, default: '' },
})
const inputUser = ref<UserInput>({
    remark: null,
})
const v$ = useVuelidate(JobRemarkRule, { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

onMounted(() => {
    inputUser.value.remark = props.currentRemark ?? null
})

async function saveRemark () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        JobService.updateRemark(props.jobId, inputUser.value)
            .then((data) => {
                addToastNotification({
                    message: 'Remark saved',
                    type: 'success',
                })
                closeModal()
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}

function closeModal () {
    document.getElementById(`btnDismissModal${props.jobId}`)?.click()
    clearAllErrors()
}
</script>