import httpClientV2 from './HttpClientV2'

export default {
    store(sessionId: number, data: { carerUserId: number }) {
        return httpClientV2.post(`/backoffice/sessions/${sessionId}/assigned-carer`, {
            carer_user_id: data.carerUserId
        })
    },
    destroy(sessionId: number) {
        return httpClientV2.delete(`/backoffice/sessions/${sessionId}/assigned-carer`)
    }
}
