<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Date Range</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range" readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar"/>
                        </span>
                    </div>
                </div>
                
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetctBookings" :is-loading="isLoading"></button-filter>
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button class="btn btn-primary" @click="generateReport">
                        <icon name="file-spreadsheet"/>
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Booking Date</th>
                            <th class="text-center">Session Date</th>
                            <th class="text-center">Session Time</th>
                            <th>Booking Status</th>
                            <th>Session Status</th>
                            <th>Booking No.</th>
                            <th>Discount Code</th>
                            <th>Job No</th>
                            <th>Customer</th>
                            <th>Carer</th>
                        </tr>
                    </thead>
                    <tbody v-if="bookings.length > 0">
                        <template v-for="booking in bookings" :key="booking.id">
                            <tr v-for="job in booking.jobs" :key="job">
                                <td>{{ formatDate(booking.created_at, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                                <td class="text-center">
                                    <div>{{ formatDate(job.started_at, 'DD-MMM-YYYY') }}</div>
                                    <div>{{ formatDate(job.started_at, '(dddd)') }}</div>
                                </td>
                                <td class="text-center">
                                    <div>{{ formatDate(job.started_at, 'hh:mm A') }} - {{ formatDate(job.ended_at, 'hh:mm A') }}</div>
                                    <div>({{ job.total_hours }} hours)</div>
                                </td>
                                <td class="text-center"><div class="badge badge-pill" :class="getBookingStatusBadge(booking.status)">{{ bookingStatuses.find(status => status.key === booking.status)?.name }}</div></td>
                                <td class="text-center"><div class="badge badge-pill" :class="getJobStatusBadge(job.status)">{{ jobStatuses.find(status => status.key === job.status)?.name }}</div></td>
                                <td>{{ booking.no }}</td>
                                <td>{{ booking.discount_code }}</td>
                                <td>{{ job.no }}</td>
                                <td>
                                    <div>{{ booking.user.email }}</div>
                                    <div>{{ booking.user.main_profile.full_name }}</div>
                                    <div>{{ booking.user.main_profile.online_id }}</div>
                                </td>
                                <td>
                                    <div>{{ job.carer?.email }}</div>
                                    <div>{{ job.carer?.main_profile.full_name }}</div>
                                    <div>{{ job.carer?.main_profile.online_id }}</div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="10" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isLoading" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { Core, easepick } from '@easepick/core'
import { dateObject, formatDate } from '@/composable/useDate'
import { onMounted, ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import useDateRangePicker from '@/composable/useDateRangePicker'
import ReportService from '@/services/ReportService'
import { useResourcesStore } from '@/stores/resources'
import { getJobStatusBadge } from '@/composable/useJob'
import useBooking from '@/composable/useBooking'

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
const bookings = ref<Array<any>>([])
const inputFilter = ref<UserFilter>({
    from_date: dateObject().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: dateObject().endOf('month').format(DEFAULT_DATE_FORMAT),
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { easePickDefaultOptions } = useDateRangePicker()
const { bookingStatuses, jobStatuses } = useResourcesStore()
const { getBookingStatusBadge } = useBooking()

const isLoading = ref<boolean>(false)

fetctBookings()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetctBookings()
    }
)

onMounted(() => {
    console.log(bookingStatuses)
    console.log(jobStatuses)
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value = {
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT),
                }
                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})

function resetFilter () {
    inputFilter.value = {
        from_date: dateObject().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: dateObject().endOf('month').format(DEFAULT_DATE_FORMAT),
    }

    fetctBookings()
}

function fetctBookings () {
    isLoading.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    ReportService.getBookings(query)
        .then(({ data }) => {
            let meta = {
                current_page: data.current_page,
                last_page: data.last_page,
                from: data.from,
                to: data.to,
                total: data.total,
            }

            bookings.value = data.data
            updateMetaPage(meta)
        })
        .finally(() => {
            isLoading.value = false
        })
}

function generateReport () {
    isLoading.value = true
    const query = {
        ...inputFilter.value,
    }

    ReportService.generateReport('bookings', query)
        .then(({ data: { downloadUrl } }) => window.open(downloadUrl, '_blank'))
        .finally(() => isLoading.value = false)
}
</script>
