<template>
    <div class="row pt-3 gx-4">
        <overlay-spinner :is-showing="isFetchingHolidays" />
        <div class="col-md-9" v-if="!isFetchingHolidays">
            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
        </div>
        <div class="col-md-3">
            <h1 class="pt-1 pb-2">Holiday on {{ date.format(selectedDate, 'DD MMMM YYYY') }}</h1>
            <ul class="list-unstyled" v-if="eventsOnSelectedDate.length > 0">
                <li v-for="(event, index) in eventsOnSelectedDate" :key="index" class="d-flex align-items-start space-x-3 py-3 border-bottom">
                    <icon name="calendar" class="text-blue"/>
                    <div>
                        <h4 class="mb-0">{{ event.description }}</h4>
                        <p class="mb-0">{{ event.state.name }}</p>
                    </div>
                </li>
            </ul>
            <p v-else>There is no holiday on selected date</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Holiday from '@/types/Holiday'
import HolidayService from '@/services/settings/HolidayService'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import date from '@/utils/date'

const isFetchingHolidays = ref<boolean>(false)
const holidays = ref<Array<Holiday>>([])
const eventsOnSelectedDate = ref<Array<any>>([])
const selectedDate = ref<string>()

fetch()

let calendarOptions = <any>{
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    weekends: false, // initial value
    dateClick: selectDate,
    events: [
        // { title: 'event 1', date: '2022-08-16' }
    ],
}

onMounted(() => {
    selectedDate.value = date.today('YYYY-MM-DD')
    selectDate(selectedDate)
})

function fetch () {
    isFetchingHolidays.value = true

    HolidayService.index()
        .then(({ data: { data, meta } }) => {
            holidays.value = data
            calendarOptions.events = holidays.value.map((holidayEvent: any) => {
                return {
                    title: holidayEvent.description,
                    date: holidayEvent.date,
                }
            })
        })
        .finally(() => {
            isFetchingHolidays.value = false
        })
}

function selectDate (date: any) {
    selectedDate.value = date.dateStr
    eventsOnSelectedDate.value = holidays.value.filter((holidayEvent: any) => {
        return holidayEvent.date === selectedDate.value
    })
}
</script>