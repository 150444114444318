<template>
    <router-link :to="redirectTo" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Sessions</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingSession" v-if="isFetchingSession"/>
</template>

<script setup lang="ts">
import { useNotificationsStore } from '@/stores/notifications'
import Session from '@/types/Session'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const sessionId = Number(route.params.sessionId)
const session = ref<Session>()
const isFetchingSession = ref(false)

const redirectTo = {
    name: 'booking-index'
}

function fetchSession () {
    isFetchingSession.value = true
}

fetchSession()
</script>