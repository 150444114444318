<template>
    <div>
        <template v-if="!isShowingUnlockConfirmation && !isShowingLockConfirmation">
            <div v-if="application.lockEndAt"
                 class="flex-column text-center pt-1"
            >
                <button class="btn btn-outline-danger text-center"
                        @click.prevent="setIsShowingUnlockConfirmation"
                >
                    <icon name="lock"/>
                </button>
                <p class="mt-1">{{ formatDate(application.lockEndAt, 'DD-MMM-YYYY hh:mm:ss A') }}</p>
            </div>
            <div v-else
                 class="flex-column text-center pt-1"
            >
                <button class="btn btn-outline-primary text-center"
                        @click.prevent="setIsShowingLockConfirmation"
                >
                    <icon name="lock-open"/>
                </button>
            </div>
        </template>
        <div v-if="isShowingUnlockConfirmation" class="flex-column text-center pt-1">
            <p>Unlock?</p>
            <div class="row">
                <button class="btn btn-sm btn-outline-secondary text-center col-sm "
                        @click.prevent="setIsShowingUnlockConfirmation(false)"
                >
                    Cancel
                </button>
                <button class=" btn btn-sm btn-danger text-center col-sm "
                        @click.prevent="confirmUnlockApplication"
                >
                    CONFIRM
                </button>
            </div>
        </div>
        <div v-if="isShowingLockConfirmation" class="flex-column text-center pt-1">
            <div class="row">
                <FormLabel label="Lock for how many minutes?"/>
                <input type="number" v-model="lockDurationMinutes">
            </div>
            <div class="row mt-2">
                <button class="btn btn-sm btn-outline-secondary text-center col-sm "
                        @click.prevent="setIsShowingLockConfirmation(false)"
                >
                    Cancel
                </button>
                <button class=" btn btn-sm btn-danger text-center col-sm "
                        @click.prevent="confirmLockApplication"
                >
                    CONFIRM
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import {ref} from 'vue'
import CarerApplication from '@/types/CarerApplication'
import FormLabel from '@/components/form/FormLabel.vue'
import {useNotificationsStore} from '@/stores/notifications'
import {useSessionCarerApplicationStore} from '@/stores/sessionCarerApplication'
import SessionCarerApplicationLockService from '@/services/SessionCarerApplicationLockService'
import { formatDate } from '@/composable/useDate'

const {addToastNotification} = useNotificationsStore()
const store = useSessionCarerApplicationStore()
const {updateApplicationDetail} = store

const props = defineProps({
    application: {
        type: Object as () => CarerApplication,
        required: true
    }
})

const lockDurationMinutes = ref(0)
const resetInputs = () => lockDurationMinutes.value = 0

const isShowingUnlockConfirmation = ref(false)
const setIsShowingUnlockConfirmation = (value = true) => isShowingUnlockConfirmation.value = value

const isShowingLockConfirmation = ref(false)
const setIsShowingLockConfirmation = (value = true) => isShowingLockConfirmation.value = value

const confirmLockApplication = async () => {
    if (props.application?.id) {
        try {
            const response = await SessionCarerApplicationLockService.lock(props.application.id, {
                lock_duration_minutes: lockDurationMinutes.value
            })

            const data = response.data.data

            const updated = await updateApplicationDetail(data)

            if (updated.success) {
                addToastNotification({
                    type: 'success',
                    message: `Carer ${ data.carer?.name }'s application for Session ${ data.session?.no } has been locked.`
                })
            }

            setIsShowingLockConfirmation(false)

            resetInputs()

        } catch (e) {
            addToastNotification({
                type: 'danger',
                message: 'Failed to lock the carer\'s application'
            })
        }
    }
}

const confirmUnlockApplication = async () => {
    if (props.application?.id) {
        try {
            const response = await SessionCarerApplicationLockService.unlock(props.application.id)

            const data = response.data.data

            const updated = await updateApplicationDetail(data)

            if (updated.success) {
                addToastNotification({
                    type: 'success',
                    message: `Carer ${ data.carer?.name }'s application for Session ${ data.session?.no } has been unlocked.`
                })
            }

            setIsShowingUnlockConfirmation(false)

            resetInputs()
        } catch (e) {
            addToastNotification({
                type: 'danger',
                message: 'Unlocking the carer\'s application. Please wait.'
            })
        }
    }
}

</script>
