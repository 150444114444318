import UserProfile from '@/types/UserProfile'
import { add } from 'lodash'

export function getMainProfile(profiles: Array<UserProfile>): UserProfile|any {
    return profiles.find(profile => profile.type === 'main')
}

export function isValidMobileNo(val: string) {
    if (!val) return false

    let mobileNo = val.split(/\D/).join('')
    return ((/(^\+)[1-9]/).test(mobileNo) || (/(^01)(\d{8,})/).test(mobileNo) || (/(60)(\d{9,})/).test(mobileNo))
}

export function getValidMobileNo(val: string) {
    if ((/(^\+)[1-9]/).test(val)) {
        return `${val.slice(1)}`
    }

    if ((/(^01)(\d{8,})/).test(val)) {
        return `6${val}`
    }

    if ((/(60)(\d{9,})/).test(val)) {
        return `${val}`
    }

    return ''
}

export function getMobileNo(val: string) {
    return val ? val : 'N/A'
}

export function getAddress(address: any): string {
    let addressString = ''

    if (address.unit_no !== null) {
        addressString += address.unit_no
    }

    if (address.floor_level !== null) {
        addressString += `, ${address.floor_level}`
    }

    if (address.block_building !== null) {
        addressString += `, ${address.block_building}`
    }

    const postcodeCityState = address.town !== address.state ? `${address.postcode} ${address.town}, ${address.state}` : `${address.postcode} ${address.state}`

    addressString += ` ${address.address_1}, ${postcodeCityState}`

    return addressString
}

export function fallbackAvatar(event: Event) {
    (event.target as HTMLInputElement).src = "../../avatar-default.png"
}
