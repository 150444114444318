<template>
    <overlay-spinner :is-showing="isFetchingCoupon" v-if="isFetchingCoupon"/>
    <template v-else>
        <coupon-form :coupon="coupon" :id="couponId"></coupon-form>
    </template>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import CouponForm from './CouponForm.vue'
import CouponService from '@/services/settings/CouponService'
import { useNotificationsStore } from '@/stores/notifications'
import Coupon from '@/types/Coupon'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const isFetchingCoupon = ref<boolean>(false)
const couponId = Number(route.params.couponId)
const coupon = ref<Coupon>()

onMounted(() => {
    isFetchingCoupon.value = true

    CouponService.show(couponId)
        .then(({ data: { data } }) => {
            coupon.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingCoupon.value = false)
})
</script>
