import httpClientV2 from '@/services/HttpClientV2'
import { getQueryString } from '@/composable/http'

export default {
    index(query = {}) {
        const queryString = getQueryString(query);
        
        return httpClientV2.get(`/backoffice/organizations?${queryString}`)
    },

    store(data: object = {}) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        
        return httpClientV2.post('/backoffice/organizations', data, config);
    },    

    show(id: number) {
        return httpClientV2.get(`/backoffice/organizations/${id}`);
    },

    update(id: number, data: object = {}) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        
        return httpClientV2.post(`/backoffice/organizations/update/${id}`, data, config);
    },
    
    destroy(id: number) {
        return httpClientV2.delete(`/backoffice/organizations/${id}`);
    }
}
