import httpClient from './HttpClient'
import CommonQuery from '@/types/query/CommonQuery'
import { getQueryString } from '@/composable/http'

export default {
    index(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClient.get(`admin/children?${queryString}`)
    },

    store(data: object = {}) {
        return httpClient.post('/admin/children', data)
    },

    update(id: number, data: object = {}) {
        return httpClient.patch(`/admin/children/${id}`, data)
    },

    destroy(id: number) {
        return httpClient.delete(`/admin/children/${id}`)
    }
}