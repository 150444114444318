<template>
    <div class="message-box">
        <template v-if="message.isUserMessage() || message.isFileMessage()">
            <li class="w-100 text-center position-relative mb-4" id="unread-divider" v-if="message.createdAt === myLastRead && unreadMessageCount">
                <small class="text-center border bg-light text-muted text-uppercase fw-bold px-3 py-1 unread-divider" style="border-radius: 12px">{{ unreadMessageCount }} Unread Message{{ unreadMessageCount > 1 ? 's' : '' }}</small>
            </li>
            <li :class="[{ 'ms-auto text-end': isSupportTeamMessage }, floating ? 'w-100' : 'w-50']" :key="message.messageId">
                <p class="mb-2 d-flex space-x-2 fs-5" :class="{ 'justify-content-end': isSupportTeamMessage }">
                    <span class="fw-bold">{{ message.sender.nickname }}</span>
                    <span class="text-muted">{{ formatDate(message.createdAt, DEFAULT_DATE_FORMAT) }}</span>
                </p>
                <div v-if="message.isFileMessage()">
                    <div class="position-relative d-flex space-x-2 message-bubble-wrapper" :class="isSupportTeamMessage ? 'justify-content-end' : 'justify-content-start'">
                        <icon name="trash" :stroke-width="1.2" 
                            class="cursor-pointer flex-shrink-0 text-muted delete-icon"
                            v-if="isSupportTeamMessage"
                            @click="confirmDeleteMessage(message)"/>
                        <p class="d-inline-block border py-2 px-3 rounded-bottom mb-0" :class="isSupportTeamMessage ? 'rounded-start' : 'rounded-end'">
                            <a :href="`${message.plainUrl}?auth=${sendbird.ekey}`" class="fw-bold d-flex inline-block" style="max-width: 300px" target="_blank" download>
                                <icon name="photo" class="me-2 flex-shrink-0" v-if="isImageFile(message.type)"/>
                                <icon name="file" class="me-2 flex-shrink-0" v-else/>
                                <span class="text-truncate">{{ message.name }}</span>
                            </a>
                        </p>
                        <icon name="trash" :stroke-width="1.2"
                              class="cursor-pointer flex-shrink-0 text-muted delete-icon mt-2"
                              v-if="! isSupportTeamMessage"
                              @click="confirmDeleteMessage(message)"/>
                    </div>
                    <a role="button" v-if="isImageFile(message.type)" class="d-flex chat-image-wrapper" :class="isSupportTeamMessage ? 'justify-content-end' : 'justify-content-start'" data-bs-toggle="modal" :data-bs-target="`#modal-show-image-${message.messageId}`">
                        <img :src="`${message.plainUrl}?auth=${sendbird.ekey}`"
                            class="my-2 rounded d-block chat-image"
                            :alt="message.name"
                            loading="lazy"/>
                    </a>
                    <chat-image-modal :modal-id="`modal-show-image-${message.messageId}`" :message="message" v-if="isImageFile(message.type)"></chat-image-modal>
                </div>
                <div class="position-relative d-flex space-x-2 mb-4 message-bubble-wrapper align-items-start" :class="isSupportTeamMessage ? 'justify-content-end' : 'justify-content-start'" v-else>
                    <icon name="trash" :stroke-width="1.2" 
                        class="cursor-pointer flex-shrink-0 text-muted delete-icon mt-2" 
                        v-if="isSupportTeamMessage"
                        @click="confirmDeleteMessage(message)"/>
                    <div v-html="renderMessage"
                        class="message-bubble d-inline-block border py-2 px-3 rounded-bottom text-start text-break"
                        :class="isSupportTeamMessage ? 'rounded-start' : 'rounded-end'">
                    </div>
                    <icon name="trash" :stroke-width="1.2"
                          class="cursor-pointer flex-shrink-0 text-muted delete-icon mt-2"
                          v-if="! isSupportTeamMessage"
                          @click="confirmDeleteMessage(message)"/>
                </div>
            </li>
        </template>
        <template v-if="message.isAdminMessage()">
            <li class="w-full" :key="message.messageId">
                <p class="mb-2 text-center fs-5">
                    <span class="text-muted">{{ formatDate(message.createdAt, DEFAULT_DATE_FORMAT) }}</span>
                </p>
                <div v-html="message.message" class="message-bubble mb-3 d-inline-block border bg-light text-muted py-2 px-3 rounded w-full"
                    style="white-space: pre-line"></div>
            </li>
        </template>
    </div>
</template>

<script setup lang="ts">

import { marked } from 'marked'
import { computed, inject } from 'vue'
import { BaseMessage, UserMessage } from '@sendbird/chat/message'
import { DEFAULT_DATE_FORMAT, formatDate } from '@/composable/useDate'
import ChatImageModal from '@/components/chats/ChatImageModal.vue'
import User from '@/types/User'
import { useSendbirdStore } from '@/stores/sendbird'
import { storeToRefs } from 'pinia'

const { sendbird } = storeToRefs(useSendbirdStore())
const deleteMessage: any = inject('deleteMessage')

const renderer = new marked.Renderer()
renderer.link = (href: string, title: string, text: string) => {
    return `<a target="_blank" href="${href}">${text}<a/>`
}

const markedOptions = marked.setOptions({
    async: true,
    renderer: renderer,
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: true,
    smartypants: false,
    xhtml: false
})

const props = defineProps({
    message: { type: Object as () => BaseMessage, required: true },
    myLastRead: { type: Number },
    unreadMessageCount: { type: Number },
    user: { type: Object as () => User, required: true },
    floating: { type: Boolean, default: false }
})

const isSupportTeamMessage = computed(() => (props.message as UserMessage)?.sender.userId === props.user.id.toString())

const isImageFile = (type: string) => {
    const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png']
    return allowedExtensions.includes(type)
}

const renderMessage = computed(() => {
    if (props.message.isUserMessage() || props.message.isAdminMessage()) {
        return marked.parse(props.message?.message)
    }
})

const confirmDeleteMessage = (message: BaseMessage) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete this message?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            deleteMessage(message)
        }
    })
}
</script>

<style scoped>
.chat-image-wrapper {
    /* min-width: calc(8px * 8); */
    /* max-width: calc(8px * 28); */
    max-height: calc(8px * 28);
    min-height: calc(8px * 8);
    margin-top: calc(8px / 2);
    margin-bottom: calc(8px / 4 * 3);
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
}

.chat-image {
    width: auto;
    max-width: calc(8px * 28);
    height: 100%;
}

.message-box .rounded-bottom {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.message-box .rounded-start {
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
}

.message-box .rounded {
    border-radius: 8px !important;
}

.message-box .rounded-end {
    border-top-right-radius: 8px !important;
}

.message-bubble :deep(p) {
    margin-bottom: 0 !important;
}

.message-bubble :deep(p+p) {
    margin-top: 1rem;
}

.message-bubble-wrapper>.delete-icon {
    display: none;
}

.message-bubble-wrapper:hover>.delete-icon {
    display: inline;
}
</style>
