<template>
    <div class="modal fade" role="dialog" tabindex="-1" :id="modalId" :data-bs-backdrop="modalBackdrop"
         :data-bs-keyboard="modalKeyboard">
        <div :class="`modal-dialog modal-${modalSize} ${verticallyCentered}`" role="document">
            <div :style="fullHeight ? 'height: 90dvh; overflow-y:scroll' : ''" class="modal-content">
                <div v-if="modalTitle" class="modal-header">
                    <div class="modal-title">
                        {{ modalTitle }}
                    </div>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div v-if="$slots.footer" class="modal-footer pt-2 border-top">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'

const props = defineProps({
    modalId: {type: String, required: true},
    modalSize: {type: String, default: 'sm'},
    modalBackdrop: {type: [String, Boolean], default: true},
    modalKeyboard: {type: Boolean, default: true},
    verticallyCentered: {type: Boolean, default: true},
    modalTitle: {type: String, required: false},
    fullHeight: {type: Boolean, default: false}
})

const verticallyCenteredClass = computed(() => props.verticallyCentered ? 'modal-dialog-centered ' : null)
</script>
