<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Reminder to Kiddocarer</h4>
            <CopyButton :data="scriptCarerReminder?.innerText ?? undefined"
                        label="Copy"
            />
        </div>

        <div class="card-body">
<pre style="max-height: 400px" id="scriptCarerReminder" ref="scriptCarerReminder">
**For Kiddocarer**

Arrival Time 
- Once you begin your trip, greet and notify the client of your ETA.
- You must arrive 15 minutes before session start time. 
- If you are going to be late, please let the client know.

Cancellation
- Penalty will be incurred for any cancellation

SOP on Arrival
- Greet the client / child.
- Avoid physically contact.
- Ask permission to change into clean clothes.
- Your things should be in your bag for hygiene.
- Sanitize before meeting the children.

SOP During Service
- Complete and verify the Service Checklist with the client.
- Check the area for possible dangers and make it safe.
- Make sure that the children and the environment are always safe and clean during service.
- Do not use other people’s eating utensils.
- Do not have any physical contact with anyone except the child you are looking after.
- Perform cleanliness and hygiene as suggested by the Ministry of Health.
- It is necessary to take care of the cleaning after activities.
- Update job status / progress every hour.
- At the end of session, complete the Service Report with the Client.

Important: The Service Report ensures that the client has checked and is satisfied with the condition of the child and house before you leave.
</pre>
        </div>
    </div>
</template>

<script setup lang="ts">

import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const scriptCarerReminder = ref<HTMLElement | null>(null)

</script>
