<template>
    <div class="form-group px-4 py-3">
        <FormLabel :label="label"/>
        <div class="row w-100">
            <div
                v-for="option in options"
                class="col-sm-4 col-md-3 col-lg-2 py-1"
            >
                <Checkbox v-model="checked"
                          :name="name"
                          :value="option.id"
                          :label="option.name"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import FormLabel from '@/components/form/FormLabel.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import {ref, watch} from 'vue'

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    name: {
        type: String,
        required: true
    },
    options: {
        type: Array,
        required: true,
        default: []
    },
    modelValue: {
        type: Array as () => Number[],
        default: []
    }
})

const emit = defineEmits(['update:modelValue'])

const checked = ref<Array<Number>>([])

watch(() => props.modelValue, () => checked.value = props.modelValue)
watch(() => checked.value, () => emit('update:modelValue', checked.value))

</script>
