<template>
    <div class="text-center mb-4">
        <a href="." class="navbar-brand navbar-brand-autodark"><img src="/kiddocare-logo.png" height="36" alt=""></a>
    </div>
    <form class="card card-md" autocomplete="off">
        <div class="card-body">
            <h2 class="card-title text-center mb-4">Login to your account</h2>
            <div class="mb-3">
                <label class="form-label">Email address</label>
                <input type="email" class="form-control" placeholder="Enter email" autocomplete="off" :disabled="isLogin" v-model="email" @focus="clearError">
            </div>
            <div class="mb-2">
                <label class="form-label">
                    Password
                    <span class="form-label-description">
                        <router-link :to="{ name: 'forgot-password-page'}">Forgot password</router-link>
                    </span>
                </label>
                <div class="input-group input-group-flat">
                    <input type="password" class="form-control" placeholder="Password"  autocomplete="off" ref="inputPassword" :disabled="isLogin" v-model="password" @focus="clearError">
                    <span class="input-group-text cursor-pointer">
                        <a class="link-secondary" title="Show password" data-bs-toggle="tooltip" @click="revealPassword">
                            <icon v-if="isPasswordRevealed" name="eye" />
                            <icon v-else name="eye-off" />
                        </a>
                    </span>
                </div>
            </div>
            <div class="mb-2">
                <label class="form-check">
                    <input type="checkbox" class="form-check-input" :disabled="isLogin" v-model="rememberMe" @focus="clearError" />
                    <span class="form-check-label">Remember me</span>
                </label>
            </div>
            <div class="form-footer">
                <button class="btn btn-primary w-100" :disabled="isLogin" @click.prevent="login">
                    <div v-if="! isLogin">Sign In</div>
                    <div class="d-flex align-self-center mx-auto" v-else>
                        <div class="spinner-border"></div>
                    </div>
                </button>
                <div class="d-flex justify-content-center mt-2">
                    <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </form>
    <footer class="footer footer-transparent d-print-none text-center">
        <span>Version {{ generalStore.backofficeVersion }}</span>
    </footer>
</template>

<script setup lang="ts">
import { PlatformEnum } from '@/enums/PlatformEnum'
import { ref } from 'vue'
import AuthService from '@/services/AuthService'
import router from '@/router'
import { useGeneralStore } from '@/stores/general'

const generalStore = useGeneralStore()

const inputPassword = ref<any>(null)

const email = ref<string>('')
const password = ref<string>('')
const rememberMe = ref<boolean>(false)
const isLogin = ref<boolean>(false)
const isPasswordRevealed = ref<boolean>(false)
const errorMessage = ref<string>('')

const revealPassword = () => {
    isPasswordRevealed.value = ! isPasswordRevealed.value
    inputPassword.value.type = isPasswordRevealed.value  ? 'text' : 'password'
}

const clearError = () => errorMessage.value = ''

const login = async () => {
    clearError()

    const input = {
        email: email.value,
        password: password.value,
        remember_me: rememberMe.value,
        platform_id: PlatformEnum.ID_BACKOFFICE,
        platform_type: PlatformEnum.TYPE_WEB,
    }

    if (input.email === '' || input.password === '') {
        return
    }

    try {
        isLogin.value = true

        await AuthService.getCsrfCookie()
        await AuthService.login(input)

        await router.push({ name: 'home-page'})
    } catch (ex: any) {
        const data = ex.response.data

        if (data.hasOwnProperty('error')) {
            errorMessage.value = data.error
        } else if (data.hasOwnProperty('message')) {
            errorMessage.value = data.message
        }
    } finally {
        isLogin.value = false
    }
}
</script>
