import httpClientV2 from '@/services/HttpClientV2'
import {getQueryString} from '@/composable/http'
import SendbirdChannelQuery from '@/types/query/SendbirdChannelQuery'
import {InviteUserToChatChannelFormData} from '@/types/formData/InviteUserToChatChannelFormData'
import {RemoveUserFromChatChannel} from '@/types/formData/RemoveUserFromChatChannel'

export default {
    index(query: SendbirdChannelQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`backoffice/sendbird-chat-channels?${ queryString }`)
    },
    createChannel(data: object = {}) {
        return httpClientV2.post('backoffice/sendbird-chat-channels', data)
    },
    inviteUser(data: InviteUserToChatChannelFormData) {
        return httpClientV2.post('backoffice/sendbird-chat-channels/invite-user', data)
    },
    removeUser(data: RemoveUserFromChatChannel) {
        return httpClientV2.put('backoffice/sendbird-chat-channels/remove-user', data)
    }
}
