<template>
    <div class="card" :class="{ 'card-borderless': !showTitle }">
        <div class="card-header">
            <h3 class="card-title" v-if="showTitle">Booking</h3>
            <div class="ms-auto" v-if="showAction">
                <router-link class="btn btn-primary float-right" :to="{ name: 'booking-create' }">
                    <icon name="plus" />
                    Add New Booking
                </router-link>
            </div>
        </div>
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label for="name" class="form-label">Booking No/Job Id</label>
                    <input type="text" placeholder="B202104-01250" class="form-control" v-model="inputFilter.job_no" @keyup.enter="fetchBookings">
                </div>
                <div class="col-12 col-md">
                    <label for="email" class="form-label">Customer ID</label>
                    <input type="email" placeholder="P20216288" class="form-control" v-model="inputFilter.customer_id" @keyup.enter="fetchBookings">
                </div>
                <div class="col-12 col-md">
                    <label for="email" class="form-label">From</label>
                    <div class="input-group">
                        <input type="text" id="inputFilterJobDateFrom" class="form-control bg-white" :value="jobDateFromDisplay" readonly>
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md">
                    <label for="email" class="form-label">To</label>
                    <div class="input-group">
                        <input type="text" id="inputFilterJobDateTo" class="form-control bg-white" :value="jobDateToDisplay" readonly>
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md">
                    <label for="email" class="form-label">Status</label>
                    <vue-select
                        :options="[{ name: 'Urgent', key: 'urgent' }, ...jobStatuses]"
                        label="name"
                        :reduce="(jobStatus: any) => jobStatus.key"
                        v-model="inputFilter.status">
                    </vue-select>
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchBookings" :is-loading="isFetchingBooking"></button-filter>
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />

            <div>
                <div class="table-responsive">
                    <table class="table card-table table-vcenter">
                        <template v-if="bookings.length">
                            <tbody v-for="booking in bookings" :key="booking.id">
                                <tr class="w-1">
                                    <booking-whatsapp-template :booking="booking" display="none"></booking-whatsapp-template>
                                    <td colspan="9" class="w-1 bg-dark-lt text-white" style="vertical-align: middle;">
                                        <span class="p-1">{{ toLocaleDate (booking.created_at, 'DD MMM YYYY h:mm A') }}</span>
                                        &nbsp;
                                        <span class="badge badge-outline text-uppercase p-1" :class="[isMobileBooking(getBookingPlatform(booking.extra_attributes)) ? 'text-teal' : 'text-yellow']">{{ getBookingPlatform (booking.extra_attributes) }}</span>
                                        &nbsp;
                                        <span><strong>{{ booking.no }}</strong></span>
                                        &nbsp;
                                        <span>[{{ getMainProfile (booking.user.profiles).online_id }}]</span>
                                        &nbsp;
                                        <span><router-link :to="{ 'name': 'customer-show', params: { customerId: booking.user.id } }" target="_blank" class="fw-bold">{{ getMainProfile (booking.user.profiles).full_name }}</router-link></span>
                                        &nbsp;
                                        <status-badge :show="booking.user.is_banned" label="Banned" color="red"></status-badge>
                                        <status-badge :show="booking.user.is_deleted" label="Deleted" color="gray"></status-badge>
                                        &nbsp;
                                        <span>({{ getMainProfile (booking.user.profiles).mobile_no ?? 'N/A' }})</span>
                                    </td>
                                    <td class="w-1 fs-5 bg-dark-lt text-white">
                                        <div class="btn-group">
                                            <router-link class="btn btn-icon btn-primary" :to="{ name: 'booking-show', params: { bookingId: booking.id } }">
                                                <icon name="eye" />
                                            </router-link>
                                            <a class="btn btn-icon btn-azure" role="button" data-bs-toggle="modal" data-bs-target="#modal-job-form" @click.prevent="modalJobMode = 'create'; modalJobFormBooking = booking">
                                                <icon name="plus" />
                                            </a>
                                            <a class="btn btn-icon btn-green" role="button" @click="showWhatsAppTemplate('booking-template', booking.no)">
                                                <icon name="brand-whatsapp" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="w-1"><input class="form-check-input m-0 align-middle" type="checkbox" aria-label="Select all bookings"></th>
                                    <th class="w-1">Status</th>
                                    <th class="w-1">Sess</th>
                                    <th class="text-center col-2">Date</th>
                                    <th class="text-center col-2">Time</th>
                                    <th class="col-2">Address</th>
                                    <th class="col-2">Carer</th>
                                    <th>Children</th>
                                    <th class="col-2">Discount</th>
                                    <th class="text-center">Action</th>
                                </tr>
                                <template v-for="(job, index) in booking.jobs" :key="job.id" :style="[job.is_urgent ? 'background-color: #fbebeb' : '']">
                                    <tr style="border-bottom-style: hidden;">
                                        <booking-job-whatsapp-template :booking="booking" :job="job" display="none"></booking-job-whatsapp-template>
                                        <td><input class="form-check-input m-0 align-middle" type="checkbox" aria-label="Select session"></td>
                                        <td>
                                            <div class="d-flex align-items-center space-x-2">
                                                <job-change-status :job-id="job.id" :status="job.status"></job-change-status>
                                                <div class="badge bg-red text-uppercase" v-if="job.is_urgent">Urgent</div>
                                                <div class="badge badge-sm bg-green text-uppercase flex-shrink-0" v-if="job.is_paid">Paid</div>
                                                <div class="badge badge-sm badge-dark text-uppercase flex-shrink-0" v-if="job.overnight">OV</div>
                                                <div class="badge badge-sm badge-dark text-uppercase flex-shrink-0" v-if="job.off_hours">OH</div>
                                                <div class="badge badge-sm bg-blue text-uppercase flex-shrink-0" v-if="job.total_hours >= 16">FULL</div>
                                            </div>
                                        </td>
                                        <td>{{ index + 1 }}</td>
                                        <td class="text-center">
                                            <div>{{ formatDate (job.started_at, 'DD MMM YYYY ') }}</div>
                                            <div>({{ formatDate (job.started_at, 'dddd') }})</div>
                                        </td>
                                        <td class="text-center">
                                            <div>{{ formatDate (job.started_at, 'hh:mm A') }} - {{ formatDate (job.ended_at, 'hh:mm A') }}</div>
                                            <div>({{ job.total_hours }} Hours)</div>
                                        </td>
                                        <td>{{ getShortAddress (job.address) }}</td>
                                        <td>
                                            <p class="mb-0 row align-items-center gap-1"><icon name="user-circle" class="cursor-pointer bg-cyan-lt p-1 rounded" role="button" data-bs-toggle="modal" data-bs-target="#modal-applied-carer" @click.prevent="modalJobId = job.id; modalJobNo = job.no; modalPreferredCarer = booking.preferable_carer?.user?.main_profile" /> [{{ job.carer ? getMainProfile(job.carer.profiles).online_id : 'empty' }}]</p>
                                            <router-link class="fw-bold" v-if="job.carer" :to="{ name: 'carer-show', params: { userId: job.assigned_carer } }" target="_blank">{{ job.carer.name }}</router-link>
                                        </td>
                                        <td>{{ getChildrenString (job.children) }} <strong>({{ job.children.length }})</strong></td>
                                        <td style="max-width: 70px;">
                                            <div v-if="booking.discount_code" class="mb-1">
                                                <icon name="discount-2" />
                                                <span class="ms-1">{{ booking.discount_code }}</span>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div>
                                                <router-link :to="{name: 'job-show', params: { jobId: job.id }}"><icon name="eye" class="text-primary me-2"/></router-link>
                                                <icon name="pencil" class="text-info me-2" role="button" data-bs-toggle="modal" data-bs-target="#modal-job-form" @click.prevent="modalJobMode = 'update'; modalJobForm = job; modalJobFormBooking = booking;"/>
                                                <icon name="notes"  class="me-2" role="button" data-bs-toggle="modal" data-bs-target="#modal-job-remark" @click.prevent="modalJobForm = job;"/>
                                                <icon name="brand-whatsapp" class="text-green me-2" role="button" @click.prevent="showWhatsAppTemplate('job-template', job.no)" />
                                            </div>
                                        </td>
                                        <job-add-remark :job-id="job.id" :job-no="job.no" :current-remark="job.remark"></job-add-remark>
                                    </tr>
                                    <tr v-if="job.remark">
                                        <td colspan="10">
                                            <div class="d-flex justify-content-end">Remark: {{ job.remark }}</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </template>
                        <template v-else>
                            <tbody>
                                <tr>
                                    <td colspan="11" class="text-center">No data</td>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                    <overlay-spinner :is-showing="isFetchingBooking" />
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
        <booking-job-carer-applied-form :job-id="modalJobId" :job-no="modalJobNo" :preferred-carer="modalPreferredCarer" />
        <booking-job-form-modal :mode="modalJobMode" :job="modalJobForm" :booking="modalJobFormBooking" />
        <booking-job-remark :job="modalJobForm" />
    </div>
</template>


<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import BookingService from '@/services/BookingService'
import JobChangeStatus from '@/pages/jobs/JobChangeStatus.vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import BookingWhatsappTemplate from './BookingWhatsappTemplate.vue'
import BookingJobWhatsappTemplate from './BookingJobWhatsappTemplate.vue'
import JobAddRemark from '@/pages/jobs/JobAddRemark.vue'
import { toLocaleDate, formatDate } from '@/composable/useDate'
import { getShortAddress } from '@/composable/useJob'
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'
import useBooking from '@/composable/useBooking'
import { getMainProfile } from '@/composable/profile'
import BookingJobCarerAppliedForm from '@/pages/bookings/BookingJobCarerAppliedForm.vue'
import Icon from '@/components/Icon.vue'
import { Core, easepick } from '@easepick/core'
import { AmpPlugin } from '@easepick/amp-plugin'
import BookingJobFormModal from '@/pages/bookings/BookingJobFormModal.vue'
import BookingJobRemark from '@/pages/bookings/BookingJobRemark.vue'
import StatusBadge from '@/components/StatusBadge.vue'

const { jobStatuses } = storeToRefs(useResourcesStore())
const {
    getBookingPlatform,
    isMobileBooking,
    getChildrenString,
    showWhatsAppTemplate,
    copyWhatsappTemplate,
} = useBooking()
const isFetchingBooking = ref<boolean>(false)
const bookings = ref<Array<any>>([])
const inputFilter = ref<UserFilter>({
    job_no: '',
    customer_id: '',
    status: '',
    job_date_from: '',
    job_date_to: '',
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const props = defineProps({
    userId: { type: Number },
    showTitle: { type: Boolean, default: false },
    showAction: { type: Boolean, default: true },
})

const modalJobMode = ref<string>('')
const modalJobId = ref<number>(0)
const modalJobNo = ref<string>('')
const modalJobForm = ref<any>({})
const modalPreferredCarer = ref<any>(null)
const modalJobFormBooking = ref<any>({})

let jobDateFromDatePicker: Core | null = null
let jobDateToDatePicker: Core | null = null

const jobDateFromDisplay = computed(() => inputFilter.value.job_date_from ? formatDate(inputFilter.value.job_date_from, 'DD-MMM-YYYY') : '')
const jobDateToDisplay = computed(() => inputFilter.value.job_date_to ? formatDate(inputFilter.value.job_date_to, 'DD-MMM-YYYY') : '')

const hasFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key) && inputFilter.value[key] !== '') {
            return true
        }
    }

    return false
}

const fetchBookings = () => {
    isFetchingBooking.value = true
    let query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
            hasFilter: hasFilter(),
        },
        ...inputFilter.value,
    }

    if (props.userId !== undefined) {
        query = { ...query, ...{ user_id: props.userId } }
    }

    BookingService.index(query)
        .then(({ data }) => {
            let meta = {
                current_page: data.current_page,
                last_page: data.last_page,
                from: data.from,
                to: data.to,
                total: data.total,
            }

            bookings.value = data.data
            updateMetaPage(meta)
        })
        .catch((error) => { })
        .finally(() => {
            isFetchingBooking.value = false
        })
}

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchBookings()
}

window.Echo.private('backoffice')
    .listen('.BookingCreated', ({ model }: any) => {
        if (metaPage.value.currentPage === 1) {
            bookings.value.unshift(model)
            bookings.value.pop()

            const total = metaPage.value.total + 1
            const lastPage = Math.ceil(total / metaPage.value.perPage)

            updateMetaPage({
                ...metaPage.value,
                current_page: metaPage.value.currentPage,
                last_page: lastPage,
                total: total,
            })
        }
    })
    .listen('.BookingUpdated', ({ model }: any) => {
        const bookingIndex = bookings.value.findIndex(booking => booking.id === model.id)
        if (bookingIndex === -1) {
            return
        }

        model.jobs = model.jobs.filter((job: any) => ! ['cancelled', 'approved', 'no_carer', 'refunded', 'credit', 'complete', 'complete_review'].includes(job.status))

        bookings.value[bookingIndex] = model
    })
    .listen('.JobCreated', ({ model }: any) => {
        const bookingIndex = bookings.value.findIndex(booking => booking.id === model.booking_id)
        if (bookingIndex === -1) {
            return
        }

        const jobIndex = bookings.value[bookingIndex].jobs.findIndex((job: any) => job.id === model.id)

        if (jobIndex === -1) {
            bookings.value[bookingIndex].jobs.unshift(model)
        } else {
            bookings.value[bookingIndex].jobs[jobIndex] = model
        }
    })
    .listen('.JobUpdated', ({ model }: any) => {
        const bookingIndex = bookings.value.findIndex(booking => booking.id === model.booking_id)
        if (bookingIndex === -1) {
            return
        }

        const jobIndex = bookings.value[bookingIndex].jobs.findIndex((job: any) => job.id === model.id)
        if (jobIndex === -1) {
            return
        }

        bookings.value[bookingIndex].jobs[jobIndex] = model
    })

onMounted(() => {
    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 2020,
                maxYear: (new Date().getFullYear()),
            }
        }
    }

    jobDateFromDatePicker = new easepick.create({
        element: <any>document.getElementById('inputFilterJobDateFrom'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.job_date_from = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })

    jobDateToDatePicker = new easepick.create({
        element: <any>document.getElementById('inputFilterJobDateTo'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/amp-plugin@1.2.0/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.job_date_to = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
            })
        },
        plugins: [AmpPlugin],
        ...ampPlugin
    })
})

fetchBookings()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchBookings()
    }
)
</script>


<style scoped>
tr {
    font-size: 13px;
}

tr>td {
    vertical-align: top;
}
</style>
