import { getQueryString } from '@/composable/http'
import httpClientV2 from '@/services/HttpClientV2'

export default {
    index(query: object = {}) {
        return httpClientV2.get(`/backoffice/promotions?${getQueryString(query)}`)
    },
    store(data: object = {}) {
        return httpClientV2.post('/backoffice/promotions', data)
    },
    show(id: number) {
        return httpClientV2.get(`/backoffice/promotions/${id}`)
    },
    update(id: number, data: object = {}) {
        return httpClientV2.patch(`/backoffice/promotions/${id}`, data)
    },
}
