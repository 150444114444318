import { ref } from 'vue'
import date from '@/utils/date'
import { Core, easepick } from '@easepick/core'
import { RangePlugin } from '@easepick/range-plugin'
import { PresetPlugin } from '@easepick/preset-plugin'

export default function useDateRangePicker(dateFormat: string | null = 'DD-MM-YYYY') {
    const easePickDefaultOptions : object = {
        format: dateFormat,
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/preset-plugin@1.2.0/dist/index.css',
        ],
        zIndex: 10,
        plugins: [RangePlugin, PresetPlugin],
        PresetPlugin: {
            customPreset: getCustomDateRanges()
        }
    }

    function getCustomDateRanges() {
        let dateObj = date.object()
        
        let predefinedDateRanges : any = {
            'Today': [dateObj, dateObj],
            'Yesterday': [dateObj.subtract(1, 'day'), dateObj.subtract(1, 'day')],
            'This Week': [dateObj.startOf('week'), dateObj.endOf('week')],
            'Last Week': [dateObj.startOf('week').subtract(1, 'week'), dateObj.endOf('week').subtract(1, 'week')],
            'This Month': [dateObj.startOf('month'), dateObj.endOf('month')],
            'Last Month': [dateObj.startOf('month').subtract(1, 'month'), dateObj.endOf('month').subtract(1, 'month')],
            'Last 7 Days': [dateObj.subtract(7, 'day'), dateObj],
            'Last 30 Days': [dateObj.subtract(30, 'day'), dateObj],
            'Last 90 Days': [dateObj.subtract(90, 'day'), dateObj],
            'Last 12 Month': [dateObj.subtract(12, 'month'), dateObj],
            'This Year': [dateObj.startOf('year'), dateObj.endOf('year')],
            'Last Year': [dateObj.startOf('year').subtract(1, 'year'), dateObj.endOf('year').subtract(1, 'year')],
        }
    
        Object.keys(predefinedDateRanges).map((key: any) => {
            predefinedDateRanges[key] = [
                predefinedDateRanges[key][0].toDate(),
                predefinedDateRanges[key][1].toDate()
            ]
        })
    
        return predefinedDateRanges
    }

    return {
        easePickDefaultOptions
    }
}
