import axios from 'axios'

const httpClient = axios.create({
    baseURL: import.meta.env.VITE_CDN_BASE_URL
})

export default {
    getResources(hash: string) {
        return httpClient.get(`/static-data/backoffice-${hash}.json`)
    },
}
