<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <button type="button" class="btn btn-primary dropdown-toggle float-right" data-bs-toggle="dropdown">
                        Action
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" style="width: 280px;">
                        <button class="dropdown-item" @click="generateReport">
                            <icon name="file-spreadsheet"/>
                            <span>Export Customer List</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Name</label>
                    <input class="form-control" type="text" v-model="inputFilter.name" @keyup.enter="fetchCustomer">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="email" v-model="inputFilter.email" @keyup.enter="fetchCustomer">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Phone No.</label>
                    <input class="form-control" type="text" v-model="inputFilter.phoneNo" @keyup.enter="fetchCustomer">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Online ID</label>
                    <input class="form-control" type="email" v-model="inputFilter.onlineId"
                        @keyup.enter="fetchCustomer">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchCustomer" :is-loading="isFetchingUsers"></button-filter>
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No</th>
                            <th>Online ID</th>
                            <th>Nationality</th>
                            <th>Registered At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="users.length > 0">
                        <tr v-for="user in users" :key="user.id">
                            <td class="fw-semibold">
                                <router-link :to="{ name: 'customer-show', params: { userId: user.id } }">{{ getMainProfile(user.profiles) ? getMainProfile(user.profiles).fullName : user.name }}</router-link>&nbsp;
                                <status-badge :show="user.isBanned" label="Banned" color="red"></status-badge>
                                <status-badge :show="user.isDeleted" label="Deleted" color="gray"></status-badge>
                            </td>
                            <td>{{ user.email }} <span v-if="user.emailVerifiedAt" class="text-green"><icon name="circle-check" /></span></td>
                            <td>{{ getMainProfile(user.profiles)?.mobileNo ?? '-' }}</td>
                            <td>{{ getMainProfile(user.profiles)?.onlineId ?? '-' }}</td>
                            <td>{{ getMainProfile(user.profiles)?.nationality ?? '-' }}</td>
                            <td>{{ user.createdAt ? formatDate(user.createdAt, 'DD-MMM-YYYY hh:mm:ss A') : '' }}</td>
                            <td class="text-end">
                                <!-- edit button and delete button -->
                                <div class="btn-group align-text-top">
                                    <router-link class="btn btn-icon btn-primary"
                                        :to="{ name: 'customer-show', params: { userId: user.id } }">
                                        <icon name="eye" />
                                    </router-link>
                                    <a href="#" role="button" class="btn btn-icon btn-info" @click="switchRole(user)">
                                        <icon name="arrows-left-right" />
                                    </a>
                                </div>
                                <!-- /edit button and delete button -->
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingUsers" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { getMainProfile } from '@/composable/profile'
import { formatDate } from '@/composable/useDate'
import { useNotificationsStore } from '@/stores/notifications'
import { ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import User from '@/types/User'
import useMetaPage from '@/composable/useMetaPage'
import StatusBadge from '@/components/StatusBadge.vue'
import CustomerService from '@/services/users/CustomerService'
import ReportService from '@/services/ReportService'

const { addToastNotification } = useNotificationsStore()

const users = ref<Array<User>>([])
const inputFilter = ref<UserFilter>({
    name: '',
    email: '',
    phoneNo: '',
    onlineId: '',
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const isFetchingUsers = ref<boolean>(false)

fetchCustomer()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchCustomer()
    }
)

function resetFilter () {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchCustomer()
}

function fetchCustomer () {
    isFetchingUsers.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    CustomerService.index(query)
        .then(({ data: { data, meta } }) => {
            users.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingUsers.value = false
        })
}

function switchRole (user: User) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to change this user ${user.name} to Carer?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            CustomerService.updateRole(user.id)
                .then(() => {
                    fetchCustomer()
                    addToastNotification({
                        message: `User ${user.name} has been switch to Carer role`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}

function generateReport () {
    isFetchingUsers.value = true
    const data = {
        ...inputFilter.value,
    }

    CustomerService.generateReport(data).then(({ data: { message }}) => {
        const response = message
        addToastNotification({
            message: response,
            type: 'success',
        })
    }).catch(() => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger',
        })
    }).finally(() => isFetchingUsers.value = false)
}
</script>
