import httpClientV2 from './HttpClientV2'
import SessionQuery from '@/types/query/SessionQuery'
import {getQueryString} from '@/composable/http'
import {UpdateSessionStatusFormData} from '@/types/formData/UpdateSessionStatusFormData'

const endpoint = '/backoffice/sessions'

export default {
    index(query: SessionQuery) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },
    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },
    store(data: object = {}) {
        return httpClientV2.post(endpoint, data)
    },
    storeWithOrder(data: object = {}) {
        return httpClientV2.post(`${endpoint}/create`, data)
    },
    update(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },
    updateStatus(id: number, data: UpdateSessionStatusFormData) {
        return httpClientV2.patch(`${endpoint}/${id}/status`, data)
    },
    updateRemarks(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${id}/remarks`, data)
    },
    liveUpdate() {
        return httpClientV2.get(`${endpoint}/live-update`)
    },
}
