<template>
    <kc-modal :modal-id="modalId" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title"> Images </h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body bg-white">
            <div style="max-height: 70vh; overflow-y:scroll">
                <ul class="list-unstyled text-center">
                    <li v-for="image in images">
                        <img :src="image.url" :alt="image.filename" class="pb-4" />
                    </li>
                </ul>
            </div>
        </div>
    </kc-modal>
    
</template>

<script setup lang="ts">

import { computed } from 'vue'

const props = defineProps({
    images: {type: Array<any>, required: true},
    id: {type: String, required: true}
})

const modalId = computed<string>(() => `modal-display-image-${props.id}`)

const closeModal = () => document.getElementById(`${modalId}-btn--close`)?.click()

</script>
