import { ref, computed } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import UserInput from '@/types/Input'
import { required, requiredIf, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = (input: UserInput) => {
    return computed(() => {
        return {
            inputUser: {
                recipient: {
                    required: helpers.withMessage(
                        'This recipient field is required',
                        required
                    ),
                },
                title: {
                    required: helpers.withMessage(
                        'This subject field is required',
                        required
                    ),
                },
                preview: {
                    required: helpers.withMessage(
                        'This preview field is required',
                        required
                    ),
                },
                body: {
                    required: helpers.withMessage(
                        'This message field is required',
                        required
                    ),
                },
                send_at: {
                    requiredIf: helpers.withMessage(
                        'This field is required',
                        requiredIf(input.send_now === false)
                    ),
                },
                csv_file: {
                    requiredIf: helpers.withMessage(
                        'CSV file is required',
                        requiredIf(input.recipient === 4)
                    ),
                }
            },
        }
    })
}