<template>
    <span v-if="show" class="badge badge-pill" :class="colorClassname">{{ label }}</span>
</template>

<script setup lang="ts">

import {computed, PropType} from 'vue'

type Color = 'gray' | 'red' | 'orange'

const props = defineProps({
    show: {type: Boolean, default: false},
    label: {type: String, required: true},
    color: {type: String as PropType<Color>}
})

const colorClassname = computed(() => {
    let classname = ''

    if (props.color) {
        classname = `bg-${props.color}`
    }

    return classname
})

</script>

<style scoped>
.badge {
    margin: 0 0.1rem;
}
</style>
