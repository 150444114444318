<template>
    <div>{{ formatDate(sendAt, 'DD-MMM-YYYY hh:mm:ss A') }}</div>
    <div>({{ moment }})</div>
</template>

<script setup lang="ts">
import { fromNow, formatDate } from '@/composable/useDate'
import { ref } from 'vue'

const props = defineProps({
    sendAt: { type: String, required: true }
})

const updateMoment = () => fromNow(props.sendAt)

const moment = ref<string>(updateMoment())

setInterval(() => {
    moment.value = updateMoment()
}, 60000)
</script>
