<template>
    <div class="stars-container align-self-center">
        <div class="stars-outer" style="color: #90959e">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" :width="size" :height="size" class="star-icon" :stroke-width="strokeWidth" v-for="star in [...Array(maxRating).keys()]" :key="star">
                <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
            </svg>
        </div>
        <div class="stars-inner" style="color: #f59f00" :style="{ width: percent }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" :width="size" :height="size" class="star-icon" :stroke-width="strokeWidth" v-for="star in [...Array(maxRating).keys()]" :key="star">
                <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
            </svg>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
    size: { type: Number, default: 24 },
    strokeWidth: { type: Number, default: 1.8 },
    maxRating: { type: Number, default: 5 },
    rating: { type: Number, default: 0 }
})

const rounded = (value: number, decimalPlaces: number) => {
    const power = 10 ** decimalPlaces
    return Math.round(value * power) / power
}

const roundedRating = computed(() =>
    rounded(props.rating, 1)
)

const percent = computed(() => {
    const percentage = (roundedRating.value / props.maxRating) * 100
    return `${percentage}%`
})

</script>

<style scoped>
.star-icon {
    width: v-bind(size) !important;
    height: v-bind(size) !important;
    stroke-width: v-bind(strokeWidth) !important;
}

.stars-outer, .stars-inner {
    height: inherit;
}

.stars-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    max-width: 100%;
    min-width: 0;
    white-space: nowrap;
    transition: 320ms cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow: hidden;
}

.stars-container svg {
    display: inline-block;
    fill: currentColor;
}

.stars-container {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}
</style>