import httpClientV2 from '@/services/HttpClientV2'
import TicketQuery from '@/types/query/TicketQuery'
import { getQueryString } from '@/composable/http'
import {SendbirdCustomTypeEnum} from '@/enums/SendbirdCustomTypeEnum'
import {RoleNameEnum} from '@/enums/RoleEnum'
import {TicketStatusEnum} from '@/enums/TicketStatusEnum'
import UpdateTicketAssigneeFormData from '@/types/formData/UpdateTicketAssigneeFormData'

const endpoint = '/backoffice/tickets'

export default {
    index(query: TicketQuery = {}) {
        const queryString = getQueryString(query)

        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },

    count() {
        return httpClientV2.get(`${endpoint}/count`)
    },

    updateStatus(id: string, data: object = {}) {
        return httpClientV2.post(`${endpoint}/${id}/status/update`, data)
    },

    updateAssignee(id: string, data: UpdateTicketAssigneeFormData) {
        return httpClientV2.post(`${endpoint}/${id}/assignee/update`, data)
    },

    updateRemark(id: string, data: object = {}) {
        return httpClientV2.post(`${endpoint}/${id}/remark/update`, data)
    },

    inviteUser(data: object = {}) {
        return httpClientV2.post(`${endpoint}/invite-user`, data)
    },

    getActivityLogs(id: number) {
        return httpClientV2.get(`${endpoint}/${id}/activity-logs`)
    },
}
