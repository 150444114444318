<template>
    <kc-modal modal-id="modal-add-chat-admin" modal-size="xl">
        <div class="modal-header">
            <h4 class="modal-title">Add Admin</h4>
            <button id="btn-modal-add-chat-admin-close" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body bg-white">
            <div class="card-body">
                <div class="form-group mb-3 row">
                    <label class="form-label col-3 col-form-label">Admin <span class="text-danger">*</span></label>
                    <div class="col">
                        <vue-select :options="adminOptions"
                                    @search="handleSearch"
                                    label="userName"
                                    v-model="inputUser.user_id"
                                    :reduce="(admin: ChatAdmin) => admin.userId"
                        ></vue-select>
                        <small class="invalid-feedback" v-if="hasError('user_id')">{{ getError('user_id') }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3 border-top">
            <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-primary" @click.prevent="saveAdmin">Save</button>
        </div>
    </kc-modal>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from 'vue'
import {AxiosError} from 'axios'
import {useVuelidate} from '@vuelidate/core'
import {rules as ChatAdminRule, $externalResults} from '@/rules/ChatAdminRule'
import {useNotificationsStore} from '@/stores/notifications'
import ChatAdmin from '@/types/ChatAdmin'
import AdminService from '@/services/users/AdminService'
import ChatAdminService from '@/services/settings/ChatAdminService'
import useFormError from '@/composable/useFormError'
import _ from 'lodash/fp'

const {addToastNotification} = useNotificationsStore()

const emit = defineEmits(['chatAdminAdded'])

const inputUser = ref({
    user_id: '',
})

const errors = ref<any>({
    user_id: null
})

let modalElement: HTMLElement | null = null
let btnCloseModal: HTMLElement | null = null

const adminOptions = ref<Array<ChatAdmin>>([])

const searchAdmin = _.debounce(350, async (search, loading) => {
    loading(true)

    try {

        const query: { exclude: string, name?: string } = {
            exclude: 'chat-admins'
        }

        if (search) {
            query.name = search
        }

        const response = await AdminService.index(query)

        adminOptions.value = response.data.data.map((admin: {id: number, name: string}) => {
            return {
                userId: admin.id,
                userName: admin.name
            }
        })
    } catch (ex: any) {
        addToastNotification({
            message: 'Error while getting available admins. Please try again.',
            type: 'danger',
        })
    }

    loading(false)
})

const handleSearch = (search: string, loading: Function) => {
    if (search.length) {
        searchAdmin(search, loading)
    }
}

const v$ = useVuelidate(ChatAdminRule, {inputUser}, {$externalResults})
const {hasError, getError, clearError, clearAllErrors} = useFormError(v$)

const saveAdmin = async () => {
    $externalResults.value.inputs = {}

    try {
        await ChatAdminService.store(inputUser.value)
        addToastNotification({
            message: `Chat admin added`,
            type: 'success',
        })

        btnCloseModal?.click()

        emit('chatAdminAdded')
    } catch (err) {
        const error = err as AxiosError
        if (error.response?.status === 422) {
            Object.assign($externalResults.value.inputs, error.response?.data)
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        }
    }
}

const openAddressModal = () => {
    btnCloseModal?.click()
}

const openChildModal = () => {
    btnCloseModal?.click()
}

onMounted(() => {
    modalElement = document.getElementById('modal-add-chat-admin')
    btnCloseModal = document.getElementById('btn-modal-add-chat-admin-close')

    if (!modalElement) {
        return
    }

    // Reset inputs and options on modal hidden
    modalElement?.addEventListener('hidden.bs.modal', () => {
        inputUser.value.user_id = ''
        adminOptions.value = []
    })

})
</script>
