import httpClientV2 from './HttpClientV2'

export default {
    store(data: Object) {
        return httpClientV2.post(`backoffice/carer-payout-items`, data)
    },

    update(itemId: number, data: Object) {
        return httpClientV2.patch(`backoffice/carer-payout-items/${itemId}`, data)
    },

    delete(itemId: number) {
        return httpClientV2.delete(`backoffice/carer-payout-items/${itemId}`)
    }
}