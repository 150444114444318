import httpClient from './HttpClient'
import CommonQuery from '@/types/query/CommonQuery'
import { getQueryString } from '@/composable/http'

export default {
    getBookingsAndJobs () {
        return httpClient.get('admin/dashboards/bookingsandjobs')
    },

    getBookingData (query: CommonQuery = {}) {
        const queryString = getQueryString(query)
        
        return httpClient.get(`admin/dashboards/bookingsandjobs/stats?${queryString}`)
    },

    getJobs () {
        return httpClient.get('admin/dashboards/jobs')
    },

    getJobData (query: CommonQuery = {}) {
        const queryString = getQueryString(query)
        
        return httpClient.get(`admin/dashboards/jobs/stats?${queryString}`)
    },

    getJobCalendar () {
        return httpClient.get(`admin/dashboards/jobs/calendar`)
    },
}