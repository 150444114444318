export enum SessionCarerApplicationStatusEnum {
    ID_APPLIED = 1,
    ID_ACCEPTED = 2,
    ID_DECLINED = 3,
    ID_NEGOTIATED = 4,
    ID_CARER_CANCELLED = 5,
    ID_CUSTOMER_CANCELLED = 6,
    ID_ADMIN_CANCELLED = 7,
    ID_CHECKING_WITH_CARER = 8,
    ID_APPLIED_WITH_ACCEPTED_NEGOTIATION = 9,
    ID_APPLIED_WITH_REJECTED_NEGOTIATION = 10,
    ID_AUTO_CANCELLED = 11,
}

export const ActiveSessionCarerApplicationStatuses: number[] = [
    SessionCarerApplicationStatusEnum.ID_APPLIED,
    SessionCarerApplicationStatusEnum.ID_ACCEPTED,
    SessionCarerApplicationStatusEnum.ID_NEGOTIATED,
    SessionCarerApplicationStatusEnum.ID_CHECKING_WITH_CARER,
    SessionCarerApplicationStatusEnum.ID_APPLIED_WITH_ACCEPTED_NEGOTIATION,
    SessionCarerApplicationStatusEnum.ID_APPLIED_WITH_REJECTED_NEGOTIATION
];

export const InactiveSessionCarerApplicationStatuses: number[] = [
    SessionCarerApplicationStatusEnum.ID_DECLINED,
    SessionCarerApplicationStatusEnum.ID_CARER_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_CUSTOMER_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_ADMIN_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_AUTO_CANCELLED
];

export const CancellingSessionCarerApplicationStatuses: number[] = [
    SessionCarerApplicationStatusEnum.ID_CARER_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_CUSTOMER_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_ADMIN_CANCELLED
];

export const CancelledSessionCarerApplicationStatuses: number[] = [
    SessionCarerApplicationStatusEnum.ID_CARER_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_CUSTOMER_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_ADMIN_CANCELLED,
    SessionCarerApplicationStatusEnum.ID_AUTO_CANCELLED
];

export const CancellableSessionCarerApplicationStatuses: number[] = [
    SessionCarerApplicationStatusEnum.ID_APPLIED,
    SessionCarerApplicationStatusEnum.ID_APPLIED_WITH_ACCEPTED_NEGOTIATION,
    SessionCarerApplicationStatusEnum.ID_APPLIED_WITH_REJECTED_NEGOTIATION,
    SessionCarerApplicationStatusEnum.ID_ACCEPTED
];