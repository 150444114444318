<template>
    <div>
        <div class="d-flex justify-content-between mb-3">
            <h2>Session {{ index + 1 }}</h2>
            <div>
                <div class="btn-group">
                    <button class="btn btn-primary" @click.prevent="$emit('copySession', session.sessionCounter)">
                        <icon name="copy" />
                        <span>Copy</span>
                    </button>
                    <button class="btn btn-danger" @click.prevent="$emit('removeSession', session.sessionCounter)">
                        <icon name="trash" />
                        <span>Remove</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group col-3">
                <label class="form-label">Session Date / Time <span class="text-danger">*</span></label>
                <div class="input-icon mb-2">
                    <input class="form-control bg-white" type="text" :id="`input-session-dates-${session.sessionCounter}`" :value="sessionDateDisplay" required />
                    <span class="input-icon-addon"><icon name="calendar" /></span>
                </div>
            </div>
            <div class="form-group col-3">
                <label class="form-label">Duration (Hour) <span class="text-danger">*</span></label>
                <input type="number" min="2" max="24" class="form-control" v-model.number="inputUser.total_hours" />
            </div>
            <div class="form-group col-6">
                <label class="form-label">Carer</label>
                <vue-select :options="carerOptions" @search="onSearchCarer" v-model="inputUser.assigned_carer" :reduce="(carer: any) => carer.id" label="name" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group">
                <label class="form-label">Address <span class="text-danger">*</span></label>
                <vue-select :options="props.addressOptions" v-model="inputUser.address" label="name"/>
                <div class="border mt-2 p-3 rounded" v-if="inputUser.address">
                    <p>{{ getFullAddress(inputUser.address, addressStates, postcodes) }}</p>
                    <p>Remarks: {{ inputUser.address.remarks }}</p>
                </div>
            </div>
        </div>

        <div class="form-group mb-3 row">
            <div class="form-group">
                <label class="form-label">Dependent <span class="text-danger">*</span></label>
                <ul v-if="props.dependents?.length > 0" class="list-unstyled border rounded">
                    <li class="p-3" :class="{ 'border-bottom' : dependentIndex !== props.dependents?.length - 1 }"
                        v-for="(dependent, dependentIndex) in props.dependents" :key="dependentIndex" >
                        <label class="form-check mb-0 row" :for="`dependent-${dependentIndex}`">
                            <input class="form-check-input col-1" type="checkbox" name="child"
                                    v-model="inputUser.dependents"
                                    :value="dependent" :id="`dependent-${dependentIndex}`"
                            >
                            <span class="form-check-label col-11 ">
                            <span class="row">
                                <span class="fw-bold mb-1 col-12">{{ dependent.name }}</span>
                                <span class="text-muted mb-0">
                                    {{ getGenderByType({ dependent }) }},
                                    {{ dependent.birthDate ? toAge(dependent.birthDate) : null }}
                                </span>
                            </span>
                            </span>
                        </label>
                    </li>
                </ul>
                <ul class="list-unstyled border rounded" v-else>
                    <li class="p-3">No Dependent</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { getGenderByType } from '@/composable/useDependent'
import { getFullAddress } from '@/composable/useSession'
import { getMainProfile } from '@/composable/profile'
import { formatDate, toAge } from '@/composable/useDate'
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'
import { TimePlugin } from '@easepick/time-plugin'
import { LockPlugin } from '@easepick/lock-plugin'
import { Core, easepick } from '@easepick/core'
import { computed, onMounted, ref, watch } from 'vue'
import Dependent from '@/types/Dependent'
import _ from 'lodash/fp'
import User from '@/types/User'
import Address from '@/types/Address'
import CarerService from '@/services/users/CarerService'

const { addressStates, postcodes } = storeToRefs(useResourcesStore())

const props = defineProps({
    index: { type: Number, required: true },
    session: { type: Object, required: true },
    dependents: { type: Array as () => Array<Dependent>, required: true },
    addressOptions: { type: Array as () => Array<Address>, required: true },
})

const emit = defineEmits(['updateSession'])

const DEFAULT_SESSION_DATE_FORMAT = 'DD-MMM-YYYY hh:mm A'
const easePickDefaultOptions = {
    format: DEFAULT_SESSION_DATE_FORMAT,
    css: [
        'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.0/dist/index.css'
    ],
    autoApply: false,
    zIndex: 10,
    plugins: [TimePlugin, LockPlugin],
    TimePlugin: {
        format12: true
    },
    LockPlugin: {
        minDate: new Date()
    }
}

let sessionDatePicker: Core|null = null
const inputUser = ref<any>(props.session)
const carerOptions = ref<Array<any>>([])
const sessionDateDisplay = computed<string>(() => formatDate(inputUser.value.started_at, DEFAULT_SESSION_DATE_FORMAT))

const onSearchCarer = (search: string, loading: Function) => {
    if (search.length) {
        searchCarers(search, loading)
    }
}

const searchCarers = _.debounce(350, (search, loading) => {
    loading(true)

    CarerService.index({search})
        .then(({data: {data}}) => {
            carerOptions.value = mapCarersData(data)
        })
        .finally(() => loading(false))
})

const mapCarersData = (carers: Array<User>) => {
    return carers.map((carer: User) => {
        const mainProfile = getMainProfile(carer.profiles)
        return {
            id: carer.id,
            name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`
        }
    })
}

onMounted(async () => {
    if (inputUser.value.assigned_carer) {
        CarerService.index({ search: inputUser.value.assigned_carer })
            .then(({ data: { data } }) => {
                carerOptions.value = mapCarersData(data.filter((carer: any) => carer.id === inputUser.value.assigned_carer))
            })
    };
    sessionDatePicker = new easepick.create({
        ...easePickDefaultOptions,
        ...{
            element: `#input-session-dates-${props.session.sessionCounter}`,
            setup: (picker: Core) => {
                picker.on('select', (e: any) => {
                    inputUser.value.started_at = formatDate(e.detail.date, 'YYYY-MM-DD HH:mm:ss')
                })
            }
        }
    })
})

watch(inputUser.value, (value) => {
    emit('updateSession', value)
})

</script>
