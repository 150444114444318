<template>
    <router-link :to="redirectTo" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Sessions</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingJob" v-if="isFetchingJob"/>
    <job-form :job-id="jobId" :job="job" :booking-id="job?.booking.id" :redirect-to="redirectTo" v-else></job-form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import JobForm from '@/pages/jobs/JobForm.vue'
import Job from '@/types/Job'
import { useNotificationsStore } from '@/stores/notifications'
import JobService from '@/services/JobService'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const jobId = Number(route.params.jobId)
const job = ref<Job>()
const isFetchingJob = ref(false)

const redirectTo = {
    name: 'booking-index'
}

function fetchJob () {
    isFetchingJob.value = true

    JobService.show(jobId)
        .then(({ data: { data } }) => {
            job.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => {
            isFetchingJob.value = false
        })
}

fetchJob()
</script>