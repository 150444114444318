<template>
    <div>
        <div class="d-flex justify-content-between mb-3">
            <h2>Session {{ index + 1 }}</h2>
            <div>
                <div class="btn-group">
                    <button class="btn btn-primary" @click.prevent="$emit('copyJob', job.jobCounter)">
                        <icon name="copy" />
                        <span>Copy</span>
                    </button>
                    <button class="btn btn-danger" @click.prevent="$emit('removeJob', job.jobCounter)">
                        <icon name="trash" />
                        <span>Remove</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group col-3">
                <label class="form-label">Job Date / Time <span class="text-danger">*</span></label>
                <div class="input-icon mb-2">
                    <input class="form-control bg-white" type="text" :id="`input-job-dates-${job.jobCounter}`" :value="jobDateDisplay" required />
                    <span class="input-icon-addon"><icon name="calendar" /></span>
                </div>
            </div>
            <div class="form-group col-3">
                <label class="form-label">Duration (Hour) <span class="text-danger">*</span></label>
                <input type="number" min="2" max="24" class="form-control" v-model.number="input.duration" />
            </div>
            <div class="form-group col-6">
                <label class="form-label">Carer</label>
                <vue-select :options="carerOptions" @search="onSearchCarer" v-model.number="input.assigned_carer" :reduce="(carer: any) => carer.id" label="name" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group">
                <label class="form-label">Address <span class="text-danger">*</span></label>
                <vue-select :options="addressOptions" v-model="input.address" label="name" />
                <div class="border mt-2 p-3 rounded" v-if="job.address" v-html="job.address.full_address"></div>
            </div>
        </div>

        <div class="form-group mb-3 row">
            <div class="form-group">
                <label class="form-label">Children <span class="text-danger">*</span></label>
                <ul class="list-unstyled border rounded">
                    <li class="p-3" v-if="childOptions.length > 0" :class="{ 'border-bottom' : childIndex !== childOptions.length - 1 }" v-for="(child, childIndex) in childOptions" :key="childIndex">
                        <label class="form-check mb-0" :for="`child-${childIndex}-session-${job.jobCounter}`">
                            <input class="form-check-input" type="checkbox" name="child" v-model="input.children" :value="child" :id="`child-${childIndex}-session-${job.jobCounter}`">
                            <span class="form-check-label">
                            <p class="fw-bold mb-1">{{ child.name }}</p>
                            <span class="text-muted mb-0">{{ child.gender === 'G' ? 'Girl' : 'Boy' }}</span>,
                            <span class="text-muted mb-0">{{ child.birth_date ? toAge(child.birth_date.toString()) : null }}</span>
                        </span>
                        </label>
                    </li>
                    <li class="p-3" v-else>No Child</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import _ from 'lodash/fp'
import CarerService from '@/services/users/CarerService'
import User from '@/types/User'
import { getMainProfile } from '@/composable/profile'
import Address from '@/types/Address'
import Child from '@/types/Child'
import { formatDate, toAge } from '@/composable/useDate'
import { Core, easepick } from '@easepick/core'
import { TimePlugin } from '@easepick/time-plugin'
import { LockPlugin } from '@easepick/lock-plugin'

const props = defineProps({
    index: { type: Number, required: true },
    job: { type: Object, required: true },
    childOptions: { type: Array as () => Array<Child>, required: true },
    addressOptions: { type: Array as () => Array<Address>, required: true },
})

const emit = defineEmits(['updateJob'])

const DEFAULT_JOB_DATE_FORMAT = 'DD-MMM-YYYY hh:mm A'
const easePickDefaultOptions = {
    format: DEFAULT_JOB_DATE_FORMAT,
    css: [
        'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.0/dist/index.css',
    ],
    autoApply: false,
    zIndex: 10,
    plugins: [TimePlugin, LockPlugin],
    TimePlugin: {
        format12: true,
    },
    LockPlugin: {
        minDate: new Date(),
    },
}

let jobDatePicker: Core|null = null
const input = ref<any>(props.job)
const carerOptions = ref<Array<any>>([])
const jobDateDisplay = computed<string>(() => formatDate(input.value.start_time, DEFAULT_JOB_DATE_FORMAT))

const onSearchCarer = (search: string, loading: Function) => {
    if (search.length) {
        searchCarers(search, loading)
    }
}

const searchCarers = _.debounce(350, (search, loading) => {
    loading(true)

    CarerService.index({ search })
        .then(({ data: { data } }) => {
            carerOptions.value = mapCarersData(data)
        })
        .finally(() => loading(false))
})

const mapCarersData = (carers: Array<User>) => {
    return carers.map((carer: User) => {
        const mainProfile = getMainProfile(carer.profiles)
        return {
            id: carer.id,
            name: `[${mainProfile?.onlineId}] ${mainProfile?.fullName}`,
        }
    })
}

onMounted(() => {
    if (input.value.assigned_carer) {
        CarerService.index({ search: input.value.assigned_carer })
            .then(({ data: { data } }) => {
                carerOptions.value = mapCarersData(data.filter((carer: any) => carer.id === input.value.assigned_carer))
            })
    }

    jobDatePicker = new easepick.create({
        ...easePickDefaultOptions,
        ...{
            element: `#input-job-dates-${props.job.jobCounter}`,
            setup: (picker: Core) => {
                picker.on('select', (e: any) => {
                    input.value.start_time = formatDate(e.detail.date, 'YYYY-MM-DD HH:mm:ss')
                })
            },
        },
    })
})

watch(input.value, (value) => {
    emit('updateJob', value)
})

</script>
