<template>
    <template v-if="carerPayoutRecipient">
        <div class="card">
            <div class="card-body">
                <h3>Summary</h3>
                <p>Carer: {{ `[${carerPayoutRecipient.carerUser.mainProfile?.onlineId}]` }} {{ carerPayoutRecipient.carerUser.mainProfile?.fullName }}</p>
                <p>Status: <div class="badge badge-pill" :class="getStatusBadgeColor(carerPayoutRecipient?.payout.status)">{{ getCarerPayoutStatus(carerPayoutRecipient?.payout.status, carerPayoutStatuses)?.label }}</div></p>
                <p v-if="carerPayoutRecipient.payout.confirmedAt">Payment Date: {{ formatDate(carerPayoutRecipient.payout.confirmedAt, 'DD-MMM-YYYY') }}</p>
                <div class="table-responsive">
                    <table class="table table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>Total Session</th>
                                <th class="text-end">Hour</th>
                                <th class="text-end">Extra Children</th>
                                <th class="text-end">Off Hours</th>
                                <th class="text-end">Extension</th>
                                <th class="text-end">Last Minute</th>
                                <th class="text-end">Overnight</th>
                                <th class="text-end">Public Holiday</th>
                                <th class="text-end">Travel Incentive</th>
                                <th class="text-end">Bonus</th>
                                <th class="text-end">Penalty</th>
                                <th class="text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ carerPayoutRecipient.metadata.totalSession }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalHourAmount) }} ({{ carerPayoutRecipient.metadata.totalHour }} hours)</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalExtraChildrenAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalOffHourAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalExtensionHourAmount) }} ({{ carerPayoutRecipient.metadata.totalExtensionHour }} hours)</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalLastMinuteAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalOvernightAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalPublicHolidayAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalTravelIncentiveAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalBonusAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.metadata.totalPenaltyAmount) }}</td>
                                <td class="text-end">{{ toMYR(carerPayoutRecipient.amount) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <overlay-spinner :is-showing="isFetching" />
                </div>
                <hr />
                <div class="d-flex justify-content-between mb-3">
                    <h3>Payout Item List</h3>
                    <button class="btn btn-primary" type="button" @click="setEditor(false, null)">
                        <icon name="plus" />Add Item
                    </button>
                </div>
                <div class="table-responsive">
                    <table class="table table-mobile-md card-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th class="w-25">Session No</th>
                            <th class="w-25">Description</th>
                            <th class="w-1">Unit</th>
                            <th class="w-5 text-end">Pay Rate</th>
                            <th>Remark</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in carerPayoutItems" :key="`item-${item.carerPayoutRecipientId}-${index}`">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.session ? item.session.no : '-' }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.unit }}</td>
                            <td class="text-end">{{ toMYR(item.amount) }}</td>
                            <td>{{ item.remark }}</td>
                            <td>
                                <div class="btn-list justify-content-end">
                                    <button class="btn btn-sm btn-secondary" @click="setEditor(true, item)">Edit</button>
                                    <button class="btn btn-sm btn-danger" @click="deleteItem(item.id)">Delete</button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <overlay-spinner :is-showing="isFetching" />
                </div>
            </div>
        </div>

        <carer-payout-item-form :is-edit="isEdit" :is-open="isOpen" :item="selectedItem"
                          :item-id="selectedItem?.id" :recipient-id="recipientId"
                          @close-modal="closeModal" @refresh-payout-items="fetchData">
        </carer-payout-item-form>
    </template>
</template>

<script setup lang="ts">
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import CarerPayoutItemForm from '@/components/payouts/CarerPayoutItemForm.vue'
import { getCarerPayoutStatus, getStatusBadgeColor } from '@/composable/useCarerPayout'
import { toMYR } from '@/composable/useCurrency'
import { formatDate } from '@/composable/useDate'
import CarerPayoutItemService from '@/services/CarerPayoutItemService'
import PayoutService from '@/services/PayoutService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import CarerPayoutItem from '@/types/CarerPayoutItem'
import CarerPayoutRecipient from '@/types/CarerPayoutRecipient'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const { addToastNotification } = useNotificationsStore()
const { carerPayoutStatuses } = storeToRefs(useResourcesStore())

const route = useRoute()
const isFetching = ref<boolean>(false)
const isOpen = ref<boolean>(false)
const isEdit = ref<boolean>(false)
const selectedItem = ref<CarerPayoutItem|null>(null)
const carerPayoutId = Number(route.params.carerPayoutId)
const carerUserId = Number(route.params.userId)
const recipientId = ref<number|undefined>()
const carerPayoutRecipient = ref<CarerPayoutRecipient>()
const carerPayoutItems = ref<Array<CarerPayoutItem>>([])

const fetchData = () => {
    isFetching.value = true
    PayoutService.getRecipientByUserId(carerPayoutId, carerUserId)
        .then(({ data: { data } }) => {
            carerPayoutRecipient.value = data
            carerPayoutItems.value = data.items
            recipientId.value = carerPayoutRecipient.value?.id
        })
        .catch(({ response: { data: { message }, status }}) => {
            const errMessage = status < 500 ? message : 'Internal server error. Please contact tech team if error persists.'

            addToastNotification({
                message: errMessage,
                type: 'danger'
            })
        }).finally(() => isFetching.value = false)
}

const setEditor = (edit: boolean, item: CarerPayoutItem|null) => {
    if (item !== null) {
        selectedItem.value = item
    }
    isEdit.value = edit
    isOpen.value = true
}

const deleteItem = (itemId: number) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Delete this payout item?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result: any) => {
        if (result.isConfirmed) {
            CarerPayoutItemService.delete(itemId)
                .then(() => {
                    addToastNotification({ message: `Payout item has been deleted.`, type: 'success' })
                    fetchData()
                })
                .catch(() => {
                    addToastNotification({
                        message: `Internal server error. Please contact tech team if the error persists.`,
                        type: 'danger'
                    })
                })
        }
    })
}

const closeModal = () => {
    selectedItem.value = null
    isOpen.value = false
}

fetchData()

</script>

<style scoped>
td {
    font-size: 13px;
}

th {
    vertical-align: top;
}
</style>