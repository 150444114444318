<template>
    <div class="card">
        <div class="card-header pb-0">
            <ul class="nav nav-bordered w-100 border-bottom-0">
                <li class="nav-item" v-for="(view, index) in views" :key="index">
                    <a href="#" role="button" class="nav-link pb-3" @click="toggleView(view.slug, view.component)" :class="{ 'active': selectedViewComponent.slug === view.slug}">{{ view.name }}</a>
                </li>
            </ul>
        </div>

        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <input type="file" accept=".csv" ref="fileInput" id="file" @change="handleFileChange" hidden>
                    <button type="button" class="btn btn-teal float-right me-3" @click="openFileInput">
                        <icon name="upload" />
                        Add Bulk Holiday
                    </button>
                    <router-link class="btn btn-primary float-right" :to="{ name: 'holiday-create' }">
                        <icon name="plus" />
                        Add New Holiday
                    </router-link>
                </div>
            </div>

            <component :is="selectedViewComponent.component"></component>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue'
import HolidayTableView from './HolidayTableView.vue'
import HolidayCalendarView from './HolidayCalendarView.vue'
import HolidayService from '@/services/settings/HolidayService'
import { useNotificationsStore } from '@/stores/notifications'

const fileInput = ref(null) as Ref<HTMLInputElement | null>

const { addToastNotification } = useNotificationsStore()

const selectedViewComponent = ref<any>({
    slug: 'table',
    component: HolidayTableView,
})

const views = [
    {
        name: 'Table View',
        slug: 'table',
        component: HolidayTableView,
    },
    {
        name: 'Calendar View',
        slug: 'calendar',
        component: HolidayCalendarView,
    },
]

function toggleView(slug: string, view: any) {
    selectedViewComponent.value.slug = slug
    selectedViewComponent.value.component = view
}

const openFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click()
    }
}

const handleFileChange = (event: Event) => {
    if (event.target instanceof HTMLInputElement) {
        const selectedFile = event.target.files ? event.target.files[0] : null

        if (selectedFile) {
            const formData = new FormData()
            formData.append('file', selectedFile)

            event.target.value = ''

            HolidayService.upload(formData).then(() => {
                addToastNotification({
                    message: 'Your file is being processed. The data will be inserted.',
                    type: 'success',
                })
            }).catch(({ response: { data, status } }) => {
                let message = 'Internal server error. Please contact tech team.'

                if (status === 422) {
                    message = data.message
                }

                addToastNotification({
                    message: message,
                    type: 'danger',
                })
            })
        }
    }
}
</script>