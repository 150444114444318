<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">2. Add Custom Rates </h4>
        </div>
        <div class="card-body">
            <ul class="px-0 mt-3 ">
                <li v-for="session in orderPreview.sessions"
                    class="list-unstyled mb-3"
                    :key="`session-rates-${session.no}`"
                >
                    <div class=" py-2">
                        <div class="pb-3 fw-bold">
                            Session {{ session.no }}
                        </div>
                        <CustomSessionRateAmounts :session-id="session.id"/>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">

import CustomSessionRateAmounts from '@/pages/orders/create/components/CustomSessionRateAmounts.vue'
import {useCreateOrderStore} from '@/stores/createOrder'
import {storeToRefs} from 'pinia'

const createOrderStore = useCreateOrderStore()

const {
    form,
    errors,
    mode,
    selectedCustomer,
    orderPreview,
    isGeneratingPreview
} = storeToRefs(createOrderStore)

</script>