<template>
    <router-link :to="{ 'name': 'orders-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Order</span>
    </router-link>

    <overlay-spinner :is-showing="isFetchingOrder" v-if="isFetchingOrder"/>
    <template v-else>
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col">
                    <div class="d-flex space-x-2 align-items-center">
                        <h2 class="page-title">{{ order.no }}</h2>
                        <span class="badge badge-pill" :class="getStatusBadge(order.status)">{{
                                getStatusLabel(order.status, orderStatuses)
                            }}</span>
                    </div>
                    <div class="page-subtitle">
                        <div class="row">
                            <div class="col-auto">
                                <icon name="calendar" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">{{
                                        formatDate(order.createdAt, 'DD-MMM-YYYY hh:mm:ss A')
                                    }}</span>
                            </div>
                            <div class="col-auto">
                                <icon name="user" :stroke-width="1.2" class="pe-2"/>
                                <span>
                                    <UserNameId :user="order.user" :role="RoleNameEnum.USER"/>
                                </span>
                            </div>
                            <div class="col-auto">
                                <icon name="phone" :stroke-width="1.2" class="pe-2"/>
                                <span class="text-reset">{{ order.user.mainProfile?.mobileNo ?? 'N/A' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto d-none d-md-flex">
                    <div class="dropdown position-static">
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                            Action
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" style="width: 280px;">
                            <order-whatsapp-template :order="order">
                                <template #action >
                                    <div class="dropdown-item">
                                        <icon name="brand-whatsapp" class="me-2 text-green"/>
                                        Order Whatsapp Template
                                    </div>
                                </template>
                            </order-whatsapp-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cards mt-2 mb-3">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Amount Invoiced</h4>
                                <h1 class="fw-normal mb-0">{{ toMYR(order.grandTotalAmount) }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="file-invoice" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h4>Amount Paid</h4>
                                <h1 class="fw-normal mb-0">{{ toMYR(order.totalPaidPayment) }}</h1>
                            </div>
                            <span class="bg-blue-lt text-center rounded-circle pt-2" style="height:48px; width: 48px">
                                <icon name="file-dollar" :size="32" :stroke-width="1.2" class="text-blue"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

        <div class="py-4">
            <KeepAlive>
                <component :is="activeComponent.component"
                           @deleted="fetchOrder"
                           @updated-or-created="fetchOrder"
                           v-bind="activeComponent.props"
                           :key="`${activeComponent.slug}-${Date.now()}`">
                </component>
            </KeepAlive>
        </div>
    </template>
</template>

<script setup lang="ts">
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import SubMenu from '@/components/SubMenu.vue'
import {toMYR} from '@/composable/useCurrency'
import {formatDate} from '@/composable/useDate'
import {getStatusBadge, getStatusLabel} from '@/composable/useOrder'
import OrderService from '@/services/OrderService'
import {useNotificationsStore} from '@/stores/notifications'
import {useResourcesStore} from '@/stores/resources'
import UserFilter from '@/types/Filter'
import Order from '@/types/Order'
import {storeToRefs} from 'pinia'
import {defineAsyncComponent, provide, ref} from 'vue'
import {useRoute} from 'vue-router'
import OrderWhatsappTemplate from './OrderWhatsappTemplate.vue'
import UserNameId from '@/components/UserNameId.vue'
import {RoleNameEnum} from '@/enums/RoleEnum'


const {addToastNotification} = useNotificationsStore()
const {orderStatuses} = storeToRefs(useResourcesStore())

provide('setActiveComponent', setActiveComponent)

const route = useRoute()
const order = ref<Order>()
const isFetchingOrder = ref<boolean>(false)
const orderId = Number(route.params.orderId)
const activeComponent = ref<object>({})
const subMenus = ref<Array<any>>([])

const inputFilter = ref<UserFilter>({
    orderId: orderId
})

function fetchOrder(reloadComponentSlug: string = '') {
    isFetchingOrder.value = (reloadComponentSlug === '')

    OrderService.show(orderId)
        .then(({data: {data}}) => {
            order.value = data
            configureSubMenu()
            setActiveComponent(reloadComponentSlug)
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        })
        .finally(() => {
            isFetchingOrder.value = false
        })
}

fetchOrder()

function setActiveComponent(menu: any = null) {
    if (typeof menu === 'string' && menu.length !== 0) {
        activeComponent.value = subMenus.value.find(
            (subMenu) => subMenu.slug === menu
        )
    } else if (typeof menu === 'object') {
        activeComponent.value = menu
    } else {
        activeComponent.value = subMenus.value[0]
    }
}

function configureSubMenu() {
    subMenus.value = [
        {
            title: 'Overview',
            component: defineAsyncComponent(
                () => import('./OrderOverview.vue')
            ),
            slug: 'order-overview',
            props: {order: order}
        },
        {
            title: 'Payments',
            component: defineAsyncComponent(() => import('./OrderPayment.vue')),
            slug: 'order-payment',
            props: {order: order, payments: order.value?.payments}
        }
    ]

    if (order.value?.isPrimary) {
        subMenus.value.push({
            title: 'Sessions',
            component: defineAsyncComponent(() => import('./OrderSession.vue')),
            slug: 'order-session',
            props: {order: order, sessions: order.value?.sessions}
        })
    }

    subMenus.value.push(
        {
            title: 'Activity Logs',
            component: defineAsyncComponent(() => import('@/pages/reports/order/OrderActivityLog.vue')),
            slug: 'order-activity-log',
            props: {activityLogs: order.value?.activityLogs}
        }
    )
}
</script>