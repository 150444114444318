import {ref} from 'vue'
import ErrorBag from '@/types/ErrorBag'
import {helpers, numeric, required, requiredIf} from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{}
})

export const rules = (mode: string) => ({
    inputUser: {
        customer: {
            required: helpers.withMessage(
                'This customer field is required',
                required
            )
        },
        start_time: {
            required: helpers.withMessage(
                'This start time field is required',
                required
            )
        },
        duration: {
            required: helpers.withMessage(
                'This duration field is required',
                required
            ),
            numeric: helpers.withMessage(
                'This duration field must be a number',
                numeric
            )
        },
        address: {
            required: helpers.withMessage(
                'This address field is required',
                required
            )
        },
        children: {
            minLength: helpers.withMessage(
                'This children field is required at least 1',
                required
            )
        },
        assigned_carer: {
            required: helpers.withMessage(
                'This assigned carer field is required',
                required
            )
        },
        session_type: {
            required: helpers.withMessage(
                'This session type field is required',
                required
            )
        },
        session_service_type: {
            required: helpers.withMessage(
                'This session service type field is required',
                required
            )
        }
    }
})
