<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <a class="btn btn-icon btn-azure" role="button" data-bs-toggle="modal"
                       data-bs-target="#modal-add-chat-admin">
                        <icon name="plus"/>
                    </a>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                              @per-page-updated="updatePerPage" :is-showing-per-page="true"/>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="w-50">Name</th>
                            <th>Date Added</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="chatAdmins.length > 0">
                        <tr v-for="admin in chatAdmins" :key="admin.userId">
                            <td>{{ admin.userName }}</td>
                            <td>{{ formatDate(admin.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <button class="btn btn-icon btn-danger" @click.prevent="removeAdmin(admin)">
                                        <icon name="trash"/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="3" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingAdmins"/>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                              @per-page-updated="updatePerPage"/>
        </div>
        <chat-admin-form-modal @chat-admin-added="fetchAdmins"></chat-admin-form-modal>
    </div>
</template>

<script setup lang='ts'>
import { ref, watch } from 'vue'
import ChatAdminService from '@/services/settings/ChatAdminService'
import {useNotificationsStore} from '@/stores/notifications'
import useMetaPage from '@/composable/useMetaPage'
import ChatAdmin from '@/types/ChatAdmin'
import { formatDate } from '@/composable/useDate'
import ChatAdminFormModal from './ChatAdminFormModal.vue'

const {addToastNotification} = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const isFetchingAdmins = ref<boolean>(false)
const chatAdmins = ref<Array<ChatAdmin>>([])

async function fetchAdmins() {
    setIsFetchingAdmins()
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
    }
    try {
        const response = await ChatAdminService.index(query)

        chatAdmins.value = response.data.data;

        updateMetaPage(response.data.meta)
    } catch (err) {
    }
    setIsFetchingAdmins(false)
}

function removeAdmin(admin: ChatAdmin) {

    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to remove ${admin.userName} from chat admins?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            ChatAdminService.destroy(admin.userId)
                .then(() => {
                    fetchAdmins()
                    addToastNotification({
                        message: `Admin ${admin.userName} removed from chat admins`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}

function setIsFetchingAdmins(value = true) {
    isFetchingAdmins.value = value
}

watch(
    () => metaPageTriggered.value,
    () => {
        fetchAdmins()
    }
)

fetchAdmins()

</script>