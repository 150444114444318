import {onMounted} from "vue";
import Modal from "bootstrap/js/dist/modal";

export function useModal(modalId: string) {

    let modalElement: HTMLElement | null = null
    let modal: Modal | null = null

    onMounted(() => initModal())

    const initModal = () => {
        modalElement = document.getElementById(modalId)

        if (!modalElement) {
            return
        }

        modal = new Modal(modalElement)
    }

    const openModal = (open = true) => open ? modal?.show() : modal?.hide()

    const onModalShown = (callback: any) => {
        onMounted(() => modalElement?.addEventListener('shown.bs.modal', callback))
    }

    const onModalHidden = (callback: any) => {
        onMounted(() => modalElement?.addEventListener('hidden.bs.modal', callback))
    }

    return {
        modalId,
        openModal,
        onModalShown,
        onModalHidden
    }

}