<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Introduction</h4>
            <CopyButton :data="scriptIntroduction?.innerText ?? undefined"
                        label="Copy"
            />
        </div>
        
        <div class="card-body">
<pre style="max-height: 400px" id="scriptIntroduction" ref="scriptIntroduction">
Dear **customer**,

Welcome to the service channel for your upcoming booking! This channel has been created to facilitate communication and coordination between you and your assigned Kiddocarer. Here, you can discuss any specific requirements, share important information, and address any questions or concerns you may have regarding the booking session.

Please ensure to use this channel for all communication related to the booking to ensure a smooth and efficient process. If you have any issues or need assistance, feel free to reach out to us directly here.

Dear Mr/Mrs client, please meet your Kiddocarer ID-XX **carer** . Please share a direct link from Waze or Google Maps of your booking location in this chat for the carer to refer to. Alternatively, you can provide directions or landmarks for reference.

We hope you have a wonderful experience with Kiddocare!

Best regards,
Kiddocare Team
</pre>
    </div>
</div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import CopyButton from '@/components/CopyButton.vue'

const scriptIntroduction = ref<HTMLElement | null>(null)

</script>
