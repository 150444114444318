<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Date Range</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range" readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar"/>
                        </span>
                    </div>
                </div>
                
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchJobs" :is-loading="isLoading"></button-filter>
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button class="btn btn-primary" @click="generateReport">
                        <icon name="file-spreadsheet"/>
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Job Date</th>
                            <th>Hours</th>
                            <th>Job Status</th>
                            <th>Job No</th>
                            <th>Discount Code</th>
                            <th>Customer</th>
                            <th>Customer ID</th>
                            <th>Customer Address</th>
                            <th>Customer Postcode</th>
                            <th>Customer City</th>
                            <th>Customer State</th>
                            <th>Carer</th>
                            <th>Carer ID</th>
                            <th>Carer Address</th>
                            <th>Carer Postcode</th>
                            <th>Carer City</th>
                            <th>Carer State</th>
                            <th>Expected Received (RM)</th>
                            <th>Expected Payout (RM)</th>
                        </tr>
                    </thead>
                    <tbody v-if="jobs.length > 0">
                        <tr v-for="job in jobs" :key="job.index">
                            <td>{{ formatDate(job.startedAt, 'DD-MMM-YYYY') }}</td>
                            <td class="text-center">{{ difference(job.endedAt, job.startedAt, 'h')  }}</td>
                            <td class="text-center"><span class="badge badge-pill" :class="getJobStatusBadge(job.status)">{{ _.startCase(job.status) }}</span></td>
                            <td>{{ job.no }}</td>
                            <td class="text-center">{{ job.booking.discountCode ?? 'N/A' }}</td>
                            <td>{{ job.booking.user.mainProfile?.fullName }}</td>
                            <td>{{ job.booking.user.mainProfile?.onlineId }}</td>
                            <td>{{ getAddress(job.address, false) }}</td>
                            <td class="text-center">{{ job.address.postcode }}</td>
                            <td>{{ job.address.town }}</td>
                            <td>{{ job.address.state }}</td>
                            <td>{{ job.carer?.mainProfile?.fullName }}</td>
                            <td>{{ job.carer?.mainProfile?.onlineId }}</td>
                            <td>{{ job.carer ? getAddress(job.carer.primaryAddress, false) : '' }}</td>
                            <td class="text-center">{{ job.carer?.primaryAddress.postcode }}</td>
                            <td>{{ job.carer?.primaryAddress.town }}</td>
                            <td>{{ job.carer?.primaryAddress.state }}</td>
                            <td>{{ toMYR(job.totalAmount)  }}</td>
                            <td>{{ toMYR(job.totalAmountPay)  }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="17" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isLoading" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { Core, easepick } from '@easepick/core'
import { formatDate, dateObject, difference } from '@/composable/useDate'
import { getJobStatusBadge, getAddress } from '@/composable/useJob'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import useDateRangePicker from '@/composable/useDateRangePicker'
import ReportService from '@/services/ReportService'
import { useResourcesStore } from '@/stores/resources'
import { toMYR } from '../../composable/useCurrency'

const { jobStatuses } = storeToRefs(useResourcesStore())
const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
const jobs = ref<Array<any>>([])
const inputFilter = ref<UserFilter>({
    from_date: dateObject().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: dateObject().endOf('month').format(DEFAULT_DATE_FORMAT),
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { easePickDefaultOptions } = useDateRangePicker()

const isLoading = ref<boolean>(false)

fetchJobs()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchJobs()
    }
)

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value = {
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT),
                }
                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})

function resetFilter () {
    inputFilter.value = {
        from_date: dateObject().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: dateObject().endOf('month').format(DEFAULT_DATE_FORMAT),
    }

    fetchJobs()
}

function fetchJobs () {
    isLoading.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    ReportService.getJobs(query)
        .then(({ data: { data, meta } }) => {
            jobs.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isLoading.value = false
        })
}

function generateReport () {
    isLoading.value = true
    const query = {
        ...inputFilter.value,
    }

    ReportService.generateReport('jobs', query)
        .then(({ data: { downloadUrl } }) => window.open(downloadUrl, '_blank'))
        .finally(() => {
            isLoading.value = false
        })
}
</script>
