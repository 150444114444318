<template>
    <div :class="cardClass">
        <div class="card mb-xl-0 p-1"
             :class="isHighlight ? 'border-primary border-2' : ''"
        >
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <div>
                        <h4 class=" text-uppercase" :class="textColor">{{ title }}</h4>
                        <h1 class="fw-normal mb-0" :class="textColor">{{ stats }}</h1>
                    </div>
                    <slot name="icon"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed} from "vue";

const props = defineProps({
    title: {type: String, required: true},
    stats: {type: Number, default: 0},
    cardClass: {type: String, default: ''},
    isHighlight: {type: Boolean, default: false}
})

const textColor = computed(() => props.isHighlight ? 'text-primary' : 'text-black-50')
</script>

<style scoped>
:slotted(.icon) {
    width: 3rem;
    height: 3rem;
}

:slotted(.icon-shape) {
    display: inline-flex;
    padding: 12px;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
</style>