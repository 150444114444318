<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">Location Service Instruction</h4>
            <button class="btn btn-default" @click="copy('scriptLocation')">
                <icon name="copy"/>
                <span>Copy</span>
            </button>
        </div>
        
        <div class="card-body">
<pre style="max-height: 400px" id="scriptLocation">
Dear Maam/Sir, please share a direct link from Waze/Google Maps of your booking location in this chat for the carer to refer to. Or, please provide directions, or landmarks for reference.
</pre>
    </div>
</div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

const copy = inject('copy')

</script>
