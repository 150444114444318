<template>
    <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table ">
            <thead>
            <tr>
                <th class="text-left">Name</th>
                <th class="text-center">Status</th>
                <th class="text-center">Travelling Tips</th>
                <th>Applied At</th>
                <th>Cancelled At</th>
                <th>Cancel Reason</th>
            </tr>
            </thead>
            <tbody v-if="cancelledCarerApplications?.length">
            <tr v-for="application in cancelledCarerApplications" :key="application.carer?.id">
                <td>
                <span v-if="application.carer">
                    <UserNameId :user="application.carer" :role="RoleNameEnum.CARER"/>
                    <span v-if="application.isPreferred" class="ms-1">
                        <icon name="star"/>
                    </span>
                </span>
                </td>
                <td class="text-center">
                    <SessionCarerApplicationStatus :application="application"
                                                   @status-changed="fetchCarerApplications"
                    />
                </td>
                <td class="col text-center">
                    <div>
                        {{ application.additionalFee ? toMYR(application.additionalFee) : 'N/A' }}
                    </div>
                    <div>
                        {{ application.additionalFeeReason ? `(${ application.additionalFeeReason })` : '' }}
                    </div>
                </td>
                <td>{{ formatDate(application.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                <td>{{ formatDate(application.cancelledAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                <td>{{ application.cancelReason }}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
                <td colspan="8" class="text-center">No data</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">

import {useSessionCarerApplicationStore} from '@/stores/sessionCarerApplication'
import {storeToRefs} from 'pinia'
import {getApplicationStatusBadge, getApplicationStatusLabel} from '@/composable/useSessionCarerApplicationStatus'
import {toMYR} from '@/composable/useCurrency'
import {useResourcesStore} from '@/stores/resources'
import UserNameId from '@/components/UserNameId.vue'
import {RoleNameEnum} from '@/enums/RoleEnum'
import {formatDate} from '@/composable/useDate'
import SessionCarerApplicationStatus from '@/components/SessionCarerApplication/SessionCarerApplicationStatus.vue'

const {sessionCarerApplicationStatuses} = storeToRefs(useResourcesStore())

const store = useSessionCarerApplicationStore()
const {fetchCarerApplications} = store
const {cancelledCarerApplications} = storeToRefs(store)
</script>