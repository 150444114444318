import { ref, computed } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import UserInput from '@/types/Input'
import { required, requiredIf, email, sameAs, minLength, maxLength, helpers } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => {
    return computed(() => {
        return {
            inputUser: {
                code: {
                    required: helpers.withMessage(
                        'This code field is required',
                        required
                    )
                },
                name: {
                    required: helpers.withMessage(
                        'This name field is required',
                        required
                    )
                },
                discount_amount: {
                    required: helpers.withMessage(
                        'This discount amount field is required',
                        required
                    )
                }
            },
        }
    })
}