<template>
    <a class="d-flex list-group-item list-group-item-action d-flex align-items-start border-bottom" 
        :class="{ 'active': currentChannel?.url === channel.url }" style="border-radius: 0" role="button" 
        @click.prevent="setCurrentChannel(channel)">
        <div class="flex-grow-1 w-full">
            <div class="d-flex justify-content-between mb-1">
                <div v-if="channel.cachedMetaData.userType === 'customer'" class="badge badge-outline badge-pill badge-sm flex-shrink-0" style="letter-spacing: 0; color:#F48B29">Customer</div>
                <div v-else-if="channel.cachedMetaData.userType === 'carer'" class="badge badge-outline badge-pill badge-sm flex-shrink-0" style="letter-spacing: 0; color:#18D2C5">Carer</div>
                <small class="text-end text-muted fw-normal" v-if="channel.lastMessage">{{ formatDate(channel.lastMessage.createdAt, DEFAULT_DATE_FORMAT) }}</small>
            </div>
            <div class="d-flex align-items-center">
                <h4 class="mb-0" :class="{ 'fw-normal': currentChannel?.url !== channel.url }">{{ channel.name }}</h4>
                <span v-if="channel.unreadMessageCount"
                    class="ms-auto flex-shrink-0 d-flex justify-content-center align-items-center top-0 start-0 rounded-circle bg-red fs-5 fw-bolder border border-white"
                    style="width: 24px; height: 24px;">{{ channel.unreadMessageCount }}</span>
            </div>
            <template v-if="channel.lastMessage && !channel?.isTyping">
                <p v-if="channel.lastMessage.isUserMessage() || channel.lastMessage.isAdminMessage()" class="mb-0 fw-normal text-muted overflow-hidden" style="max-height: 40px">{{ channel.lastMessage.message }}</p>
                <p v-if="channel.lastMessage.isFileMessage()" class="mb-0 fw-normal text-muted overflow-hidden" style="max-height: 40px">
                    <icon :name="isImageFile(channel.lastMessage.type) ? 'photo' : 'file'" :stroke-width="1.2"/>
                    <span class="text-break">{{ channel.lastMessage.name }}</span>
                </p>
            </template>
            <p v-if="channel.lastMessage && channel?.isTyping" class="mb-0 fw-bold text-blue fst-italic">{{ channel?.getTypingUsers()[0].nickname }} is typing...</p>
            <p v-if="!channel.lastMessage && !channel?.isTyping" class="mb-0 fw-normal text-muted fst-italic">No message</p>
        </div>
    </a>
</template>

<script setup lang="ts">
import { GroupChannel } from '@sendbird/chat/groupChannel'
import { formatDate } from '@/composable/useDate'
import { inject } from 'vue'

const DEFAULT_DATE_FORMAT = 'DD MMM YYYY hh:mm:ss A'

const setCurrentChannel = <any>inject('setCurrentChannel')
const props = defineProps({
    currentChannel: { type: GroupChannel, required: true },
    channel: { type: GroupChannel, required: true }
})

const isImageFile = (type: string) => {
    const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png']
    return allowedExtensions.includes(type)
}
</script>