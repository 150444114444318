<template>
    <router-link :to="redirectTo" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left"/>
        <span>Back to Booking</span>
    </router-link>

    <job-form :booking-id="bookingId" :redirect-to="redirectTo"></job-form>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import JobForm from '@/pages/jobs/JobForm.vue'

const route = useRoute()
const bookingId = Number(route.params.bookingId)
const redirectTo = {
    name: 'booking-show',
    params: { bookingId: bookingId },
}
</script>