import httpClientV2 from './HttpClientV2'

export default {
    store(carerApplicationId: number) {
        return httpClientV2.post(`/backoffice/carer-applications/${carerApplicationId}/negotiation-approval`)
    },
    destroy(carerApplicationId: number) {
        return httpClientV2.delete(`/backoffice/carer-applications/${carerApplicationId}/negotiation-approval`)
    }
}
