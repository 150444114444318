<template>
    <kc-modal modal-id="bank-form-modal" :modal-keyboard="false" :modal-title="modalTitle">
        <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': v$.name.$error }" v-model="name"
                @input="clearError" />
            <div class="invalid-feedback">{{ v$.name.$errors[0]?.$message }}</div>
        </div>
        <template #footer>
            <button type="button" id="close-bank-form-modal-button" class="btn btn-link link-secondary"
                data-bs-dismiss="modal">
                Close
            </button>
            <button class="btn btn-primary ms-auto" :class="{ disabled: isSaving }" type="submit" @click="save">
                <span v-if="isSaving" class="spinner-border spinner-border-sm me-2" role="status"></span>
                {{ isSaving ? 'Saving' : 'Save' }}
            </button>
        </template>
    </kc-modal>
</template>

<script setup lang="ts">
import BankService from '@/services/BankService'
import { useNotificationsStore } from '@/stores/notifications'
import Bank from '@/types/Bank'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { AxiosError } from 'axios'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{
    bank?: Bank
}>()

const emit = defineEmits<{
    (e: 'save'): void
}>()

const { addToastNotification } = useNotificationsStore()

const name = ref<string>('')
const isSaving = ref<boolean>(false)
const $externalResults = ref({})

const mode = computed(() => props.bank ? 'updated' : 'created')
const modalTitle = computed(() => mode.value === 'created' ? 'Add New Bank' : 'Edit Bank')
const notificationMessage = computed(() => `${name.value} is successfully ${mode.value}`)

const rules = computed(() => ({
    name: {
        required: helpers.withMessage(
            'The name field is required',
            required
        )
    }
}))

const v$ = useVuelidate(rules, { name }, { $scope: false, $externalResults })

const save = async () => {
    $externalResults.value = {}
    const validated = await v$.value.$validate()

    if (!validated) return

    isSaving.value = true

    try {
        switch (mode.value) {
            case 'created':
                await BankService.store({ name: name.value })
                break
            default:
                if (props.bank === undefined) {
                    addToastNotification({
                        type: 'danger',
                        message: 'Bank info cannot be loaded.'
                    })

                    return
                }

                await BankService.update(props.bank.id, { name: name.value })
                break
        }

        document.getElementById('close-bank-form-modal-button')?.click()

        emit('save')

        addToastNotification({
            type: 'success',
            message: notificationMessage.value
        })
    } catch (error) {
        handleError(error)
    }

    isSaving.value = false
}

const handleError = (error: any) => {
    if (error instanceof AxiosError && error.response && error.response.status === 422) {
        Object.assign($externalResults.value, error.response.data.errors)
    } else {
        addToastNotification({
            type: 'danger',
            message: 'Internal server error. Please contach tech team.'
        })
    }
}

const clearError = () => {
    $externalResults.value = {}
}

onMounted(() => {
    document.getElementById('bank-form-modal')?.addEventListener('hidden.bs.modal', () => {
        v$.value.$reset()
        name.value = props.bank?.name ?? ''
    })
})

watch(
    () => props.bank,
    (bank) => {
        name.value = bank?.name ?? ''
    }
)

</script>