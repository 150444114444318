<template>
    <kc-modal modal-id="modal-edit-session" modal-size="xl">
        <div class="modal-header">
            <h4 class="modal-title">Session {{ session?.no }}</h4>
            <button id="btn-modal-edit-session-close" type="button" class="btn-close" data-bs-dismiss="modal"
                    data-bs-target="#modal-edit-session" aria-label="Close"></button>
        </div>
        <div class="modal-body bg-white">
            <!--  Original Details  -->
            <div class="row">
                <div class="col-12">
                    <h3>Original</h3>
                </div>
                <div class="col-2">
                    <form-label label="Date"/>
                    <div class="mt-2">{{ session.startedAtDate }}</div>
                    <div>({{ session.startedAtDay }})</div>
                </div>
                <div class="col-2">
                    <form-label label="Time"/>
                    <div class="mt-2">{{ session.startedAtTime }} - {{ session.endedAtTime }}</div>
                    <div>({{ session.totalHours }} hours)</div>
                </div>
                <div class="col-4">
                    <form-label label="Address"/>
                    <p v-if="session.address" class="mt-2">{{ getFullAddress(session.address, addressStates, postcodes) }}</p>
                    <p v-if="session.address">Remarks: {{ session.address.remarks }}</p>
                </div>
                <div class="col-4">
                    <form-label label="Dependents"/>
                    <ol>
                        <li v-for="dependent in session.dependents" :key="`dependent-${dependent.id}`">
                            {{ dependent.name }}
                        </li>
                    </ol>
                </div>
            </div>

            <!--  Form: Update Session -->
            <form>
                <div class="row">
                    <div class="col-4">
                        <div class=" mb-3">
                            <form-label label="Date"/>
                            <div class="input-group">
                                <input type="text" id="input-modal-session-form-date" class="form-control bg-white"
                                       :class="{'is-invalid': !!errors.started_at}" :value="updatedDateDisplay"
                                       readonly>
                                <span class="input-group-text"><icon name="calendar"/></span>
                            </div>
                            <error-message :has-error="!!errors.started_at" :message="errors.started_at?.[0]"/>
                            <helper-text text="Leave blank if no changes needed"/>
                        </div>
                        <div class="col">
                            <form-label label="Duration (Hours)"/>
                            <input class="form-control" :class="{'is-invalid': !!errors.duration}"
                                   v-model.number="input.duration_hours"
                                   @input="errors.duration_hours = null"
                                   type="number" min="2" max="24"
                            >
                            <error-message :has-error="!!errors.duration_hours" :message="errors.duration_hours?.[0]"/>
                            <helper-text text="Leave blank if no changes needed"/>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="d-flex justify-content-between">
                            <form-label label="Address"/>
                            <icon name="address-book" role="button" class="text-cyan"
                                  @click.prevent="openAddressModal"
                            />
                        </div>
                        <vue-select :options="addressOptions" label="name" v-model="input.address_id"
                                    :reduce="(address: any) => address.id"/>
                        <textarea class="form-control bg-white mt-2" style="resize: none" rows="5" readonly
                                  :value="updatedAddressDisplay"></textarea>
                        <helper-text text="Leave blank if no changes needed"/>
                    </div>

                    <div class="col-4">
                        <div class="d-flex justify-content-between">
                            <label class="form-label col-form-label">Dependent</label>
                            <icon name="mood-kid" class="text-cyan" role="button" @click.prevent="openDependentModal"/>
                        </div>
                        <template v-for="dependent in dependentOptions" :key="`input-dependent-${dependent.id}`">
                            <label class="form-check">
                                <input type="checkbox" class="form-check-input" @input="updateDependent"
                                       :value="dependent.id"/>
                                {{ dependent.name }}
                            </label>
                        </template>
                        <helper-text text="Leave blank if no changes needed"/>
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer pt-3 border-top">
            <button class="btn btn-light" type="button" data-bs-dismiss="modal" data-bs-target="#modal-edit-session">
                Close
            </button>
            <button class="btn btn-primary" @click.prevent="save" :disabled="isSavingSession">
                <span v-if="isSavingSession">Saving...</span>
                <span v-else>Save</span>
            </button>
        </div>
    </kc-modal>
    <child-modal :user-id="session.customerUserId"/>
    <address-modal :user-id="session.customerUserId"/>
</template>

<script setup lang="ts">

import ErrorMessage from '@/components/form/ErrorMessage.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import HelperText from '@/components/form/HelperText.vue'
import { dateObject, formatDate } from '@/composable/useDate'
import { getFullAddress } from '@/composable/useSession'
import { DependentTypeEnum } from '@/enums/DependentTypeEnum'
import { SessionTypeEnum } from '@/enums/SessionTypeEnum'
import AddressModal from '@/pages/address/AddressModal.vue'
import ChildModal from '@/pages/child/ChildModal.vue'
import AddressService from '@/services/AddressService'
import DependentService from '@/services/DependentService'
import SessionService from '@/services/SessionService'
import { useResourcesStore } from '@/stores/resources'
import { useNotificationsStore } from '@/stores/notifications'
import Address from '@/types/Address'
import Child from '@/types/Child'
import Session from '@/types/Session'
import { AmpPlugin } from '@easepick/amp-plugin'
import { Core, easepick } from '@easepick/core'
import { TimePlugin } from '@easepick/time-plugin'
import { AxiosError } from 'axios'
import Modal from 'bootstrap/js/dist/modal'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

const {addToastNotification} = useNotificationsStore()
const {addressStates, postcodes} = storeToRefs(useResourcesStore())

type InputType = {
    started_at: string | null
    duration_hours: number | null
    address_id: number | null
    dependent_ids: number[]
}

const emit = defineEmits(['sessionUpdated', 'updated-or-created'])

const props = defineProps({
    session: {type: Object as () => Session, required: true},
    fromOrder: {type: Boolean, default: false}
})

const isFetchingAddresses = ref<boolean>(false)
const isFetchingDependents = ref<boolean>(false)
const isSavingSession = ref<boolean>(false)

const addressOptions = ref<Array<Address>>([])
const dependentOptions = ref<Array<Child>>([])


let modalElement: HTMLElement | null = null
let btnCloseModal: HTMLElement | null = null
let modalDependentBootstrap: Modal | null = null
let modalAddressBootstrap: Modal | null = null
let sessionDatePicker: Core | null = null

const updatedDateDisplay = computed(() => input.value.started_at ? formatDate(input.value.started_at, 'DD-MMM-YYYY hh:mm A') : '')
const updatedAddressDisplay = computed(() => {
    const address = addressOptions.value.find(address => address.id === input.value.address_id)

    if (address) {
        const fullAddress = getFullAddress(address, addressStates.value, postcodes.value)
        return `${fullAddress}\n\nRemarks: ${address.remarks ?? ''}`
    }

    return ''
})

const input = ref<InputType>({
    started_at: null,
    duration_hours: null,
    address_id: null,
    dependent_ids: []
})

const errors = ref<any>({
    started_at: null,
    duration_hours: null,
    address_id: null,
    dependent_ids: []
})

const save = async () => {
    if (input.value.started_at && ! input.value.duration_hours) {
        errors.value.duration_hours = ['Please input duration hours']
        return
    }

    if (! input.value.started_at && input.value.duration_hours) {
        errors.value.started_at = ['Please select session date']
        return
    }

    try {
        isSavingSession.value = true

        const data = formattedData()

        await SessionService.update(props.session.id, data)
        resetInputs()
        resetOptions()
        if (props.fromOrder) {
            emit('updated-or-created', 'order-session')
        } else {
            emit('sessionUpdated', {
                id: props.session.id,
                ...data
            })
        }
        
        btnCloseModal?.click()
        addToastNotification({
            message: `Session updated.`,
            type: 'success'
        })
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.status === 422) {
            const responseData = error.response.data as { errors?: Record<string, string[]> }
            errors.value = responseData.errors
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }

    } finally {
        isSavingSession.value = false
    }
}

const formattedData = () => {
    let data: {
        started_at?: string
        duration_hours?: number
        address_id?: number
        dependent_ids?: Array<number>
    } = {}

    if (input.value.address_id) {
        data.address_id = input.value.address_id
    }

    if (input.value.dependent_ids.length > 0) {
        data.dependent_ids = input.value.dependent_ids
    }

    if (input.value.started_at && input.value.duration_hours) {
        data.started_at = input.value.started_at
        data.duration_hours = input.value.duration_hours
    }

    return data
}

const openAddressModal = () => {
    btnCloseModal?.click()
    modalAddressBootstrap?.show()
}

const openDependentModal = () => {
    btnCloseModal?.click()
    modalDependentBootstrap?.show()
}

onMounted(() => {
    initModalElement()

    modalDependentBootstrap = new Modal('#modal-child-form')
    modalAddressBootstrap = new Modal('#modal-address-form')

    initDatePicker()
})

const initModalElement = () => {
    modalElement = document.getElementById('modal-edit-session')
    btnCloseModal = document.getElementById('btn-modal-edit-session-close')

    if (!modalElement) {
        return
    }

    modalElement?.addEventListener('shown.bs.modal', () => {
        initOptions()
    })

    modalElement?.addEventListener('hidden.bs.modal', () => {
        resetInputs()
        resetOptions()
    })
}

const resetInputs = () => {
    input.value.started_at = null
    input.value.duration_hours = null
    input.value.address_id = null
    input.value.dependent_ids = []
}
const resetOptions = () => {
    addressOptions.value = []
    dependentOptions.value = []
}

const initOptions = async () => {
    addressOptions.value = await fetchAddresses()
    dependentOptions.value = await fetchDependents()
}

const fetchAddresses = async () => {
    isFetchingAddresses.value = true

    let addresses = []

    try {
        const {data} = await AddressService.index({user_id: props.session.customerUserId})
        addresses = data.data
    } catch (e) {

    }

    isFetchingAddresses.value = false

    return addresses

}

const fetchDependents = async () => {
    isFetchingDependents.value = true

    let dependents = []
    try {
        let dependentTypeId = 0

        if (props.session?.sessionTypeId === SessionTypeEnum.ID_CHILDCARE) {
            dependentTypeId = DependentTypeEnum.ID_CHILD
        } else if (props.session?.sessionTypeId === SessionTypeEnum.ID_ELDERCARE) {
            dependentTypeId = DependentTypeEnum.ID_ELDER
        }

        const {data} = await DependentService.index({user_id: props.session.customerUserId, dependent_type_id: dependentTypeId})
        dependents = data.data
    } catch (e) {

    }

    isFetchingDependents.value = false

    return dependents
}


const updateDependent = (e: any) => {
    const selectedId = parseInt(e.target.value)
    if (e.target.checked) {
        input.value.dependent_ids.push(selectedId)
    } else {
        input.value.dependent_ids = input.value.dependent_ids.filter((dependentId: number) => dependentId !== selectedId)
    }
}

const initDatePicker = () => {

    const ampPlugin: object = {
        AmpPlugin: {
            resetButton: () => {
                input.value.started_at = null
                return true
            }
        }
    }

    sessionDatePicker = new easepick.create({
        element: '#input-modal-session-form-date',
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css'
        ],
        autoApply: false,
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                errors.value.started_at = null
                input.value.started_at = e.detail.date.format('YYYY-MM-DD HH:mm')
                picker.hide()
            })
        },
        plugins: [AmpPlugin, TimePlugin],
        TimePlugin: {
            format12: true
        },
        ...ampPlugin
    })
}
</script>
