import UpdateAdditionalFeeFormData from '@/types/formData/UpdateAdditionalFeeFormData'
import httpClientV2 from './HttpClientV2'

export default {
    patch(carerApplicationId: number, data: UpdateAdditionalFeeFormData) {
        return httpClientV2.patch(`/backoffice/carer-applications/${carerApplicationId}/additional-fee`, data)
    },
    delete(carerApplicationId: number) {
        return httpClientV2.delete(`/backoffice/carer-applications/${carerApplicationId}/additional-fee`)
    }
}
