export enum CarerPayoutTypeEnum {
    TYPE_NORMAL = 1,
    TYPE_MYSTEP = 2,
    TYPE_KYOUTH2023 = 3,
}

export enum CarerPayoutStatusEnum {
    STATUS_GENERATING = 1,
    STATUS_DRAFT = 2,
    STATUS_CONFIRMED = 3,
}

export enum CarerPayoutScheduleEnum {
    SCHEDULE_WEEKLY = 1,
    SCHEDULE_MONTHLY = 2,
}
