<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right" :to="{ name: 'organization-create' }">
                        <icon name="plus" />
                        Add New Organization
                    </router-link>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Organization Name</label>
                    <input class="form-control" type="text" v-model="inputFilter.name" @keyup.enter="fetchOrganization">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchOrganization" :is-loading="isFetchingOrganizations"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Name</th>
                            <th>Registered At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="organizations.length > 0">
                        <tr v-for="organization in organizations" :key="organization.id">
                            <td><img :src="organization.logo_url" alt="Logo" width="50" /></td>
                            <td>{{ organization.name }}</td>
                            <td>{{ organization.createdAt }}</td>
                            <td class="text-center">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-primary" :to="{ name: 'organization-show', params: { organizationId: organization.id } }">
                                        <icon name="eye" />
                                    </router-link>
                                    <router-link class="btn btn-icon btn-success" :to="{ name: 'organization-edit', params: { organizationId: organization.id } }">
                                        <icon name="pencil" />
                                    </router-link>
                                    <button class="btn btn-icon btn-danger" @click.prevent="deleteOrganization(organization)">
                                        <icon name="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingOrganizations" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import UserFilter from '@/types/Filter'

import { useNotificationsStore } from '@/stores/notifications'
import useMetaPage from '@/composable/useMetaPage'
import OrganizationService from '@/services/settings/OrganizationService'
import Organization from '@/types/Organization'

const { addToastNotification } = useNotificationsStore()
const organizations = ref<Array<Organization>>([])
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const inputFilter = ref<UserFilter>({
    name: '',
    created_at: '',
})

const isFetchingOrganizations = ref<boolean>(false)

fetchOrganization()

watch(
    () => metaPageTriggered.value,
    () => {
        fetchOrganization()
    }
)

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchOrganization()
}

function fetchOrganization () {
    isFetchingOrganizations.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    OrganizationService.index(query)
        .then(({ data: { data, meta } }) => {
            organizations.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingOrganizations.value = false
        })
}

function deleteOrganization (organization: Organization) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete organization ${organization.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            let organizationId = Number(organization.id)

            OrganizationService.destroy(organizationId)
                .then(() => {
                    fetchOrganization()
                    addToastNotification({
                        message: `Organization ${organization.name} deleted`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}

</script>
